import React, { createRef } from "react";
import NormalSpin from "../../../base/basicComponents/spin";
import {
  downloadSingleFile,
  flowCategoryId,
} from "../../publicDictionaryValues";
import reasonEditDefaultIcon from "../../../assets/image/reasonEditIcon.png";
import styles from "../procedureFileProcess/style/index.module.scss";
import ProcessBase from "../../commonComponents/processBase/index.jsx";
import ApproveApplyJb from "./approveApplyJb";
import ApproveApplyForJbComplete from "./approveCompleteApplyJb";
import SubmitApplyForJbComplete from "./submitCompleteApplyJb";
import applyForJbService from "../../../service/applyForJb/applyForJbService";
import "./index.scss";
import LostPermissionModal from "../../sections/lostPermissionModal";

const procedureReplaceFileRef = createRef();
const procedureRef = createRef();
const procedureServerPathRef = createRef();
const procedureSavedInRef = createRef();
const approvePageRef = createRef();

export default class ApplyForJbProcess extends ProcessBase {
  constructor(props) {
    super(props);
    this.setGlobalVariables();
    this.initProcedureFileState();
    this.procedureFileStepID = null;
    this.initProcedureTableData = [];
  }

  /**
   * Set Component Global Variables
   */
  setGlobalVariables = () => {
    this.initSavedInSectionState = {
      savedInRadioValue: null,
    };

    this.primitiveSavedInSectionState = JSON.parse(
      JSON.stringify(this.initSavedInSectionState)
    );

    this.initServerPathSectionState = {
      serverPathValue: null,
    };

    this.primitiveServerPathSectionState = JSON.parse(
      JSON.stringify(this.initServerPathSectionState)
    );

    this.initProcedureFileTableState = {
      returnedList: [],
      fileTableJbStatus: null,
      fileTableReturned: false,
      fileTableReturnReasonValue: null,
      fileTableReasonStatus: null,
      attachment: {},
    };
  };

  initApplyJbPageState = () => {
    return {
      flowCategoryID: null,
      requestByID: null,
      requestByName: null,
      uploadProcedureFileNum: 0,
      procedureFileNum: 0,
      receiveDate: null,
      actionName: null,
      procedureStatus: null,
      isSaveDisabled: false,
      isCancelDisabled: false,
      isSubmitDisabled: false,
      isRejectDisabled: false,
      rejectReason: null,
      rejectStatus: null,
      currentRole: null,
      procedureFolder: {},
      loading: false,
      procedureStepStatus: null,
      procedureID: null,
      procedureProcessId: null,
      procedureStepID: null,
      procedureNote: "",
    };
  };

  /**
   * Init Component State
   */
  initProcedureFileState = () => {
    this.state = {
      pageState: this.initApplyJbPageState(),
      initTableDate: {},
      isReturnReasonLogOpen: false,
      ppoList: [],
      applyJBStatus: {},
      taskInfoData: {},
      procedureFileData: {},
      procedureInfoData: {},
      fileTableData: [],
      supportingTableData: [],
      procedureFileTableState: this.initProcedureFileTableState,
      savedInSectionState: this.initSavedInSectionState,
      serverPathSectionState: this.initServerPathSectionState,
      shareTaskData: {},
      tlReviewData: {},
      noPermission: false,
    };
  };

  setSavedInServerPathState = (result) => {
    this.setState({
      savedInSectionState: {
        ...this.state.savedInSectionState,
        savedInRadioValue:
          result.procedureFolder?.procedureSaveInCategoryID || null,
      },
      serverPathSectionState: {
        ...this.state.serverPathSectionState,
        serverPathValue: result.procedureFolder?.clientServerPath || null,
      },
    });
  };

  /* function about adjust data when get data from api */
  handleDataNull = (file, key) => {
    if (file[key] === null) {
      if (key === "procedureFileID") {
        return 0;
      } else {
        return null;
      }
    } else {
      return file[key];
    }
  };

  setApplyJbInitProcedureTableData = () => {
    this.procedureTableData.forEach((item) => {
      this.initProcedureTableData.push({
        data: JSON.parse(JSON.stringify(item.data)),
        checked: item.checked,
        isApproveDisabled: item.isApproveDisabled,
        isReturnDisabled: item.isReturnDisabled,
        isReturned: item.isReturned,
        isError: item.isError,
        replaceRef: item.replaceRef,
        attachmentEditIcon: reasonEditDefaultIcon,
        isDelete: false,
        isUpdate: false,
      });
    });
  };

  setProcedureFileData = (result) => {
    if (result.procedureFolder !== null) {
      this.procedureFileStepID = result.procedureFolder.procedureFileStepID;
      this.setProcedureTableData(result.procedureFolder);
      this.setApplyJbInitProcedureTableData();

      this.setState({ fileTableData: this.procedureTableData });
      this.setSavedInServerPathState(result);
    }
  };

  /**
   * Build SaveIn Section Data From ProcedureFolder
   * @param {*} data
   * @param {*} isRevise
   * @returns
   */
  buildSavedInSectionState = (data) => {
    return {
      savedInRadioValue: data.procedureSaveInCategoryID,
    };
  };

  /**
   * Build ServerPath Section Data From ProcedureFolder
   * @param {*} data
   * @param {*} isRevise
   * @returns
   */
  buildServerPathSectionState = (data) => {
    return {
      ...this.primitiveServerPathSectionState,
      serverPathValue: data.clientServerPath,
    };
  };

  componentDidMount() {
    this.getInitData(
      applyForJbService.getApplyJBStatusStepItem,
      "Get Procedure Files Failed"
    );
  }

  /* function about get data from procedure file table */
  returnApplyJbFileDataValue = (name, index) => {
    const procedureFileData = procedureRef.current.getDataList();
    let deleteList = procedureRef.current.getDeleteList();
    let combineDatalist = procedureFileData.concat(deleteList);
    return combineDatalist[index].data.find((item) => item.name === name);
  };

  /* function about return submit data to use in integrate trigger api */
  handleReturnSubmitData = (actionID, actionName) => {
    const fileDtos = [];
    if (procedureRef.current !== null) {
      const procedureFileData = procedureRef.current.getDataList();
      let deleteList = procedureRef.current.getDeleteList();
      let combineDatalist = procedureFileData.concat(deleteList);
      combineDatalist.forEach((item, index) =>
        fileDtos.push({
          fileID:
            this.returnApplyJbFileDataValue("newVersion", index).fileId === null
              ? this.returnApplyJbFileDataValue("fileId", index).value
              : this.returnApplyJbFileDataValue("newVersion", index).fileId,
          createdDate: this.returnApplyJbFileDataValue("uploadedDate", index)
            .value,
          uploadBy: this.returnApplyJbFileDataValue("uploadById", index).value,
          uploadByName: this.returnApplyJbFileDataValue("uploadByName", index)
            .value,
          fileName:
            this.returnApplyJbFileDataValue("newVersion", index).value === null
              ? this.returnApplyJbFileDataValue("fileName", index).value
              : this.returnApplyJbFileDataValue("newVersion", index).value,
          fileKey: this.returnApplyJbFileDataValue("fileKey", index).value,
          dataStatusID: this.returnApplyJbFileDataValue("status", index).value,
          attachmentFileID: this.returnApplyJbFileDataValue("attachment", index)
            .fileId,
          attachmentName: this.returnApplyJbFileDataValue("attachment", index)
            .value,
          returnReason: this.returnApplyJbFileDataValue("returnReason", index)
            .value,
          fileTagID: this.returnApplyJbFileDataValue("fileName", index)
            .tagValue,
          procedureFileStepID: this.returnApplyJbFileDataValue(
            "procedureFileStepID",
            index
          ).value,
          procedureFileID: this.returnApplyJbFileDataValue(
            "procedureFileID",
            index
          ).value,
          note: this.returnApplyJbFileDataValue("note", index).value,
          oldFileID:
            this.returnApplyJbFileDataValue("newVersion", index).fileId === null
              ? null
              : this.returnApplyJbFileDataValue("fileId", index).value,
          oldFileName:
            this.returnApplyJbFileDataValue("newVersion", index).value === null
              ? null
              : this.returnApplyJbFileDataValue("fileName", index).value,
          isDelete: item.isDelete,
          isUpdate: item.isUpdate,
        })
      );
    }
    let serverPathValue = null;

    if (procedureServerPathRef.current !== null) {
      const serverPathState =
        procedureServerPathRef.current.state.serverPathSectionState;
      serverPathValue = serverPathState.serverPathValue;
    }

    const { savedInRadioValue } =
      procedureSavedInRef.current.state.savedInSectionState;
    return {
      procedureId: this.procedureId,
      workflowProcessInstanceId: this.workflowProcessInstanceId,
      flowCategoryID: this.flowCategoryID,
      actionName: actionName,
      actionID: actionID,
      accountID: null,
      rejectReason:
        approvePageRef.current === null
          ? null
          : approvePageRef.current.state.pageState.rejectReason,
      procedureStepID: this.procedureStepID,
      clientServerPath: serverPathValue,
      procedureSaveInCategoryID: savedInRadioValue,
      isInitial: this.isRevisePage,
      procedureFiles: fileDtos,
      procedureFileStepID: this.procedureFileStepID,
    };
  };

  setApplyJbData = (result) => {
    this.setState({
      applyJBStatus: result.applyJBStatus,
    });
  };

  setSupportingFileData = (result) => {
    this.setSupportingTableData(result);
    this.setState({
      supportingTableData: this.supportingTableData,
    });
  };

  handleApplyJbPageProps = () => {
    const {
      receiveDate,
      requestByName,
      procedureStepStatus,
      procedureStatus,
      rejectStatus,
      currentRole,
      flowCategoryID,
    } = this.state.pageState;

    return {
      flowCategoryID,
      receiveDate,
      requestByName,
      procedureStatus,
      rejectStatus,
      currentRole,
      procedureReplaceFileRef,
      isDisableAll: true,
      procedureStepStatus,
    };
  };

  handleApplyJbSectionProps = () => {
    const { taskInfoData, procedureInfoData } = this.state;

    return {
      taskInfoData,
      procedureInfoData,
      ppoList: this.state.ppoList,
    };
  };

  handleApplyJbParams = () => {
    return {
      ProcedureTaskId: this.taskID,
      ProcedureID: this.procedureID,
      ProcedureStepID: this.procedureStepID,
      ProcedureFileStepID: this.procedureFileStepID,
      WorkflowProcessInstanceId: this.workflowProcessInstanceId,
      FlowCategoryID: this.flowCategoryID,
    };
  };

  handleApplyJbApprovePage = (procedureTableData) => {
    const { shareTaskData, applyJBStatus } = this.state;

    const { procedureProcessId } = this.state.pageState;

    const { savedInRadioValue } = this.state.savedInSectionState;
    const { serverPathValue } = this.state.serverPathSectionState;

    return (
      <ApproveApplyJb
        pageProps={{ ...this.handleApplyJbPageProps(), procedureProcessId }}
        sectionProps={this.handleApplyJbSectionProps()}
        savedInProps={{
          savedInRadioValue,
        }}
        serverPathProps={{
          serverPathValue,
        }}
        returnFileDataValue={this.returnApplyJbFileDataValue}
        handleReturnSubmitData={this.handleReturnSubmitData}
        initProcedureTableData={[...this.initProcedureTableData]}
        params={this.handleApplyJbParams()}
        shareTaskData={shareTaskData}
        fileTableData={procedureTableData}
        supportingTableData={this.state.supportingTableData}
        primitiveSavedInSectionState={this.primitiveSavedInSectionState}
        primitiveServerPathSectionState={this.primitiveServerPathSectionState}
        applyJBStatus={applyJBStatus}
        procedureRef={procedureRef}
        procedureSavedInRef={procedureSavedInRef}
        procedureServerPathRef={procedureServerPathRef}
        _showLoading={this._showLoading}
        _hideLoading={this._hideLoading}
        downloadFile={downloadSingleFile}
        closePage={this.closePage}
        setPermission={(result) => this.setState({ noPermission: result })}
        setProcedureFileData={this.setProcedureFileData}
        setSupportingFileData={this.setSupportingFileData}
        setTaskInfoData={this.setTaskInfoData}
        setProcedureInfoData={this.setProcedureInfoData}
        setShareTaskData={this.setShareTaskData}
      />
    );
  };

  handleApplyJbApproveCompltePage = (procedureTableData) => {
    const { shareTaskData, applyJBStatus } = this.state;

    const { savedInRadioValue } = this.state.savedInSectionState;
    const { serverPathValue } = this.state.serverPathSectionState;

    const isCopyPage =
      this.flowCategoryID &&
      this.flowCategoryID === flowCategoryId.copyJBStatus;

    return (
      <ApproveApplyForJbComplete
        pageProps={this.handleApplyJbPageProps()}
        sectionProps={this.handleApplyJbSectionProps()}
        savedInProps={{
          savedInRadioValue,
        }}
        serverPathProps={{
          serverPathValue,
        }}
        applyJBStatus={applyJBStatus}
        closePage={this.closePage}
        initProcedureTableData={[...this.initProcedureTableData]}
        fileTableData={procedureTableData}
        shareTaskData={shareTaskData}
        supportingTableData={this.state.supportingTableData}
        params={this.handleApplyJbParams()}
        returnFileDataValue={this.returnApplyJbFileDataValue}
        handleReturnSubmitData={this.handleReturnSubmitData}
        procedureRef={procedureRef}
        procedureSavedInRef={procedureSavedInRef}
        procedureServerPathRef={procedureServerPathRef}
        _showLoading={this._showLoading}
        _hideLoading={this._hideLoading}
        downloadFile={downloadSingleFile}
        primitiveSavedInSectionState={this.primitiveSavedInSectionState}
        primitiveServerPathSectionState={this.primitiveServerPathSectionState}
        isCopyPage={isCopyPage}
        setProcedureFileData={this.setProcedureFileData}
        setSupportingFileData={this.setSupportingFileData}
        setTaskInfoData={this.setTaskInfoData}
        setProcedureInfoData={this.setProcedureInfoData}
        setShareTaskData={this.setShareTaskData}
      />
    );
  };

  handleApplyJbSubmitCompltePage = (procedureTableData) => {
    const { shareTaskData, applyJBStatus } = this.state;

    const { savedInRadioValue } = this.state.savedInSectionState;
    const { serverPathValue } = this.state.serverPathSectionState;

    const isCopyPage =
      this.flowCategoryID &&
      this.flowCategoryID === flowCategoryId.copyJBStatus;

    return (
      <SubmitApplyForJbComplete
        pageProps={this.handleApplyJbPageProps()}
        sectionProps={this.handleApplyJbSectionProps()}
        params={this.handleApplyJbParams()}
        procedureRef={procedureRef}
        procedureSavedInRef={procedureSavedInRef}
        procedureServerPathRef={procedureServerPathRef}
        _showLoading={this._showLoading}
        _hideLoading={this._hideLoading}
        savedInProps={{
          savedInRadioValue,
        }}
        serverPathProps={{
          serverPathValue,
        }}
        downloadFile={downloadSingleFile}
        returnFileDataValue={this.returnApplyJbFileDataValue}
        handleReturnSubmitData={this.handleReturnSubmitData}
        primitiveSavedInSectionState={this.primitiveSavedInSectionState}
        primitiveServerPathSectionState={this.primitiveServerPathSectionState}
        applyJBStatus={applyJBStatus}
        closePage={this.closePage}
        initProcedureTableData={[...this.initProcedureTableData]}
        fileTableData={procedureTableData}
        shareTaskData={shareTaskData}
        supportingTableData={this.state.supportingTableData}
        isCopyPage={isCopyPage}
        setProcedureFileData={this.setProcedureFileData}
        setSupportingFileData={this.setSupportingFileData}
        setTaskInfoData={this.setTaskInfoData}
        setProcedureInfoData={this.setProcedureInfoData}
        setShareTaskData={this.setShareTaskData}
      />
    );
  };

  handleApplyJbProcedureTableData = () => {
    return JSON.parse(JSON.stringify(this.state.fileTableData));
  };

  render() {
    const {
      loading,
      procedureFileNum,
      uploadProcedureFileNum,
      procedureStepStatus,
    } = this.state.pageState;

    const procedureTableData = this.handleApplyJbProcedureTableData();

    const isApprovePendingPage = procedureStepStatus === "Pending";
    const isApproveCompletePage =
      procedureStepStatus === "Complete" && this.triggerTimes > 1;
    const isSubmitCompletePage =
      procedureStepStatus === "Complete" && this.triggerTimes === 1;

    return (
      <NormalSpin
        spinning={loading}
        size="large"
        indicator={
          procedureFileNum > 0 ? (
            <p className={styles.loadingText}>
              uploading {uploadProcedureFileNum}/{procedureFileNum} file
            </p>
          ) : undefined
        }
        children={
          <>
            {isApprovePendingPage &&
              this.handleApplyJbApprovePage(procedureTableData)}
            {isApproveCompletePage &&
              this.handleApplyJbApproveCompltePage(procedureTableData)}
            {isSubmitCompletePage &&
              this.handleApplyJbSubmitCompltePage(procedureTableData)}
            <LostPermissionModal isModalOpen={this.state.noPermission} />
          </>
        }
      />
    );
  }
}
