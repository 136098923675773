import React, { createRef } from "react";
import { Radio } from "antd";
import { SectionStatusDiv } from "../../../../../../base/basicComponents/status/index";
import { ModalWithComponent } from "../../../../../../base/basicComponents/modal";
import ReturnReasonInput from "../../../../../../base/basicComponents/returnReasonInput";
import returnReasonLogIcon from "../../../../../../assets/image/returnReasonLogIcon.png";
import reasonEditIcon from "../../../../../../assets/image/reasonEditIcon.png";
import {
  dataStatus,
  savedInRadioValues,
} from "../../../../../publicDictionaryValues";
import ApprovedSavedIn from "../approvedSavedIn";
import styles from "../index.module.scss";

const savedInReturnRef = createRef();
export default class ReviseProcedureSavedIn extends ApprovedSavedIn {
  constructor(props) {
    super(props);
    this.state = {
      savedInSectionState: {
        savedInReturnReasonInputValue: this.props.savedInReturnReasonInputValue,
        savedInReturnReasonValue: this.props.savedInReturnReasonValue,
        procedureSaveInCategoryStatusID:
          this.props.procedureSaveInCategoryStatusID,
        isSavedInShowEditBtn: false,
        savedInReasonEditIcon: reasonEditIcon,
        isSavedInReturnReasonDisabled: this.props.isSavedInReturnReasonDisabled,
        savedInReasonLogIcon: returnReasonLogIcon,
        isSavedInError: false,
        isSavedInReturned: this.props.isSavedInReturned,
        savedInRadioValue: this.props.savedInRadioValue,
        savedInReasonStatus: null,
        isSavedInSectionChange: this.props.isSavedInSectionChange,
      },
      modalState: {
        isModalWithComponentOpen: false,
        modalTitle: "",
        okText: "",
        cancelText: "Cancel",
        modalLabel: "",
        textAreaPlacement: "",
        textAreaMaxLength: 0,
        textAreaValue: "",
        isShowTextArea: false,
        modalWidth: 520,
        handleOk: null,
        handleCancel: null,
      },
    };
  }

  /* funtion about click confirm when saved in radio button changed to client server only */
  handleChangeSavedInCofirm = (value) => {
    this.setState({
      savedInSectionState: {
        ...this.state.savedInSectionState,
        savedInRadioValue: value,
      },
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: false,
        modalLabel: "",
        okText: "",
      },
    });
    this.props.updateSavedInRadioValue !== undefined &&
      this.props.updateSavedInRadioValue(value);
  };

  handleChangeSavedInRadioAlertModal = (e) => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: true,
        modalLabel: (
          <span className={styles.modalText}>
            You are switching the <b>Procedure Saved In</b> to{" "}
            <b>Client Server Only</b>. Please note that if you want to change
            it to <b>PMP System Only</b> or <b>PMP System & Client Server</b> in
            future revisions, the approved files listed cannot be revised.
            Please double check.
          </span>
        ),
        okText: "Confirm",
        handleOk: () => this.handleChangeSavedInCofirm(e.target.value),
        handleCancel: this.hideModal,
      },
    });
  };

  handleChangeSavedInRadioUpdateSavedInRadio = (e) => {
    this.setState({
      savedInSectionState: {
        ...this.state.savedInSectionState,
        savedInRadioValue: e.target.value,
      },
    });
    this.props.updateSavedInRadioValue !== undefined &&
      this.props.updateSavedInRadioValue(e.target.value);
  };

  /* funtion about saved in radio button change */
  handleChangeSavedInRadio = (e) => {
    let fileList = [];
    if (this.props.procedureRef.current !== null) {
      fileList = this.props.procedureRef.current.getDataList();
    }
    if (
      e.target.value === savedInRadioValues.serverOnly &&
      fileList.length > 0
    ) {
      this.handleChangeSavedInRadioAlertModal(e);
    } else {
      this.handleChangeSavedInRadioUpdateSavedInRadio(e);
    }
  };

  handleReturnSavedInRadios = (obj) => {
    const { procedureSaveInCategoryStatusID, savedInRadioValue, isDisableAll } =
      obj;
    return (
      <Radio.Group
        onChange={(e) => {
          procedureSaveInCategoryStatusID !== dataStatus.approval &&
            this.handleChangeSavedInRadio(e);
        }}
        value={savedInRadioValue}
        disabled={
          procedureSaveInCategoryStatusID === dataStatus.approval ||
          isDisableAll
        }
      >
        <Radio value={savedInRadioValues.systemOnly}>PMP System Only</Radio>
        <Radio value={savedInRadioValues.systemAndClientServer}>
          PMP System & Client Server
        </Radio>
        <Radio value={savedInRadioValues.serverOnly}>Client Server Only</Radio>
      </Radio.Group>
    );
  };

  handleChangeSavedInRadioProcedureSavedIn = () => {
    const { isDisableAll } = this.props;
    const { procedureSaveInCategoryStatusID, savedInRadioValue } =
      this.state.savedInSectionState;

    return (
      <div>
        <div className={styles.savedInTop}>
          <div className={styles.procedureSaveInLabel}>Procedure Saved In:</div>
          <div className={styles.procedureSaveInChoice}>
            {this.handleReturnSavedInRadios({
              procedureSaveInCategoryStatusID,
              savedInRadioValue,
              isDisableAll,
            })}
          </div>
        </div>
      </div>
    );
  };

  handleChangeSavedInRadioProcedureSavedInContent = () => {
    const { isDisableAll } = this.props;
    const {
      procedureSaveInCategoryStatusID,
      isSavedInReturnReasonDisabled,
      isSavedInShowEditBtn,
      savedInReturnReasonInputValue,
      isSavedInSectionChange,
    } = this.state.savedInSectionState;

    return (
      <div className={styles.savedInContent}>
        <div className={styles.statusBlock}>
          <div className={styles.statusLabel}>Status</div>
          <div className={styles.statusContent}>
            {isSavedInSectionChange !== 'SavedInNoChange' && (
              <SectionStatusDiv status={procedureSaveInCategoryStatusID} />
            )}
          </div>
        </div>
        <div className={styles.returnReasonBlock}>
          <div className={styles.returnReasonLabel}>Return Reason</div>
          <ReturnReasonInput
            inputRef={savedInReturnRef}
            value={savedInReturnReasonInputValue}
            isDisabled={isSavedInReturnReasonDisabled || isDisableAll}
            isShowEditBtn={isSavedInShowEditBtn}
            handleReturnEditClick={() => this.handleSavedInReturnClick()}
            isShowReasonLogIcon={this.props.savedInReturnReasonLog.length > 0}
            reasonLog={this.props.savedInReturnReasonLog}
          />
        </div>
        <div className={styles.actionBtns}></div>
      </div>
    );
  };

  handleChangeSavedInRadioModalWithComponent = () => {
    const { savedInReturnReasonValue, savedInReasonStatus } =
      this.state.savedInSectionState;
    const {
      modalTitle,
      modalLabel,
      isModalWithComponentOpen,
      textAreaPlacement,
      modalWidth,
      okText,
      cancelText,
      handleOk,
      handleCancel,
      textAreaMaxLength,
      isShowTextArea,
    } = this.state.modalState;

    return (
      <ModalWithComponent
        textAreaPlacement={textAreaPlacement}
        textAreaMaxLength={textAreaMaxLength}
        label={modalLabel}
        isMultiple={false}
        isShowDeleteBtn={true}
        isModalOpen={isModalWithComponentOpen}
        okText={okText}
        className={styles.approvePageModal}
        isShowTextArea={isShowTextArea}
        textAreaValue={savedInReturnReasonValue}
        handleOk={handleOk}
        handleCancel={handleCancel}
        textAreaOnChange={this.handleSetSavedInReturnReasonValue}
        textAreaStatus={savedInReasonStatus}
        modalWidth={modalWidth}
        cancelText={cancelText}
        title={modalTitle}
      />
    );
  };

  render() {
    const { procedureSavedInRef } = this.props;
    const { isSavedInError } = this.state.savedInSectionState;

    return (
      <div className={styles.procedureFileTop}>
        <div
          className={styles.procedureSavedIn}
          style={{ borderColor: isSavedInError ? "red" : "#b3b3b3" }}
          ref={procedureSavedInRef}
        >
          {this.handleChangeSavedInRadioProcedureSavedIn()}
          {this.handleChangeSavedInRadioProcedureSavedInContent()}
        </div>
        {this.handleChangeSavedInRadioModalWithComponent()}
      </div>
    );
  }
}
