import { createRef } from "react";
import ProcedureBlock from "../../../../../base/basicComponents/procedureBlock";
import UpdateProcedureFileApproveSection from "../../../../sections/procedureFileSections/updateProcedureFileApproveSection";
import ApproveNewProcedurePending from "../../createNewProcedureProcess/approveNewProcedurePending";
import styles from "../../style/index.module.scss";
import {
  actions,
  dataStatus,
  fileTags,
  handleRevisionInfoManatoryCheck,
  revisionInfoMandatoryCheck,
  savedInRadioValues,
  proposedChangeTags
} from "../../../../publicDictionaryValues";
import DetailRevisionInformation from "../../../../sections/procedureFileSections/fields/revisionInformationFields/detailRevisionInformation";
import ProcedureDetailService from "../../../../../service/procedureFile/ProcedureDetailService";
import AppSetting from "../../../../../config/AppSetting";

const procedureFileTableRef = createRef();
const reasonRef = createRef();
const noteRef = createRef();
export default class ApproveUpdateProcedurePending extends ApproveNewProcedurePending {
  /**
   * function about return page name
   * @returns
   */
  handleReturnPageName = () => {
    return "Approve Update Procedure";
  };

  /**
   * function about return revision information section
   * @returns
   */
  handleReturnRevisionInformation = () => {
    return (
      <ProcedureBlock
        className={`${styles.approveProcedureFile} revisionInfoSection`}
        child={
          <DetailRevisionInformation
            title="Revision Information"
            openLoading={this.props._showLoading}
            hideLoading={this.props._hideLoading}
            accountId={this.props.pageProps.procedureAccountID}
            ref={this.props.revisionInfoRef}
            isDisabledAll={false}
            revisionInfoData={this.props.revisionInfoData}
            updateFileNumLoading={this.props.updateFileNumLoading}
          />
        }
        isShowArrow={true}
        isOpen={true}
      />
    );
  };

  /**
   * function about return procedure file section
   * @param {object} obj
   * @returns
   */
  handleReturnProcedureFileSection = (obj) => {
    const {
      currentRole,
      isDisableAll,
      procedureReplaceFileRef,
      isTableBorderError,
    } = obj;
    const {
      data,
      downloadFile,
      isMount,
      savedInProps,
      serverPathProps,
      initSavedInSectionState,
      initServerPathSectionState,
      initProcedureTableData,
      procedureSavedInRef,
      procedureServerPathRef,
      procedureRef,
    } = this.props;
    const { CategoryID, AccountID, TaskGroupID, TaskLabelID } =
      this.props.params;
    return (
      <UpdateProcedureFileApproveSection
        sectionProps={{ currentRole, isDisableAll }}
        refs={{
          procedureSavedInRef: procedureSavedInRef,
          procedureServerPathRef: procedureServerPathRef,
          procedureRef: procedureRef,
          procedureFileTableRef,
          reasonRef,
          noteRef,
          procedureReplaceFileRef,
        }}
        data={data}
        isTableBorderError={isTableBorderError}
        downloadFile={downloadFile}
        isMount={isMount}
        savedInProps={savedInProps}
        serverPathProps={serverPathProps}
        initSavedInSectionState={initSavedInSectionState}
        initServerPathSectionState={initServerPathSectionState}
        initProcedureTableData={initProcedureTableData}
        ptParams={{ CategoryID, AccountID, TaskGroupID, TaskLabelID }}
        isLevelCChange={this.props.isLevelCChange}
      />
    );
  };

  handleApproveSubmitClickCheckRequired = (isRevisonInfoError) => {
    if (
      this.props.procedureSavedInRef.current.state.savedInSectionState
        .procedureSaveInCategoryStatusID === null
    ) {
      this.props.procedureSavedInRef.current.setSavedInError();
      this.handleControlErrorScroll(
        this.props.procedureSavedInRef.current.offsetTop
      );
    }
    if (
      this.props.procedureServerPathRef.current !== null &&
      this.props.procedureServerPathRef.current.state.serverPathSectionState
        .serverPathStatus === null &&
      this.props.savedInProps.savedInRadioValue !==
        savedInRadioValues.systemOnly
    ) {
      this.handleCheckServerPathHighLight();
    }
    if (isRevisonInfoError) {
      const revisionInfo = document.getElementsByClassName(
        "revisionInfoSection"
      )[0];
      revisionInfoMandatoryCheck(this.props.revisionInfoRef, revisionInfo);
      this.handleControlErrorScroll(revisionInfo.offsetTop);
    }
  };

  /* function about click submit button in approve page */
  handleApproveSubmitClickCheck = (procedureFileData, tableErrorList) => {
    let isTableError = false;
    const isRevisonInfoError = handleRevisionInfoManatoryCheck(
      this.props.revisionInfoRef
    );
    const errorIndexList = [];
    procedureFileData.forEach((fileData, index) => {
      const statusData = fileData.data.find((item) => item.name === "status");
      if (
        statusData.value === null &&
        fileData.data.find((item) => item.name === "fileName").tagValue !== null
      ) {
        procedureFileData[index].isError = true;
        isTableError = true;
        errorIndexList.push(index);
        procedureFileTableRef.current.updateDataList(procedureFileData);
        tableErrorList.push(statusData);
      }
    });
    if (
      this.props.procedureSavedInRef.current.state.savedInSectionState
        .procedureSaveInCategoryStatusID === null ||
      (this.props.savedInProps.savedInRadioValue !==
        savedInRadioValues.systemOnly &&
        this.props.procedureServerPathRef.current !== null &&
        this.props.procedureServerPathRef.current.state.serverPathSectionState
          .serverPathStatus === null) ||
      isRevisonInfoError
    ) {
      this.handleApproveSubmitClickCheckRequired(isRevisonInfoError);
    } else if (
      isTableError &&
      this.props.savedInProps.savedInRadioValue !==
        savedInRadioValues.serverOnly
    ) {
      const firstErrorTr = document.getElementById(
        `table-tr-${errorIndexList[0]}`
      );
      this.handleControlErrorScroll(
        firstErrorTr.offsetTop + firstErrorTr.clientHeight
      );
    } else {
      this.handleCreateActionClickSectionCheckModal(
        "",
        this.modalContentLabels.submitConfirmModalLabel,
        this.handleSubmitOkClick,
        this.hideModal
      );
    }
  };

  /* function about click submit or save button to trigger api */
  createProcedureFileTriggerAction = () => {
    ProcedureDetailService.updateProcedureTriggerApprovePageAction(
      this.para,
      this._triggerSuccess,
      this._triggerError
    );
  };

  /**
   * function about return submit data for api
   * @param {string} actionName
   * @param {boolean} isReturn
   */
  handleReturnSubmitPara = (actionName, isReturn) => {
    if (actionName === "Reject") {
      this.para = this.props.handleReturnSubmitData(
        actions.reject,
        "Reject",
        this.props.procedureSavedInRef,
        this.props.procedureServerPathRef,
        this.props.procedureRef,
        this.props.approvePageRef,
        this.props.revisionInfoRef
      );
    } else {
      if (isReturn) {
        this.para = this.props.handleReturnSubmitData(
          actions.return,
          "Return",
          this.props.procedureSavedInRef,
          this.props.procedureServerPathRef,
          this.props.procedureRef,
          this.props.approvePageRef,
          this.props.revisionInfoRef
        );
      } else {
        this.para = this.props.handleReturnSubmitData(
          actions.approve,
          "Approve",
          this.props.procedureSavedInRef,
          this.props.procedureServerPathRef,
          this.props.procedureRef,
          this.props.approvePageRef,
          this.props.revisionInfoRef
        );
      }
    }
  };

  /**
   * delete revision information before submit or reject
   * @param {string} actionName
   * @param {boolean} isReturn
   */
  handleRejectOrSubmitClickAdjustDeleteFile = (actionName, isReturn) => {
    const deleteIds = this.props.revisionInfoRef.current.state.deleteIds;
    if (deleteIds.length > 0) {
      deleteIds.forEach((item, index) => {
        let dto = {
          ProductKey: AppSetting.urlPrefix.deleteFile.ProductKey,
          StorageFileID: item,
        };
        ProcedureDetailService.deleteFile(
          dto,
          () => {
            this.props.revisionInfoRef.current.state.currentAttachment.splice(
              index,
              1
            );
            this.props.revisionInfoRef.current.handleSetAttachmentFromProps(
              this.props.revisionInfoRef.current.state.currentAttachment
            );
            this.handleReturnSubmitPara(actionName, isReturn);
            this.createProcedureFileTriggerAction();
          },
          (response) => {
            this._alertError(response);
          },
          item
        );
      });
    } else {
      this.handleReturnSubmitPara(actionName, isReturn);
      this.createProcedureFileTriggerAction();
    }
  };

  /* function about click submit button in submit modal */
  handleSubmitOkClick = () => {
    const { data } = this.props;
    const dataList = data.map(item => item.data);
    this.setState({
      pageState: {
        ...this.state.pageState,
        isSubmitDisabled: true,
      },
    });
    this.props._showLoading();
    this.hideModal();
    let isTableDataReturn = false;
    const isSavedInReturn =
      this.props.procedureSavedInRef.current.state.savedInSectionState
        .procedureSaveInCategoryStatusID === dataStatus.return;
    const isServerPathReturn =
      this.props.procedureServerPathRef.current !== null &&
      this.props.procedureServerPathRef.current.state.serverPathSectionState
        .serverPathStatus === dataStatus.return;
    const fileList =
      this.props.procedureRef.current === null
        ? []
        : this.props.procedureRef.current.getDataList();
    try {
      fileList.forEach((fileData, index) => {
        const statusData = fileData.data.find((item) => item.name === "status");
        const file = fileData.data.find((item) => item.name === "fileName");
        const dataItem = dataList.find(item => {
          const fileName = item.find(e => e.name === 'fileName').value;
          return fileName === file.value
        })
        const proposedChange = dataItem.find(item => item.name === 'proposedChange').value;
        isTableDataReturn = statusData.value === dataStatus.return && !(file.tagValue === fileTags.deleted && proposedChange === proposedChangeTags.new);
        if (isTableDataReturn) {
          throw new Error();
        }
      });
    } catch (e) {
      console.log(e);
    }
    const isReturn = isTableDataReturn || isSavedInReturn || isServerPathReturn;
    if (isTableDataReturn || isSavedInReturn || isServerPathReturn) {
      this.handleRejectOrSubmitClickAdjustDeleteFile("Return", isReturn);
    } else {
      this.handleRejectOrSubmitClickAdjustDeleteFile("Approve", isReturn);
    }
  };

  /* function about click submit button in reject modal */
  handleRejectOkClick = () => {
    const rejectReasonValue = this.state.pageState.rejectReason;
    const reason =
      rejectReasonValue !== null ? rejectReasonValue.trim() : rejectReasonValue;
    if (reason === null || reason === "") {
      this.setState({
        pageState: { ...this.state.pageState, rejectStatus: "error" },
      });
    } else {
      this.setState({
        pageState: {
          ...this.state.pageState,
          rejectStatus: null,
          isRejectDisabled: true,
        },
      });
      this.props._showLoading();
      this.hideModal();
      this.handleRejectOrSubmitClickAdjustDeleteFile("Reject", false);
    }
  };
}
