import React, { createRef } from "react";
import ProcessBaseFn from "../../../../commonComponents/processBaseFn";
import ProcedureBlock from "../../../../../base/basicComponents/procedureBlock";
import Buttons from "../../../../../base/basicComponents/button";
import NormalToolTip from "../../../../../base/basicComponents/toolTip";
import { ModalWithComponent } from "../../../../../base/basicComponents/modal";
import InputLabel from "../../../../../base/basicComponents/inputLabel";
import PageHeader from "../../../../commonComponents/pageHeaders/PageHeader";
import TaskInformation from "../../../../sections/taskInfoSections";
import ProcedureInformation from "../../../../sections/procedureInfoSections/readOnlyProcedureInfoSection";
import {
  actions,
  dataStatus,
  fileTags,
} from "../../../../publicDictionaryValues";
import redTipIcon from "../../../../../assets/image/redTipIcon.png";
import styles from "../../style/index.module.scss";
import CreateSupportingFileApproveSection from "../../../../sections/supportingFileSections/createSupportingFileApproveSection";
import ReadOnlyProcedureFileSection from "../../../../sections/procedureFileSections/readOnlyProcedureFileSection";
import createSupportingFileService from "../../../../../service/supportingFile/createSupportingFileService";
import ActiveShareTaskReadOnly from "../../../../sections/sharedTaskSections/activeShareTaskReadOnly";
import appService from "../../../../../service/app";
import LostPermissionModal from "../../../../sections/lostPermissionModal";
import StepService from "../../../../../service/stepBase/StepService";
import StepBusinessType from "../../../../../service/stepBase/stepBusinessType";

const supportingFileTableRef = createRef();
const reasonRef = createRef();
const noteRef = createRef();
const procedureFileRef = createRef();

export default class ApproveNewSupportingFilePending extends ProcessBaseFn {
  constructor(props) {
    super(props);
    this.state = {
      pageState: this.initApproveNewSupportingFilePendingPageState(),
      modalState: this.initApproveNewSupportingFilePendingModalState(),
      noPermission: false,
    };
    this.para = null;
    this.modalContentLabels =
      this.initApproveNewSupportingFilePendingModalContentLabels();
    this.params = {
      TaskId: this.props.params.ProcedureTaskId,
      ProcedureID: this.props.params.ProcedureID,
      ProcedureStepID: this.props.params.ProcedureStepID,
    };
  }

  initApproveNewSupportingFilePendingPageState = () => {
    const { newRejectReason, rejectStatus } = this.props.pageProps;

    return {
      rejectReason: newRejectReason,
      rejectStatus,
      isCancelDisabled: false,
      isSubmitDisabled: false,
      isRejectDisabled: false,
      isTableBorderError: false,
    };
  };

  initApproveNewSupportingFilePendingModalState = () => {
    return {
      modalTitle: "",
      modalLabel: "",
      okText: "",
      cancelText: "",
      actionType: "",
      closable: false,
      isOkBtnDisabled: false,
      isCancelBtnDisabled: false,
      maskClosable: false,
      isModalWithComponentOpen: false,
      isShowTextArea: false,
      rowIndex: 0,
      fileIds: [],
      fileNames: [],
      modalWidth: 520,
      textAreaPlacement: "",
      textAreaMaxLength: 0,
      textAreaClassName: "",
      isShowSelect: false,
      isShowTextAreaAndUploadDragger: false,
      uploadDraggerFileType: "",
    };
  };

  initApproveNewSupportingFilePendingModalContentLabels = () => {
    return {
      submitConfirmModalLabel: (
        <span className={styles.modalText}>Are you sure to submit?</span>
      ),
      cancelConfimModalLabel: (
        <span className={styles.modalText}>
          Are you sure to quit without saving?
        </span>
      ),
      rejectModalWarningLabel: (
        <>
          <div className={styles.rejectModalWarning}>
            Are you sure to reject this request? Reject means that the process
            will be terminated. The status will be changed back to the original
            status before this initiation.
          </div>
          <InputLabel text="Reject Reason" required={true} />
        </>
      ),
    };
  };

  /* success callback about click submit or save button to trigger api */
  _createSupportingFiletriggerSuccess = (response) => {
    if (response.succeeded === false) {
      console.log(response.message);
    } else {
      this.props.closePage();
    }
    this._hideLoading();
  };

  /* error callback about click submit or save button to trigger api */
  _createSupportingFiletriggerError = (response) => {
    console.log(response);
  };

  /* function about click submit or save button to trigger api */
  createSupportingFileTriggerAction = () => {
    createSupportingFileService.createSupportingFileTriggerApprovePageAction(
      this.para,
      this._createSupportingFiletriggerSuccess,
      this._createSupportingFiletriggerError
    );
  };

  setApproveNewSupportingFilePendingSubmitPara = (isTableDataReturn) => {
    if (isTableDataReturn) {
      this.para = this.props.handleReturnSubmitData(
        actions.return,
        "Return",
        this.props.supportingRef,
        this.props.approvePageRef
      );
    } else {
      this.para = this.props.handleReturnSubmitData(
        actions.approve,
        "Approve",
        this.props.supportingRef,
        this.props.approvePageRef
      );
    }
  };

  setApproveNewSupportingFilePendingSubmitDisabled = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        isSubmitDisabled: true,
      },
    });
  };

  getApproveNewSupportingFilePendingDataList = () => {
    return this.props.supportingRef.current === null
      ? []
      : this.props.supportingRef.current.getDataList();
  };

  /* function about click submit button in submit modal */
  handleApproveNewSupportingFilePendingSubmitOkClick = () => {
    this.setApproveNewSupportingFilePendingSubmitDisabled();
    this.props._showLoading();
    this.hideModal();
    let isApproveNewSupportingFilePendingTableDataReturn = false;
    const fileList = this.getApproveNewSupportingFilePendingDataList();
    try {
      fileList.forEach((fileData, index) => {
        const statusData = fileData.data.find((item) => item.name === "status");
        const file = fileData.data.find((item) => item.name === "fileName");
        isApproveNewSupportingFilePendingTableDataReturn =
          statusData.value === dataStatus.return &&
          file.tagValue !== fileTags.deleted;
        if (isApproveNewSupportingFilePendingTableDataReturn) {
          throw new Error();
        }
      });
    } catch (e) {
      console.log(e);
    }

    this.setApproveNewSupportingFilePendingSubmitPara(
      isApproveNewSupportingFilePendingTableDataReturn
    );
    this.createSupportingFileTriggerAction();
  };

  handleApproveNewSupportingRejectSubmit = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        rejectStatus: null,
        isRejectDisabled: true,
      },
    });
    this.props._showLoading();
    this.hideModal();
    this.para = this.props.handleReturnSubmitData(
      actions.reject,
      "Reject",
      this.props.supportingRef,
      this.props.approvePageRef
    );
    this.createSupportingFileTriggerAction();
  };

  /* function about click submit button in reject modal */
  handleApproveNewSupportingRejectOkClick = () => {
    const rejectReasonValue = this.state.pageState.rejectReason;
    const reason =
      rejectReasonValue !== null ? rejectReasonValue.trim() : rejectReasonValue;
    if (reason === null || reason === "") {
      this.setState({
        pageState: { ...this.state.pageState, rejectStatus: "error" },
      });
    } else {
      this.handleApproveNewSupportingRejectSubmit();
    }
  };

  /* function about click submit button in cancel action modal */
  handleCancelOkClick = () => {
    this.setState({
      pageState: { ...this.state.pageState, isCancelDisabled: true },
    });
    this.hideModal();
    this.props.closePage();
  };

  /* function about click cancel button in reject modal */
  handleApproveNewSupportingRejectCancelClick = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        rejectReason: null,
        rejectStatus: null,
      },
    });
    this.hideModal();
  };

  handleApproveSupportingControlErrorScroll = (top) =>
    window.scrollTo(0, top || 0);

  /* function about click submit button in approve page */
  handleApproveSupportingSubmitClickCheck = (
    procedureFileData,
    tableErrorList
  ) => {
    let isTableError = false;
    const errorIndexList = [];
    procedureFileData.forEach((fileData, index) => {
      const statusData = fileData.data.find((item) => item.name === "status");
      if (
        statusData.value === null &&
        fileData.data.find((item) => item.name === "fileName").tagValue !==
          fileTags.deleted
      ) {
        procedureFileData[index].isError = true;
        isTableError = true;
        errorIndexList.push(index);
        supportingFileTableRef.current.updateDataList(procedureFileData);
        tableErrorList.push(statusData);
      }
    });
    if (isTableError) {
      const firstErrorTr = document.getElementById(
        `table-tr-${errorIndexList[0]}`
      );
      this.handleApproveSupportingControlErrorScroll(
        firstErrorTr.offsetTop + firstErrorTr.clientHeight
      );
    } else {
      this.handleCreateActionClickSectionCheckModal(
        "",
        this.modalContentLabels.submitConfirmModalLabel,
        this.handleApproveNewSupportingFilePendingSubmitOkClick,
        this.hideModal
      );
    }
  };

  _checkStepPermissionSuccess = (result, actionType) => {
    this.props._hideLoading();
    const { statusCode } = result;
    const noPermission = statusCode !== 200;
    this.setState({
      noPermission,
    });
    if (noPermission) return;
    if (actionType === "submit") {
      this.handleSubmitClick();
    } else if (actionType === "reject") {
      this.handleApproveNewSupportingNewSupportingRejectClick();
    }
  };

  checkStepPermission = (actionType) => {
    this.props._showLoading();
    appService.checkStepPermission(
      this.props.params,
      (response) => this._checkStepPermissionSuccess(response, actionType),
      (response) => console.log(response.message)
    );
  };

  /* function about click submit button in approve page */
  handleSubmitClick = () => {
    const supportingFileData =
      this.props.supportingRef.current === null
        ? []
        : this.props.supportingRef.current.getDataList();
    let tableErrorList = [];
    this.handleApproveSupportingSubmitClickCheck(
      supportingFileData,
      tableErrorList
    );
  };

  /* function about click reject button */
  handleApproveNewSupportingNewSupportingRejectClick = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: true,
        modalTitle: <span className="fs-18">Reject</span>,
        isShowTextArea: true,
        modalLabel: this.modalContentLabels.rejectModalWarningLabel,
        textAreaMaxLength: 1000,
        textAreaClassName: styles.rejectReasonInput,
        okText: "Confirm",
        cancelText: "Cancel",
        actionType: "reject",
        modalWidth: 520,
        handleCancel: this.handleApproveNewSupportingRejectCancelClick,
        handleOk: this.handleApproveNewSupportingRejectOkClick,
      },
    });
  };

  /* function about click submit button in revise page to show the tip modal */
  handleCreateActionClickSectionCheckModal = (
    modalTitle,
    modalLabel,
    okMethod,
    cancelMethod
  ) => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: true,
        modalTitle: modalTitle,
        modalLabel: modalLabel,
        modalWidth: 520,
        handleOk: okMethod,
        handleCancel: cancelMethod,
        okText: "Confirm",
        cancelText: "Cancel",
      },
    });
  };

  handleApproveNewSupportingSetRejectValue = (e) => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        rejectReason: e.target.value,
        rejectStatus: null,
      },
    });
  };

  handleApproveNewSupportingCancelClick = () =>
    this.handleCreateActionClickSectionCheckModal(
      "",
      this.modalContentLabels.cancelConfimModalLabel,
      this.handleCancelOkClick,
      this.hideModal
    );

  handleApproveNewSupportingReturnCancelButton = (isCancelDisabled) => (
    <Buttons
      color="grey"
      size="middle"
      onClick={this.handleApproveNewSupportingCancelClick}
      disabled={isCancelDisabled}
      btnClassName={styles.approveActionBtn}
    >
      Cancel
    </Buttons>
  );

  handleApproveNewSupportingReturnSubmitButton = (isSubmitDisabled) => (
    <Buttons
      color="blue"
      size="middle"
      onClick={() => this.checkStepPermission("submit")}
      disabled={isSubmitDisabled}
      btnClassName={styles.approveActionSubmitBtn}
    >
      Submit
    </Buttons>
  );

  handleApproveNewSupportingReturnRejectButton = (isRejectDisabled) => (
    <div className={styles.rejectBtn}>
      <Buttons
        color="red"
        size="middle"
        onClick={() => this.checkStepPermission("reject")}
        disabled={isRejectDisabled}
      >
        Reject
      </Buttons>
      <NormalToolTip
        element={
          <div className={styles.rejectTipIcon}>
            <img src={redTipIcon} alt="Reject" />
          </div>
        }
        title="Reject means that the process will be terminated. The status will be changed back to the original status before this initiation."
        color="#FF5858"
        overlayClassName={styles.rejectTip}
      />
    </div>
  );

  handleReturnTitle = () => {
    return "Approve New Supporting File";
  };

  handleReturnSupportingFileSection = (obj) => {
    const {
      currentRole,
      isDisableAll,
      procedureReplaceFileRef,
      isTableBorderError,
    } = obj;
    return (
      <CreateSupportingFileApproveSection
        sectionProps={{ currentRole, isDisableAll }}
        initSupportingTableData={this.props.initSupportingTableData}
        refs={{
          supportingRef: this.props.supportingRef,
          supportingFileTableRef,
          reasonRef,
          noteRef,
          procedureReplaceFileRef,
        }}
        data={this.props.data}
        isTableBorderError={isTableBorderError}
        downloadFile={this.props.downloadFile}
        isMount={this.props.isMount}
      />
    );
  };

  handleApproveNewSupportingPageHeader = () => {
    const { receiveDate, requestByName } = this.props.pageProps;

    return (
      <PageHeader
        title={this.handleReturnTitle()}
        receiveDate={this._formateDate(receiveDate)}
        requestBy={requestByName}
        status="Pending"
      />
    );
  };

  handleApproveNewSupportingTaskInformationSection = () => {
    const { taskInfoData } = this.props.sectionProps;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={<TaskInformation data={taskInfoData} />}
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.props.setTaskInfoData,
            this.setError,
            [StepBusinessType.taskInfo]
          );
        }}
      />
    );
  };

  handleApproveNewSupportingProcedureFileSection = () => {
    const { fileTableData, jbProcedureSavedIn } = this.props.sectionProps;

    return (
      <ProcedureBlock
        child={
          <ReadOnlyProcedureFileSection
            procedureFileRef={procedureFileRef}
            clientServerPath={this.props.serverPathValue}
            savedInRadioValue={this.props.savedInRadioValue}
            procedureFileData={fileTableData !== undefined ? fileTableData : []}
            showHintMsg={true}
            jbProcedureSavedIn={jbProcedureSavedIn}
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.props.setProcedureFileData,
            this.setError,
            [StepBusinessType.procedureFile]
          );
        }}
      />
    );
  };

  handleApproveNewSupportingFileSection = () => {
    const { currentRole, procedureReplaceFileRef, isDisableAll } =
      this.props.pageProps;
    const { isTableBorderError } = this.state.pageState;

    return (
      <ProcedureBlock
        className={styles.approveSupportingFile}
        child={this.handleReturnSupportingFileSection({
          currentRole,
          isDisableAll,
          procedureReplaceFileRef,
          isTableBorderError,
        })}
        isShowArrow={true}
        isOpen={true}
      />
    );
  };

  handleApproveNewSupportingProcedureInformationSection = () => {
    const { procedureStatus } = this.props.pageProps;
    const { procedureInfoData, ppoList } = this.props.sectionProps;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={
          <ProcedureInformation
            isShowActions={false}
            noteDisabled={true}
            ppoList={ppoList}
            procedureStatus={procedureStatus}
            isPPODisabled={true}
            showHintMsg={true}
            submitData={procedureInfoData}
            title="Procedure Information"
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.props.setProcedureInfoData,
            this.setError,
            [
              StepBusinessType.procedureOwner,
              StepBusinessType.pmpExcluded,
              StepBusinessType.primaryProcedureOwner,
            ]
          );
        }}
      />
    );
  };

  handleApproveNewSupportingShareTaskSection = () => {
    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={
          <ActiveShareTaskReadOnly
            showHintMsg={true}
            shareTaskData={this.props.shareTaskData}
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.props.setShareTaskData,
            this.setError,
            [StepBusinessType.shareTask]
          );
        }}
      />
    );
  };

  handleApproveNewSupportingModalWithComponent = () => {
    const { rejectReason, rejectStatus } = this.state.pageState;

    const {
      modalTitle,
      modalLabel,
      handleOk,
      handleCancel,
      okText,
      cancelText,
      closable,
      textAreaPlacement,
      textAreaMaxLength,
      textAreaClassName,
      isOkBtnDisabled,
      isCancelBtnDisabled,
      maskClosable,
      isModalWithComponentOpen,
      isShowTextArea,
      modalWidth,
    } = this.state.modalState;

    return (
      <ModalWithComponent
        handleOk={handleOk}
        handleCancel={handleCancel}
        isModalOpen={isModalWithComponentOpen}
        closable={closable}
        okText={okText}
        isOkDisabled={isOkBtnDisabled}
        isCancelDisabled={isCancelBtnDisabled}
        maskClosable={maskClosable}
        cancelText={cancelText}
        title={modalTitle}
        label={modalLabel}
        modalWidth={modalWidth}
        isShowTextArea={isShowTextArea}
        textAreaPlacement={textAreaPlacement}
        textAreaMaxLength={textAreaMaxLength}
        textAreaValue={rejectReason}
        textAreaStatus={rejectStatus}
        textAreaClassName={textAreaClassName}
        textAreaOnChange={this.handleApproveNewSupportingSetRejectValue}
      />
    );
  };

  render() {
    const { noPermission } = this.state;
    const { isCancelDisabled, isSubmitDisabled, isRejectDisabled } =
      this.state.pageState;

    return (
      <div className={styles.approveNewPro}>
        {this.handleApproveNewSupportingPageHeader()}
        {this.handleApproveNewSupportingTaskInformationSection()}
        {this.handleApproveNewSupportingProcedureFileSection()}
        {this.handleApproveNewSupportingFileSection()}
        {this.handleApproveNewSupportingProcedureInformationSection()}
        {this.handleApproveNewSupportingShareTaskSection()}
        <div className={styles.actionBtns}>
          <div className={styles.normalBtns}>
            {this.handleApproveNewSupportingReturnCancelButton(
              isCancelDisabled
            )}
            {this.handleApproveNewSupportingReturnSubmitButton(
              isSubmitDisabled
            )}
          </div>
          {this.handleApproveNewSupportingReturnRejectButton(isRejectDisabled)}
        </div>
        {this.handleApproveNewSupportingModalWithComponent()}
        <LostPermissionModal isModalOpen={noPermission} />
      </div>
    );
  }
}
