import React, { Component } from "react";
import DelegationIcon from "./delegationIcon";
import DelegationModal from "./delegationModal";

export default class DelegationField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isEditDisabled: false,
    };
  }
  handleModalOpen = () => {
    this.setState({ isModalOpen: true });
  };
  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const { isModalOpen, isEditDisabled } = this.state;
    const {
      setDelegatedValueToListData,
      dataList,
      transactionId,
      procedureTaskId,
      procedureStepId,
      delegatedTo,
      delegatedToId,
      processCategory,
      highlyRegulated,
    } = this.props;
    return (
      <div>
        <div className="delegateIcon">
          <DelegationIcon
            handleModalOpen={this.handleModalOpen}
            disabled={isEditDisabled}
            procedureTaskId={procedureTaskId}
          />
        </div>
        <DelegationModal
          isModalOpen={isModalOpen}
          handleCloseModal={this.handleCloseModal}
          transactionId={transactionId}
          procedureTaskId={procedureTaskId}
          procedureStepId={procedureStepId}
          delegatedTo={delegatedTo}
          delegatedToId={delegatedToId}
          processCategory={processCategory}
          highlyRegulated={highlyRegulated}
          setDelegatedValueToListData={setDelegatedValueToListData}
          dataList={dataList}
        />
      </div>
    );
  }
}
