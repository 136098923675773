import React, { createRef } from "react";
import ProcedureBlock from "../../../../../base/basicComponents/procedureBlock";
import createSupportingFileService from "../../../../../service/supportingFile/createSupportingFileService";
import {
  dataStatus,
  fileTags,
  proposedChangeTags,
} from "../../../../publicDictionaryValues";
import UpdateSupportingFileReviseSection from "../../../../sections/supportingFileSections/updateSupportingFileReviseSection";
import ReviseNewSupportingFilePending from "../../createSupportingFileProcess/reviseNewSupportingFilePending";
import styles from "../../style/index.module.scss";

const supportingFileTableRef = createRef();
const supportingFileRef = createRef();
const reasonRef = createRef();
const noteRef = createRef();
export default class ReviseUpdateSupportingFilePending extends ReviseNewSupportingFilePending {
  /* function about click submit button in revise page if returned files don't do change */
  handleCheckFileTableHighLight = (
    supportingFileData,
    tableErrorList,
    isTableError
  ) => {
    let errorIndexList = [];
    let deleteList = [];
    supportingFileData.map((fileData, index) => {
      const isReviewed = fileData.data.find(
        (file) => file.name === "isReviewed"
      ).value;
      const proposedChange = fileData.data.find(
        (file) => file.name === "proposedChange"
      ).value;
      const fileTag = fileData.data.find(
        (file) => file.name === "fileName"
      ).tagValue;
      const status = fileData.data.find((file) => file.name === "status").value;
      if (
        !isReviewed &&
        status !== dataStatus.approval &&
        !(
          proposedChange === null ||
          (proposedChange === proposedChangeTags.new &&
            fileTag === fileTags.deleted) ||
          (status === null && proposedChange === proposedChangeTags.new)
        )
      ) {
        errorIndexList = [];
        tableErrorList.push(supportingFileData[index]);
        errorIndexList.push(index);
        isTableError = true;
        supportingFileData[index].isError = true;
      }
    });
    if (
      tableErrorList.length === supportingFileData.length ||
      deleteList.length === supportingFileData.length
    ) {
      this.isTableBorderError = true;
      this.setState({
        pageState: { ...this.state.pageState, isTableBorderError: true },
      });
      isTableError = true;
    }

    this.constrolTableScrollWhenSubmit(
      isTableError,
      supportingFileData,
      errorIndexList,
      supportingFileTableRef
    );
  };

  handleReturnTitle = () => {
    return "Revise Update Supporting Files";
  };

  handleReturnSupportingFileSection = (obj) => {
    const {
      currentRole,
      supportingReplaceFileRef,
      isTableBorderError,
      isDisableAll,
    } = obj;
    return (
      <ProcedureBlock
        className={styles.approveSupportingFile}
        child={
          <UpdateSupportingFileReviseSection
            sectionProps={{ currentRole }}
            initSupportingTableData={this.props.initSupportingTableData}
            initProcedureFileTableState={this.props.initProcedureFileTableState}
            refs={{
              supportingRef: this.props.supportingRef,
              supportingFileTableRef,
              supportingFileRef,
              reasonRef,
              noteRef,
              supportingReplaceFileRef,
            }}
            data={this.props.data}
            isTableBorderError={isTableBorderError}
            setIsTableChange={this.setIsTableChange}
            cancelIsTableChange={this.cancelIsTableChange}
            _showLoading={this.props._showLoading}
            _hideLoading={this.props._hideLoading}
            updateUploadProcedureFileNum={
              this.props.updateUploadProcedureFileNum
            }
            updateSupportingFileNum={this.props.updateSupportingFileNum}
            isDisableAll={isDisableAll}
            downloadFile={this.props.downloadFile}
            isMount={this.props.isMount}
          />
        }
        isShowArrow={true}
        isOpen={true}
      />
    );
  };

  /* function about click submit or save button to trigger api */
  createSupportingFileTriggerAction = () => {
    createSupportingFileService.updateSupportingFileTriggerRevisePageAction(
      this.para,
      this._triggerReviseNewSupportingFilePendingSuccess,
      this._triggerReviseNewSupportingFilePendingError
    );
  };
}
