import ReadOnlyFileManagement from "../../../../../commonComponents/fileManagementFields/readOnlyFileManagement";

export default class ReadOnlyActiveSupportingFileManagement extends ReadOnlyFileManagement {
  /**
   * function about return table columns
   * @param {object} obj
   * @returns
   */
  handleReturnColumns = (obj) => {
    return [
      {
        name: "ProcedureId",
        dataName: "procedureId",
        type: "text",
        isShow: false,
      },
      {
        name: "checkbox",
        dataName: "checkbox",
        type: "checkbox",
        isShow: true,
        check: this._check,
        onCheck: this._onCheck,
        headCheck: this._headCheck,
        headOnCheck: this._headOnCheck,
      },
      {
        name: "File Name",
        dataName: "fileName",
        type: "fileName",
        isShow: true,
        isDisabled: false,
      },
      {
        name: "Uploaded Date",
        dataName: "uploadedDate",
        type: "text",
        isShow: true,
      },
      {
        name: "Uploaded By",
        dataName: "uploadByName",
        type: "text",
        isShow: true,
      },
      {
        name: "Approval Date",
        dataName: "approveDate",
        type: "text",
        isShow: true,
      },
    ];
  };
}
