import React from "react";
import Buttons from "../../../../base/basicComponents/button";
import InputLabel from "../../../../base/basicComponents/inputLabel";
import Input, {
  NormalTextArea,
  ExpendTextArea,
} from "../../../../base/basicComponents/input";
import NormalSelect from "../../../../base/basicComponents/select";
import NormalModal from "../../../../base/basicComponents/modal";
import styles from "./index.module.scss";
import BaseProcedureInformation from "../baseProcedureInfoSection";
import ApproveChangePPOService from "../../../../service/changePPO/ApproveChangePPOService";
import ProcedureDetailService from "../../../../service/procedureFile/ProcedureDetailService";
import { actions } from "../../../publicDictionaryValues";
import appService from "../../../../service/app";
import LostPermissionModal from "../../lostPermissionModal";

export default class ReassignProcedureInformation extends BaseProcedureInformation {
  constructor(props) {
    super(props);
    this.state = {
      ppoList: [],
      primaryProcedureOwnerId: null,
      currentPPO: null,
      currentReason: "",

      isModalOpen: false,
      modalOkText: "",
      modalCancelText: "",
      modalChildContent: "",
      handleModalConfirm: () => {},
      handleModalCancel: () => {},
      noPermission: false,
    };
  }

  handlePPOChange = (value, ref) => {
    this.setState({
      currentPPO: value,
    });
  };

  handleReasonChange = (value, ref) => {
    this.setState({
      currentReason: value,
    });
  };

  _getPrimaryProcedureOwnerListSuccess = (result) => {
    this.setState({
      ppoList: result,
    });
  };

  _getPrimaryProcedureOwnerListFailed = () => {
    this._alertError("Get Primary Procedure Owner Failed");
  };

  getReassignProcedureInformationPrimaryProcedureOwnerList = () => {
    ProcedureDetailService.getPrimaryProcedureOwnerDDL(
      this.props.procedureTaskId,
      this._getPrimaryProcedureOwnerListSuccess,
      this._getPrimaryProcedureOwnerListFailed
    );
  };

  _checkPpoIsExistedSuccess = (result) => {
    if (result) {
      this.setState({ isModalOpen: false });
      this._alertError("This task already has a Primary Procedure Owner");
    } else {
      const { procedureID, procedureStepID, workflowProcessInstanceId } =
        this.props.procedureData;
      const param = {
        procedureId: procedureID,
        actionID: actions.submit,
        procedureStepID,
        workflowProcessInstanceId,
        primaryProcedureOwnerId: this.state.currentPPO,
        reason: "Primary Procedure Owner Lost Account Membership.",
      };

      this.changePrimaryProcedureOwnerReassignProcedureInformationTriggerStepAction(
        param
      );

      this.setState({ isModalOpen: false });
    }
  };

  _checkPpoIsExistedFailed = () => {
    this._alertError("Check PPO Is Existed Failed");
  };

  checkPpoIsExisted = () => {
    const param = {
      procedureTaskId: this.props.procedureTaskId,
    };
    ApproveChangePPOService.CheckPpoIsExisted(
      param,
      this._checkPpoIsExistedSuccess,
      this._checkPpoIsExistedFailed
    );
  };

  changePrimaryProcedureOwnerReassignProcedureInformationTriggerStepAction = (
    param
  ) => {
    ApproveChangePPOService.ChangePrimaryProcedureOwnerTriggerStepAction(
      param,
      () => {
        this.props.closePage();
      },
      (response) => {
        console.log(response.message);
      }
    );
  };

  _checkStepPermissionSuccess = (result) => {
    this.props._hideLoading();
    const { statusCode } = result;
    const noPermission = statusCode !== 200;
    this.setState({
      noPermission,
    });
    if (noPermission) return;
    this.handelReassignProcedureInformationApproveClick();
  };

  checkStepPermission = () => {
    this.props._showLoading();
    const param = {
      taskID: this.props.params.ProcedureTaskId,
      procedureID: this.props.params.ProcedureID,
      procedureStepID: this.props.params.ProcedureStepID,
      parentPage: null,
    };
    appService.checkStepPermission(
      param,
      this._checkStepPermissionSuccess,
      (response) => console.log(response.message)
    );
  };

  handleReassignProcedureInformationCancel = () => {
    this.setState({
      modalChildContent: "Are you sure to quit without saving?",
      handleModalConfirm: this.props.closePage,
      handleModalCancel: () => {
        this.setState({ isModalOpen: false });
      },
      isModalOpen: true,
      modalOkText: "Confirm",
      modalCancelText: "Cancel",
    });
  };

  handleReassignProcedureInformationApproveConfirm = () => {
    this.props._showLoading();
    this.checkPpoIsExisted();
  };

  handelReassignProcedureInformationApproveClick = () => {
    this.setState({
      isModalOpen: true,
      handleModalConfirm: this.handleReassignProcedureInformationApproveConfirm,
      handleModalCancel: () => {
        this.setState({ isModalOpen: false });
      },
      modalOkText: "Confirm",
      modalCancelText: "Cancel",
      modalChildContent: "Are you sure to submit?",
    });
  };

  componentDidMount() {
    this.props._showLoading();
    this.getReassignProcedureInformationPrimaryProcedureOwnerList();
  }

  handleReassignProcedureInformationLabelInputComponent = (
    text,
    value,
    disabled
  ) => {
    return (
      <div className={styles.informationItem}>
        <InputLabel text={text} className={styles.informationLabel} />
        <Input value={value} disabled={disabled} />
      </div>
    );
  };

  getReassignProcedureInformationSortPPOList = () => {
    const { ppoList } = this.state;

    const PPOList = ppoList.map((item) => {
      return {
        name: item.name,
        value: item.id,
      };
    });
    const sortList = (s, t) => {
      let a = s.name.toLowerCase();
      let b = t.name.toLowerCase();
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    };

    return PPOList.sort((s, t) => sortList(s, t));
  };

  handleReassignProcedureInformationTitle = () => {
    const { title } = this.props;
    return (
      <div className={styles.procedureInfoTop}>
        <div className={styles.informationTitle}>
          <span className={`${styles.titleText} fs-18`}>{title}</span>
        </div>
      </div>
    );
  };

  handleReassignProcedureInformationProcedureNote = () => {
    const { procedureData } = this.props;
    const { procedureNote } = procedureData;

    return (
      <div className={styles.informationItem}>
        <InputLabel text="Note" className={styles.informationLabel} />
        <ExpendTextArea
          className={styles.informationExpendTextArea}
          value={procedureNote}
          disabled={true}
        />
      </div>
    );
  };

  handleReassignProcedureInformationReassignPendingButton = () => {
    const { currentPPO } = this.state;

    return (
      <div className={styles.informationBtns}>
        <Buttons
          color="grey"
          size="middle"
          onClick={this.handleReassignProcedureInformationCancel}
        >
          Cancel
        </Buttons>
        <Buttons
          color="blue"
          size="middle"
          onClick={this.checkStepPermission}
          disabled={!currentPPO}
        >
          Submit
        </Buttons>
      </div>
    );
  };

  render() {
    const { currentRef, procedureData, isReassignPending } = this.props;

    const {
      procedureStatus,
      isExcluded,
      primaryProcedureOwner,
      procedureOwner,
    } = procedureData;

    const {
      isModalOpen,
      modalOkText,
      modalCancelText,
      modalChildContent,
      handleModalConfirm,
      handleModalCancel,
      currentPPO,
    } = this.state;

    const handleReassignProcedureInformationOptionList = (inputValue, option) =>
      (option?.title || "").toLowerCase().includes(inputValue.toLowerCase());

    return (
      <div className={styles.ProcedureInformation}>
        {this.handleReassignProcedureInformationTitle()}
        <div className={styles.informationContent}>
          {this.handleReassignProcedureInformationLabelInputComponent(
            "Procedure Status",
            this.getProcedureStatus(procedureStatus),
            true
          )}
          {this.handleReassignProcedureInformationLabelInputComponent(
            "PMP Excluded",
            isExcluded,
            true
          )}
          <div className={styles.informationItem}>
            {isReassignPending ? (
              <>
                <InputLabel
                  text="Primary Procedure Owner"
                  className={styles.informationLabel}
                />
                <NormalSelect
                  optionList={this.getReassignProcedureInformationSortPPOList()}
                  value={currentPPO}
                  handleChange={(e) => {
                    this.handlePPOChange(e, currentRef);
                  }}
                  showSearch={true}
                  handleFilterOption={
                    handleReassignProcedureInformationOptionList
                  }
                />
              </>
            ) : (
              <>
                {this.handleReassignProcedureInformationLabelInputComponent(
                  "Primary Procedure Owner",
                  primaryProcedureOwner?.primaryProcedureOwnerName,
                  true
                )}
              </>
            )}
          </div>
          <div className={styles.informationReasonItem}>
            <InputLabel 
              text="Reason" 
              className={styles.informationLabel}
            />
            <ExpendTextArea
              className={styles.informationExpendTextArea}
              value="Primary Procedure Owner Lost Account Membership."
              maxLength={1000}
              disabled={true}
            />
          </div>
          {this.handleReassignProcedureInformationLabelInputComponent(
            "Procedure Owner",
            procedureOwner?.procedureOwnerName,
            true
          )}
          {this.handleReassignProcedureInformationProcedureNote()}
        </div>
        {isReassignPending &&
          this.handleReassignProcedureInformationReassignPendingButton()}
        <NormalModal
          okText={modalOkText}
          cancelText={modalCancelText}
          childContent={<p className="fs-16">{modalChildContent}</p>}
          isModalOpen={isModalOpen}
          handleOk={handleModalConfirm}
          handleCancel={handleModalCancel}
          closable={false}
        />
        <LostPermissionModal isModalOpen={this.state.noPermission} />
      </div>
    );
  }
}
