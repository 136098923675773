import { Input, Tooltip } from "antd";
import ReasonLogIcon from "../../../../base/basicComponents/reasonLogIcon";
import BaseComponent from "../../../../base/common/BaseComponent";
import ReturnReasonLogModal from "../../../../base/basicComponents/returnReasonLogModal";
import RequestReasonLogModal from "../../../../base/basicComponents/requestReasonLogModal";
import ProcedureDetailService from "../../../../service/procedureFile/ProcedureDetailService";
import shareTaskService from "../../../../service/shareTask";
import "../../../commonComponents/readOnlyTable/antReadOnlyTableCustom.scss";
import styles from "../index.module.scss";
import reviseStyles from "../reviseShareTask/reviseShareTask.module.scss";
import approveStyles from "../approveShareTask/approveShareTask.module.scss";
import InputLabel from "../../../../base/basicComponents/inputLabel";
import { FileStatusDiv } from "../../../../base/basicComponents/status";

export default class BaseShareTask extends BaseComponent {
  setFileText = (num) => (num <= 1 ? "task" : "tasks");

  TransformSelectOption = (inputData) => {
    const outputData = inputData.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

    return outputData;
  };

  GetTaskTreePathDDLOnSuccess = (result) => {
    this.setState({
      taskTreePathOption: this.TransformSelectOption(result),
      taskTreePathValue: null,
    });
  };

  GetTaskTreePathDDLOnError = (result) => {
    console.log(result);
  };

  AccountSelectOnChange = (value) => {
    const accountValue = value;
    this.setState({
      accountSelectValue: accountValue,
      taskTreePathValue: null,
    });
    this.setTaskTreePathDDL(accountValue);
  };

  TaskTreePathOnSearch = () => {};

  TaskTreePathOnChange = (value) => {
    this.setState({
      taskTreePathValue: value,
    });
  };

  ClearAddTaskOnClick = () => {
    this.setState({
      accountSelectValue: this.state.accountSelectOption[0].value,
      taskTreePathValue: null,
    });
    this.setTaskTreePathDDL(this.state.accountSelectOption[0].value);
  };

  transformRequestReasonLogData = (data) => {
    if (data === undefined || data === null) {
      return [];
    }
    const outputData = data.map((item) => {
      return {
        ...item,
        submittedDate: this._formateDate(item.submittedDate),
        submittedBy: item.submittedByName,
        reasonColumn: item.reason,
      };
    });

    return outputData;
  };

  transformReturnReasonLogData = (data) => {
    if (data === undefined || data === null) {
      return [];
    }
    const outputData = data.map((item) => {
      return {
        ...item,
        returnDate: this._formateDate(item.returnDate),
        returnedBy: item.returnByName,
        returnReasonColumn: item.returnReason,
      };
    });

    return outputData;
  };

  transformPendingData = (data) => {
    if (data === undefined || data === null || data.length === 0) {
      return null;
    }

    const outputData = data.map((obj) => {
      if (obj.hasOwnProperty("isDel")) {
        obj.isDeleted = obj.isDel;
        delete obj.isDel;
      }
      return obj;
    });

    return outputData;
  };

  HandleModalTableTag = (record) => {
    if (record.isDeleted) {
      return "AD0377D7-1499-4489-8BF7-080F668BC3B6";
    } else if (record.isNew) {
      return "E2172EF2-6770-4767-A9E5-539C571E8E81";
    }

    return "";
  };

  HandleTaskInfoModalClose = () => {
    this.setState({
      taskInformationData: {},
      isTaskInfoModalOpen: false,
    });
  };

  GetTaskInfoSuccess = (result) => {
    this.setState({
      taskInformationData: result,
      isTaskInfoModalOpen: true,
    });
  };

  GetTaskInfoFailed = (result) => {
    console.log(result);
  };

  HandleTaskLableClick = (record) => {
    ProcedureDetailService.getTaskInfo(
      record.procedureTaskId,
      this.GetTaskInfoSuccess,
      this.GetTaskInfoFailed
    );
  };

  transformData = (data) => {
    if (data === undefined) {
      return null;
    }
    const outputData = data.map((item) => {
      return {
        ...item,
        isDeleted: item.isDel,
      };
    });

    return outputData;
  };

  setTaskTreePathDDL = (procedureAccountId) => {
    const para = {
      procedureAccountId: procedureAccountId,
      searchValue: "",
    };
    shareTaskService.getTaskTreePathDDL(
      para,
      this.GetTaskTreePathDDLOnSuccess,
      this.GetTaskTreePathDDLOnError
    );
  };

  GetAccountDDLOnSuccess = (result) => {
    const option = this.TransformSelectOption(result);
    this.setState({
      accountSelectOption: option,
      accountSelectValue: option[0].value,
    });
    this.setTaskTreePathDDL(option[0].value);
  };

  GetAccountDDLOnError = (result) => {
    console.log(result);
  };

  OpenRetrunReasonLogModal = () => {
    this.setState({
      isReasonLogModalOpen: true,
    });
  };

  CloseRetrunReasonLogModal = () => {
    this.setState({
      isReasonLogModalOpen: false,
    });
  };

  OpenRequestReasonLogModal = () => {
    this.setState({
      isRequestReasonLogModalOpen: true,
    });
  };

  CloseRequestRetrunReasonLogModal = () => {
    this.setState({
      isRequestReasonLogModalOpen: false,
    });
  };

  HandleChangeMainTaskTag = (record) => {
    if (record.isNewMainTask) {
      return "NewMainTask";
    } else if (record.isOldMainTask) {
      return "OldMainTask";
    }

    return "";
  };

  handleSubmitReason = () => {
    // this is an empty function
  };

  handleReturnReason = () => {
    // this is an empty function
  };

  handleReturnReasonLog = () => {
    // this is an empty function
  };

  initReadOnlyState = (isRevise) => {
    return {
      isTaskInfoModalOpen: false,
      taskInformationData: {},
      isShowReturnReasonLogIcon:
        this.props.shareTaskData.returnReasonLogs?.length > 0,
      isReasonLogModalOpen: false,
      returnReasonLog: this.transformReturnReasonLogData(
        this.props.shareTaskData.returnReasonLogs
      ),
      isRequestReasonLogModalOpen: false,
      requestReasonLog: this.transformRequestReasonLogData(
        this.props.shareTaskData.submitReasonLogs
      ),
      reason: isRevise
        ? this.props.shareTaskData.returnReason
        : this.props.shareTaskData.reason,
      reasonValue: this.props.shareTaskData.reason,
    };
  };

  initState = (isRevise, resonRequired) => {
    return {
      pendingApprovalData: this.transformPendingData(
        this.props.shareTaskData.shareTasks
      ),
      isShowReturnReasonLogIcon:
        this.props.shareTaskData.returnReasonLogs?.length > 0,
      isReasonLogModalOpen: false,
      returnReasonLog: this.transformReturnReasonLogData(
        this.props.shareTaskData.returnReasonLogs
      ),
      isRequestReasonLogModalOpen: false,
      requestReasonLog: this.transformRequestReasonLogData(
        this.props.shareTaskData.submitReasonLogs
      ),
      initReviseData: this.transformPendingData(
        this.props.shareTaskData.shareTasks
      ),
      reviseData: this.transformPendingData(
        this.props.shareTaskData.shareTasks
      ),
      isAddNewShareTaskShow: false,
      accountSelectOption: [],
      accountSelectValue: null,
      taskTreePathOption: [],
      taskTreePathValue: null,
      resonRequired: resonRequired,
      reasonValue: "",
      reasonInputStatus: "",
      switchDisable: false,
      addNewSelectDisable: false,
      reason: isRevise
        ? this.props.shareTaskData.returnReason
        : this.props.shareTaskData.reason,
      dataHasChange: false,
    };
  };

  handleReasonLogModal = (
    isReasonLogModalOpen,
    returnReasonLog,
    isRequestReasonLogModalOpen,
    requestReasonLog
  ) => {
    return (
      <>
        <ReturnReasonLogModal
          isReasonLogModalOpen={isReasonLogModalOpen}
          handleReturnReasonLog={this.CloseRetrunReasonLogModal}
          returnReasonLogData={returnReasonLog}
        />
        <RequestReasonLogModal
          isReasonLogModalOpen={isRequestReasonLogModalOpen}
          handleRequestReasonLog={this.CloseRequestRetrunReasonLogModal}
          requestReasonLogData={requestReasonLog}
        />
      </>
    );
  };

  handleReasonLog = (
    title,
    isLabelIcon,
    isShowReturnReasonLogIcon,
    isRequestReasonLog
  ) => {
    return (
      <>
        {(isShowReturnReasonLogIcon || isRequestReasonLog) && (
          <Tooltip title={title} placement="right" color="#3E6CB5">
            <div
              className={
                isLabelIcon
                  ? reviseStyles.reasonLogIconDiv
                  : approveStyles.reasonLogIconDiv
              }
            >
              <ReasonLogIcon
                onClick={() =>
                  isRequestReasonLog
                    ? this.OpenRequestReasonLogModal()
                    : this.OpenRetrunReasonLogModal()
                }
                altText="Return Reason Log"
                rowindex={1}
              />
            </div>
          </Tooltip>
        )}
      </>
    );
  };

  handleReadOnlyReasonInput = (reason) => {
    return (
      <div className="returnReasonWrapper">
        <Input.TextArea
          className="antTextare"
          readOnly={true}
          value={reason}
          style={{
            height: 120,
            resize: "none",
          }}
          showCount={true}
          maxLength={1000}
        />
      </div>
    );
  };

  handleReasonInput = (reasonValue, reasonInputStatus) => {
    return (
      <div>
        <Input.TextArea
          className="antTextare"
          value={reasonValue}
          onChange={(e) => this.ReasonOnchange(e.target)}
          status={reasonInputStatus}
          style={{
            height: 120,
            resize: "none",
          }}
          showCount={true}
          maxLength={1000}
        />
      </div>
    );
  };

  handleReviseReturnReason = () => {
    const { isShowReturnReasonLogIcon, reason } = this.state;
    return (
      <>
        <div className={reviseStyles.reviseShareInfoTop}>
          <div className={approveStyles.approveShareTitleDiv}>
            <span className={`${styles.shareTitle} fs-14`}>Return Reason</span>
          </div>
          {this.handleReasonLog(
            "View previous Return Reason",
            false,
            isShowReturnReasonLogIcon,
            false
          )}
        </div>
        {this.handleReadOnlyReasonInput(reason)}
      </>
    );
  };

  handleApproveSubmitReason = () => {
    const { isShowReturnReasonLogIcon, reason } = this.state;
    return (
      <>
        <div className={approveStyles.approveShareInfoTop}>
          <div className={approveStyles.approveShareTitleDiv}>
            <span className={`${styles.shareTitle} fs-14`}>Reason</span>
          </div>
          {this.handleReasonLog(
            "View previous Reason",
            false,
            isShowReturnReasonLogIcon,
            true
          )}
        </div>
        {this.handleReadOnlyReasonInput(reason)}
      </>
    );
  };

  handleReviseSubmitReason = (resonRequired = false) => {
    const { isShowReturnReasonLogIcon, reasonValue } = this.state;
    return (
      <>
        <div className={approveStyles.approveShareInfoTop}>
          <div className={approveStyles.approveShareTitleDiv}>
            <InputLabel
              text="Reason"
              required={resonRequired}
              className={styles.modalText}
            />
          </div>
          {this.handleReasonLog(
            "View previous Reason",
            true,
            isShowReturnReasonLogIcon,
            true
          )}
        </div>
        {this.handleReadOnlyReasonInput(reasonValue)}
      </>
    );
  };

  handleSubmitSubmitReason = () => {
    const { reasonValue } = this.state;
    return (
      <>
        <p className={`${styles.reasonTitle} fs-14`}>Reason</p>
        {this.handleReadOnlyReasonInput(reasonValue)}
      </>
    );
  };

  handleApproveReturnReasonLog = () => {
    const { isShowReturnReasonLogIcon } = this.state;
    return this.handleReasonLog(
      "View previous Return Reason",
      false,
      isShowReturnReasonLogIcon,
      false
    );
  };

  isMainTaskColumn = {
    title: "",
    dataIndex: "isMainTask",
    width: "9%",
    render: (isMainTask) => (
      <span
        className={`${
          isMainTask ? styles.mainTaskText : styles.tableText
        } fs-14`}
      >
        {isMainTask ? "Main Task" : "Shared Task"}
      </span>
    ),
  };

  effectiveDateColumn = {
    title: "Effective Date",
    dataIndex: "effectiveDate",
    ellipsis: {
      showTitle: false,
    },
    render: (effectiveDate) => (
      <Tooltip placement="topLeft" title={this._formateDate(effectiveDate)}>
        <span className={`${styles.tableText} fs-14`}>
          {this._formateDate(effectiveDate)}
        </span>
      </Tooltip>
    ),
  };

  changeMainTagText = {
    title: "",
    dataIndex: "changeMainTaskTag",
    render: (text, record) => {
      if (record.isOldMainTask) {
        return (
          <span className={styles.switchTextSharedTask}>Old Main Task</span>
        );
      }

      if (record.isNewMainTask) {
        return <span className={styles.switchTextMainTask}>New Main Task</span>;
      }

      if (!record.isNewMainTask && record.isMainTask) {
        return <span className={styles.switchTextMainTask}>Main Task</span>;
      }

      return <span className={styles.switchTextSharedTask}>Shared Task</span>;
    },
  };

  tagColumn = (width) => {
    return {
      title: "",
      dataIndex: "tag",
      render: (text, record) => (
        <div className={styles.fileStatus}>
          <FileStatusDiv status={this.HandleModalTableTag(record)} />
        </div>
      ),
      width: width,
    };
  };

  commonShareDataColumn = [
    {
      title: "Account",
      dataIndex: "account",
      ellipsis: {
        showTitle: false,
      },
      render: (account, record) => (
        <Tooltip placement="topLeft" title={account}>
          <span className={`${styles.tableText} fs-14`}>{account}</span>
        </Tooltip>
      ),
    },
    {
      title: "Task Tree Path",
      dataIndex: "taskTreePath",
      ellipsis: {
        showTitle: false,
      },
      render: (taskTreePath) => (
        <Tooltip placement="topLeft" title={taskTreePath}>
          <span className={`${styles.tableText} fs-14`}>{taskTreePath}</span>
        </Tooltip>
      ),
    },
    {
      title: "Task Label Name",
      dataIndex: "taskLabelName",
      ellipsis: {
        showTitle: false,
      },
      render: (taskLabelName, record) => (
        <Tooltip placement="topLeft" title={taskLabelName}>
          <span
            className={`${styles.clickTableText} fs-14`}
            onClick={() => this.HandleTaskLableClick(record)}
          >
            {taskLabelName}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Task ID",
      dataIndex: "taskId",
      ellipsis: {
        showTitle: false,
      },
      width: "7%",
      render: (taskId) => (
        <Tooltip placement="topLeft" title={taskId}>
          <span className={`${styles.tableText} fs-14`}>{taskId}</span>
        </Tooltip>
      ),
    },
    {
      title: "Primary Procedure Owner",
      dataIndex: "ppo",
      ellipsis: {
        showTitle: false,
      },
      width: "13.5%",
      render: (ppo) => (
        <Tooltip placement="bottomLeft" title={ppo}>
          <span className={`${styles.tableText} fs-14`}>{ppo}</span>
        </Tooltip>
      ),
    },
    {
      title: "Procedure Owner",
      dataIndex: "po",
      ellipsis: {
        showTitle: false,
      },
      render: (po) => (
        <Tooltip placement="bottomLeft" title={po}>
          <span className={`${styles.tableText} fs-14`}>{po}</span>
        </Tooltip>
      ),
    },
    {
      title: "Procedure Saved In",
      dataIndex: "procedureSavedIn",
      ellipsis: {
        showTitle: false,
      },
      render: (procedureSavedIn) => (
        <Tooltip placement="topLeft" title={procedureSavedIn}>
          <span className={`${styles.tableText} fs-14`}>
            {procedureSavedIn}
          </span>
        </Tooltip>
      ),
      width: "10.3%",
    },
    {
      title: "Client Server Path",
      dataIndex: "clientServerPath",
      ellipsis: {
        showTitle: false,
      },
      render: (clientServerPath) => (
        <Tooltip placement="topLeft" title={clientServerPath}>
          <span className={`${styles.tableText} fs-14`}>
            {clientServerPath}
          </span>
        </Tooltip>
      ),
    },
  ];

  render() {
    return null;
  }
}
