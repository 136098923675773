import TransitionWhenHeightChange from "../../../../../../base/basicComponents/auto-height-transition/transition-when-height-change";
import BaseFileTable from "../../../../../commonComponents/fileTables/baseFileTable";
import BaseThead from "../../../../../commonComponents/fileTheads/baseFileThead";
import BaseTbody from "../../../../../commonComponents/fileTbodys/baseFileTbody";
import fullScreenIcon from "../../../../../../assets/image/fullScreen.png";
import "../index.scss";

class DetailFileTable extends BaseFileTable {
  handleReturnTbody = (obj) => {
    const {
      data,
      columns,
      inputOnChange,
      procedureNoteMaxLength,
      fileSectionDisabled,
      procedureNoteSize,
      hasSubmit,
      fileTableRef,
      optionList,
      isShowReturnReason,
      handleReturnClick,
      handleFileSelect,
      currentRole,
      isRevisePage,
      downloadFile,
      isSupportingFile,
      isExpendData,
    } = obj;
    return (
      <BaseTbody
        data={data}
        columns={columns}
        inputOnChange={inputOnChange}
        procedureNoteMaxLength={procedureNoteMaxLength}
        fileSectionDisabled={fileSectionDisabled}
        procedureNoteSize={procedureNoteSize}
        hasSubmit={hasSubmit}
        fileTableRef={fileTableRef}
        optionList={optionList}
        isHasReturnReason={isShowReturnReason}
        handleReturnClick={handleReturnClick}
        handleFileSelect={handleFileSelect}
        currentRole={currentRole}
        isRevisePage={isRevisePage}
        downloadFile={downloadFile}
        isSupportingFile={isSupportingFile}
        handleChangeTableHeight={this.handleChangeTableHeight}
        setIsExpendNote={this.setIsExpendNote}
        isExpendData={isExpendData}
      />
    );
  };

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: this.props.columns,
      isExpendData: false,
      isExpendNote: false,
      iconSrc: fullScreenIcon,
      expendHeight: 40,
      isTableBorderError: false,
    };
    this.defaultShowDataNum = 4;
    this.returnNumber = 0;
    this.approveNumber = 0;
    this.newDataList = [];
  }

  render() {
    const {
      procedureNoteMaxLength,
      fileSectionDisabled,
      procedureNoteSize,
      inputOnChange,
      hasSubmit,
      isScrollData,
      fileTableRef,
      className,
      isShowTotalFileNum,
      optionList,
      isShowReturnReason,
      handleReturnClick,
      handleFileSelect,
      currentRole,
      isRevisePage,
      downloadFile,
      isSupportingFile,
      isShowExpendIcon,
      fileTableStatus,
      isShowHistoryVersion,
    } = this.props;
    const { columns, data, isExpendData, iconSrc, expendHeight, isExpendNote } =
      this.state;
    const height = data.length < 4 ? data.length * 76 + 40 : 344;

    return (
      <>
        <div className="tableTop">
          {data.length > this.defaultShowDataNum && isShowExpendIcon && (
            <p className="listControlIcon">
              <img
                src={iconSrc}
                alt={isExpendData ? "pick up" : "show all"}
                onMouseEnter={this.handleHoverExpendIcon}
                onMouseLeave={this.handleDefaultExpendIcon}
                onClick={this.handleExpendFlag}
              />
            </p>
          )}
        </div>
        <div className="scrollTable">
          {isScrollData ? (
            <TransitionWhenHeightChange
              height={this.handleTransitionHeight(
                data.length,
                isExpendData,
                isExpendNote,
                expendHeight,
                height
              )}
              duretion={300}
              timeFunction={"ease"}
              overflow={this.handleOverFlow(
                data.length,
                isExpendData,
                isExpendNote
              )}
            >
              <table
                className={`${className} table ${
                  fileTableStatus === "error" ? "tableError" : ""
                }`}
              >
                <BaseThead
                  columns={columns}
                  hasSubmit={hasSubmit}
                  fileSectionDisabled={fileSectionDisabled}
                />
                {this.handleReturnTbody({
                  data,
                  columns,
                  inputOnChange,
                  procedureNoteMaxLength,
                  fileSectionDisabled,
                  procedureNoteSize,
                  hasSubmit,
                  fileTableRef,
                  optionList,
                  isShowReturnReason,
                  handleReturnClick,
                  handleFileSelect,
                  currentRole,
                  isRevisePage,
                  downloadFile,
                  isSupportingFile,
                  isExpendData,
                  isShowHistoryVersion,
                })}
              </table>
            </TransitionWhenHeightChange>
          ) : (
            <table className={`${className} table`}>
              <BaseThead columns={columns} hasSubmit={hasSubmit} />
              {this.handleReturnTbody({
                data,
                columns,
                inputOnChange,
                procedureNoteMaxLength,
                fileSectionDisabled,
                procedureNoteSize,
                hasSubmit,
                fileTableRef,
                optionList,
                isShowReturnReason,
                handleReturnClick,
                handleFileSelect,
                currentRole,
                isRevisePage,
                downloadFile,
                isSupportingFile,
                isExpendData,
                isShowHistoryVersion,
              })}
            </table>
          )}
        </div>
        {isShowTotalFileNum && (
          <p className="fileNumTip" style={{ color: "#333" }}>
            <span className="fileNum">{this.state.data.length}</span>{" "}
            {this.setFileText(this.state.data.length)} in total
          </p>
        )}
      </>
    );
  }
}

export default DetailFileTable;
