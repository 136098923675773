import React, { createRef } from "react";
import { Input } from "antd";
import BaseComponent from "../../../../../base/common/BaseComponent";
import { ExpendTextArea } from "../../../../../base/basicComponents/input";
import Buttons from "../../../../../base/basicComponents/button/index";
import InputLabel from "../../../../../base/basicComponents/inputLabel";
import NormalModal, {
  ModalWithComponent,
} from "../../../../../base/basicComponents/modal";
import {
  dataStatus,
  procedureJbStatus,
} from "../../../../publicDictionaryValues";
import styles from "./index.module.scss";
import "./serverPath.scss";
import { SectionStatusDiv } from "../../../../../base/basicComponents/status";
import ProcedureDetailService from "../../../../../service/procedureFile/ProcedureDetailService";
import AppSetting from "../../../../../config/AppSetting";

const modalRef = createRef();
export default class ApplyJbClientServerPath extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      modalChildContent: "",
      handleModalConfirm: () => {
        //there is a empty function
      },
      serverPathSectionState: {
        serverPathStatus: this.props.serverPathStatusID,
        serverPathReturnReasonValue: this.props.clientServerPathReturnReason,
        isServerPathReturnBtnDisabled: this.props.isServerPathReturnBtnDisabled,
        isServerPathApproveBtnDisabled:
          this.props.isServerPathApproveBtnDisabled,
        isServerPathShowEditBtn: false,
        serverPathHide: false,
        isServerPathError: false,
        serverPathReasonStatus: null,
        serverPathReturnReasonInputValue:
          this.props.serverPathReturnReasonInputValue,
        serverPathValue: this.props.serverPathValue,
      },
      modalState: {
        isModalWithComponentOpen: false,
        modalTitle: "",
        okText: "",
        cancelText: "Cancel",
        textAreaPlacement: "",
        textAreaMaxLength: 0,
        modalLabel: "",
        isShowTextArea: false,
        closable: false,
        modalWidth: 900,
        handleOk: null,
        handleCancel: null,
        isShowSelect: false,
      },
    };

    this.isServerPathReturned = this.props.isServerPathReturned;
    this.returnModalInputPlacement = "Please enter your return reason.";
    this.modalContentLabels = {
      returnModalLable: (
        <InputLabel
          text="Please fill in the reason for rejection"
          required={true}
          className={styles.modalText}
        />
      ),
      approveAfterReturnModalLabel: (
        <span className={styles.modalText}>
          Are you sure to change the selection? Your Reject Reason will be
          cleared.
        </span>
      ),
    };
    this.modalTitles = {
      returnModalTitle: <span className="fs-18">Reason for Rejection</span>,
    };
  }

  /* function about close modal */
  hideModal = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: false,
        modalTitle: "",
        okText: "",
        cancelText: "Cancel",
        textAreaPlacement: "",
        textAreaMaxLength: 0,
        modalLabel: "",
        isShowTextArea: false,
        closable: false,
        // modalWidth: 900,
        isShowSelect: false,
      },
    });
  };

  handleReturnCancelComfirm = () => {
    const serverPathReturnReasonValue =
      this.state.serverPathSectionState.serverPathReturnReasonValue;

    this.setState({
      isModalOpen: false,
      modalChildContent: "",
      handleModalConfirm: () => {
        // thre is a empty function
      },
      serverPathSectionState: {
        ...this.state.serverPathSectionState,
        serverPathReturnReasonInputValue: serverPathReturnReasonValue,
        serverPathReasonStatus: null,
      },
    });

    const allToxTinymceElements = document.querySelectorAll(".tox-tinymce");

    allToxTinymceElements.forEach((element) => {
      element.style.border = "2px solid #eee";
    });
    this.hideModal();
  };

  /* function about click return popUp cancel button */
  handleReturnCancelClick = () => {
    if (this.props.readOnly === true) {
      this.handleReturnCancelComfirm();
    } else {
      this.setState({
        isModalOpen: true,
        modalChildContent: "Are you sure to quit without saving?",
        handleModalConfirm: this.handleReturnCancelComfirm,
      });
    }
  };

  /* function about click confirm button of return to draft modal in server path section */
  handleServerPathReturnOkClick = () => {
    const serverPathReturnReasonInputValue =
      this.state.serverPathSectionState.serverPathReturnReasonInputValue;
    let value =
      serverPathReturnReasonInputValue !== null
        ? serverPathReturnReasonInputValue.trim()
        : serverPathReturnReasonInputValue;
    if (
      value !== null &&
      value !== "" &&
      this.richTextEditorValueNotEmpty(value)
    ) {
      this.cancelServerPathError();
      this.hideModal();
      this.setState({
        serverPathSectionState: {
          ...this.state.serverPathSectionState,
          serverPathReturnReasonValue: value,
          serverPathStatus: dataStatus.reject,
          isServerPathReturnBtnDisabled: true,
          isServerPathApproveBtnDisabled: false,
          isServerPathShowEditBtn: true,
        },
      });

      this.isServerPathReturned = true;
    } else {
      this.setState({
        serverPathSectionState: {
          ...this.state.serverPathSectionState,
          serverPathReasonStatus: "error",
        },
      });

      const allToxTinymceElements = document.querySelectorAll(".tox-tinymce");

      allToxTinymceElements.forEach((element) => {
        element.style.border = "2px solid red";
      });
    }
  };

  /* function about click approve popUp cancel button */
  handleSectionApproveCancelClick = () => {
    this.hideModal();
  };

  /**
   * function about set return reason value in server path section
   * @param {*} e
   */
  handleSetServerPathReturnReasonValue = (content, editor) => {
    this.setState({
      serverPathSectionState: {
        ...this.state.serverPathSectionState,
        serverPathReturnReasonInputValue: content,
        serverPathReasonStatus: null,
      },
    });

    const allToxTinymceElements = document.querySelectorAll(".tox-tinymce");

    allToxTinymceElements.forEach((element) => {
      element.style.border = "2px solid #eee";
    });
  };

  /* function about click client server path section return to draft button */
  handleServerPathReturnClick = () => {
    console.log(
      this.state.serverPathSectionState.serverPathReturnReasonInputValue
    );
    modalRef.current.setRichTextValue(
      this.state.serverPathSectionState.serverPathReturnReasonValue
    );
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: true,
        modalTitle: this.modalTitles.returnModalTitle,
        okText: "Confirm",
        textAreaPlacement: this.returnModalInputPlacement,
        textAreaMaxLength: 1000,
        modalLabel: this.modalContentLabels.returnModalLable,
        isShowTextArea: true,
        closable: false,
        modalWidth: 900,
        handleOk: this.handleServerPathReturnOkClick,
        handleCancel: this.handleReturnCancelClick,
      },
    });
  };

  /* function about data change click approve in client server path section after clicking return to draft button */
  serverPathApproveAfterReturnDataChange = () => {
    this.setState({
      serverPathSectionState: {
        ...this.state.serverPathSectionState,
        serverPathReturnReasonInputValue: null,
        serverPathReturnReasonValue: null,
        serverPathStatus: dataStatus.approval,
        isServerPathApproveBtnDisabled: true,
        isServerPathReturnBtnDisabled: false,
        isServerPathShowEditBtn: false,
        isServerPathError: false,
      },
    });
    this.isServerPathReturned = false;
    this.hideModal();
  };

  /* function about click confirm button in approve modal in client server path section after clicking return to draft button */
  handleServerPathApproveAfterReturnOkClick = () => {
    this.serverPathApproveAfterReturnDataChange();
  };

  /* function about click saved in section approve button after i click return to draft before */
  handleApprovedAfterReturned = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: true,
        modalLabel: this.modalContentLabels.approveAfterReturnModalLabel,
        okText: "Confirm",
        modalTitle: null,
        closable: false,
        modalWidth: 520,
        handleOk: this.handleServerPathApproveAfterReturnOkClick,
        handleCancel: this.hideModal,
      },
    });
  };

  handleServerPathNoReturnApprove = () => {
    this.setState({
      serverPathSectionState: {
        ...this.state.serverPathSectionState,
        serverPathStatus: dataStatus.approval,
        isServerPathApproveBtnDisabled: true,
        isServerPathReturnBtnDisabled: false,
        isServerPathExpendNote: false,
        isServerPathShowEditBtn: false,
        isServerPathError: false,
      },
    });
    this.hideModal();
  };

  /* function about click approve button in client server path section */
  handleServerPathApprovedClick = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: true,
        modalWidth: 900,
      },
    });
    if (this.isServerPathReturned) {
      this.handleApprovedAfterReturned();
    } else {
      this.handleServerPathNoReturnApprove();
    }
  };

  /* function about click confirm button in approve modal in client server path section */
  handleServerPathApproveOkClick = () => {
    this.serverPathApproveAfterReturnDataChange();
  };

  /**
   * if server path required not follow will set error state
   * @returns
   */
  setServerPathError = () =>
    this.setState({
      serverPathSectionState: {
        ...this.state.serverPathSectionState,
        isServerPathError: true,
      },
    });

  /**
   * remove the server path error state
   * @returns
   */
  cancelServerPathError = () =>
    this.setState({
      serverPathSectionState: {
        ...this.state.serverPathSectionState,
        isServerPathError: false,
      },
    });

  setApproveServerPathTop = () => {
    return (
      <div className={styles.approveServerPathTop}>
        <div className={styles.procedureServerPathLabel}>
          Client Server Path:
        </div>
        <ExpendTextArea
          className={styles.procedureServerPathValue}
          value={this.state.serverPathSectionState.serverPathValue}
          placeholder="Please enter Client Server Path"
          readonly="readonly"
          maxLength={500}
        />
      </div>
    );
  };

  handleJbProcedureSavedValue = (procedureJbStatusId) => {
    if (procedureJbStatusId === null) {
      return null;
    }

    return procedureJbStatus[procedureJbStatusId];
  };

  getAddFileDto = (item) => {
    return {
      FileName: item.name,
      ContainerName: AppSetting.urlPrefix.addFile.ContainerName,
      AccountName: AppSetting.urlPrefix.addFile.AzureAccountName,
      ProductKey: AppSetting.urlPrefix.addFile.ProductKey,
      FolderName: AppSetting.urlPrefix.addFile.ProcedureFileFolder,
    };
  };

  uploadFileOnSuccess = (result, returnPara) => {
    returnPara.callback(returnPara.fileName, { text: returnPara.fileName });
  };

  uploadFileOnError = () => {};

  uploadFile = (e, callback) => {
    var file = e.files[0];
    let fileForm = new FormData();
    let addFileDto = this.getAddFileDto(file);
    fileForm.append("formFile", file);
    fileForm.append("addFileDto", JSON.stringify(addFileDto));
    let returnPara = {
      fileName: file.name,
      callback: callback,
      // fileIndex: fileIndex,
    };

    ProcedureDetailService.addFile(
      fileForm,
      this.uploadFileOnSuccess,
      this.uploadFileOnError,
      returnPara
    );
  };

  handleUploadFile = (callback, value, meta) => {
    var input = document.createElement("input");
    input.setAttribute("type", "file");
    input.click();
    input.onchange = (e) => this.uploadFile(e.target, callback);
  };

  handleRejectReasonClass = () => {
    const value = this.state.serverPathSectionState.serverPathReturnReasonValue;
    if (value !== null && value !== undefined && value !== "") {
      return styles.rejectReasonShow;
    }

    return styles.rejectReasonHide;
  };

  handleRejectReasonClick = () => {
    this.handleServerPathReturnClick();
  };

  hideNormalModal = () => {
    this.setState({
      isModalOpen: false,
      modalChildContent: "",
      handleModalConfirm: () => {
        // thre is a empty function
      },
    });
  };

  applyForValueNotJb = (applyForValue) => {
    if (procedureJbStatus[applyForValue] === "JB") {
      return false;
    }

    return true;
  };

  createServerPathBtns = (
    isServerPathApproveBtnDisabled,
    isDisableAll,
    handleServerPathApprovedClick,
    isServerPathReturnBtnDisabled,
    handleServerPathReturnClick
  ) => (
    <>
      <div>
        <Buttons
          color="blue"
          disabled={isServerPathApproveBtnDisabled || isDisableAll}
          size="small"
          onClick={handleServerPathApprovedClick}
          btnClassName={styles.approveBtn}
        >
          Approve
        </Buttons>
      </div>
      <div>
        <Buttons
          color="red"
          disabled={isServerPathReturnBtnDisabled || isDisableAll}
          size="small"
          onClick={handleServerPathReturnClick}
          btnClassName={styles.returnBtn}
        >
          Reject
        </Buttons>
      </div>
    </>
  );

  render() {
    const {
      procedureServerPathRef,
      isDisableAll,
      procedureJbStatusId,
      applyForValue,
      readOnly = false,
    } = this.props;
    const {
      serverPathStatus,
      isServerPathApproveBtnDisabled,
      isServerPathReturnBtnDisabled,
      isServerPathError,
      serverPathReturnReasonInputValue,
      serverPathReasonStatus,
    } = this.state.serverPathSectionState;
    const {
      isModalWithComponentOpen,
      modalTitle,
      okText,
      cancelText,
      textAreaPlacement,
      textAreaMaxLength,
      modalLabel,
      isShowTextArea,
      closable,
      modalWidth,
      handleOk,
      handleCancel,
      isShowSelect,
    } = this.state.modalState;
    const { isModalOpen, modalChildContent, handleModalConfirm } = this.state;

    return (
      <div className={styles.procedureFileTop}>
        <div
          className={styles.clientServerPath}
          style={{ borderColor: isServerPathError ? "red" : "#b3b3b3" }}
          ref={procedureServerPathRef}
        >
          <div>{this.setApproveServerPathTop()}</div>
          <div className={styles.serverPathContent}>
            <div className={styles.statusBlock}>
              <div className={styles.statusLabel}>Status</div>
              <div className={styles.statusContent}>
                <SectionStatusDiv status={serverPathStatus} />
              </div>
            </div>
            <div className={styles.jbProcedureBlock}>
              <span className={styles.returnReasonLabel}>
                JB Procedure Saved
              </span>
              <Input
                value={this.handleJbProcedureSavedValue(procedureJbStatusId)}
                disabled
                className="jbProcedureInput"
              />
            </div>
            <div className={styles.applyForBlock}>
              <span className={styles.returnReasonLabel}>Apply for?</span>
              <Input
                value={this.handleJbProcedureSavedValue(applyForValue)}
                disabled
                className="jbProcedureInput"
              />
            </div>
            <div className={styles.returnReasonBlock}>
              <div className={styles.returnReasonLabel}>Reject Reason</div>
              <p
                className={this.handleRejectReasonClass()}
                onClick={this.handleRejectReasonClick}
              >
                View Reject Reason
              </p>
            </div>
            <div
              className={`${styles.actionBtns} ${
                (readOnly || this.applyForValueNotJb(applyForValue.trim())) &&
                styles.hide
              }`}
            >
              {this.createServerPathBtns(
                isServerPathApproveBtnDisabled,
                isDisableAll,
                this.handleServerPathApprovedClick,
                isServerPathReturnBtnDisabled,
                this.handleServerPathReturnClick
              )}
            </div>
          </div>
        </div>
        <ModalWithComponent
          ref={modalRef}
          isModalOpen={isModalWithComponentOpen}
          closable={readOnly ? true : closable}
          okText={okText}
          cancelText={cancelText}
          title={modalTitle}
          className={styles.approvePageModal}
          handleOk={handleOk}
          handleCancel={handleCancel}
          isShowRichTextEditor={isShowTextArea}
          isLoading={false}
          richTextValue={serverPathReturnReasonInputValue}
          handleEditorChange={this.handleSetServerPathReturnReasonValue}
          handleUploadFile={this.handleUploadFile}
          textAreaPlacement={textAreaPlacement}
          textAreaMaxLength={textAreaMaxLength}
          textAreaStatus={serverPathReasonStatus}
          label={readOnly ? null : modalLabel}
          modalWidth={modalWidth}
          isShowSelect={isShowSelect}
          footer={readOnly ? false : undefined}
          richTextDisabled={readOnly ? true : false}
          centered={true}
        />
        <NormalModal
          okText="Confirm"
          cancelText="Cancel"
          childContent={<p className="fs-16">{modalChildContent}</p>}
          isModalOpen={isModalOpen}
          handleOk={handleModalConfirm}
          handleCancel={this.hideNormalModal}
        />
      </div>
    );
  }
}
