import BaseComponent from "base/common/RoutingComponent";
import AppSetting from "config/AppSetting.js";

export default class reportIFrame extends BaseComponent {
  constructor(props) {
    super(props);
  }

  iFrameHeight = () => {
    var n = document.getElementById("iframepage");
    n.height = document.documentElement.clientHeight;
  };

  render() {
    const internalUserID =
      AppSetting.localStorageValue.user.get().identityUserInfo.internalUserID;
    const reportKey = this._getRoutingParam("ReportKey");
    const reportLinks = [
      {
        key: "ProcedureMasterList",
        value: `${process.env.REPORT_PROCEDURE_MASTER_LIST}&IdentityUserID=${internalUserID}&rc:Zoom=100%&rs:Embed=true`,
      },
      {
        key: "ProcedureUpdateTrackingLog",
        value: `${process.env.REPORT_PROCEDURE_UPDATE_TRACKING_LOG}&IdentityUserID=${internalUserID}&rc:Zoom=100%&rs:Embed=true`,
      },
    ];

    const reportLink =
      reportLinks.find((x) => x.key === reportKey)?.value ?? null;

    return reportLink == null ? (
      <span>Not Found This Report</span>
    ) : (
      <iframe
        id="iframepage"
        scrolling="no"
        frameBorder={"0"}
        src={reportLink}
        // onload={this.iFrameHeight()}
        height="100%"
        width={"100%"}
      ></iframe>
    );
  }
}
