import React from "react";
import InputLabel from "../../../../../base/basicComponents/inputLabel";
import Input, {
  NormalTextArea,
  ExpendTextArea,
} from "../../../../../base/basicComponents/input";
import NormalSelect from "../../../../../base/basicComponents/select";
import Buttons from "../../../../../base/basicComponents/button";
import SectionEditIcon from "../../../../../base/basicComponents/sectionEditIcon";
import NormalToolTip from "../../../../../base/basicComponents/toolTip";
import BaseProcedureInformation from "../../baseProcedureInfoSection";
import styles from "../../index.module.scss";
import { sortSelectOptionList } from "../../../../publicDictionaryValues";
import redTipIcon from "../../../../../assets/image/redTipIcon.png";
export default class ProcedureInfoDetailBase extends BaseProcedureInformation {
  PPOdisabledMessage = () => {
    return (
      <div className={styles.messageWrapper}>
        <div className={styles.title}>
          Please note that you cannot edit Primary Procedure Owner field due to one of the following reasons:
        </div>
        <div className={styles.content}>
            <div className={styles.contentItem}>1. You are not Primary Procedure Owner or Account Specialist and above.</div>
            <div className={styles.contentItem}>2. This task is a shared task.</div>
            <div className={styles.contentItem}>3. There is a pending Change Primary Procedure Owner process.</div>
            <div className={styles.contentItem}>4. There is a pending Manage Shared Task process or Change Main Task process.</div>
        </div>
      </div>
    );
  }

  POdisabledMessage = () => {
    return (
      <div className={styles.messageWrapper}>
        <div className={styles.title}>
          Please note that you cannot edit Procedure Owner field due to one of the following reasons:
        </div>
        <div className={styles.content}>
            <div className={styles.contentItem}>1. You are not Account Specialist and above.</div>
            <div className={styles.contentItem}>2. This task is not a shared task.</div>
            <div className={styles.contentItem}>3. There is a pending Change Procedure Owner process.</div>
        </div>
      </div>
    );
  }

  disabledMessage = (type) => {
    return (
      <div className={styles.disabledMsgWrapper}>
        <div className={styles.disabledMessage}>This field is not editable.</div>
        <NormalToolTip
          element={
            <div className={styles.disabledTipIcon}>
              <img src={redTipIcon} alt="Reject" />
            </div>
          }
          title={type === 'PPO' ? this.PPOdisabledMessage() : this.POdisabledMessage()}
          color="#FFFFFF"
          overlayStyle={{minWidth: type === 'PPO' ? '35rem' : '31.5rem'}}
          placement="right"
        />
      </div>
    );
  }

  render() {
    const {
      onSubmit,
      onCancel,
      handlePpoChange,
      handlePoChange,
      handleNoteChange,
      changePPOReason,
      changePOReason,
      isShowActions,
      noteDisabled,
      reasonDisabled,
      PPOmessageCode,
      POmessageCode,
      isPPODisabled,
      isPODisabled,
      ppoList,
      procedureStatus,
      isShowEdit,
      handleEditClick,
      isEditToolTipOpen,
      onBlur,
      currentRef,
      title,
      hintMsg,
      isSection,
    } = this.props;

    const {
      currentProcedureStatus,
      currentPMPExcluded,
      currentPPO,
      currentPPOName,
      currentPO,
      currentPOName,
      currentNote,
      currentReason,
      isChange,
    } = this.state;

    const infoList = {
      PPOList: ppoList.map((item) => {
        return {
          name: item.name,
          value: item.id,
        };
      }),
      status: procedureStatus,
      notes: "",
      reason: "",
    };

    const handleOptionList = (inputValue, option) =>
      (option?.title || "").toLowerCase().includes(inputValue.toLowerCase());

    const labelInputComponent = (text, value, disabled) => {
      return (
        <div className={styles.informationItem}>
          <InputLabel text={text} className={styles.informationLabel} />
          <Input value={value} disabled={disabled} />
        </div>
      );
    };

    const newPPOValue = () => {
      let newCurrentPPO = currentPPO;
      if (
        infoList.PPOList.find((item) => item.value === currentPPO) === undefined
      ) {
        newCurrentPPO = currentPPOName;
      }
      return newCurrentPPO;
    }

    const newPOValue = () => {
      let newCurrentPO = currentPO;
      if (
        infoList.PPOList.find((item) => item.value === currentPO) === undefined
      ) {
        newCurrentPO = currentPOName;
      }
      return newCurrentPO;
    }

    const ReadOnlyMessage = (type) => {
      let text = null;
      switch (type) {
        case 38:
          text = "Change Primary Procedure Owner Process is pending approval.";
          break;
        case 9:
          text = "Change Procedure Owner Process is pending approval.";
          break;
        default:
          break;
      }
      return <div className={styles.PPOReadOnlyMessage}>{text}</div>;
    };

    return (
      <div className={styles.ProcedureInformation}>
        <div className={styles.procedureInfoTop}>
          <div className={styles.informationTitle}>
            <span className={`${styles.titleText} fs-18`}>{title}</span>
          </div>
          {isShowEdit && (
            <NormalToolTip
              element={
                <SectionEditIcon
                  handleEditClick={handleEditClick}
                  onBlur={onBlur}
                />
              }
              title={hintMsg}
              trigger="click"
              open={isEditToolTipOpen}
              placement="left"
              color="#3E6CB5"
            />
          )}
        </div>
        <div className={styles.informationContent}>
          {labelInputComponent(
            "Procedure Status",
            currentProcedureStatus,
            true
          )}
          {labelInputComponent(
            "PMP Excluded",
            currentPMPExcluded === true ? "Yes" : "No",
            true
          )}
          <div className={styles.informationItem}>
            <InputLabel
              text="Primary Procedure Owner"
              className={styles.informationLabel}
            />
            <NormalSelect
              optionList={sortSelectOptionList(infoList.PPOList)}
              value={newPPOValue()}
              handleChange={(e) => {
                handlePpoChange(e, currentRef);
              }}
              disabled={isPPODisabled}
              showSearch={true}
              handleFilterOption={handleOptionList}
            />
            {ReadOnlyMessage(PPOmessageCode)}
            {isPPODisabled && !isSection && this.disabledMessage('PPO')}
          </div>
          {this.state.isPPOChange && (
            <div className={styles.informationReasonItem}>
              <InputLabel 
                text="Reason" 
                className={styles.informationLabel}
              />
              <ExpendTextArea
                className={styles.informationExpendTextArea}
                placeholder="Please enter your reason."
                onChange={(e) => {
                  changePPOReason(e.target.value, currentRef);
                }}
                value={currentReason}
                disabled={reasonDisabled}
                maxLength={1000}
              />
            </div>
          )}
          <div className={styles.informationItem}>
            <InputLabel
              text="Procedure Owner"
              className={styles.informationLabel}
            />
            <NormalSelect
              optionList={sortSelectOptionList(infoList.PPOList)}
              value={newPOValue()}
              handleChange={(e) => {
                handlePoChange(e, currentRef);
              }}
              disabled={isPODisabled}
              showSearch={true}
              handleFilterOption={handleOptionList}
            />
            {ReadOnlyMessage(POmessageCode)}
            {isPODisabled && !isSection && this.disabledMessage('PO')}
          </div>
          {this.state.isPOChange && (
            <div className={styles.informationReasonItem}>
              <InputLabel 
                text="Reason" 
                className={styles.informationLabel}
              />
              <ExpendTextArea
                className={styles.informationExpendTextArea}
                placeholder="Please enter your reason."
                onChange={(e) => {
                  changePOReason(e.target.value, currentRef);
                }}
                value={currentReason}
                disabled={reasonDisabled}
                maxLength={1000}
              />
            </div>
          )}
          <div className={styles.informationItem}>
            <InputLabel text="Note" className={styles.informationLabel} />
            <ExpendTextArea
              className={styles.informationExpendTextArea}
              onChange={(e) => {
                handleNoteChange(e.target.value, currentRef);
              }}
              value={currentNote}
              disabled={noteDisabled}
              maxLength={1000}
            />
          </div>
        </div>
        {isShowActions && (
          <div className={styles.informationBtns}>
            <Buttons
              color="grey"
              disabled={!isChange}
              size="middle"
              onClick={onCancel}
            >
              Cancel
            </Buttons>
            <Buttons
              color="blue"
              disabled={!isChange}
              size="middle"
              onClick={onSubmit}
            >
              Submit for Approval
            </Buttons>
          </div>
        )}
      </div>
    );
  }
}
