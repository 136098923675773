import React, { createRef } from "react";
import BaseComponent from "../../../../../base/common/BaseComponent";
import ProcedureBlock from "../../../../../base/basicComponents/procedureBlock";
import PageHeader from "../../../../commonComponents/pageHeaders/PageHeader";
import TaskInformation from "../../../../sections/taskInfoSections";
import ProcedureInformation from "../../../../sections/procedureInfoSections/readOnlyProcedureInfoSection";
import Buttons from "../../../../../base/basicComponents/button";
import { ModalWithComponent } from "../../../../../base/basicComponents/modal";
import {
  actions,
  dataStatus,
  fileTags,
} from "../../../../publicDictionaryValues";
import CreateSupportingFileReviseSection from "../../../../sections/supportingFileSections/createSupportingFileReviseSection";
import ReadOnlyProcedureFileSection from "../../../../sections/procedureFileSections/readOnlyProcedureFileSection";
import createSupportingFileService from "../../../../../service/supportingFile/createSupportingFileService";
import styles from "../../style/index.module.scss";
import ActiveShareTaskReadOnly from "../../../../sections/sharedTaskSections/activeShareTaskReadOnly";
import appService from "../../../../../service/app";
import LostPermissionModal from "../../../../sections/lostPermissionModal";
import StepService from "../../../../../service/stepBase/StepService";
import StepBusinessType from "../../../../../service/stepBase/stepBusinessType";

const supportingReturnAddFileRef = createRef();
const reasonRef = createRef();
const noteRef = createRef();
const procedureFileRef = createRef();
const supportingFileRef = createRef();
const supportingFileTableRef = createRef();

export default class ReviseNewSupportingFilePending extends BaseComponent {
  constructor(props) {
    super(props);
    const { rejectReason, rejectStatus } = this.props.pageProps;
    this.state = {
      pageState: {
        rejectReason,
        rejectStatus,
        isCancelDisabled: false,
        isSubmitDisabled: false,
        isRejectDisabled: false,
        isTableBorderError: false,
        isChange: false,
      },
      modalState: {
        modalTitle: "",
        modalLabel: "",
        okText: "",
        cancelText: "",
        closable: false,
        isOkBtnDisabled: false,
        isCancelBtnDisabled: false,
        maskClosable: false,
        isModalWithComponentOpen: false,
        isShowSelect: false,
        rowIndex: 0,
        fileIds: [],
        fileNames: [],
        modalWidth: 520,
      },
      noPermission: false,
    };
    this.para = null;
    this.isTableBorderError = false;
    this.isSaveDisabled = true;
    this.modalContentLabels = {
      cancelConfimModalLabel: (
        <span className={styles.modalText}>
          Are you sure to quit without saving?
        </span>
      ),
      submitConfirmModalLabel: (
        <span className={styles.modalText}>Are you sure to submit?</span>
      ),
    };
    this.params = {
      TaskId: this.props.params.ProcedureTaskId,
      ProcedureID: this.props.params.ProcedureID,
      ProcedureStepID: this.props.params.ProcedureStepID,
    };
  }

  /* function about get data from procedure file table */
  returnFileDataValue = (name, index) => {
    const supportingFileData = this.props.supportingRef.current.getDataList();
    let deleteList = this.props.supportingRef.current.getDeleteList();
    let combineDatalist = supportingFileData.concat(deleteList);
    return combineDatalist[index].data.find((item) => item.name === name);
  };

  /* success callback about click submit or save button to trigger api */
  _triggerReviseNewSupportingFilePendingSuccess = (response) => {
    if (response.succeeded === false) {
      console.log(response.message);
    } else {
      this.props.closePage();
    }
    this.props._hideLoading();
  };

  /* error callback about click submit or save button to trigger api */
  _triggerReviseNewSupportingFilePendingError = (response) => {
    console.log(response);
  };

  /* function about click submit or save button to trigger api */
  createSupportingFileTriggerAction = () => {
    createSupportingFileService.createSupportingFileTriggerRevisePageAction(
      this.para,
      this._triggerReviseNewSupportingFilePendingSuccess,
      this._triggerReviseNewSupportingFilePendingError
    );
  };

  setReviseNewSupportingFilePendingSubmitDisabled = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        isSubmitDisabled: true,
      },
    });
  };

  handleReviseNewSupportingFilePendingSubmitTriggerAction = () => {
    this.para = this.props.handleReturnSubmitData(
      actions.revise,
      "Revise",
      this.props.supportingRef
    );
    this.createSupportingFileTriggerAction();
  };

  /* function about click submit button in submit modal */
  handleReviseNewSupportingFilePendingSubmitOkClick = () => {
    let isReviseNewSupportingFilePendingTableDataReturn = false;
    this.setReviseNewSupportingFilePendingSubmitDisabled();
    this.props._showLoading();
    this.hideReviseNewSupportingFilePendingModal();
    try {
      this.props.data.forEach((fileData, index) => {
        const file = fileData.data.find((item) => item.name === "fileName");
        const statusData = fileData.data.find((item) => item.name === "status");
        isReviseNewSupportingFilePendingTableDataReturn =
          statusData.value === dataStatus.return &&
          file.tagValue !== fileTags.deleted;
        if (isReviseNewSupportingFilePendingTableDataReturn) {
          throw new Error();
        }
      });
    } catch (e) {
      console.log(e);
    }

    this.handleReviseNewSupportingFilePendingSubmitTriggerAction();
  };

  /* function about click submit button in cancel action modal */
  handleReviseNewSupportingFilePendingCancelOkClick = () => {
    this.setState({
      pageState: { ...this.state.pageState, isCancelDisabled: true },
    });
    this.hideReviseNewSupportingFilePendingModal();
    this.props.closePage();
  };

  handleReviseNewSupportingFilePendingCancelClick = () =>
    this.handleReviseNewSupportingFilePendingCreateActionClickSectionCheckModal(
      "",
      this.modalContentLabels.cancelConfimModalLabel,
      this.handleReviseNewSupportingFilePendingCancelOkClick,
      this.hideReviseNewSupportingFilePendingModal
    );

  /* function about close modal */
  hideReviseNewSupportingFilePendingModal = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: false,
        modalLabel: "",
        isShowTextAreaAndUploadDragger: false,
        modalTitle: "",
        uploadDraggerFileType: "",
        modalWidth: 520,
        isShowSelect: false,
        fileNames: [],
        textAreaClassName: "",
        textAreaMaxLength: 0,
        textAreaPlacement: "",
        maskClosable: false,
        isShowTextArea: false,
        actionType: "",
        fileIds: [],
      },
    });
  };

  /* function about click submit button in revise page to show the tip modal */
  handleReviseNewSupportingFilePendingCreateActionClickSectionCheckModal = (
    modalTitle,
    modalLabel,
    okMethod,
    cancelMethod
  ) => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        cancelText: "Cancel",
        isModalWithComponentOpen: true,
        handleCancel: cancelMethod,
        modalTitle: modalTitle,
        modalWidth: 520,
        okText: "Confirm",
        handleOk: okMethod,
        modalLabel: modalLabel,
      },
    });
  };

  handleReviseNewSupportingFilePendingControlErrorScroll = (top) =>
    window.scrollTo(0, top || 0);

  /* function about click submit button in revise page to scroll page if table has error */
  constrolTableScrollWhenSubmit = (
    isTableError,
    procedureFileData,
    errorIndexList,
    ref
  ) => {
    if (isTableError) {
      if (this.isTableBorderError) {
        procedureFileData.forEach((item, index) => {
          procedureFileData[index].isError = true;
        });
        const tableEle = document.getElementsByClassName(
          "approveSupportingTable"
        )[0];
        tableEle !== undefined &&
          this.handleReviseNewSupportingFilePendingControlErrorScroll(
            tableEle.offsetTop + tableEle.clientHeight
          );
        this.isTableBorderError = true;
        this.setState({
          pageState: { ...this.state.pageState, isTableBorderError: true },
        });
      } else {
        const firstErrorTr = document.getElementById(
          `table-tr-${errorIndexList[0]}`
        );
        this.handleReviseNewSupportingFilePendingControlErrorScroll(
          firstErrorTr.offsetTop + firstErrorTr.clientHeight
        );
      }
      ref.current.updateDataList(procedureFileData);
    } else {
      this.handleReviseNewSupportingFilePendingCreateActionClickSectionCheckModal(
        "",
        this.modalContentLabels.submitConfirmModalLabel,
        this.handleReviseNewSupportingFilePendingSubmitOkClick,
        this.hideReviseNewSupportingFilePendingModal
      );
    }
  };

  /* function about click submit button in revise page if returned files don't do change */
  handleCheckFileTableHighLight = (
    supportingFileData,
    tableErrorList,
    isTableError
  ) => {
    let errorIndexList = [];
    let deleteList = [];
    if (supportingFileData.length === 0) {
      isTableError = true;
      this.isTableBorderError = true;
      this.setState({
        pageState: { ...this.state.pageState, isTableBorderError: true },
      });
    } else if (supportingFileData.length === 1) {
      const isReturned =
        supportingFileData[0].data.find((file) => file.name === "status")
          .value === dataStatus.return;
      const fileTag = supportingFileData[0].data.find(
        (file) => file.name === "fileName"
      ).tagValue;
      if (isReturned && fileTag !== (fileTags.replaced || fileTags.deleted)) {
        errorIndexList = [];
        tableErrorList.push(supportingFileData[0]);
        isTableError = true;
        supportingFileData[0].isError = true;
        errorIndexList.push(0);
      }
    } else {
      supportingFileData.map((fileData, index) => {
        const isReturned =
          fileData.data.find((file) => file.name === "status").value ===
          dataStatus.return;
        const fileTag = fileData.data.find(
          (file) => file.name === "fileName"
        ).tagValue;
        const isReviewed = fileData.data.find(
          (file) => file.name === "isReviewed"
        ).value;
        if (isReturned && !isReviewed && fileTag !== fileTags.deleted) {
          errorIndexList = [];
          tableErrorList.push(supportingFileData[index]);
          errorIndexList.push(index);
          isTableError = true;
          supportingFileData[index].isError = true;
        } else if (fileTag === fileTags.deleted) {
          deleteList.push(index);
        }
      });
      if (
        tableErrorList.length === supportingFileData.length ||
        deleteList.length === supportingFileData.length
      ) {
        this.isTableBorderError = true;
        this.setState({
          pageState: { ...this.state.pageState, isTableBorderError: true },
        });
        isTableError = true;
      }
    }
    this.constrolTableScrollWhenSubmit(
      isTableError,
      supportingFileData,
      errorIndexList,
      supportingFileTableRef
    );
  };

  /* function about click submit button in revise page */
  handleReviseSubmitClickCheck = (procedureFileData, tableErrorList) => {
    let isTableError = false;
    this.handleCheckFileTableHighLight(
      procedureFileData,
      tableErrorList,
      isTableError
    );
  };

  _checkStepPermissionSuccess = (result, actionType) => {
    this.props._hideLoading();
    const { statusCode } = result;
    const noPermission = statusCode !== 200;
    this.setState({
      noPermission,
    });
    if (noPermission) return;
    if (actionType === "submit") {
      this.handleSubmitClick();
    } else if (actionType === "save") {
      this.handleSaveClick();
    }
  };

  checkStepPermission = (actionType) => {
    this.props._showLoading();
    const param = {
      taskID: this.props.params.ProcedureTaskId,
      procedureID: this.props.params.ProcedureID,
      procedureStepID: this.props.params.ProcedureStepID,
      parentPage: null,
    };
    appService.checkStepPermission(
      param,
      (response) => this._checkStepPermissionSuccess(response, actionType),
      (response) => console.log(response.message)
    );
  };

  /* function about click submit button in approve page */
  handleSubmitClick = () => {
    const supportingFileData =
      this.props.supportingRef.current === null
        ? []
        : this.props.supportingRef.current.getDataList();
    let tableErrorList = [];
    let isTableError = false;
    this.handleCheckFileTableHighLight(
      supportingFileData,
      tableErrorList,
      isTableError
    );
  };

  /* function about click save button */
  handleSaveClick = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        actionID: actions.save,
      },
    });
    this.isSaveDisabled = true;
    this.props._showLoading();
    this.para = this.props.handleReturnSubmitData(
      actions.save,
      "Save",
      this.props.supportingRef
    );

    this.createSupportingFileTriggerAction();
  };

  /* function about control save button disable when procedure file table change */
  setIsTableChange = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        isChange: true,
      },
    });
  };

  /* function about control save button disable when procedure file table change */
  cancelIsTableChange = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        isChange: false,
      },
    });
  };

  /**
   * function about return page name
   * @returns
   */
  handleReturnTitle = () => {
    return "Revise New Supporting File";
  };

  /**
   * function about return revision information section
   * @returns
   */
  handleReturnRevisionInformation = () => {
    return null;
  };

  handleReturnSupportingFileSection = (obj) => {
    const {
      currentRole,
      supportingReplaceFileRef,
      isDisableAll,
      isTableBorderError,
    } = obj;

    this.props.data.forEach((item) => {
      const tagValue = item.data.find(
        (item) => item.name === "fileName"
      ).tagValue;
      if (tagValue === fileTags.deleted) {
        item.data.find((item) => item.name === "note").isDisabled = true;
      }
      return item;
    });

    return (
      <ProcedureBlock
        className={styles.approveSupportingFile}
        child={
          <CreateSupportingFileReviseSection
            sectionProps={{ currentRole }}
            setIsTableChange={this.setIsTableChange}
            initProcedureFileTableState={this.props.initProcedureFileTableState}
            cancelIsTableChange={this.cancelIsTableChange}
            initSupportingTableData={this.props.initSupportingTableData}
            updateUploadProcedureFileNum={
              this.props.updateUploadProcedureFileNum
            }
            refs={{
              supportingFileTableRef,
              noteRef,
              supportingReplaceFileRef,
              supportingFileRef,
              reasonRef,
              supportingRef: this.props.supportingRef,
            }}
            data={this.props.data}
            isTableBorderError={isTableBorderError}
            _hideLoading={this.props._hideLoading}
            updateSupportingFileNum={this.props.updateSupportingFileNum}
            isDisableAll={isDisableAll}
            _showLoading={this.props._showLoading}
            downloadFile={this.props.downloadFile}
          />
        }
        isOpen={true}
        isShowArrow={true}
      />
    );
  };

  handleReturnProcedureFileSection = () => {
    return (
      <ProcedureBlock
        className={styles.approveProcedureFile}
        child={
          <ReadOnlyProcedureFileSection
            procedureFileRef={procedureFileRef}
            clientServerPath={this.props.serverPathValue}
            savedInRadioValue={this.props.savedInRadioValue}
            procedureFileData={
              this.props.sectionProps.fileTableData !== undefined
                ? this.props.sectionProps.fileTableData
                : []
            }
            showHintMsg={true}
            jbProcedureSavedIn={this.props.sectionProps.jbProcedureSavedIn}
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.props.setProcedureFileData,
            this.setError,
            [StepBusinessType.procedureFile]
          );
        }}
      />
    );
  };

  handleReviseNewSupportingFilePendingProcedureInformation = () => {
    const { procedureStatus } = this.props.pageProps;
    const { procedureInfoData, ppoList } = this.props.sectionProps;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={
          <ProcedureInformation
            isShowActions={false}
            noteDisabled={true}
            ppoList={ppoList}
            procedureStatus={procedureStatus}
            isPPODisabled={true}
            showHintMsg={true}
            submitData={procedureInfoData}
            title="Procedure Information"
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.props.setProcedureInfoData,
            this.setError,
            [
              StepBusinessType.procedureOwner,
              StepBusinessType.pmpExcluded,
              StepBusinessType.primaryProcedureOwner,
            ]
          );
        }}
      />
    );
  };

  handleReviseNewSupportingFilePendingShareTaskSection = () => {
    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={
          <ActiveShareTaskReadOnly
            showHintMsg={true}
            shareTaskData={this.props.shareTaskData}
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.props.setShareTaskData,
            this.setError,
            [StepBusinessType.shareTask]
          );
        }}
      />
    );
  };

  handleReviseNewSupportingFilePendingButton = () => {
    const { isCancelDisabled, isSubmitDisabled, isChange } =
      this.state.pageState;

    return (
      <div className={styles.actionBtns}>
        <div className={styles.normalBtns}>
          <Buttons
            color="grey"
            size="middle"
            onClick={() => this.checkStepPermission("save")}
            disabled={!isChange}
            btnClassName={styles.approveActionBtn}
          >
            Save as Draft
          </Buttons>
          <Buttons
            color="grey"
            size="middle"
            onClick={this.handleReviseNewSupportingFilePendingCancelClick}
            disabled={isCancelDisabled}
            btnClassName={styles.approveActionBtn}
          >
            Cancel
          </Buttons>
          <Buttons
            color="blue"
            size="middle"
            onClick={() => this.checkStepPermission("submit")}
            disabled={isSubmitDisabled}
            btnClassName={styles.approveActionSubmitBtn}
          >
            Submit for Approval
          </Buttons>
        </div>
      </div>
    );
  };

  handleReviseNewSupportingFilePendingModalWithComponent = () => {
    const { supportingReplaceFileRef } = this.props.pageProps;
    const {
      modalTitle,
      modalLabel,
      closable,
      modalWidth,
      handleOk,
      handleCancel,
      isOkBtnDisabled,
      isCancelBtnDisabled,
      maskClosable,
      isModalWithComponentOpen,
      rowIndex,
      okText,
      cancelText,
      fileIds,
      fileNames,
    } = this.state.modalState;

    return (
      <ModalWithComponent
        isModalOpen={isModalWithComponentOpen}
        cancelText={cancelText}
        fileNames={fileNames}
        closable={closable}
        title={modalTitle}
        handleOk={handleOk}
        handleCancel={handleCancel}
        isOkDisabled={isOkBtnDisabled}
        isLoading={isOkBtnDisabled}
        modalWidth={modalWidth}
        isCancelDisabled={isCancelBtnDisabled}
        maskClosable={maskClosable}
        label={modalLabel}
        fileUploadRef={supportingReplaceFileRef}
        fileUploadInputRef={supportingReturnAddFileRef}
        okText={okText}
        hideLoading={this._hideModalBtnsDisabled}
        openLoading={this._openModalBtnsDisabled}
        rowindex={rowIndex}
        fileIds={fileIds}
      />
    );
  };

  render() {
    const {
      receiveDate,
      requestByName,
      currentRole,
      supportingReplaceFileRef,
      isDisableAll,
    } = this.props.pageProps;

    const { isTableBorderError } = this.state.pageState;
    const { taskInfoData } = this.props.sectionProps;

    return (
      <div className={styles.approveNewPro}>
        <PageHeader
          title={this.handleReturnTitle()}
          receiveDate={this._formateDate(receiveDate)}
          requestBy={requestByName}
          status="Pending"
        />
        <ProcedureBlock
          className={styles.approveProcedureBlock}
          child={<TaskInformation data={taskInfoData} />}
          isShowArrow={true}
          isOpen={false}
          onShowDataLoad={() => {
            return StepService.getStepData(
              this.params,
              this.props.setTaskInfoData,
              this.setError,
              [StepBusinessType.taskInfo]
            );
          }}
        />
        {this.handleReturnRevisionInformation()}
        {this.handleReturnProcedureFileSection()}
        {this.handleReturnSupportingFileSection({
          currentRole,
          supportingReplaceFileRef,
          isTableBorderError,
          isDisableAll,
        })}
        {this.handleReviseNewSupportingFilePendingProcedureInformation()}
        {this.handleReviseNewSupportingFilePendingShareTaskSection()}
        {this.handleReviseNewSupportingFilePendingButton()}
        {this.handleReviseNewSupportingFilePendingModalWithComponent()}
        <LostPermissionModal isModalOpen={this.state.noPermission} />
      </div>
    );
  }
}
