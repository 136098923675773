import { React, createRef } from "react";
import Buttons from "../../../../../base/basicComponents/button/index";
import ApplyJbSavedIn from "../../fields/applyJbSavedIn";
import {
  dataStatus,
  savedInRadioValues,
} from "../../../../publicDictionaryValues";
import downloadIcon from "../../../../../assets/image/downloadIcon.png";
import ApplyJbClientServerPath from "../../fields/applyJbClientServerPath";
import styles from "../../index.module.scss";
import "../../applyForJbApproveSection/approve.scss";
import { ModalWithComponent } from "../../../../../base/basicComponents/modal";
import ApproveApplyJbTable from "../../../procedureFileSections/fields/procedureFileTableFields/approveApplyJbFileTable";
import AppSetting from "../../../../../config/AppSetting";
import ProcedureDetailService from "../../../../../service/procedureFile/ProcedureDetailService";
import BaseApplyForJbSection from "../../baseApplyForJbSection";

const procedureServerPathRef = createRef();
class ApplyForJbApproveReadOnlySection extends BaseApplyForJbSection {
  constructor(props) {
    super(props);

    this.state = {
      clientServerPathReturnReason: this.props.rejectReason,
      isServerPathReturnBtnDisabled: false,
      isServerPathApproveBtnDisabled: false,
      isServerPathReturned: false,
      serverPathReturnReasonInputValue: this.props.rejectReason,
      downloadBtnDisabled: true,
      downloadIcon: downloadIcon,
      modalState: {
        isModalWithComponentOpen: false,
        closable: false,
        okText: "",
        cancelText: "",
        modalTitle: "",
        handleOk: null,
        handleCancel: null,
        isOkBtnDisabled: false,
        isCancelBtnDisabled: false,
        maskClosable: false,
        isShowTextArea: false,
        textAreaPlacement: "",
        textAreaMaxLength: 1000,
        isShowSelect: false,
        isShowTextAreaAndUploadDragger: false,
        uploadDraggerFileType: "",
        rowIndex: 0,
        fileIds: [],
        fileNames: [],
        modalWidth: 900,
        isTableBorderError: this.props.isTableBorderError,
      },
      procedureFileTableState: {
        fileTableReasonStatus: "",
        isTableBorderError: false,
        fileTableReturnReasonValue: "",
        isChange: false,
      },
    };

    this.fileTableRef = createRef();
    this.files = [];
  }

  setDefault = () => {};

  updateApplyForJbApproveReadOnlyDataList = (dataList) => {
    this.fileTableRef.current.updateDataList(dataList);
  };

  updateDataList = (dataList) => {
    this.updateApplyForJbApproveReadOnlyDataList(dataList);
  };

  initApplyForJbApproveReadOnlyDataList = () => {
    if (this.fileTableRef.current === null) {
      return;
    }
    const dataList = this.props.procedureFileData;
    this.fileTableRef.current.updateDataList(dataList);
  };

  getApplyForJbApproveReadOnlyDataList = () => {
    if (this.fileTableRef.current === null) {
      return;
    }
    const dataList = JSON.parse(
      JSON.stringify(this.fileTableRef.current.state.data)
    );

    return dataList;
  };

  handleApplyForJbApproveReadOnlyProcedureFileTableApproveAfterReturnOkClick =
    () => {
      const index = this.procedureFileCurrentRowIndex;
      const procedureFileData = this.getApplyForJbApproveReadOnlyDataList();
      this.handleApplyForJbApproveReadOnlyProcedureFileNoReturnApprove(
        procedureFileData,
        index
      );
    };

  handleApplyForJbApproveReadOnlyProcedureFileTableApproveCancelClick = () => {
    this.hideApplyForJbApproveReadOnlyModal();
  };

  handleApplyForJbApproveReadOnlyApprovedAfterReturned = (type) => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: true,
        modalLabel: (
          <span className={styles.modalText}>
            Are you sure to change the selection? Your Return Reason and
            Attachment will be cleared.
          </span>
        ),
        okText: "Confirm",
        isShowTextArea: false,
        modalTitle: null,
        actionType: type,
        closable: false,
        modalWidth: 520,
        handleOk:
          this
            .handleApplyForJbApproveReadOnlyProcedureFileTableApproveAfterReturnOkClick,
        handleCancel:
          this
            .handleApplyForJbApproveReadOnlyProcedureFileTableApproveCancelClick,
      },
    });
  };

  handleApplyForJbApproveReadOnlyProcedureFileNoReturnApprove = (
    procedureFileData,
    value
  ) => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        modalWidth: 520,
      },
    });
    procedureFileData[value].isApproveDisabled = true;
    procedureFileData[value].isReturnDisabled = false;
    procedureFileData[value].isError = false;
    procedureFileData[value].isRejected = false;
    procedureFileData[value].data.find((item) => item.name === "status").value =
      dataStatus.approval;
    procedureFileData[value].data.find(
      (item) => item.name === "attachment"
    ).isDisabled = true;
    procedureFileData[value].data.find(
      (item) => item.name === "attachment"
    ).value = null;
    procedureFileData[value].data.find(
      (item) => item.name === "attachmentFileID"
    ).value = null;
    procedureFileData[value].data.find(
      (item) => item.name === "rejectReason"
    ).value = null;
    procedureFileData[value].data.find(
      (item) => item.name === "rejectReason"
    ).isDisabled = true;
    this.fileTableRef.current.updateDataList(procedureFileData);
    this.hideApplyForJbApproveReadOnlyModal();
  };

  hideApplyForJbApproveReadOnlyModal = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: false,
        modalLabel: "",
        handleOk: null,
        handleCancel: null,
        isShowSelect: false,
        isShowTextArea: false,
        textAreaPlacement: "",
        isShowTextAreaAndUploadDragger: false,
        okText: "",
        cancelText: "",
        modalTitle: "",
        isOkBtnDisabled: false,
        isCancelBtnDisabled: false,
        maskClosable: false,
      },
    });
  };

  handleApplyForJbApproveReadOnlyFileApprovedClick = (value) => {
    this.procedureFileCurrentRowIndex = value;
    const procedureFileData = this.getApplyForJbApproveReadOnlyDataList();
    if (procedureFileData[value].isRejected) {
      this.handleApplyForJbApproveReadOnlyApprovedAfterReturned();
    } else {
      this.handleApplyForJbApproveReadOnlyProcedureFileNoReturnApprove(
        procedureFileData,
        value
      );
    }
  };

  setApplyForJbApproveReadOnlyReturnReasonValue = (
    tableDataList,
    index,
    returnValue
  ) => {
    tableDataList[index].data.find((item) => item.name === "status").value =
      dataStatus.reject;
    tableDataList[index].data.find(
      (item) => item.name === "rejectReason"
    ).value = returnValue;
    tableDataList[index].data.find(
      (item) => item.name === "rejectReason"
    ).isDisabled = false;
    this.setState({
      procedureFileTableState: {
        fileTableReturnReasonValue: null,
        fileTableReasonStatus: null,
      },
    });
  };

  handleApplyForJbApproveReadOnlyProcedureFileTableReturnOkClick = () => {
    const fileTableReturnValue =
      this.state.procedureFileTableState.fileTableReturnReasonValue;
    let returnValue =
      fileTableReturnValue !== null
        ? fileTableReturnValue.trim()
        : fileTableReturnValue;
    const tableDataList = this.getApplyForJbApproveReadOnlyDataList();
    const index = this.procedureFileCurrentRowIndex;
    const files = this.files;
    if (returnValue !== null && returnValue) {
      this.hideApplyForJbApproveReadOnlyModal();
      tableDataList[index].isRejected = true;
      if (files.length > 0) {
        files.forEach((item, i) => {
          this.setApplyForJbApproveReadOnlyReturnReasonValue(
            tableDataList,
            index,
            returnValue
          );
          tableDataList[index].data.find(
            (dataItem) => dataItem.name === "attachment"
          ).value = item.fileName;
          tableDataList[index].data.find(
            (dataItem) => dataItem.name === "attachmentFileID"
          ).value = item.fileId;
          tableDataList[index].isApproveDisabled = false;
          tableDataList[index].isReturnDisabled = true;
          tableDataList[index].isReturned = true;
          tableDataList[index].isError = false;
          this.setState({
            procedureFileTableState: {
              fileTableReturnReasonValue: null,
              fileTableReasonStatus: null,
            },
          });
          this.files = [];
        });
      } else {
        this.setApplyForJbApproveReadOnlyReturnReasonValue(
          tableDataList,
          index,
          returnValue
        );
        tableDataList[index].isApproveDisabled = false;
        tableDataList[index].isReturnDisabled = true;
        tableDataList[index].isRejected = true;
        tableDataList[index].isError = false;
      }
      this.fileTableRef.current.updateDataList(tableDataList);
    } else {
      this.setState({
        procedureFileTableState: {
          ...this.state.procedureFileTableState,
          fileTableReasonStatus: "error",
        },
      });
    }
  };

  handleApplyForJbApproveReadOnlyProcedureFileTableReturnCancelClick = () => {
    this.files = [];
    const tableDataList = this.getApplyForJbApproveReadOnlyDataList();
    this.fileTableRef.current.updateDataList(tableDataList);
    this.hideApplyForJbApproveReadOnlyModal();
    this.setState({
      procedureFileTableState: {
        ...this.state.procedureFileTableState,
        fileTableReturnReasonValue: null,
        fileTableReasonStatus: null,
      },
    });
  };

  handleSetReturnModal = (value) => {
    const procedureFileData = this.getApplyForJbApproveReadOnlyDataList();
    const fileId = procedureFileData[value].data.find(
      (item) => item.name === "attachmentFileID"
    ).value;
    const fileName = procedureFileData[value].data.find(
      (item) => item.name === "attachment"
    ).value;
    const fileIds = fileId == null ? [] : [fileId];
    const fileNames = fileName == null ? [] : [fileName];
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: true,
        isShowTextAreaAndUploadDragger: true,
        uploadDraggerFileType: "text",
        modalTitle: <span className="fs-18">Reason for Rejection</span>,
        modalLabel: null,
        textAreaPlacement: this.returnModalInputPlacement,
        maskClosable: false,
        rowIndex: value,
        okText: "OK",
        closable: false,
        fileIds: fileIds,
        fileNames: fileNames,
        modalWidth: 900,
        isShowTextArea: true,
        handleOk:
          this.handleApplyForJbApproveReadOnlyProcedureFileTableReturnOkClick,
        handleCancel:
          this
            .handleApplyForJbApproveReadOnlyProcedureFileTableReturnCancelClick,
      },
    });
  };

  handleFileRejectClick = (value) => {
    const procedureFileData = this.getApplyForJbApproveReadOnlyDataList();
    const returnReasonValue = procedureFileData[value].data.find(
      (item) => item.name === "rejectReason"
    ).value;
    this.setState({
      procedureFileTableState: {
        ...this.state.procedureFileTableState,
        fileTableReturnReasonValue: returnReasonValue,
      },
    });
    this.procedureFileCurrentRowIndex = value;
    this.handleSetReturnModal(value);
  };

  handleApplyForJbApproveReadOnlySetServerPathReturnReasonValue = (
    content,
    editor
  ) => {
    this.setState({
      procedureFileTableState: {
        ...this.state.procedureFileTableState,
        fileTableReturnReasonValue: content,
        fileTableReasonStatus: null,
      },
    });
  };

  getApplyForJbApproveReadOnlyAddFileDto = (item) => {
    return {
      FileName: item.name,
      ContainerName: AppSetting.urlPrefix.addFile.ContainerName,
      AccountName: AppSetting.urlPrefix.addFile.AzureAccountName,
      ProductKey: AppSetting.urlPrefix.addFile.ProductKey,
      FolderName: AppSetting.urlPrefix.addFile.ProcedureFileFolder,
    };
  };

  uploadApplyForJbApproveReadOnlyFileOnSuccess = (result, returnPara) => {
    returnPara.callback(returnPara.fileName, { text: returnPara.fileName });
    this.files.unshift({
      fileId: result.storageFileID,
      fileName: returnPara.fileName,
    });
  };

  uploadApplyForJbApproveReadOnlyFileOnError = () => {};

  uploadApplyForJbApproveReadOnlyFile = (e, callback) => {
    var file = e.files[0];
    let fileForm = new FormData();
    let addFileDto = this.getApplyForJbApproveReadOnlyAddFileDto(file);
    fileForm.append("formFile", file);
    fileForm.append("addFileDto", JSON.stringify(addFileDto));
    let returnPara = {
      fileName: file.name,
      callback: callback,
    };

    ProcedureDetailService.addFile(
      fileForm,
      this.uploadApplyForJbApproveReadOnlyFileOnSuccess,
      this.uploadApplyForJbApproveReadOnlyFileOnError,
      returnPara
    );
  };

  handleApplyForJbApproveReadOnlyUploadFile = (callback, value, meta) => {
    var input = document.createElement("input");
    input.setAttribute("type", "file");
    input.click();
    input.onchange = (e) =>
      this.uploadApplyForJbApproveReadOnlyFile(e.target, callback);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.procedureFileData !== this.props.procedureFileData) {
      this.initApplyForJbApproveReadOnlyDataList();
    }
  }

  componentDidMount() {
    this.initApplyForJbApproveReadOnlyDataList();
  }

  handleApplyForJbApproveReadOnlyColumn = () => {
    return [
      {
        name: "ProcedureId",
        dataName: "procedureId",
        type: "text",
        isShow: false,
      },
      {
        name: "checkbox",
        dataName: "checkbox",
        type: "checkbox",
        isShow: true,
        check: this._check,
        onCheck: this._onCheck,
        headCheck: this._headCheck,
        headOnCheck: this._headOnCheck,
      },
      {
        name: "File Name",
        dataName: "fileName",
        type: "fileName",
        isShow: true,
        isDisabled: false,
      },
      {
        name: "Uploaded Date",
        dataName: "uploadedDate",
        type: "text",
        isShow: true,
      },
      {
        name: "Uploaded By",
        dataName: "uploadByName",
        type: "text",
        isShow: true,
      },
      {
        name: "Approval Date",
        dataName: "approveDate",
        type: "text",
        isShow: true,
      },
      {
        name: "Status",
        dataName: "status",
        type: "tag",
        isShow: !this.props.isCopyPage,
      },
      {
        name: "JB Procedure",
        dataName: "jbProcedure",
        type: "text",
        isShow: true,
      },
      {
        name: "Apply for?",
        dataName: "applyfor",
        type: "select",
        isShow: !this.props.isCopyPage,
        isDisabled: true,
      },
      {
        name: "Reject Reason",
        dataName: "rejectReason",
        type: "modal",
        showText: "View Reject Reason",
        isShow: !this.props.isCopyPage,
      },
      {
        name: "Attachment",
        dataName: "attachment",
        type: "link",
        isShow: !this.props.isCopyPage,
        showEdit: false,
      },
    ];
  };

  handleApplyForJbApproveReadOnlyApplyJbClientServerPath = () => {
    const {
      procedureJbStatusId,
      serverPathValue,
      applyfor,
      jbProcedureSavedInStatusID,
    } = this.props;
    const {
      clientServerPathReturnReason,
      isServerPathReturnBtnDisabled,
      isServerPathApproveBtnDisabled,
      isServerPathReturned,
      serverPathReturnReasonInputValue,
    } = this.state;

    return (
      <div className={styles.clientPathDiv}>
        <ApplyJbClientServerPath
          clientServerPathReturnReason={clientServerPathReturnReason}
          isServerPathReturnBtnDisabled={isServerPathReturnBtnDisabled}
          isServerPathApproveBtnDisabled={isServerPathApproveBtnDisabled}
          serverPathReturnReasonInputValue={serverPathReturnReasonInputValue}
          isServerPathReturned={isServerPathReturned}
          ref={procedureServerPathRef}
          serverPathValue={serverPathValue}
          procedureJbStatusId={procedureJbStatusId}
          applyForValue={applyfor}
          readOnly={true}
          serverPathStatusID={jbProcedureSavedInStatusID}
        />
      </div>
    );
  };

  handleApplyForJbApproveReadOnlyApproveApplyJbTableSectionButton = () => {
    const { downloadBtnDisabled } = this.state;

    return (
      <div className={styles.downloadButtonDiv}>
        <Buttons
          color="black"
          size="middle"
          onMouseEnter={this.handleDownloadBtnMouseEnter}
          onMouseLeave={this.handleDownloadBtnMouseLeave}
          onClick={this.downloadFile}
          disabled={downloadBtnDisabled}
        >
          <img
            src={downloadIcon}
            alt="Download"
            className={styles.downloadIcon}
          />
          Download
        </Buttons>
      </div>
    );
  };

  handleApplyForJbApproveReadOnlyApproveApplyJbTable = () => {
    const columns = this.handleApplyForJbApproveReadOnlyColumn();

    return (
      <div className={`${styles.downloadButtonDiv} applyJbTable`}>
        <ApproveApplyJbTable
          ref={this.fileTableRef}
          columns={columns}
          procedureNoteMaxLength={1000}
          fileSectionDisabled={false}
          procedureNoteSize="small"
          inputOnChange={this.inputOnChange}
          hasSubmit={false}
          isScrollData={false}
          fileTableRef={this.fileTableRef}
          className="procedureFiles"
          isShowTotalFileNum={true}
          isSupportingFile={false}
          isShowExpendIcon={false}
          handleReturnClick={(e) =>
            this.handleFileRejectClick(e.target.attributes.rowindex.value)
          }
          downloadFile={this.downloadFile}
          readOnly={true}
        />
      </div>
    );
  };

  handleApplyForJbApproveReadOnlyApproveApplyJbTableSection = () => {
    return (
      <>
        {this.handleApplyForJbApproveReadOnlyApproveApplyJbTableSectionButton()}
        {this.handleApplyForJbApproveReadOnlyApproveApplyJbTable()}
      </>
    );
  };

  handleApplyForJbApproveReadOnlyModalWithComponent = () => {
    const {
      okText,
      cancelText,
      modalTitle,
      isModalWithComponentOpen,
      modalLabel,
      textAreaPlacement,
      textAreaMaxLength,
      isShowTextArea,
      modalWidth,
      handleOk,
      handleCancel,
    } = this.state.modalState;

    const { fileTableReasonStatus, fileTableReturnReasonValue } =
      this.state.procedureFileTableState;

    return (
      <div>
        <ModalWithComponent
          isModalOpen={isModalWithComponentOpen}
          closable={true}
          okText={okText}
          cancelText={cancelText}
          title={modalTitle}
          className={styles.approvePageModal}
          handleOk={handleOk}
          handleCancel={handleCancel}
          isLoading={false}
          isShowRichTextEditor={isShowTextArea}
          richTextValue={fileTableReturnReasonValue}
          handleEditorChange={
            this.handleApplyForJbApproveReadOnlySetServerPathReturnReasonValue
          }
          handleUploadFile={this.handleApplyForJbApproveReadOnlyUploadFile}
          textAreaPlacement={textAreaPlacement}
          textAreaMaxLength={textAreaMaxLength}
          textAreaStatus={fileTableReasonStatus}
          label={modalLabel}
          modalWidth={modalWidth}
          footer={false}
          richTextDisabled={true}
          centered={true}
        />
      </div>
    );
  };

  render() {
    const { savedInRadioValue } = this.props;

    return (
      <div className={styles.applyJbSectionsDiv}>
        <div className={styles.applyJbSectionTop}>
          <span className={`${styles.applyJbSectionTitle} fs-18`}>
            Procedure Files
          </span>
        </div>
        <div className={styles.topDiv}>
          <div className={styles.savedInDiv}>
            <ApplyJbSavedIn savedInRadioValue={savedInRadioValue} />
          </div>
          {savedInRadioValue !== savedInRadioValues.systemOnly &&
            this.handleApplyForJbApproveReadOnlyApplyJbClientServerPath()}
        </div>
        {savedInRadioValue !== savedInRadioValues.serverOnly &&
          this.handleApplyForJbApproveReadOnlyApproveApplyJbTableSection()}
        {this.handleApplyForJbApproveReadOnlyModalWithComponent()}
      </div>
    );
  }
}

export default ApplyForJbApproveReadOnlySection;
