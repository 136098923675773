import { Checkbox, Divider, Button, Spin } from "antd";
import "./index.scss";

export const GetColumnCheckboxGroupFilterDropdown = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  filters,
  filterLoading,
}) => {
  const onChange = (checkedValues) => {
    setSelectedKeys(checkedValues);
  };
  const clear = () => {
    setSelectedKeys([]);
    clearFilters();
  };
  const onOk = () => {
    confirm();
  };
  return (
    <Spin spinning={filterLoading} size="small">
      <div className="filter-content">
        <Checkbox.Group
          onChange={onChange}
          value={selectedKeys}
          style={{ width: "100%" }}
        >
          {filters?.map((item) => (
            <div className="checkboxItem" key={item.value}>
              <Checkbox value={item.value} style={{ width: "100%" }}>
                {item.text}
              </Checkbox>
            </div>
          ))}
        </Checkbox.Group>
        <Divider />
        <div className="footer">
          <Button
            type="link"
            size="small"
            disabled={!selectedKeys?.length}
            onClick={clear}
          >
            Reset
          </Button>
          <Button type="primary" size="small" onClick={onOk}>
            OK
          </Button>
        </div>
      </div>
    </Spin>
  );
};
