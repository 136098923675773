import BaseReadOnlyShareTask from "../baseReadOnlyShareTask";

export default class SubmitShareTaskReadOnly extends BaseReadOnlyShareTask {
  constructor(props) {
    super(props);
    this.state = {
      isTaskInfoModalOpen: false,
      taskInformationData: {},
      reasonValue: this.props.shareTaskData.reason,
    };
  }

  handleSubmitReason = () => {
    return this.handleSubmitSubmitReason();
  };
}
