import React, { createRef } from "react";
import ReadOnlyActiveSupportingFileManagement from "../fields/supportingFileManagement/readOnlyActiveSupportingFileManagement";
import SupportingFileSection from "../supportingFileSection";

const supportingFileTableRef = createRef();
export default class ReadOnlySupportingFileSection extends SupportingFileSection {
  componentDidMount() {
    supportingFileTableRef.current !== null &&
      this.props.supportingFileData &&
      supportingFileTableRef.current.updateDataList(
        this.props.supportingFileData
      );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.supportingFileData !== this.props.supportingFileData) {
      supportingFileTableRef.current !== null &&
        this.props.supportingFileData &&
        supportingFileTableRef.current.updateDataList(
          this.props.supportingFileData
        );
    }
  }

  render() {
    const { supportingFileRef, showHintMsg } = this.props;
    return (
      <ReadOnlyActiveSupportingFileManagement
        fileInputRef={supportingFileRef}
        fileTableRef={supportingFileTableRef}
        title={"Supporting Files"}
        clientPathShow={false}
        isScrollData={true}
        className="supportingFiles"
        isShowExpendIcon={true}
        isShowDownloadBtn={true}
        isShowShareBtn={false}
        isShowActionBtns={false}
        savedInDisabled={true}
        clientPathDisabled={true}
        noteDisabled={true}
        hasSubmit={false}
        showHintMsg={showHintMsg}
        data={this.props.supportingFileData}
      />
    );
  }
}
