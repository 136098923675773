import React from "react";
import { withRouter } from "react-router-dom";
import { Layout } from "antd";
import BaseComponent from "../../common/BaseComponent";
import LeftMenu from "./leftMenu";
import TopMenu from "./topMenu";
import { Spin } from "antd";

import styles from "./style/index.module.scss";
import "./style/layout.scss";

const { Content, Footer } = Layout;

class CurrentLayout extends BaseComponent {
  constructor(props) {
    super(props);

    this.props.history.push({ state: { showLoad: false } });
    this.state = {
      pathName: this.props.location.pathname,
      collapsed: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.history.action === "REPLACE") {
      return null;
    }

    if (nextProps.location.pathname !== prevState) {
      return {
        pathName: nextProps.location.pathname,
      };
    }

    return null;
  }

  _getActiveKey = () => {
    const reg = new RegExp(`/[A-Za-z]*/[A-Za-z0-9-]*`);

    const activeKey = reg.test(this.state.pathName)
      ? reg.exec(this.state.pathName)[0]
      : this.state.pathName;
    return activeKey;
  };

  render() {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        {this.props.location.state != null &&
        this.props.location.state.showLoad > 0 ? (
          <div className={styles.loading}>
            <Spin size="large" />
          </div>
        ) : (
          ""
        )}
        <TopMenu />
        <Layout className="site-layout">
          <LeftMenu
            history={this.props.history}
            activeKey={this._getActiveKey()}
          />
          <Layout className="content-layout">
            <Content style={{ margin: "0 16px" }}>
              <div className={`${styles.content} site-layout-background`}>
                {this.props.children}
              </div>
            </Content>
            <Footer style={{ textAlign: "center" }}>
              Copyright ©{new Date().getFullYear()} ReSource Pro. All rights
              reserved.
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

export default withRouter(CurrentLayout);
