import React from "react";
import CreateSupportingFileApproveSection from "../createSupportingFileApproveSection";
import ApproveUpdateSupportingFileManagement from "../fields/supportingFileManagement/approveUpdateSupportingFileManagement";
import styles from "../index.module.scss";

export default class UpdateSupportingFileApproveSection extends CreateSupportingFileApproveSection {
  render() {
    const { currentRole, isDisableAll } = this.props.sectionProps;
    const {
      supportingFileTableRef,
      supportingRef,
      reasonRef,
      noteRef,
      supportingFileRef,
      supportingReplaceFileRef,
    } = this.props.refs;

    return (
      <div className={styles.fileSection}>
        <div className={styles.fileSectionTitle}>Supporting Files</div>

        <div
          className={`${styles.fileSectionTable} ${
            this.state.procedureFileTableState.isTableBorderError
              ? styles.border_error
              : ""
          }`}
        >
          {this.props.isMount && (
            <ApproveUpdateSupportingFileManagement
              data={this.props.data}
              ref={supportingRef}
              procedureRef={supportingRef}
              fileTableRef={supportingFileTableRef}
              handleApprovedClick={(e) =>
                this.handleProcedureFileTableApprovedClick(
                  e.target.attributes.rowindex.value
                )
              }
              handleReturnClick={(e) =>
                this.handleProcedureFileTableReturnClick(
                  e.target.attributes.rowindex.value
                )
              }
              className="approveUpdateSupportingTable"
              pageType="supportingPage"
              reasonRef={reasonRef}
              noteRef={noteRef}
              fileInputRef={supportingFileRef}
              tableReplaceFileInputRef={supportingReplaceFileRef}
              currentRole={currentRole}
              handleFileSelect={(e) => this.handleReplaceFile(e, false)}
              handleReplaceFileSelect={this.handleRowReplaceFileSelect}
              downloadFile={this.props.downloadFile}
              isShowExpendIcon={false}
              initProcedureTableData={[...this.props.initSupportingTableData]}
              isDisableAll={isDisableAll}
              isTableChange={this.state.procedureFileTableState.isChange}
              isTableBorderError={
                this.state.procedureFileTableState.isTableBorderError
              }
              isCopyPage={this.props.isCopyPage}
            />
          )}
        </div>
      </div>
    );
  }
}
