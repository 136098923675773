export default function TopFilterBase() {
  const handleOptionList = (list, isAccountAbbr, isProcessStatus) => {
    let tempOptionList = [];
    list.map((item) => {
      let optionItem = isAccountAbbr
        ? { name: item.accountAbbr, value: item.accountId }
        : { name: item.name, value: item.id };
      tempOptionList.push(optionItem);
    });

    if(isProcessStatus) {
      return tempOptionList;
    }

    return tempOptionList.sort((s, t) => sortList(s, t));
  };

  /* function about sort filter list by alphabetical order */
  const sortList = (s, t) => {
    const a = (s.name || "").toLowerCase();
    const b = (t.name || "").toLowerCase();
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };

  /* function about sort MyRequest Process Status list by id order */
  const sortProcessStatusList = (s, t) => {
    const a = s.value;
    const b = t.value;
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };

  /**
   * function about when select option the filter options will move the selected options top
   * @param {*} optionList
   * @param {*} selectList
   * @returns
   */
  const handleChange = (optionList, selectList, isProcessStatus) => {
    const selectOptionList = [];
    if (Array.isArray(selectList)) {
      selectList.forEach((selectItem) => {
        optionList.map((OptionItem, optionIndex) => {
          if (OptionItem.value === selectItem) {
            const item = optionList.splice(optionIndex, 1);
            selectOptionList.push(item[0]);
          }
        });
      });
    } else {
      optionList.forEach((OptionItem, optionIndex) => {
        if (OptionItem.value === selectList) {
          const item = optionList.splice(optionIndex, 1);
          selectOptionList.push(item[0]);
        }
      });
    }

    if(isProcessStatus) {
      return selectOptionList.sort((s, t) => sortProcessStatusList(s, t));
    }
    return selectOptionList.sort((s, t) => sortList(s, t));
  };

  /**
   * function about delete seleced option
   * @param {*} value: delete item's value
   * @param {*} optionList: current select options
   */
  const handleDeSelect = (value, selectList) => {
    const i = selectList.findIndex((item) => item === value);
    i !== -1 && selectList.splice(i, 1);
    return selectList;
  };

  /**
   * function about select filter option
   * @param {*} value input enter value
   * @param {*} optionList current filter all options
   */
  const handleFilterOption = (input, option) =>
    (option?.title ?? "").toLowerCase().includes(input.toLowerCase());

  return {
    handleOptionList,
    sortList,
    handleChange,
    handleDeSelect,
    handleFilterOption,
    sortProcessStatusList,
  };
}
