import React, { useEffect, useRef } from "react";
import { useMount, useSetState } from "ahooks";
import TopFilterBase from "../../../../commonComponents/listBase/TopFilterBase";
import InputLabel from "../../../../../base/basicComponents/inputLabel";
import NormalSelect from "../../../../../base/basicComponents/select";
import Buttons from "../../../../../base/basicComponents/button";
import changeHistoryService from "../../../../../service/changeHistory";
import styles from "../../../toDoList/toDoListSections/topFilter/index.module.scss";
import "../../../../../assets/css/style.css";

export default function TopFilter(props) {
  const { sortList, handleChange, handleDeSelect, handleFilterOption } =
    TopFilterBase();

  const isFirstRender = useRef(true);

  /* state about selects' data */
  const [filterOptionList, setFilterOptionList] = useSetState({
    processCategory: [],
    processStatus: [],
    initiator: [],
  });

  /* state about select's selected data */
  const [selectOptions, setSelectOptions] = useSetState({
    processCategory: [],
    processStatus: [],
    initiator: [],
  });

  const handleOptionList = (list, isSort) => {
    let tempOptionList = [];
    list.map((item) => {
      let optionItem = { name: item.value, value: item.key };
      tempOptionList.push(optionItem);
    });

    return tempOptionList;
  };

  const onSuccess = (response, type) => {
    let option;
    switch (type) {
      case "processCategory":
        option = handleOptionList(response.data);
        setFilterOptionList({ processCategory: option });
        break;
      case "processStatus":
        option = handleOptionList(response.data);
        setFilterOptionList({
          processStatus: option,
        });
        break;
      case "initiator":
        option = handleOptionList(response.data);
        setFilterOptionList({ initiator: option });
        break;
      default:
        break;
    }
  };

  /* callback about when call api error */
  const onError = (error) => {
    console.log(error);
  };

  const handleFilterClick = () => {
    props.handleFilterClick(filterOptionList, selectOptions);
  };

  const handleClearClick = () => {
    const defaultOption = {
      processStatus: [],
      initiator: [],
      processCategory: [],
    };
    setSelectOptions(defaultOption);
    props.handleFilterClick(filterOptionList, defaultOption);
  };

  //   const getAccountAbbr = () => {
  //     if (props.isOpex) {
  //       toDoListService.getAllAccountAbbreviations(
  //         2,
  //         onSuccess,
  //         onError,
  //         "accountAbbr"
  //       );
  //     } else {
  //       toDoListService.getAccountAbbrList(onSuccess, onError, "accountAbbr");
  //     }
  //   };

  const getProcessStatus = () => {
    changeHistoryService.getProcessStatusList(
      onSuccess,
      onError,
      "processStatus"
    );
  };

  const getInitiator = () => {
    const para = {
      ProcedureTaskId: props.procedureTaskId,
      HistoryType: props.tab,
    };
    changeHistoryService.getInitiatorList(
      para,
      onSuccess,
      onError,
      "initiator"
    );
  };

  const getProcessCategory = () => {
    const para = {
      ProcedureTaskId: props.procedureTaskId,
      HistoryType: props.tab,
    };
    changeHistoryService.getCategoryList(
      para,
      onSuccess,
      onError,
      "processCategory"
    );
  };

  /* function about when page init get filter selects option list */
  useMount(() => {
    getProcessStatus();
    getInitiator();
    getProcessCategory();
    const filterOptionPara = {
      processStatus: [],
      initiator: [],
      processCategory: [],
    };
    const selectOptionsPara = {
      processStatus: [],
      initiator: [],
      processCategory: [],
    };
    props.handleFilterClick(filterOptionPara, selectOptionsPara);
  });

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    getInitiator();
    getProcessCategory();

    setSelectOptions({
      processStatus: [],
      initiator: [],
      processCategory: [],
    });
    const filterOptionPara = {
      processStatus: [],
      initiator: [],
      processCategory: [],
    };
    const selectOptionsPara = {
      processStatus: [],
      initiator: [],
      processCategory: [],
    };
    props.handleFilterClick(filterOptionPara, selectOptionsPara);
  }, [props.tab]);

  return (
    <div className={styles.topFilterContainer}>
      <div className={styles.topFilterSelects}>
        <div className={styles.topFilterSelectItem}>
          <InputLabel
            text="Process Category"
            className={`${styles.topFilterSelectLabel} fs-14`}
          />
          <NormalSelect
            value={selectOptions.processCategory}
            optionList={filterOptionList.processCategory}
            className="topFilterSelectInput"
            mode="multiple"
            showArrow={true}
            maxTagCount="responsive"
            handleChange={(e) => {
              const optionList = filterOptionList.processCategory;
              setSelectOptions({
                processCategory: e,
              });
              setFilterOptionList({
                processCategory: [
                  ...handleChange(optionList, e),
                  ...optionList.sort((s, t) => sortList(s, t)),
                ],
              });
            }}
            handleDeSelect={(e) => {
              const selectList = [...selectOptions.processCategory];
              setSelectOptions({
                processCategory: handleDeSelect(e, selectList),
              });
            }}
            handleFilterOption={(input, option) =>
              handleFilterOption(input, option)
            }
          />
        </div>
        {
          <div className={styles.topFilterSelectItem}>
            <InputLabel
              text="Process Status"
              className={`${styles.topFilterSelectLabel} fs-14`}
            />
            <NormalSelect
              value={selectOptions.processStatus}
              optionList={filterOptionList.processStatus}
              className="topFilterSelectInput"
              mode="multiple"
              showArrow={true}
              maxTagCount="responsive"
              handleChange={(e) => {
                const optionList = filterOptionList.processStatus;
                setSelectOptions({
                  processStatus: e,
                });
                setFilterOptionList({
                  processStatus: [
                    ...handleChange(optionList, e),
                    ...optionList.sort((s, t) => sortList(s, t)),
                  ],
                });
              }}
              handleFilterOption={(input, option) =>
                handleFilterOption(input, option)
              }
            />
          </div>
        }
        {
          <div className={styles.topFilterSelectItem}>
            <InputLabel
              text="Initiator"
              className={`${styles.topFilterSelectLabel} fs-14`}
            />
            <NormalSelect
              value={selectOptions.initiator}
              optionList={filterOptionList.initiator}
              className="topFilterSelectInput"
              mode="multiple"
              showArrow={true}
              maxTagCount="responsive"
              handleChange={(e) => {
                const optionList = filterOptionList.initiator;
                setSelectOptions({
                  initiator: e,
                });
                setFilterOptionList({
                  initiator: [
                    ...handleChange(optionList, e),
                    ...optionList.sort((s, t) => sortList(s, t)),
                  ],
                });
              }}
              handleDeSelect={(e) => {
                const selectList = [...selectOptions.initiator];
                setSelectOptions({
                  initiator: handleDeSelect(e, selectList),
                });
              }}
              handleFilterOption={(input, option) =>
                handleFilterOption(input, option)
              }
            />
          </div>
        }
      </div>
      <div className={styles.topFilterBtns}>
        <Buttons color="blue" size="middle" onClick={handleFilterClick}>
          Filter
        </Buttons>
        <Buttons color="grey" size="middle" onClick={handleClearClick}>
          Clear
        </Buttons>
      </div>
    </div>
  );
}
