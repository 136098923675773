import React, { createRef } from "react";
import { Modal, Input } from "antd";
import BaseComponent from "../../../../base/common/BaseComponent";
import ProcedureBlock from "../../../../base/basicComponents/procedureBlock";
import PageHeader from "../../../commonComponents/pageHeaders/PageHeader";
import TaskInformation from "../../../sections/taskInfoSections";
import ProcedureInformation from "../../../sections/procedureInfoSections/readOnlyProcedureInfoSection";
import ReadOnlyProcedureFileSection from "../../../sections/procedureFileSections/readOnlyProcedureFileSection";
import ReadOnlySupportingFileSection from "../../../sections/supportingFileSections/readOnlySupportingFileSection";
import InputLabel from "../../../../base/basicComponents/inputLabel";
import Buttons from "../../../../base/basicComponents/button";
import { ModalWithComponent } from "../../../../base/basicComponents/modal";
import { actions, shareTaskMassage } from "../../../publicDictionaryValues";
import changeMainTaskService from "../../../../service/changeMainTask/changeMainTaskService";
import ReviseChangeMainTask from "../../../sections/sharedTaskSections/reviseChangeMainTask";
import NormalModel from "../../../../base/basicComponents//modal/index";
import styles from "../../procedureFileProcess/style/index.module.scss";
import reviseShareService from "../../../../service/shareTask/reviseShareService";
import appService from "../../../../service/app";
import LostPermissionModal from "../../../sections/lostPermissionModal";
import StepBusinessType from "../../../../service/stepBase/stepBusinessType";
import StepService from "../../../../service/stepBase/StepService";

const procedureReturnAddFileRef = createRef();
const procedureFileRef = createRef();
const reviseChangeMainTaskTaskRef = createRef();
const supportingFileRef = createRef();
const supportingFileTableRef = createRef();

export default class ReviseChangeMainTaskProcess extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageState: this.handleReviseChangeMainTaskPageState(),
      modalState: this.handleReviseChangeMainTaskModalState(),
      isApproveComfirmOpen: false,
      confirmModalText: "",
      handleApproveComfirm: () => {
        //this is a empty function
      },
      returnOrRejectModalTitle: "",
      isReturnOrRejectModalOpen: false,
      returnOrRejectInputStatus: "",
      returnOrRejectInputValue: "",
      noPermission: false,
    };
    this.para = null;
    this.isTableBorderError = false;
    this.params = {
      TaskId: this.props.params.ProcedureTaskId,
      ProcedureID: this.props.params.ProcedureID,
      ProcedureStepID: this.props.params.ProcedureStepID,
    };
  }

  handleReviseChangeMainTaskPageState = () => {
    const { rejectReason, rejectStatus } = this.props.pageProps;

    return {
      rejectReason,
      rejectStatus,
      isCancelDisabled: false,
      isSubmitDisabled: false,
      isRejectDisabled: false,
      isTableBorderError: false,
      isChange: false,
    };
  };

  handleReviseChangeMainTaskModalState = () => {
    return {
      modalTitle: "",
      modalLabel: "",
      okText: "",
      cancelText: "",
      closable: false,
      isOkBtnDisabled: false,
      isCancelBtnDisabled: false,
      maskClosable: false,
      isModalWithComponentOpen: false,
      isShowSelect: false,
      rowIndex: 0,
      fileIds: [],
      fileNames: [],
      modalWidth: 520,
    };
  };

  reviseChangMainTaskCancelConfirm = () => {
    this.setState({
      isApproveComfirmOpen: false,
    });
    reviseChangeMainTaskTaskRef.current.resetReviseData();
    this.props.closePage();
  };

  handleChangMainTaskCancel = () => {
    this.setState({
      isApproveComfirmOpen: true,
      confirmModalText: "Are you sure to quit without saving?",
      handleApproveComfirm: this.reviseChangMainTaskCancelConfirm,
    });
  };

  transformChangMainTaskData = (data) => {
    if (data === undefined) {
      return null;
    }
    const outputData = data.map((obj) => {
      if (obj.hasOwnProperty("isDeleted")) {
        obj.isDel = obj.isDeleted;
        delete obj.isDeleted;
      }
      return obj;
    });

    return outputData;
  };

  handleChangMainTaskSubmitPara = (actionID, actionName) => {
    return {
      tasks: this.transformChangMainTaskData(
        JSON.parse(
          JSON.stringify(reviseChangeMainTaskTaskRef.current.state.reviseData)
        )
      ),
      flowCategoryID: this.props.params.FlowCategoryID,
      procedureProcessId: this.props.shareTaskData.procedureProcessID,
      workflowProcessInstanceId: this.props.params.WorkflowProcessInstanceId,
      rejectReason: this.state.pageState.rejectReason,
      procedureStepID: this.props.params.ProcedureStepID,
      shareTaskProcedureStepId:
        this.props.shareTaskData.shareTaskProcedureStepID,
      reason: reviseChangeMainTaskTaskRef.current.state.reasonValue,
      actionName: actionName,
      actionID: actionID,
    };
  };

  approveSuccess = () => {
    this.props._hideLoading();
    this._alertSuccess("Revise Successful");
    this.props.closePage();
  };

  approveFailed = () => {
    this.props._hideLoading();
    this._alertError("Revise Failed");
  };

  checkSubmitOnError = () => {
    this._alertError("Submit Failed");
  };

  checkSubmitOnSuccess = (result) => {
    let currentData = JSON.parse(
      JSON.stringify(reviseChangeMainTaskTaskRef.current.state.reviseData)
    );

    if (!result.result) {
      const errorMsg =
        "There are some tasks that do not meet the conditions for adding to or removing from the sharing relationship. Please check the specific reasons in the task list.";
      this._alertError(errorMsg, 6);
      const responseData = result.resultList;
      for (let index = 0; index < currentData.length; index++) {
        const responseIndex = responseData.findIndex(
          (x) => x.taskId === currentData[index].taskId && !x.checkResult
        );
        if (responseIndex > -1) {
          currentData[index] = {
            ...currentData[index],
            hasError: true,
            errorMessage: shareTaskMassage[responseData[responseIndex].msgCode],
          };
        }
      }
      reviseChangeMainTaskTaskRef.current.updateReviseData(currentData);
    } else {
      const data = currentData.map((item) => {
        if (item.hasError === true) {
          return {
            ...item,
            hasError: false,
            errorMessage: "",
          };
        }

        return item;
      });
      reviseChangeMainTaskTaskRef.current.updateReviseData(data);
      if (reviseChangeMainTaskTaskRef.current.dataHasChange()) {
        this.setState({
          isApproveComfirmOpen: true,
          confirmModalText: "Are you sure to submit?",
          handleApproveComfirm: this.handleApproveComfirm,
        });
      } else {
        this.setState({
          isApproveComfirmOpen: true,
          confirmModalText:
            "No changes made! Are you sure to resubmit this request without any changes?",
          handleApproveComfirm: this.handleApproveComfirm,
        });
      }
    }
  };

  handleApproveComfirm = () => {
    this.props._showLoading();
    this.setState({
      isApproveComfirmOpen: false,
    });
    const para = this.handleChangMainTaskSubmitPara(actions.revise, "Revise");
    const deleteList = reviseChangeMainTaskTaskRef.current.getDeleteData();
    if (deleteList.length > 0) {
      for (let item of deleteList) {
        const para = {
          procedureTaskId: item.procedureTaskId,
          shareTaskProcedureStepId:
            this.props.shareTaskData.shareTaskProcedureStepID,
        };
        reviseShareService.delTaskRowInfo(
          para,
          () => {
            // this is an empty function
          },
          () => {
            // this is an empty function
          }
        );
      }
    }

    changeMainTaskService.manageShareTaskTriggerAction(
      para,
      this.approveSuccess,
      this.approveFailed
    );
  };

  handleChangeMainSubmitClick = () => {
    if (reviseChangeMainTaskTaskRef.current.state.reasonValue.trim() === "") {
      reviseChangeMainTaskTaskRef.current.updateReasonInputErrorStatus();
      return;
    }
    const currentData = JSON.parse(
      JSON.stringify(reviseChangeMainTaskTaskRef.current.state.reviseData)
    );
    let oldMainTask = currentData.find((item) => item.isOldMainTask === true);
    if (
      currentData.length === 2 &&
      oldMainTask !== undefined &&
      oldMainTask.isDeleted
    ) {
      this._alertError(
        "Please note that the Change Main Task process cannot be initiated when there is no active shared task.",
        6
      );
      return;
    }

    let transformChangMainTaskData = this.transformChangMainTaskData(
      JSON.parse(
        JSON.stringify(reviseChangeMainTaskTaskRef.current.state.reviseData)
      )
    );
    let transformNewMainTask = transformChangMainTaskData.find(
      (item) => item.isNewMainTask === true
    );
    let transformOldMainTask = transformChangMainTaskData.find(
      (item) => item.isOldMainTask === true
    );
    if (transformNewMainTask === undefined || transformNewMainTask === null) {
      const result = {
        result: true,
      };
      this.checkSubmitOnSuccess(result);
      return;
    }
    const checkPara = {
      procedureId: this.props.params.ProcedureID,
      procedureStepId: this.props.params.ProcedureStepID,
      oldMainTask: transformOldMainTask,
      newMainTask: transformNewMainTask,
      taskList: currentData,
    };
    changeMainTaskService.checkSubmit(
      checkPara,
      this.checkSubmitOnSuccess,
      this.checkSubmitOnError
    );
  };

  _checkStepPermissionSuccess = (result) => {
    this.props._hideLoading();
    const { statusCode } = result;
    const noPermission = statusCode !== 200;
    this.setState({
      noPermission,
    });
    if (noPermission) return;
    this.handleReviseSubmitClick();
  };

  checkStepPermission = () => {
    this.props._showLoading();
    const param = {
      taskID: this.props.params.ProcedureTaskId,
      procedureID: this.props.params.ProcedureID,
      procedureStepID: this.props.params.ProcedureStepID,
      parentPage: null,
    };
    appService.checkStepPermission(
      param,
      this._checkStepPermissionSuccess,
      (response) => this._alertError(response.message)
    );
  };

  handleReviseSubmitClick = () => {
    this.handleChangeMainSubmitClick();
  };

  handleApproveComfirmCancel = () => {
    this.setState({
      isApproveComfirmOpen: false,
    });
  };

  handleChangeMainTaskPageHeader = () => {
    const { receiveDate, requestByName, procedureStepStatus } =
      this.props.pageProps;

    return (
      <PageHeader
        title="Revise Main Task"
        receiveDate={this._formateDate(receiveDate)}
        requestBy={requestByName}
        status={procedureStepStatus}
      />
    );
  };

  handleChangeMainTaskTaskInformation = () => {
    const { taskInfoData } = this.props.sectionProps;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={<TaskInformation data={taskInfoData} />}
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.props.setTaskInfoData,
            this.setError,
            [StepBusinessType.taskInfo]
          );
        }}
      />
    );
  };

  handleChangeMainTaskTaskProcedureFileSection = () => {
    const {
      serverPathValue,
      savedInRadioValue,
      fileTableData,
      jbProcedureSavedIn,
    } = this.props;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={
          <ReadOnlyProcedureFileSection
            showHintMsg={false}
            procedureFileRef={procedureFileRef}
            clientServerPath={serverPathValue}
            savedInRadioValue={savedInRadioValue}
            procedureFileData={fileTableData !== undefined ? fileTableData : []}
            jbProcedureSavedIn={jbProcedureSavedIn}
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.props.setProcedureFileData,
            this.setError,
            [StepBusinessType.procedureFile]
          );
        }}
      />
    );
  };

  handleChangeMainTaskTaskSupportingFileSection = () => {
    const { supportingTableData } = this.props;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={
          <ReadOnlySupportingFileSection
            showHintMsg={true}
            supportingFileRef={supportingFileRef}
            supportingFileTableRef={supportingFileTableRef}
            supportingFileData={supportingTableData}
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.props.setSupportingFileData,
            this.setError,
            [StepBusinessType.supportingFile]
          );
        }}
      />
    );
  };

  handleChangeMainTaskTaskProcedureInformationSection = () => {
    const { procedureStatus } = this.props.pageProps;
    const { procedureInfoData, ppoList } = this.props.sectionProps;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={
          <ProcedureInformation
            isShowActions={false}
            noteDisabled={true}
            ppoList={ppoList}
            procedureStatus={procedureStatus}
            isPPODisabled={true}
            showHintMsg={true}
            submitData={procedureInfoData}
            title="Procedure Information"
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.props.setProcedureInfoData,
            this.setError,
            [
              StepBusinessType.procedureOwner,
              StepBusinessType.pmpExcluded,
              StepBusinessType.primaryProcedureOwner,
            ]
          );
        }}
      />
    );
  };

  handleReviseChangeMainTaskSection = () => {
    const { shareTaskData } = this.props;

    return (
      <ProcedureBlock
        className={styles.approveProcedureFile}
        child={
          <ReviseChangeMainTask
            ref={reviseChangeMainTaskTaskRef}
            procedureTaskId={this.props.params.ProcedureTaskId}
            shareTaskData={shareTaskData}
            procedureStepId={this.props.params.ProcedureStepID}
          />
        }
        isShowArrow={true}
        isOpen={true}
      />
    );
  };

  handleReviseChangeMainButton = () => {
    const { isCancelDisabled, isSubmitDisabled } = this.state.pageState;

    return (
      <div className={styles.actionBtns}>
        <div className={styles.normalBtns}>
          <Buttons
            color="grey"
            size="middle"
            onClick={this.handleChangMainTaskCancel}
            disabled={isCancelDisabled}
            btnClassName={styles.approveActionBtn}
          >
            Cancel
          </Buttons>
          <Buttons
            color="blue"
            size="middle"
            onClick={this.checkStepPermission}
            disabled={isSubmitDisabled}
            btnClassName={styles.approveActionSubmitBtn}
          >
            Submit for Approval
          </Buttons>
        </div>
      </div>
    );
  };

  handleReviseChangeMainModalWithComponent = () => {
    const { procedureReplaceFileRef } = this.props.pageProps;
    const {
      modalTitle,
      modalLabel,
      okText,
      cancelText,
      closable,
      isOkBtnDisabled,
      isCancelBtnDisabled,
      maskClosable,
      isModalWithComponentOpen,
      rowIndex,
      fileIds,
      fileNames,
      modalWidth,
      handleOk,
      handleCancel,
    } = this.state.modalState;

    return (
      <ModalWithComponent
        isModalOpen={isModalWithComponentOpen}
        closable={closable}
        okText={okText}
        cancelText={cancelText}
        title={modalTitle}
        handleOk={handleOk}
        handleCancel={handleCancel}
        isOkDisabled={isOkBtnDisabled}
        isCancelDisabled={isCancelBtnDisabled}
        maskClosable={maskClosable}
        label={modalLabel}
        fileUploadRef={procedureReplaceFileRef}
        fileUploadInputRef={procedureReturnAddFileRef}
        rowindex={rowIndex}
        fileIds={fileIds}
        fileNames={fileNames}
        hideLoading={this._hideModalBtnsDisabled}
        openLoading={this._openModalBtnsDisabled}
        isLoading={isOkBtnDisabled}
        modalWidth={modalWidth}
      />
    );
  };

  handleReviseChangeMainTaskModal = () => {
    return (
      <Modal
        title={
          <InputLabel
            text={this.state.returnOrRejectModalTitle}
            required={true}
            className={styles.modalText}
          />
        }
        open={this.state.isReturnOrRejectModalOpen}
        okText="Submit"
        onOk={this.handleReturnOrRejectOk}
        onCancel={this.handleReturnOrRejectCancel}
        centered
        maskClosable={false}
      >
        <Input.TextArea
          maxLength={1000}
          style={{
            height: 120,
            resize: "none",
          }}
          status={this.state.returnOrRejectInputStatus}
          onChange={(e) => this.returnOrRejectInputOnChange(e.target)}
          value={this.state.returnOrRejectInputValue}
        />
      </Modal>
    );
  };

  handleReviseChangeMainTaskNormalModel = () => {
    return (
      <NormalModel
        okText="Confirm"
        cancelText="Cancel"
        childContent={<p className="fs-16">{this.state.confirmModalText}</p>}
        isModalOpen={this.state.isApproveComfirmOpen}
        handleOk={this.state.handleApproveComfirm}
        handleCancel={this.handleApproveComfirmCancel}
      />
    );
  };

  render() {
    const { procedureStepStatus } = this.props.pageProps;

    return (
      <div className={styles.approveNewPro}>
        {this.handleChangeMainTaskPageHeader()}
        {this.handleChangeMainTaskTaskInformation()}
        {this.handleChangeMainTaskTaskProcedureFileSection()}
        {this.handleChangeMainTaskTaskSupportingFileSection()}
        {this.handleChangeMainTaskTaskProcedureInformationSection()}
        {this.handleReviseChangeMainTaskSection()}
        {procedureStepStatus === "Pending" &&
          this.handleReviseChangeMainButton()}
        {this.handleReviseChangeMainModalWithComponent()}
        {this.handleReviseChangeMainTaskModal()}
        {this.handleReviseChangeMainTaskNormalModel()}
        <LostPermissionModal isModalOpen={this.state.noPermission} />
      </div>
    );
  }
}
