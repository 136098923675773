import { Checkbox } from "antd";
import InputLabel from "../../../../../../base/basicComponents/inputLabel";
import ApproveUpdateSupportingFileManagement from "../../../../supportingFileSections/fields/supportingFileManagement/approveUpdateSupportingFileManagement";
import ApproveUpdateProcedureFileTable from "../../procedureFileTableFields/approveUpdateProcedureFileTable";
import styles from "../index.module.scss";

export default class ApproveUpdateProcedureFileManagement extends ApproveUpdateSupportingFileManagement {
  handleLevelCChange = (e) => {
    this.setState({ isLevelCChange: e.target.checked });
  };

  handleReturnLevelCShow = () => {
    const { CategoryID, AccountID, TaskGroupID, TaskLabelID } =
      this.props.ptParams;
    const currentHref =
      process.env.SUPERVISOR_PORTAL_ADDRESS +
      "?TaskCreateTaskNew.aspx?catid=" +
      CategoryID +
      "&tgid=" +
      TaskGroupID +
      "&aid=" +
      AccountID +
      "&vtid=" +
      TaskLabelID;
    return (
      <>
        <div className={styles.levelCLink}>
          <InputLabel
            text="Review/Update level C: "
            className={styles.linkTip}
          />
          <a
            href={currentHref}
            className={styles.linkContent}
            target="_blank"
            rel="noreferrer"
          >
            Click here to view task detail page
          </a>
        </div>
        <div className={styles.levelCChange}>
          <InputLabel
            text="Level C is changed: "
            className={styles.changeTip}
          />
          <Checkbox
            onChange={this.handleLevelCChange}
            checked={this.state.isLevelCChange}
            disabled={this.props.isDisableAll}
            className={styles.changeCheckbox}
          ></Checkbox>
        </div>
      </>
    );
  };

  handleReturnFileTable = (obj) => {
    const {
      fileTableRef,
      columns,
      className,
      optionList,
      currentRole,
      downloadFile,
      isDisableAll,
      initProcedureTableData,
      fileTableStatus,
      isTableBorderError,
    } = obj;
    return (
      <ApproveUpdateProcedureFileTable
        ref={fileTableRef}
        columns={columns}
        procedureNoteMaxLength={1000}
        procedureNoteSize="small"
        inputOnChange={this.inputOnChange}
        fileTableRef={fileTableRef}
        className={className}
        optionList={optionList}
        handleReturnClick={(e) =>
          this.handleProcedureFileTableReturnClick(
            e.target.attributes.rowindex.value
          )
        }
        currentRole={currentRole}
        downloadFile={downloadFile}
        isComplete={isDisableAll}
        initProcedureTableData={initProcedureTableData}
        setIsTableChange={this.setIsTableChange}
        cancelIsTableChange={this.cancelIsTableChange}
        fileTableStatus={fileTableStatus}
        isTableBorderError={isTableBorderError}
        isShowReturnReason={true}
      />
    );
  };
}
