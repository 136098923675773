import { Checkbox } from "antd";
import EllipsisToolTip from "../../../../../../base/basicComponents/ellipsisToolTip";
import { ExpendTextAreaByProps } from "../../../../../../base/basicComponents/input/index";
import Buttons from "../../../../../../base/basicComponents/button";
import NormalSelect from "../../../../../../base/basicComponents/select";
import { FileStatusDiv } from "../../../../../../base/basicComponents/status";
import ReturnReasonInput from "../../../../../../base/basicComponents/returnReasonInput";
import UploadFile from "../../../../../../base/basicComponents/uploadFile";
import EditIcon from "../../../../../../base/basicComponents/EditIcon";
import { dataStatus, fileTags } from "../../../../../publicDictionaryValues";
import BaseTbody from "../../../../../commonComponents/fileTbodys/baseFileTbody";
import { createRef } from "react";
import "../index.scss";
const ReturnResonRef = createRef();
class ApproveProcedureFileTbody extends BaseTbody {
  constructor(props) {
    super(props);
    this.state = {
      inputRef: "",
    };
    this.isHideReplaceBtn = false;
    this.isHideDeleteBtn = false;
    this.isHideApproveBtn = false;
    this.isHideReturnBtn = false;
  }

  /**
   * function about show checkbox if column type is checkbox
   * @param {*} column
   * @param {*} row
   * @returns
   */
  returnCheckBox = (column, row) => {
    const lineId = row.data.find((item) => item.name === "lineId").value;
    return (
      <td key={lineId} className="checkboxtd">
        <Checkbox
          checked={
            column.check(row.data.find((x) => x.name === "lineId").value)
              ? true
              : false
          }
          onChange={(e) =>
            column.onCheck(
              row.data.find((x) => x.name === "lineId").value,
              e.target
            )
          }
        />
      </td>
    );
  };

  /**
   * function about show link if column type is link
   * @param {*} rowColumn
   * @param {*} column
   * @param {*} rowIndex
   * @param {*} row
   * @returns
   */
  returnLink = (rowColumn, column, rowIndex, row) => {
    const fileTag = row.data.find((item) => item.name === "fileName").tagValue;

    return (
      <div className="linkContainer">
        <a
          href="#JavaScript:;"
          className="linkName"
          onClick={() => {
            this.props.downloadFile(rowColumn.fileId, rowColumn.value);
          }}
          disabled={rowColumn.tagValue === fileTags.deleted}
        >
          <EllipsisToolTip
            children={rowColumn.value}
            title={rowColumn.value}
            placement="top"
          />
        </a>
        {rowColumn.name === "attachment" &&
          rowColumn.value !== null &&
          column.showEdit && (
            <EditIcon
              className="editAttachment"
              onClick={(e) => this.props.handleReturnClick(e)}
              rowindex={rowIndex}
              altText="Edit Attachment"
            />
          )}
      </div>
    );
  };

  /**
   * function about show link if column type is link
   * @param {*} rowColumn
   * @param {*} column
   * @returns
   */
  returnFileName = (rowColumn, column) => {
    return (
      <div className="fileNameContainer">
        <EllipsisToolTip
          children={rowColumn.value}
          title={rowColumn.value}
          placement="top"
        />
        {column.tagValue !== null && (
          <FileStatusDiv status={rowColumn.tagValue} />
        )}
      </div>
    );
  };

  /**
   * function about show input if column type is input
   * @param {*} row
   * @param {*} rowColumn
   * @param {*} column
   * @param {*} rowIndex
   * @param {*} isShowReturnReason
   * @param {*} procedureNoteMaxLength
   * @returns
   */
  returnInput = (
    row,
    rowColumn,
    column,
    rowIndex,
    isShowReturnReason,
    procedureNoteMaxLength
  ) => {
    const fileTage =
      row.data.find((item) => item.name === "fileName") !== undefined &&
      row.data.find((item) => item.name === "fileName").tagValue;
    return (
      <>
        {rowColumn.name === "returnReason" ? (
          isShowReturnReason && (
            <>
              <ReturnReasonInput
                inputRef={ReturnResonRef}
                value={rowColumn.value}
                rowindex={rowIndex}
                isDisabled={
                  rowColumn.isDisabled ||
                  column.isDisabled ||
                  row.data.find((item) => item.name === "returnReason")
                    .value === null ||
                  row.data.find((item) => item.name === "returnReason")
                    .value === ""
                }
                isShowEditBtn={
                  rowColumn.value !== null &&
                  column.showEdit
                }
                handleReturnEditClick={(e) => {
                  this.props.handleReturnClick(e);
                }}
                isShowReasonLogIcon={rowColumn.isShowReasonLog}
                reasonLog={rowColumn.reasonLog}
                isTable={true}
              />
            </>
          )
        ) : (
          <ExpendTextAreaByProps
            value={rowColumn.value}
            onChange={(ele) => this.props.inputOnChange(ele.target)}
            maxLength={procedureNoteMaxLength}
            disabled={true}
            dataname={column.dataName}
            rowindex={rowIndex}
            style={{
              display: column.isShow ? "table-cell" : "none",
            }}
            ref={column.ref}
            readOnly={rowColumn.readOnly}
            handleTableChange={this.props.handleChangeTableHeight}
            isExpendData={this.props.isExpendData}
          />
        )}
      </>
    );
  };

  /**
   * function about show select if column tye is select
   * @param {*} currentRole
   * @param {*} rowColumn
   * @param {*} column
   * @param {*} row
   * @param {*} rowIndex
   * @param {*} optionList
   * @returns
   */
  returnSelect = (
    currentRole,
    rowColumn,
    column,
    row,
    rowIndex,
    optionList
  ) => (
    <>
      <NormalSelect
        optionList={rowColumn.optionList}
        disabled={rowColumn.isDisabled || column.isDisabled || row.isReturned}
        value={rowColumn.value}
        handleChange={(e) => column.onChange(e, rowIndex)}
      />
    </>
  );

  /**
   * function about show button if column type is button
   * @param {*} row
   * @param {*} column
   * @param {*} rowIndex
   * @param {*} status
   * @returns
   */
  returnButton = (row, column, rowIndex, status) => {
    const fileNameData = row.data.find((item) => item.name === "fileName");
    const tagValue = fileNameData && fileNameData.tagValue;
    if (column.hideActionButton !== undefined && column.hideActionButton(row)) {
      return;
    }
    return (
      <div className="buttonTd-content">
        {!this.isHideReplaceBtn &&
          !(tagValue === fileTags.deleted && status.value === null) && (
            <>
              <Buttons
                color={column.approveColor}
                size="small"
                onClick={(e) =>
                  column.onApproveClick(e.target.attributes.rowindex.value)
                }
                disabled={row.isApproveDisabled}
                rowindex={rowIndex}
              >
                {column.approveText}
              </Buttons>
              <UploadFile
                fileInputRef={row.replaceRef}
                isMultiple={false}
                handleFileSelect={this.props.handleFileSelect}
                rowindex={rowIndex}
                isSupportingFile={false}
              />
            </>
          )}
        {!this.isHideDeleteBtn &&
          !(tagValue === fileTags.deleted && status.value === null) && (
            <Buttons
              color={column.returnColor}
              size="small"
              onClick={() => column.onReturnClick(rowIndex)}
              disabled={row.isReturnDisabled || tagValue === fileTags.deleted}
              rowindex={rowIndex}
            >
              {column.returnText}
            </Buttons>
          )}
      </div>
    );
  };

  returnModal = (rowColumn, column, row, rowIndex) => {
    return (
      <div>
        <p
          className="modalLink"
          onClick={(e) => this.props.handleReturnClick(e)}
          rowindex={rowIndex}
        >
          {rowColumn.value !== "" &&
            rowColumn.value !== null &&
            column.showText}
        </p>
      </div>
    );
  };

  /**
   * function about control the replace and delete buttons show in revise page
   * @param {*} row
   */
  judgeIsShowReplaceAndDeleteBtn = (row) => {
    const fileNameData = row.data.find((item) => item.name === "fileName");
    const tagValue = fileNameData && fileNameData.tagValue;
    const isApprovedBefore =
      row.data.find((item) => item.name === "previousStatus").value ===
      dataStatus.approval;
    this.isHideReplaceBtn =
      (tagValue === fileTags.deleted &&
        row.data.find((x) => x.name === "status").value ===
          dataStatus.return) ||
      isApprovedBefore;
    this.isHideDeleteBtn =
      (tagValue === fileTags.deleted &&
        row.data.find((x) => x.name === "status").value ===
          dataStatus.return) ||
      isApprovedBefore;
  };

  /**
   * control display text base on column type
   * @param {*} column
   * @returns
   */
  judgeIsShowText = (column) => {
    return (
      column.type !== "link" &&
      column.type !== "input" &&
      column.type !== "select" &&
      column.type !== "tag" &&
      column.type !== "button" &&
      column.type !== "fileName" &&
      column.type !== "modal"
    );
  };

  returnTrErrorClass = (row) => (row.isError ? "red" : "normal");

  columnShowStyle = (column) => (column.isShow ? "table-cell" : "none");

  render() {
    const {
      data,
      columns,
      procedureNoteMaxLength,
      fileSectionDisabled,
      optionList,
      isShowReturnReason,
      currentRole,
    } = this.props;

    const typeToClassName = {
      link: "linkTd",
      input: "inputTd",
      default: "textTd",
      tag: "tagTd",
      select: "selectTd",
      button: "buttonTd",
      fileName: "fileNameTd",
      modal: "modalTd",
    };

    return (
      <>
        <tbody>
          {data.map((row, rowIndex) => {
            const fileNameData = row.data.find(
              (item) => item.name === "fileName"
            );
            const status = row.data.find((item) => item.name === "status");
            fileNameData &&
              (status || fileNameData.tagValue) &&
              this.judgeIsShowReplaceAndDeleteBtn(row);
            return (
              <tr
                key={row.data.find((item) => item.name === "lineId").value}
                className={`table-tr ${this.returnTrErrorClass(row)}`}
                id={`table-tr-${rowIndex}`}
              >
                {columns.map((column, columnIndex) => {
                  if (!column.isShow) {
                    return;
                  }
                  const rowColumn = row.data.find(
                    (x) => x.name === column.dataName
                  );

                  const tagValue = row.data.find(
                    (x) => x.name === "fileName"
                  ).tagValue;
                  if (column.type === "checkbox") {
                    return this.returnCheckBox(column, row);
                  } else {
                    return (
                      <td
                        key={column.dataName}
                        style={{
                          display: this.columnShowStyle(column),
                        }}
                        className={
                          typeToClassName[column.type] ||
                          typeToClassName.default
                        }
                      >
                        {column.type === "link" &&
                          this.returnLink(rowColumn, column, rowIndex, row)}
                        {column.type === "fileName" &&
                          this.returnFileName(rowColumn, column)}
                        {column.type === "input" &&
                          this.returnInput(
                            row,
                            rowColumn,
                            column,
                            rowIndex,
                            isShowReturnReason,
                            procedureNoteMaxLength,
                            fileSectionDisabled
                          )}
                        {column.type === "select" &&
                          this.returnSelect(
                            currentRole,
                            rowColumn,
                            column,
                            row,
                            rowIndex,
                            optionList
                          )}
                        {column.type === "button" &&
                          this.returnButton(row, column, rowIndex, status)}
                        {column.type === "tag" &&
                          this.returnTag(column, rowColumn, tagValue)}
                        {column.type === "modal" &&
                          this.returnModal(rowColumn, column, row, rowIndex)}
                        {this.judgeIsShowText(column) && rowColumn.value}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </>
    );
  }
}

export default ApproveProcedureFileTbody;
