import React, { useRef, useState } from "react";
import { useMount } from "ahooks";
import AppSetting from "../../../config/AppSetting";
import { userRoles } from "../../publicDictionaryValues";
import NormalSpin from "../../../base/basicComponents/spin";
import TopFilter from "../../sections/myRequestListSections/topFilter/index";
import SearchAndColumnSelect from "../procedureList/procedureListSections/searchAndColumnSelect";
import MyRequestListColumnSelectOption, {
  MyRequestListColumnSelectValue,
} from "../../sections/myRequestListSections/columnSelectOption";
import ListTable from "../../commonComponents/listTable/index";
import MyRequestListColumn from "../../sections/myRequestListSections/myRequestListColumn";
import MyRequestTableFilterMap from "../../commonComponents/listTable/myRequestListFilterMap.js";
import FooterPagination from "../procedureList/procedureListSections/footerPagination";
import myRequestListService from "../../../service/myRequestListService/index";
import styles from "./index.module.scss";
import { getTimeZoneString } from "../../publicDictionaryValues";

export default function MyRequestList() {
  const searchAndColumnSelectRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [taskLabelName, setTaskLabelName] = useState("");
  const [taskId, setTaskId] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [currentTaskLabelName, setCurrentTaskLabelName] = useState("");
  const [currentTaskId, setCurrentTaskId] = useState("");
  const [currentTransactionId, setCurrentTransactionId] = useState("");
  const [currentPage, setCurrentPage] = useState({
    pageNumber: 1,
    pageSize: 30,
  });
  const [totalPage, setTotalPage] = useState(0);
  const [totalRecord, setTotalRecord] = useState(0);
  const [myRequestListData, setMyRequestListData] = useState([]);

  const [topFilterData, setTopFilterData] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [tableSortData, setTableSortData] = useState([]);
  const [myRequestListFilterData, setMyRequestListFilterData] = useState({});
  const [processStatusData, setProcessStatusData] = useState([]);
  const [accountAbbrData, setAccountAbbrData] = useState([]);
  const [allTopFilterData, setAllTopFilterData] = useState([]);
  const [tableColumnFilterParam, setTableColumnFilterParam] = useState(null);

  const [transactionIdShow, setTransactionIdShow] = useState(true);
  const [actionCompletedDateShow, setActionCompletedDateShow] = useState(true);
  const [processCategoryShow, setProcessCategoryShow] = useState(true);
  const [actionRequiredShow, setActionRequiredShow] = useState(true);
  const [actionTakenShow, setActionTakenShow] = useState(true);
  const [delegatedByShow, setDelegatedByShow] = useState(true);
  const [delegatedToShow, setDelegatedToShow] = useState(true);
  const [dueDateShow, setDueDateShow] = useState(true);
  const [receiveDateShow, setReceiveDateShow] = useState(true);
  const [requestByShow, setRequestByShow] = useState(true);
  const [processInitiateDateShow, setProcessInitiateDateShow] = useState(true);
  const [processInitiatorShow, setProcessInitiatorShow] = useState(true);
  const [accountAbbrShow, setAccountAbbrShow] = useState(true);
  const [accountNameShow, setAccountNameShow] = useState(false);
  const [taskGroupShow, setTaskGroupShow] = useState(true);
  const [categoryShow, setCategoryShow] = useState(true);
  const [taskIdShow, setTaskIdShow] = useState(true);
  const [taskLabelNameShow, setTaskLabelNameShow] = useState(true);
  const [highlyRegulatedShow, setHighlyRegulatedShow] = useState(false);
  const [triggerProcessShow, setTriggerProcessShow] = useState(true);
  const [processStatusShow, setProcessStatusShow] = useState(true);
  const [currentProcessorShow, setCurrentProcessorShow] = useState(true);
  const [processCompletedDateShow, setProcessCompletedDateShow] =
    useState(true);

  const [myRequestListDivHeight, setMyRequestListDivHeight] = useState([0]);
  const [myRequestListTableBodyHeight, setMyRequestListTableBodyHeight] =
    useState([0]);

  let currentDate = null;
  let threeMonthsAgoDate = null;
  const [processInitiateDateArr, setProcessInitiateDateArr] = useState(
    getDate()
  );

  const resetState = () => {
    setTaskLabelName((pre) => "");
    setTaskId((pre) => "");
    setTransactionId((pre) => "");
    setCurrentTaskLabelName((pre) => "");
    setCurrentTaskId((pre) => "");
    setCurrentTransactionId((pre) => "");
    setCurrentPage({
      pageNumber: 1,
      pageSize: 30,
    });
    setTotalPage((pre) => 0);
    setTotalRecord((pre) => 0);
    setMyRequestListData((pre) => []);
    setTopFilterData((pre) => []);
    setTableFilterData((pre) => []);
    setFilteredInfo((pre) => {
      return {};
    });
    setTableSortData((pre) => []);
    setMyRequestListFilterData((pre) => {
      return {};
    });
    setTransactionIdShow((pre) => true);
    setActionCompletedDateShow((pre) => true);
    setProcessCategoryShow((pre) => true);
    setActionRequiredShow((pre) => true);
    setActionTakenShow((pre) => true);
    setDelegatedByShow((pre) => true);
    setDelegatedToShow((pre) => true);
    setDueDateShow((pre) => true);
    setReceiveDateShow((pre) => true);
    setRequestByShow((pre) => true);
    setProcessInitiateDateShow((pre) => true);
    setProcessInitiatorShow((pre) => true);
    setAccountAbbrShow((pre) => true);
    setAccountNameShow((pre) => false);
    setTaskGroupShow((pre) => true);
    setCategoryShow((pre) => true);
    setTaskIdShow((pre) => true);
    setTaskLabelNameShow((pre) => true);
    setHighlyRegulatedShow((pre) => false);
    setTriggerProcessShow((pre) => true);
    setProcessStatusShow((pre) => true);
    setCurrentProcessorShow((pre) => true);
    setProcessCompletedDateShow((pre) => true);
  };

  const handleUserRole = (roleModel) => {
    if (roleModel?.length === undefined || roleModel?.length === 0) {
      return false;
    }
    return roleModel.some((x) => x.roleID === userRoles.Opex);
  };

  const isOpex = handleUserRole(AppSetting.localStorageValue.role.get());
  function getDate() {
    const date = new Date();
    const day = date.getDate();
    const current =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + day;

    const agoDate = new Date();
    agoDate.setDate(day - 90);
    const threeMonthsAgo =
      agoDate.getFullYear() +
      "-" +
      (agoDate.getMonth() + 1) +
      "-" +
      agoDate.getDate();

    threeMonthsAgoDate = getTimeZoneString(threeMonthsAgo);
    currentDate = getTimeZoneString(current);

    return [threeMonthsAgoDate, currentDate];
  }

  const getProcessInitiateDateFrom = () => {
    return {
      filterColumn: "processInitiateDateFrom",
      filterValue: processInitiateDateArr[0] || null,
    };
  };

  const getProcessInitiateDateTo = () => {
    return {
      filterColumn: "processInitiateDateTo",
      filterValue: processInitiateDateArr[1] || null,
    };
  };

  const getInitiatedBy = (initiatedByOption, initiatedBySelect) => {
    let filterItem = null;
    if (initiatedBySelect.length === 0) {
      filterItem = parseInt(initiatedByOption[0].value);
    } else {
      filterItem = parseInt(initiatedBySelect);
    }

    return {
      filterColumn: "InitiatedBy",
      filterValue: filterItem,
    };
  };

  const getAccountAbbr = (accountAbbrOption, accountAbbrSelect) => {
    let filterArr = [];
    if (accountAbbrSelect.length === 0) {
      filterArr = accountAbbrOption.map((x) => parseInt(x.value));
    } else {
      filterArr = accountAbbrSelect.map((x) => parseInt(x));
    }

    return {
      filterColumn: "AccountID",
      filterValue: filterArr,
    };
  };

  const getProcessCategory = (processCategoryOption, processCategorySelect) => {
    let filterArr = [];
    if (processCategorySelect.length === 0) {
      filterArr = processCategoryOption.map((x) => x.value);
    } else {
      filterArr = processCategorySelect.map((x) => x);
    }

    return {
      filterColumn: "processCategory",
      filterValue: filterArr,
    };
  };

  const getProcessStatus = (processStatusOption, processStatusSelect) => {
    let filterArr = [];
    if (processStatusSelect.length === 0) {
      filterArr = processStatusOption.map((x) => x.value);
    } else {
      filterArr = processStatusSelect;
    }

    return {
      filterColumn: "ProcessStatusID",
      filterValue: filterArr,
    };
  };

  const getMyRequestListSuccess = (result) => {
    setLoading(false);
    setMyRequestListData(result.data);
    setTotalPage(result.totalPage);
    setTotalRecord(result.totalRecord);
  };

  const getMyRequestListError = (result) => {
    console.log(result);
  };

  const getMyRequestListFilterSuccess = (result, currentField) => {
    setMyRequestListFilterData({
      ...myRequestListFilterData,
      [currentField]: result?.fields,
    });

    setLoading(false);
    setFilterLoading(false);
  };

  const getMyRequestListFilterError = (result) => {
    console.log(result);
  };

  const getTopFilterCondition = () => {
    return [
      {
        key: "processInitiateDateFrom",
        filterFunction: getProcessInitiateDateFrom,
      },
      {
        key: "processInitiateDateTo",
        filterFunction: getProcessInitiateDateTo,
      },
      {
        key: "initiatedBy",
        filterFunction: getInitiatedBy,
      },
      {
        key: "accountAbbr",
        filterFunction: getAccountAbbr,
      },
      {
        key: "processCategory",
        filterFunction: getProcessCategory,
      },
      {
        key: "processStatus",
        filterFunction: getProcessStatus,
      },
    ];
  };

  const getTopFilter = (filterOptionList, selectOptions) => {
    const topFilterArr = [];
    const topFilterOptionList = JSON.parse(JSON.stringify(filterOptionList));
    const topFilterSelectOptions = JSON.parse(JSON.stringify(selectOptions));

    const filterConditions = getTopFilterCondition().filter(
      (item) => item.key === "processCategory"
    );

    filterConditions.forEach(({ key, tabCondition, filterFunction }) => {
      if (
        topFilterOptionList[key] !== undefined &&
        topFilterOptionList[key].length !== 0 &&
        (tabCondition === undefined || tabCondition)
      ) {
        if (
          isOpex &&
          key === "accountAbbr" &&
          topFilterSelectOptions[key].length === 0
        ) {
          return;
        }
        const filteredData = filterFunction(
          topFilterOptionList[key],
          topFilterSelectOptions[key]
        );
        topFilterArr.push(filteredData);
      }
    });

    return topFilterArr;
  };

  const getAllTopFilter = (filterOptionList, selectOptions) => {
    const topFilterArr = [];
    const topFilterOptionList = JSON.parse(JSON.stringify(filterOptionList));
    const topFilterSelectOptions = JSON.parse(JSON.stringify(selectOptions));

    const filterConditions = getTopFilterCondition();

    filterConditions.forEach(({ key, tabCondition, filterFunction }) => {
      if (
        topFilterOptionList[key] !== undefined &&
        topFilterOptionList[key].length !== 0 &&
        (tabCondition === undefined || tabCondition)
      ) {
        if (
          isOpex &&
          key === "accountAbbr" &&
          topFilterSelectOptions[key].length === 0
        ) {
          return;
        }
        const filteredData = filterFunction(
          topFilterOptionList[key],
          topFilterSelectOptions[key]
        );
        topFilterArr.push(filteredData);
      }
    });

    return topFilterArr;
  };

  const getMyRequestListTable = (getMyRequestListPara) => {
    myRequestListService.getMyRequestList(
      getMyRequestListPara,
      getMyRequestListSuccess,
      getMyRequestListError
    );
  };

  const handleTopFilterClick = (filterOptionList, selectOptions) => {
    const topFilterArr = getTopFilter(filterOptionList, selectOptions);
    const allTopFilterArr = getAllTopFilter(filterOptionList, selectOptions);
    setLoading(true);
    setTopFilterData(topFilterArr);
    setAllTopFilterData(allTopFilterArr);
    setTaskId("");
    setTaskLabelName("");
    setTransactionId("");
    setCurrentTaskLabelName("");
    setCurrentTaskId("");
    setCurrentTransactionId("");
    setTableFilterData([]);
    setTableSortData([]);
    setMyRequestListFilterData({});
    setFilteredInfo({});
    setCurrentPage({ ...currentPage, pageNumber: 1 });
    getDate();

    const allAccountIds = [];
    filterOptionList.accountAbbr.forEach((item) =>
      allAccountIds.push(item.value)
    );
    const accountID =
      accountAbbrData.length > 0 ? accountAbbrData : allAccountIds;

    const getMyRequestListPara = {
      filterFields: topFilterArr.filter(
        (item) => item.filterColumn === "processCategory"
      ),
      sortFields: [],
      transactionId: "",
      taskId: "",
      taskLabelName: "",
      pageSize: currentPage.pageSize,
      pageIndex: 1,
      processInitiateDateFrom:
        selectOptions?.processInitiateDateFrom || threeMonthsAgoDate,
      processInitiateDateTo:
        selectOptions?.processInitiateDateTo || currentDate,
      initiatedBy: selectOptions?.initiatedBy ? selectOptions.initiatedBy : 1,
      accountID:
        selectOptions.accountAbbr?.length > 0
          ? selectOptions.accountAbbr
          : accountID,
      processStatus:
        selectOptions.processStatus?.length > 0
          ? selectOptions.processStatus
          : processStatusData,
    };

    const getMyRequestListFilterPara = {
      filterFields: topFilterArr.filter(
        (item) => item.filterColumn === "processCategory"
      ),
      transactionId: "",
      taskId: "",
      taskLabelName: "",
      processInitiateDateFrom:
        selectOptions?.processInitiateDateFrom || threeMonthsAgoDate,
      processInitiateDateTo:
        selectOptions?.processInitiateDateTo || currentDate,
      initiatedBy: selectOptions?.initiatedBy ? selectOptions.initiatedBy : 1,
      accountID:
        selectOptions.accountAbbr?.length > 0
          ? selectOptions.accountAbbr
          : accountID,
      processStatus:
        selectOptions.processStatus?.length > 0
          ? selectOptions.processStatus
          : processStatusData,
    };
    setTableColumnFilterParam({ ...getMyRequestListFilterPara });

    getMyRequestListTable(getMyRequestListPara);
  };

  const ShowSearchColumn = () => {
    let searchColumnArr = [];
    if (transactionId.trim() !== "") {
      searchColumnArr.push("transactionIdShow");
    }

    if (taskId.trim() !== "") {
      searchColumnArr.push("taskIdShow");
    }

    if (taskLabelName.trim() !== "") {
      searchColumnArr.push("taskLabelNameShow");
    }

    searchAndColumnSelectRef.current.handleColumnSearchSelect(searchColumnArr);
  };

  const getAllTopFilterDataValue = (topFilter, filterColumn) => {
    const data = topFilter.find((item) => item.filterColumn === filterColumn);
    return data?.filterValue;
  };

  const handleSearchClick = () => {
    const topFilter = JSON.parse(JSON.stringify(allTopFilterData));
    setLoading(true);
    setCurrentPage({ ...currentPage, pageNumber: 1 });
    setCurrentTaskLabelName(taskLabelName.trim());
    setCurrentTaskId(taskId.trim());
    setCurrentTransactionId(transactionId.trim());
    getDate();
    setTableFilterData([]);
    setTableSortData([]);
    setMyRequestListFilterData({});
    setFilteredInfo({});
    const getMyRequestListPara = {
      filterFields: topFilter.filter(
        (item) => item.filterColumn === "processCategory"
      ),
      sortFields: [],
      transactionId: transactionId.trim(),
      taskId: taskId.trim(),
      taskLabelName: taskLabelName.trim(),
      pageSize: currentPage.pageSize,
      pageIndex: 1,
      processInitiateDateFrom: processInitiateDateArr[0] || threeMonthsAgoDate,
      processInitiateDateTo: processInitiateDateArr[1] || currentDate,
      initiatedBy: getAllTopFilterDataValue(topFilter, "InitiatedBy") || 1,
      accountID:
        getAllTopFilterDataValue(topFilter, "AccountID") || accountAbbrData,
      processStatus:
        getAllTopFilterDataValue(topFilter, "ProcessStatusID") ||
        processStatusData,
    };

    const getMyRequestListFilterPara = {
      filterFields: topFilterData.filter(
        (item) => item.filterColumn === "processCategory"
      ),
      transactionId: transactionId.trim(),
      taskId: taskId.trim(),
      taskLabelName: taskLabelName.trim(),
      processInitiateDateFrom: processInitiateDateArr[0] || threeMonthsAgoDate,
      processInitiateDateTo: processInitiateDateArr[1] || currentDate,
      initiatedBy: getAllTopFilterDataValue(topFilter, "InitiatedBy") || 1,
      accountID:
        getAllTopFilterDataValue(topFilter, "AccountID") || accountAbbrData,
      processStatus:
        getAllTopFilterDataValue(topFilter, "ProcessStatusID") ||
        processStatusData,
    };
    setTableColumnFilterParam({ ...getMyRequestListFilterPara });

    getMyRequestListTable(getMyRequestListPara);

    ShowSearchColumn();
  };

  const handleTransactionIdChange = (e) => {
    setTransactionId(e.value);
  };

  const handleTaskLabelNameChange = (e) => {
    setTaskLabelName(e.value);
  };

  const handleTaskIdChange = (e) => {
    setTaskId(e.value);
  };

  const handleColumnSelectUpdate = (key, value) => {
    const updateFunctions = {
      transactionIdShow: () => setTransactionIdShow(value),
      actionCompletedDateShow: () => setActionCompletedDateShow(value),
      processCategoryShow: () => setProcessCategoryShow(value),
      actionRequiredShow: () => setActionRequiredShow(value),
      actionTakenShow: () => setActionTakenShow(value),
      delegatedByShow: () => setDelegatedByShow(value),
      delegatedToShow: () => setDelegatedToShow(value),
      dueDateShow: () => setDueDateShow(value),
      receiveDateShow: () => setReceiveDateShow(value),
      requestByShow: () => setRequestByShow(value),
      processInitiateDateShow: () => setProcessInitiateDateShow(value),
      processInitiatorShow: () => setProcessInitiatorShow(value),
      accountAbbrShow: () => setAccountAbbrShow(value),
      accountNameShow: () => setAccountNameShow(value),
      taskGroupShow: () => setTaskGroupShow(value),
      categoryShow: () => setCategoryShow(value),
      taskIdShow: () => setTaskIdShow(value),
      taskLabelNameShow: () => setTaskLabelNameShow(value),
      highlyRegulatedShow: () => setHighlyRegulatedShow(value),
      triggerProcessShow: () => setTriggerProcessShow(value),
      processStatusShow: () => setProcessStatusShow(value),
      currentProcessorShow: () => setCurrentProcessorShow(value),
      processCompletedDateShow: () => setProcessCompletedDateShow(value),
    };

    const updateFunction = updateFunctions[key];
    updateFunction && updateFunction();
  };

  const handleColumnSearchSelect = (valueArr) => {
    for (let value of valueArr) {
      handleColumnSelectUpdate(value, true);
    }
  };

  const handleColumnSearchDeSelect = (value) => {
    handleColumnSelectUpdate(value, false);
  };

  const concatMyRequestFilters = (topFilter, tableFilter) => {
    const mergedArray = [...topFilter];

    for (const objB of tableFilter) {
      const filterColumnB = objB.filterColumn;
      const filterValueB = objB.filterValue;
      const existingObjIndex = mergedArray.findIndex(
        (objA) => objA.filterColumn === filterColumnB
      );

      if (existingObjIndex !== -1) {
        const existingObj = mergedArray[existingObjIndex];
        const filterValueA = existingObj.filterValue;
        const intersection = filterValueA.filter((value) =>
          filterValueB.includes(value)
        );
        existingObj.filterValue = intersection.length > 0 ? intersection : [];
      } else {
        mergedArray.push(objB);
      }
    }

    return mergedArray;
  };

  const getListTableData = (getMyRequestListPara) => {
    myRequestListService.getMyRequestList(
      getMyRequestListPara,
      getMyRequestListSuccess,
      getMyRequestListError
    );
  };

  const getFilterArrValue = (filterArr, key) => {
    const value = filterArr.find(
      (item) => item.filterColumn === key
    )?.filterValue;
    return value;
  };

  const handlePageChange = (pageSize, pageNumber) => {
    getDate();
    const topFilter = JSON.parse(JSON.stringify(allTopFilterData));
    const tableFilter = JSON.parse(JSON.stringify(tableFilterData));
    const sort = JSON.parse(JSON.stringify(tableSortData));
    const filterArr = concatMyRequestFilters(topFilter, tableFilter);
    const getMyRequestListPara = {
      filterFields: filterArr.filter(
        (item) => item.filterColumn === "processCategory"
      ),
      sortFields: sort,
      taskId: currentTaskId,
      taskLabelName: currentTaskLabelName,
      transactionId: currentTransactionId,
      pageSize: pageSize === null ? currentPage.pageSize : pageSize,
      pageIndex: pageNumber === null ? currentPage.pageNumber : pageNumber,
      processInitiateDateFrom: processInitiateDateArr[0] || threeMonthsAgoDate,
      processInitiateDateTo: processInitiateDateArr[1] || currentDate,
      initiatedBy: getFilterArrValue(filterArr, "InitiatedBy") || 1,
      accountID: getFilterArrValue(filterArr, "AccountID") || accountAbbrData,
      processStatus:
        getFilterArrValue(filterArr, "ProcessStatusID") || processStatusData,
    };

    getListTableData(getMyRequestListPara);
  };

  const handlePageNumberChange = (pageNumber, pageSize) => {
    setLoading(true);
    let newPageNumber = pageSize === currentPage.pageSize ? pageNumber : 1;
    setCurrentPage({
      pageSize: pageSize,
      pageNumber: newPageNumber,
    });
    handlePageChange(pageSize, newPageNumber);
  };

  const handleTableFilterSort = (tableFilterPara, tableSortPara, filters) => {
    const topFilter = JSON.parse(JSON.stringify(allTopFilterData));
    const tableFilter = JSON.parse(JSON.stringify(tableFilterPara));
    const sort = JSON.parse(JSON.stringify(tableSortPara));
    const filterArr = concatMyRequestFilters(topFilter, tableFilter);

    setLoading(true);
    setCurrentPage({ ...currentPage, pageNumber: 1 });
    setTableFilterData(tableFilter);
    setTableSortData(sort);
    setFilteredInfo(filters);
    getDate();

    const filterFields = filterArr.filter(
      (item) =>
        item.filterColumn !== "processInitiateDateFrom" &&
        item.filterColumn !== "processInitiateDateTo" &&
        item.filterColumn !== "InitiatedBy" &&
        item.filterColumn !== "AccountID" &&
        item.filterColumn !== "ProcessStatusID"
    );
    const getMyRequestListPara = {
      filterFields: filterFields,
      sortFields: sort,
      transactionId: currentTransactionId,
      taskId: currentTaskId,
      taskLabelName: currentTaskLabelName,
      pageSize: currentPage.pageSize,
      pageIndex: 1,
      processInitiateDateFrom: processInitiateDateArr[0] || threeMonthsAgoDate,
      processInitiateDateTo: processInitiateDateArr[1] || currentDate,
      initiatedBy: getFilterArrValue(filterArr, "InitiatedBy") || 1,
      accountID: getFilterArrValue(filterArr, "AccountID") || accountAbbrData,
      processStatus:
        getFilterArrValue(filterArr, "ProcessStatusID") || processStatusData,
    };

    getListTableData(getMyRequestListPara);
  };

  const handleColumnFilterClick = (isOpen, currentField) => {
    if (!isOpen) return;

    const filterFieldList = Object.keys(myRequestListFilterData);

    if (!filterFieldList.includes(currentField)) {
      setFilterLoading(true);
      const getTodoTableFilterPara = {
        ...tableColumnFilterParam,
        CurrentField: currentField,
      };

      myRequestListService.getMyRequestFilter(
        getTodoTableFilterPara,
        (result) => getMyRequestListFilterSuccess(result, currentField),
        getMyRequestListFilterError
      );
    }
  };

  const handleColumnSelectValue = (value) => {
    return value;
  };

  const handleColumnSelectOption = (option) => {
    return option;
  };

  const handleTableWidth = () => {
    if (
      searchAndColumnSelectRef.current?.state.columnSelectValue === undefined
    ) {
      return columnSelectValue.length * 220;
    }

    return (
      searchAndColumnSelectRef.current.state.columnSelectValue.length * 220
    );
  };

  const columnSelectValue = MyRequestListColumnSelectValue();

  const columnSelectOption = MyRequestListColumnSelectOption();

  const columns = MyRequestListColumn({
    myRequestListFilterData,
    filteredInfo,
    transactionIdShow,
    taskLabelNameShow,
    actionCompletedDateShow,
    processCategoryShow,
    actionRequiredShow,
    delegatedByShow,
    delegatedToShow,
    actionTakenShow,
    dueDateShow,
    receiveDateShow,
    requestByShow,
    processInitiateDateShow,
    processInitiatorShow,
    accountAbbrShow,
    accountNameShow,
    taskGroupShow,
    categoryShow,
    taskIdShow,
    highlyRegulatedShow,
    triggerProcessShow,
    processStatusShow,
    currentProcessorShow,
    processCompletedDateShow,
    tableSortData,
    handleColumnFilterClick,
    filterLoading,
  });

  const calculateHeight = () => {
    // const screenHeight = window.innerHeight;
    // const desiredHeight = screenHeight - 134;
    // setProcedureListDivHeight(desiredHeight);
    // const tableContainer = document.querySelector(".ant-table-body");
    // if (tableContainer) {
    //   tableContainer.style.height = `${desiredHeight - 400}px`;
    // }

    const screenHeight = window.innerHeight;
    const desiredHeight = screenHeight - 114 + 8;
    setMyRequestListDivHeight(desiredHeight);
    var myRequestListTopFilterDiv = document.getElementById(
      "myRequestListTopFilter"
    );
    var myRequestListTopFilterDivHeight =
      myRequestListTopFilterDiv.offsetHeight;
    const tableContainer = document.querySelector(".ant-table-body");

    if (tableContainer) {
      const myRequestListTableBodyHeight =
        desiredHeight - myRequestListTopFilterDivHeight - 191 + 8;
      setMyRequestListTableBodyHeight(myRequestListTableBodyHeight);
      tableContainer.style.height = `${myRequestListTableBodyHeight}px`;
    }
  };

  useMount(() => {
    setLoading(true);
    resetState();
    getDate();
    calculateHeight();
    window.addEventListener("resize", calculateHeight);

    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  });

  return (
    <NormalSpin spinning={loading} size="large">
      <div
        className={styles.procedureListDiv}
        style={{ height: myRequestListDivHeight }}
      >
        <div className={styles.procedureTopFilter} id="myRequestListTopFilter">
          <TopFilter
            handleFilterClick={handleTopFilterClick}
            isOpex={isOpex}
            defaultDateValue={[threeMonthsAgoDate, currentDate]}
            processInitiateDateArr={processInitiateDateArr}
            setProcessInitiateDateArr={setProcessInitiateDateArr}
            setProcessStatusData={setProcessStatusData}
            setAccountAbbrData={setAccountAbbrData}
          />
          <SearchAndColumnSelect
            ref={searchAndColumnSelectRef}
            columnSelectValue={handleColumnSelectValue(columnSelectValue)}
            columnSelectOption={handleColumnSelectOption(columnSelectOption)}
            handleSearch={handleSearchClick}
            taskLabelName={taskLabelName}
            taskId={taskId}
            transactionId={transactionId}
            handleTransactionIdChange={handleTransactionIdChange}
            handleTaskLabelNameChange={handleTaskLabelNameChange}
            handleTaskIdChange={handleTaskIdChange}
            handleColumnSearchSelect={handleColumnSearchSelect}
            handleColumnSearchDeSelect={handleColumnSearchDeSelect}
            showExport={false}
          />
        </div>
        <div className={styles.tableDiv}>
          <ListTable
            data={myRequestListData}
            columns={columns}
            handleFilterSort={handleTableFilterSort}
            tableFilterMap={MyRequestTableFilterMap}
            tableWidth={handleTableWidth()}
            tableHeight={myRequestListTableBodyHeight}
          />
          <div className={styles.myRequestFooter}>
            <FooterPagination
              totalRecord={totalRecord}
              totalPage={totalPage}
              currentPage={currentPage}
              handlePageNumberChange={handlePageNumberChange}
            />
          </div>
        </div>
      </div>
    </NormalSpin>
  );
}
