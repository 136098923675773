import React, { createRef } from "react";
import BaseComponent from "../../common/BaseComponent";
import AppSetting from "../../../config/AppSetting";
import ProcedureDetailService from "../../../service/procedureFile/ProcedureDetailService";
import FileItem from "./fileItem";
import NormalModal from "../modal";
import uploadFileIcon from "../../../assets/image/uploadFileicon.png";
import tipIcon from "../../../assets/image/tipIcon.png";
import "./index.scss";
import { handleAlertUploadFileError } from "../../../app/publicDictionaryValues";

class UploadFile extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      refresh: false,
    };
  }

  componentDidUpdate = () => {
    if (this.state.refresh) {
      this.setState({ refresh: false });
    }
  };

  onChange = (event) => {
    this.props.handleFileSelect(
      event,
      this.props.rowindex,
      this.props.isSupportingFile
    );
    if (this.props.fileInputRef !== undefined) {
      this.props.fileInputRef.current.value = "";
    }
    this.setState({ refresh: true });
  };

  render() {
    const { fileInputRef, isMultiple = true, rowindex } = this.props;
    return (
      !this.state.refresh && (
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: "none" }}
          onChange={this.onChange}
          multiple={isMultiple}
          id={rowindex}
        />
      )
    );
  }
}

const fileItemRef = createRef();
class FileUploadDragger extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      fileIdList: [],
      isModalOpen: false,
      isError: false,
      modalChildContent: "",
      handleModalConfirm: () => {
        // This is an empty function
      },
      handleModalCancel: () => {
        // This is an empty function
      },
    };
    this.dropBox = document.getElementsByClassName("uploadInputContainer")[0];
    this.fileSize = 0;
    this.fileIndex = 0;
    this.fileNum = 0;
    this.uploadFileNum = 0;
    this.fileList = [];
    this.fileNum = 0;
  }

  /* function about click file Input */
  handleFileUpload = () => {
    const isEditable = this.props.isShowDeleteBtn;
    if (isEditable !== undefined && isEditable === true) {
      this.props.fileInputRef.current.click();
      this.props.fileInputRef.current.value = "";
      this.cancelMandatory();
    }
  };

  /* function about close modal */
  hideModal = () => {
    this.setState({ isModalOpen: false });
  };

  /* get param to use in the file servies api */
  getAddFileDto = (item) => {
    return {
      FileName: item.name,
      ContainerName: AppSetting.urlPrefix.addFile.ContainerName,
      AccountName: AppSetting.urlPrefix.addFile.AzureAccountName,
      ProductKey: AppSetting.urlPrefix.addFile.ProductKey,
      FolderName: AppSetting.urlPrefix.addFile.ProcedureFileFolder,
    };
  };

  /**
   * Remove same file from state by current file
   * @param {*} currentItem
   */
  removeSameFile = (currentItem) => {
    const fileIndex = this.state.fileList.findIndex(
      (x) => x.name === currentItem.name
    );
    if (fileIndex > -1) {
      this.state.fileIdList.splice(
        this.state.fileIdList.findIndex(
          (x) => x === this.state.fileList[fileIndex].fileId
        )
      );
      this.state.fileList.splice(fileIndex, 1);
    }
  };

  /**
   * CallBack of addFileFunc
   * @param {*} response
   * @param {*} preRequestData
   * @returns
   */
  onAddFileSuccess = (response, preRequestData) => {
    this.removeSameFile(preRequestData.dataItem);
    preRequestData.dataItem.storageFileID = response.storageFileID;
    const fileList = this.props.isMultiple
      ? [...this.state.fileList, preRequestData.dataItem]
      : [preRequestData.dataItem];
    const fileIdList = this.props.isMultiple
      ? [...this.state.fileIdList, response.storageFileID]
      : [response.storageFileID];
    this.setState({
      fileList: fileList,
      fileIdList: fileIdList,
    });
    this.props.isMultiple && this.props.handleFileListChange(fileList);

    this.props.updateFileNumLoading &&
      this.props.updateFileNumLoading({
        currentNum: this.fileNum - preRequestData.currentDataList.length,
        allNum: this.fileNum,
      });
    /// Stop callback AddFile
    if (preRequestData.currentDataList.length === 0) {
      this.uploadFileNum = 0;
      this.props.updateFileNumLoading != null
        ? this.props.updateFileNumLoading({
            currentNum: 0,
            allNum: 0,
          })
        : this.props.hideLoading();
      return;
    }

    this.uploadFileNum--;
    this.addFile(preRequestData.dataList, preRequestData.currentDataList);
  };

  /**
   * Add Files - Default upload one file and will callback self if has other files
   * @param {*} dataList Page File List
   * @param {*} currentDataList The remaining data needs to be uploaded
   * @returns
   */
  addFile = (dataList, currentDataList) => {
    currentDataList = currentDataList ?? this.fileList;
    const item = currentDataList.splice(0, 1)[0];

    if (this.checkFileDuplicate(dataList, currentDataList, item)) {
      return;
    }
    this.addFileFunc(dataList, currentDataList, item);
  };

  /**
   * Check File Name Duplicate
   * @param {*} dataList Page File List
   * @param {*} currentDataList The remaining data needs to be uploaded
   * @param {*} currentItem Current File
   * @returns {boolean}
   */
  checkFileDuplicate = (dataList, currentDataList, currentItem) => {
    const dataIndex = dataList.findIndex(
      (dataItem) => dataItem.name === currentItem.name
    );
    if (dataIndex !== -1 && this.props.isMultiple) {
      this.setState({
        isModalOpen: true,
        modalChildContent: `${currentItem.name} already exists. Do you want to replace it?`,
        handleModalConfirm: () => {
          if (dataIndex !== -1) {
            dataList.splice(dataIndex, 1);
          }
          this.hideModal();
          this.addFileFunc(dataList, currentDataList, currentItem);
        },
        handleModalCancel: () => {
          this.hideModal();
          if (currentDataList.length === 0) {
            this.props.updateFileNumLoading != null
              ? this.props.updateFileNumLoading({
                  currentNum: 0,
                  allNum: 0,
                })
              : this.props.hideLoading();
            this.uploadFileNum = 0;
            return;
          }

          this.addFile(dataList, currentDataList);
        },
      });
      /// Has Duplicate File Name
      return true;
    }

    return false;
  };

  /**
   * General dto to send upload file request
   * @param {*} dataList Page File List
   * @param {*} currentDataList The remaining data needs to be uploaded
   * @param {*} currentItem Current File
   */
  addFileFunc = (dataList, currentDataList, currentItem) => {
    let fileForm = new FormData();
    let addFileDto = this.getAddFileDto(currentItem);
    fileForm.append("formFile", currentItem);
    fileForm.append("addFileDto", JSON.stringify(addFileDto));
    ProcedureDetailService.addFile(
      fileForm,
      this.onAddFileSuccess,
      (response) => {
        console.log(response);
      },
      {
        dataList: dataList,
        currentDataList: currentDataList,
        dataItem: currentItem,
      }
    );
  };

  /* function about upload file */
  FileProcess = (index, dataList) => {
    if (
      this.fileList === undefined ||
      this.fileList === null ||
      this.fileList.length === 0
    ) {
      return;
    }

    this.uploadFileNum += this.fileList.length;
    this.props.updateFileNumLoading != null
      ? this.props.updateFileNumLoading({
          currentNum: 0,
          allNum: this.fileList.length,
        })
      : this.props.openLoading();
    this.addFile(dataList);
  };

  /* function about upload mutiple file */
  handleInsertFile = (files) => {
    this.fileList = [...files];
    this.fileNum = files.length;
    if (handleAlertUploadFileError(this.fileList)) {
      return;
    }

    /// File List Before Insert
    let dataList = [...this.state.fileList];

    this.FileProcess(0, dataList);
  };

  /* function about upload single file */
  handleInsertSingleFile = (files) => {
    this.fileList = [...files];
    this.fileNum = files.length;
    for (let fileItem of this.fileList) {
      if (fileItem.size > 300 * 1024 * 1024) {
        this._alertError(
          "The limit of a single attachment is no larger than 300MB."
        );
        return;
      }
    }

    /// File List Before Insert
    let dataList = [...this.state.fileList];

    this.FileProcess(0, dataList);
  };

  clearFileList = () => {
    this.setState({
      fileList: [],
    });
  };

  /* function about click delete button to delete file */
  deleteFile = (index) => {
    this.state.fileList.splice(index, 1);
    this.setState({
      fileList: this.state.fileList,
      fileIdList: this.state.fileIdList,
    });
    const fileId = this.state.fileIdList[index];
    let dto = {
      ProductKey: AppSetting.urlPrefix.deleteFile.ProductKey,
      StorageFileID: fileId,
    };
    this.props.isMultiple &&
      ProcedureDetailService.deleteFile(
        dto,
        () => {
          this._alertSuccess("Delete Successful");
          this.state.fileIdList.splice(index, 1);
          this.props.isMultiple &&
            this.props.handleFileListChange(this.state.fileList);
        },
        (response) => {
          console.log(response);
        },
        fileId
      );
  };

  /* function about check whether error if this part is manatory */
  checkMandatory = () => {
    this.setState({
      isError: true,
    });
  };

  /* function about cancel error if this part is manatory */
  cancelMandatory = () => {
    this.setState({
      isError: false,
    });
  };

  /* function about drag and drop files to upload */
  dragEnter = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  dragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  drop = (e) => {
    const isEditable = this.props.isShowDeleteBtn;
    if (isEditable !== undefined && isEditable === true) {
      if (this.props.isMultiple) {
        this.handleInsertFile(e.dataTransfer.files);
      } else {
        this.handleInsertSingleFile(e.dataTransfer.files);
      }
    }
    e.preventDefault();
    e.stopPropagation();
  };

  /* function about clear files */
  clearFileAttach = () => {
    this.setState({
      fileList: [],
    });
  };

  /* function about download file */
  loadFiles = () => {
    const { fileIds, fileNames } = this.props;
    const files = [];
    fileNames.forEach((item, index) => {
      files.unshift({
        name: item,
        size: index,
      });
    });

    this.setState({
      fileList: [...this.state.fileList, ...files],
      fileIdList: [...this.state.fileIdList, ...fileIds],
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { fileIds } = this.props;
    if (
      JSON.stringify(prevProps.fileIds) !==
        JSON.stringify(this.props.fileIds) ||
      prevProps.isShowDragger !== this.props.isShowDragger
    ) {
      fileIds.length !== 0 && this.loadFiles();
    }
  }

  componentDidMount() {
    this.props.fileIds.length !== 0 && this.loadFiles();
  }

  render() {
    const {
      isMultiple = false,
      rowindex,
      className = "",
      isShowDeleteBtn,
      fileNameType,
      isDownload,
      fileUploadDraggerLabel,
    } = this.props;
    const {
      fileList,
      fileIdList,
      modalChildContent,
      isModalOpen,
      isError,
      handleModalCancel,
      handleModalConfirm,
    } = this.state;
    const disableClass = !isShowDeleteBtn ? "disableUpload" : "";
    return (
      <div className={`${className} fileUploadContainer`}>
        {fileUploadDraggerLabel}
        <div
          className={`uploadInputContainer ${disableClass}`}
          onClick={this.handleFileUpload}
          onDragEnter={this.dragEnter}
          onDragOver={this.dragOver}
          onDrop={this.drop}
          style={{
            borderColor: isError ? "red" : "rgba(47, 97, 176, 0.5)",
          }}
        >
          <p className="fileUploadIcon">
            <img src={uploadFileIcon} alt="Upload File" />
          </p>
          <p className="fileUploadTip">Drag and Drop files here</p>
          {!isMultiple && (
            <div className="tipContainer">
              <div>
                <img src={tipIcon} alt="Only One Tip" />
              </div>
              <p className="onlyOneTip">
                You can only upload one file as attachment. If you upload again,
                the old one will be replaced.
              </p>
            </div>
          )}
        </div>
        <input
          type="file"
          id="uploadInput"
          multiple={isMultiple}
          ref={this.props.fileInputRef}
          onChange={(e) => {
            if (this.props.isMultiple) {
              this.handleInsertFile(e.target.files);
            } else {
              this.handleInsertSingleFile(e.target.files);
            }
          }}
          rowindex={rowindex}
        />
        {fileList.map((item, index) => (
          <FileItem
            fileName={item.name}
            fileType={item.name}
            fileSize={item.size}
            ref={fileItemRef}
            index={index}
            fileItemRef={fileItemRef}
            deleteFile={() => this.deleteFile(index)}
            fileList={fileList}
            isShowDeleteBtn={isShowDeleteBtn}
            key={crypto.randomUUID()}
            fileNameType={fileNameType}
            fileId={fileIdList[item.size]}
            isDownload={isDownload}
          />
        ))}
        <NormalModal
          okText="Replace"
          cancelText="Skip"
          childContent={<p className="fs-16">{modalChildContent}</p>}
          isModalOpen={isModalOpen}
          zIndex={1501}
          handleOk={handleModalConfirm}
          handleCancel={() => {
            handleModalCancel();
          }}
        />
      </div>
    );
  }
}

/**
 * component for attachment field for revision information
 */
export class RevisionInfoUploadFile extends FileUploadDragger {
  /* function about click delete button to delete file */
  deleteFile = (index) => {
    this.state.fileList.splice(index, 1);
    this.setState({
      fileList: this.state.fileList,
      fileIdList: this.state.fileIdList,
    });
    const fileId = this.state.fileIdList[index];
    this.state.fileIdList.splice(index, 1);
    this.props.handleChangeDeleteIds(fileId);
  };
}

export default UploadFile;

export { FileUploadDragger };
