import fileDeleteDefaultIcon from "../../../assets/image/fileDeleteIcon.png";
import fileDeleteHoverIcon from "../../../assets/image/fileDeleteHoverIcon.png";
import defaultIcon from "./images/default-ico.png";
import docIcon from "./images/doc-ico.png";
import imgIcon from "./images/img-ico.png";
import pdfIcon from "./images/pdf-ico.png";
import pptIcon from "./images/ppt-ico.png";
import xlsxIcon from "./images/xlsx-ico.png";
import videoIcon from "./images/video-ico.png";
import BaseComponent from "../../common/BaseComponent";
import EllipsisToolTip from "../ellipsisToolTip";
import { downloadSingleFile } from "../../../app/publicDictionaryValues";
export default class FileItem extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      fileDeleteIcon: fileDeleteDefaultIcon,
    };
  }

  adjustSize = (fileSize) => {
    this.fileSize =
      fileSize / 1024 > 1
        ? (fileSize / 1024).toFixed(2) + "MB"
        : fileSize + "KB";
  };

  choseDocumentImg = (fileType) => {
    switch (fileType) {
      case "pdf":
        return pdfIcon;
      case "docx":
        return docIcon;
      case "doc":
        return docIcon;
      case "png":
        return imgIcon;
      case "jpeg":
        return imgIcon;
      case "jpg":
        return imgIcon;
      case "gif":
        return imgIcon;
      case "ppt":
        return pptIcon;
      case "pptx":
        return pptIcon;
      case "xlsx":
        return xlsxIcon;
      case "xls":
        return xlsxIcon;
      case "mp4":
        return videoIcon;
      default:
        return defaultIcon;
    }
  };

  render() {
    const { fileDeleteIcon } = this.state;
    const {
      fileName,
      fileSize,
      fileType,
      index,
      isShowDeleteBtn,
      fileNameType,
      fileId,
      isDownload,
    } = this.props;
    return (
      <div className="fileList" id={index}>
        <div className="fileListLeft">
          <div className="fileIcon">
            <img src={this.choseDocumentImg(fileType)} alt="File Icon" />
          </div>
          <div className="fileMsg">
            {fileNameType === "text" && <p className="fileName">{fileName}</p>}
            {fileNameType === "link" && (
              <a
                className="fileName"
                href="#JavaScript:;"
                fileId={fileId}
                onClick={() => {
                  isDownload && downloadSingleFile(fileId, fileName);
                }}
              >
                <EllipsisToolTip
                  children={fileName}
                  title={fileName}
                  placement="top"
                />
              </a>
            )}
            <p className="fileSize">{this.adjustSize(fileSize)}</p>
          </div>
        </div>
        {isShowDeleteBtn && (
          <div className="fileListBtns">
            <div
              className="deleteFileIcon"
              title="Delete File"
              onMouseEnter={() =>
                this.setState({ fileDeleteIcon: fileDeleteHoverIcon })
              }
              onMouseLeave={() =>
                this.setState({ fileDeleteIcon: fileDeleteDefaultIcon })
              }
              onClick={this.props.deleteFile}
            >
              <img src={fileDeleteIcon} alt="Delete File" />
            </div>
          </div>
        )}
      </div>
    );
  }
}
