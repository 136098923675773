import React, { createRef } from "react";
import BaseChangeMainTaskComplete from "../baseChangeMainTaskComplete";
import ReviseChangeMainTaskReadOnly from "../../../sections/sharedTaskSections/reviseChangeMainTaskReadOnly";
import ReadOnlyProcedureFileSection from "../../../sections/procedureFileSections/readOnlyProcedureFileSection";

const procedureFileRef = createRef();

export default class ReviseChangeMainTaskComplete extends BaseChangeMainTaskComplete {
  constructor(props) {
    super(props);
    const { rejectReason, rejectStatus } = this.props.pageProps;
    this.state = {
      pageState: {
        rejectReason,
        rejectStatus,
        isCancelDisabled: false,
        isSubmitDisabled: false,
        isRejectDisabled: false,
        isTableBorderError: false,
        isChange: false,
      },
    };
  }
  /* function about control save button disable when procedure file table change */
  cancelIsTableChange = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        isChange: false,
      },
    });
  };

  /* function about control save button disable when procedure file table change */
  setIsTableChange = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        isChange: true,
      },
    });
  };

  handleReturnPageName = () => {
    return "Revise Main Task";
  };

  handleReturnShareTaskSection = () => {
    const { shareTaskData } = this.props;

    return <ReviseChangeMainTaskReadOnly shareTaskData={shareTaskData} />;
  };

  handleReturnProcedureFileSection = () => {
    const {
      serverPathValue,
      savedInRadioValue,
      fileTableData,
      jbProcedureSavedIn,
    } = this.props;

    return (
      <ReadOnlyProcedureFileSection
        showHintMsg={false}
        procedureFileRef={procedureFileRef}
        clientServerPath={serverPathValue}
        savedInRadioValue={savedInRadioValue}
        procedureFileData={fileTableData !== undefined ? fileTableData : []}
        jbProcedureSavedIn={jbProcedureSavedIn}
      />
    );
  };
}
