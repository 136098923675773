import BaseComponent from "../../../../base/common/BaseComponent";
import downloadIcon from "../../../../assets/image/downloadIcon.png";
import downloadHoverIcon from "../../../../assets/image/downloadHover.png";
import AppSetting from "../../../../config/AppSetting";
import ProcedureDetailService from "../../../../service/procedureFile/ProcedureDetailService";
import { downloadFileSuccess } from "../../../publicDictionaryValues";

export default class BaseApplyForJbSection extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      downloadIcon: downloadIcon,
      downloadBtnDisabled: true,
    };
  }

  /**
   * function about download file
   */
  downloadFile = (fileId, value) => {
    if (fileId && value) {
      const para = {
        ProductKey: AppSetting.urlPrefix.addFile.ProductKey,
        fileId: fileId,
      };
      ProcedureDetailService.downloadFile(
        para,
        (response) => downloadFileSuccess(response, value),
        (response) => console.log(response)
      );
    } else {
      const dataList = this.fileTableRef.current.state.data;
      let downloadList = dataList.filter((x) => {
        return x.checked === true;
      });
      downloadList.map((data, index) => {
        const fileID = data.data.find((item) => item.name === "fileId").value;
        const para = {
          ProductKey: AppSetting.urlPrefix.addFile.ProductKey,
          fileId: fileID,
        };
        ProcedureDetailService.downloadFile(
          para,
          (response) =>
            downloadFileSuccess(
              response,
              data.data.find((item) => item.name === "fileName").value
            ),
          (response) => console.log(response)
        );
      });
      this.fileTableRef.current.updateDataList(dataList);
    }
  };

  /**
   * change download button style when mouse enter
   */
  handleDownloadBtnMouseEnter = () => {
    this.setState({
      downloadIcon: downloadHoverIcon,
    });
  };

  /**
   * change download button style when mouse leave
   */
  handleDownloadBtnMouseLeave = () => {
    this.setState({
      downloadIcon: downloadIcon,
    });
  };

  /**
   * undisable download btn
   */
  openDownloadBtn = () => {
    this.setState({ downloadBtnDisabled: false });
  };

  /**
   * disable download btn
   */
  disableDownloadBtn = () => {
    this.setState({ downloadBtnDisabled: true });
  };

  /**
   * control table delete button diabled
   * @param {*} dataList table data
   */
  openOrDisableDownloadButton = (dataList) => {
    const checked = dataList.find((x) => x.checked === true);
    if (checked === undefined) {
      this.disableDownloadBtn();
    } else {
      this.openDownloadBtn();
    }
  };
  /**
   * function about control table header checked
   * @param {*} lineId current row lineId
   * @returns
   */
  _check = (lineId) => {
    if (this.fileTableRef.current !== null) {
      const dataList = this.fileTableRef.current.state.data;
      let currentRow =
        dataList !== null &&
        dataList !== undefined &&
        dataList.find(
          (x) => x.data.find((y) => y.name === "lineId").value === lineId
        );
      return !!(
        currentRow !== undefined &&
        currentRow.checked !== undefined &&
        currentRow.checked
      );
    }
  };

  /**
   * function about control table header checked when chehck each row
   * @param {*} lineId current row lineId
   * @param {*} ele current checkbox
   */
  _onCheck = (lineId, ele) => {
    const dataList =
      this.fileTableRef.current !== null &&
      this.fileTableRef.current.state.data;
    let currentRow = dataList.find(
      (x) => x.data.find((y) => y.name === "lineId").value === lineId
    );
    const currentRowIndex = dataList.findIndex(
      (x) => x.data.find((y) => y.name === "lineId").value === lineId
    );

    if (ele.checked) {
      currentRow = { ...currentRow, checked: true };
    } else {
      currentRow = { ...currentRow, checked: false };
    }

    dataList[currentRowIndex] = currentRow;
    this.openOrDisableDownloadButton(dataList);
    this.fileTableRef.current.updateDataList(dataList);
  };

  /**
   * function about control procedure table header checked
   * @returns
   */
  _headCheck = () => {
    if (this.fileTableRef === undefined || this.fileTableRef.current === null) {
      return false;
    }
    const dataList =
      this.fileTableRef !== undefined && this.fileTableRef.current.state.data;
    if (dataList.length === 0) {
      return false;
    }

    let checked = false;
    checked = dataList.find((x) => x.checked !== true);
    return checked === undefined;
  };

  /**
   * function about control row's checkbox when check table header checkbox
   * @param {*} ele table header checkbox element
   */
  _headOnCheck = (ele) => {
    const dataList = this.fileTableRef.current.state.data;
    if (ele.checked) {
      for (let index = 0; index < dataList.length; index++) {
        dataList[index] = { ...dataList[index], checked: true };
      }
    } else {
      for (let index = 0; index < dataList.length; index++) {
        dataList[index] = { ...dataList[index], checked: false };
      }
    }
    this.openOrDisableDownloadButton(dataList);
    this.fileTableRef.current.updateDataList(dataList);
  };
}
