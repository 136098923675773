import React, { createRef } from "react";
import ProcedureBlock from "../../../../../base/basicComponents/procedureBlock";
import PageHeader from "../../../../commonComponents/pageHeaders/PageHeader";
import TaskInformation from "../../../../sections/taskInfoSections";
import ReadOnlyProcedureFileSection from "../../../../sections/procedureFileSections/readOnlyProcedureFileSection";
import ReadOnlySupportingFileSection from "../../../../sections/supportingFileSections/readOnlySupportingFileSection";
import PMPExcludedProcedureInformation from "../../../../sections/procedureInfoSections/pmpExcludedProcedureInfoSection";
import styles from "../../style/index.module.scss";
import BaseComponent from "../../../../../base/common/BaseComponent";
import ActiveShareTaskReadOnly from "../../../../sections/sharedTaskSections/activeShareTaskReadOnly";
import StepService from "../../../../../service/stepBase/StepService";
import StepBusinessType from "../../../../../service/stepBase/stepBusinessType";

const procedureFileRef = createRef();
const supportingFileRef = createRef();
const supportingFileTableRef = createRef();
export default class BasePMPExcludedComplete extends BaseComponent {
  handleReturnRejectReason = () => {
    // this.is an empty function
  };

  handleReturnPageName = () => {
    // this is an empty function
  };

  render() {
    const { procedureStatus, receiveDate, requestByName } =
      this.props.pageProps;
    const {
      taskInfoData,
      procedureInfoData,
      ppoList,
      fileTableData,
      jbProcedureSavedIn,
    } = this.props.sectionProps;
    const { supportingTableData } = this.props;

    const params = {
      TaskId: this.props.params.ProcedureTaskId,
      ProcedureID: this.props.params.ProcedureID,
      ProcedureStepID: this.props.params.ProcedureStepID,
    };

    return (
      <div className={styles.approveNewPro}>
        <PageHeader
          title={this.handleReturnPageName()}
          receiveDate={this._formateDate(receiveDate)}
          requestBy={requestByName}
          status="Complete"
        />
        <ProcedureBlock
          className={styles.approveProcedureBlock}
          child={<TaskInformation data={taskInfoData} />}
          isShowArrow={true}
          isOpen={false}
          onShowDataLoad={() => {
            return StepService.getStepData(
              params,
              this.props.setTaskInfoData,
              this.setError,
              [StepBusinessType.taskInfo]
            );
          }}
        />
        <ProcedureBlock
          child={
            <ReadOnlyProcedureFileSection
              procedureFileRef={procedureFileRef}
              clientServerPath={this.props.serverPathValue}
              savedInRadioValue={this.props.savedInRadioValue}
              procedureFileData={
                fileTableData !== undefined ? fileTableData : []
              }
              showHintMsg={true}
              jbProcedureSavedIn={jbProcedureSavedIn}
            />
          }
          isShowArrow={true}
          isOpen={false}
          onShowDataLoad={() => {
            return StepService.getStepData(
              params,
              this.props.setProcedureFileData,
              this.setError,
              [StepBusinessType.procedureFile]
            );
          }}
        />
        <ProcedureBlock
          child={
            <ReadOnlySupportingFileSection
              supportingFileData={supportingTableData}
              showHintMsg={true}
              supportingFileRef={supportingFileRef}
              supportingFileTableRef={supportingFileTableRef}
            />
          }
          isShowArrow={true}
          isOpen={false}
          onShowDataLoad={() => {
            return StepService.getStepData(
              params,
              this.props.setSupportingFileData,
              this.setError,
              [StepBusinessType.supportingFile]
            );
          }}
        />
        <ProcedureBlock
          className={`${styles.approveProcedureBlock} ${styles.excludedProcedureInfo}`}
          child={
            <PMPExcludedProcedureInformation
              isShowActions={false}
              noteDisabled={true}
              ppoList={ppoList}
              procedureStatus={procedureStatus}
              isPPODisabled={true}
              submitData={procedureInfoData}
              title="Procedure Information"
              isDisabledAll={true}
            />
          }
          isShowArrow={true}
          isOpen={true}
        />
        <ProcedureBlock
          className={styles.approveProcedureBlock}
          child={
            <ActiveShareTaskReadOnly
              showHintMsg={true}
              shareTaskData={this.props.shareTaskData}
            />
          }
          isShowArrow={true}
          onShowDataLoad={() => {
            return StepService.getStepData(
              params,
              this.props.setShareTaskData,
              this.setError,
              [StepBusinessType.shareTask]
            );
          }}
        />
        {this.handleReturnRejectReason()}
      </div>
    );
  }
}
