import React, { createRef } from "react";
import SupportingFileProcess from "..";
import NormalSpin from "../../../../base/basicComponents/spin";
import {
  downloadSingleFile,
  handleSortFileByStatus,
  processDefaultTableSortFile,
  flowCategoryId,
} from "../../../publicDictionaryValues";
import ApproveNewSupportingFileComplete from "./approveNewSupportingFileComplete";
import ApproveNewSupportingFilePending from "./approveNewSupportingFilePending";
import ReviseNewSupportingFileComplete from "./reviseNewSupportingFileComplete";
import ReviseNewSupportingFilePending from "./reviseNewSupportingFilePending";
import SupportingFileSubmitComplete from "./supportingFileSubmitComplete";
import styles from "../style/index.module.scss";

const supportingRef = createRef();
const approvePageRef = createRef();
const supportingReplaceFileRef = createRef();
export default class CreateSupportingFileProcess extends SupportingFileProcess {
  /* sort supporting files follow status in revise page */
  handleSortFile = (result) => {
    let isRevise = false;
    if (
      result.workflowProcessStepInfo !== null &&
      result.workflowProcessStepInfo.annotations.length !== 0
    ) {
      isRevise =
        result.workflowProcessStepInfo.annotations.find(
          (item) => (item.name = "IsRevise")
        ).value === "1";
    }
    const tiggerTimes = result.sequence;
    if (tiggerTimes > 1) {
      return handleSortFileByStatus(
        result.supportingFolder.supportingFiles,
        this._formateDate,
        isRevise
      );
    }
    return processDefaultTableSortFile(
      result.supportingFolder.supportingFiles,
      this._formateDate
    );
  };

  render() {
    const {
      procedureStepStatus,
      procedureStatus,
      receiveDate,
      requestByName,
      rejectReason,
      rejectStatus,
      loading,
      supportingFileNum,
      uploadSupportingFileNum,
      flowCategoryID,
    } = this.state.pageState;

    const { supportingTableData, isMount, jbProcedureSavedIn } = this.state;
    const isSubmitComplete =
      this.isRevisePage &&
      procedureStepStatus === "Complete" &&
      this.triggerTimes === 1;
    const isApprovePending =
      !this.isRevisePage && procedureStepStatus === "Pending";
    const isApproveComplete =
      !this.isRevisePage && procedureStepStatus === "Complete";
    const isRevisePending =
      this.isRevisePage &&
      procedureStepStatus === "Pending" &&
      this.tiggerTimes > 1;
    const isReviseComplete =
      this.isRevisePage &&
      procedureStepStatus === "Complete" &&
      this.tiggerTimes > 1;
    const {
      taskInfoData,
      procedureInfoData,
      ppoList,
      fileTableData,
      shareTaskData,
    } = this.state;
    const serverPathValue =
      this.state.serverPathSectionState !== undefined
        ? this.state.serverPathSectionState.serverPathValue
        : "";
    const savedInRadioValue =
      this.state.savedInSectionState !== undefined
        ? this.state.savedInSectionState.savedInRadioValue
        : null;

    const replaceRegex = /(\n\r|\r\n|\r|\n)/g;
    let newRejectReason = "";
    if (rejectReason !== undefined && rejectReason !== null) {
      newRejectReason = rejectReason.replace(replaceRegex, "<br/>");
    } else {
      newRejectReason = rejectReason;
    }

    const newSupportingTableData = JSON.parse(
      JSON.stringify(supportingTableData)
    );

    const isCopyPage =
      flowCategoryID && flowCategoryID === flowCategoryId.copySupportingFile;

    return (
      <NormalSpin
        spinning={loading}
        size="large"
        indicator={
          supportingFileNum > 0 ? (
            <p className={styles.loadingText}>
              uploading {uploadSupportingFileNum}/{supportingFileNum} file
            </p>
          ) : undefined
        }
        children={
          <>
            {isSubmitComplete && (
              <SupportingFileSubmitComplete
                params={{
                  ProcedureTaskId: this.taskID,
                  ProcedureID: this.procedureID,
                  ProcedureStepID: this.procedureStepID,
                }}
                pageProps={{
                  flowCategoryID,
                  procedureStatus,
                  receiveDate,
                  requestByName,
                }}
                sectionProps={{
                  taskInfoData,
                  procedureInfoData,
                  ppoList,
                  fileTableData,
                  jbProcedureSavedIn,
                }}
                supportingRef={supportingRef}
                isMount={isMount}
                isCopyPage={isCopyPage}
                shareTaskData={shareTaskData}
                _showLoading={this._showLoading}
                _hideLoading={this._hideLoading}
                initSupportingTableData={[...this.initSupportingTableData]}
                handleReturnSubmitData={this.handleReturnSubmitData}
                setProcedureFileData={this.setProcedureFileData}
                setSupportingFileData={this.setSupportingFileData}
                setTaskInfoData={this.setTaskInfoData}
                setProcedureInfoData={this.setProcedureInfoData}
                setShareTaskData={this.setShareTaskData}
                data={newSupportingTableData}
                serverPathValue={serverPathValue}
                savedInRadioValue={savedInRadioValue}
                downloadFile={downloadSingleFile}
              />
            )}
            {isApprovePending && (
              <ApproveNewSupportingFilePending
                params={{
                  ProcedureTaskId: this.taskID,
                  ProcedureID: this.procedureID,
                  ProcedureStepID: this.procedureStepID,
                }}
                pageProps={{
                  flowCategoryID,
                  procedureStatus,
                  receiveDate,
                  requestByName,
                  newRejectReason,
                  rejectStatus,
                  supportingReplaceFileRef,
                  isDisableAll: false,
                }}
                sectionProps={{
                  taskInfoData,
                  procedureInfoData,
                  ppoList,
                  fileTableData,
                  jbProcedureSavedIn,
                }}
                closePage={this.closePage}
                ref={approvePageRef}
                approvePageRef={approvePageRef}
                shareTaskData={shareTaskData}
                supportingRef={supportingRef}
                initSupportingTableData={[...this.initSupportingTableData]}
                data={newSupportingTableData}
                serverPathValue={serverPathValue}
                savedInRadioValue={savedInRadioValue}
                handleReturnSubmitData={this.handleReturnSubmitData}
                downloadFile={downloadSingleFile}
                isMount={isMount}
                setProcedureFileData={this.setProcedureFileData}
                setProcedureInfoData={this.setProcedureInfoData}
                setShareTaskData={this.setShareTaskData}
                setSupportingFileData={this.setSupportingFileData}
                setTaskInfoData={this.setTaskInfoData}
              />
            )}
            {isApproveComplete && (
              <ApproveNewSupportingFileComplete
                params={{
                  ProcedureStepID: this.procedureStepID,
                  ProcedureTaskId: this.taskID,
                  ProcedureID: this.procedureID,
                }}
                sectionProps={{
                  taskInfoData,
                  fileTableData,
                  procedureInfoData,
                  ppoList,
                  jbProcedureSavedIn,
                }}
                pageProps={{
                  flowCategoryID,
                  procedureStatus,
                  actionID: this.actionID,
                  supportingReplaceFileRef,
                  isDisableAll: true,
                  receiveDate,
                  requestByName,
                  newRejectReason,
                  rejectStatus,
                }}
                initSupportingTableData={[...this.initSupportingTableData]}
                data={newSupportingTableData}
                serverPathValue={serverPathValue}
                savedInRadioValue={savedInRadioValue}
                ref={approvePageRef}
                approvePageRef={approvePageRef}
                isMount={isMount}
                isCopyPage={isCopyPage}
                shareTaskData={shareTaskData}
                supportingRef={supportingRef}
                _showLoading={this._showLoading}
                _hideLoading={this._hideLoading}
                setProcedureFileData={this.setProcedureFileData}
                setSupportingFileData={this.setSupportingFileData}
                setTaskInfoData={this.setTaskInfoData}
                setProcedureInfoData={this.setProcedureInfoData}
                setShareTaskData={this.setShareTaskData}
                downloadFile={downloadSingleFile}
                updateSupportingFileNum={this.updateSupportingFileNum}
              />
            )}
            {isRevisePending && (
              <ReviseNewSupportingFilePending
                params={{
                  ProcedureTaskId: this.taskID,
                  ProcedureID: this.procedureID,
                  ProcedureStepID: this.procedureStepID,
                }}
                pageProps={{
                  flowCategoryID,
                  procedureStatus,
                  receiveDate,
                  requestByName,
                  newRejectReason,
                  rejectStatus,
                  actionID: this.actionID,
                  supportingReplaceFileRef,
                  isDisableAll: false,
                }}
                sectionProps={{
                  taskInfoData,
                  procedureInfoData,
                  ppoList,
                  fileTableData,
                  jbProcedureSavedIn,
                }}
                initSupportingTableData={[...this.initSupportingTableData]}
                updateSupportingFileNum={this.updateSupportingFileNum}
                isMount={isMount}
                shareTaskData={shareTaskData}
                supportingRef={supportingRef}
                _showLoading={this._showLoading}
                _hideLoading={this._hideLoading}
                setTaskInfoData={this.setTaskInfoData}
                setProcedureInfoData={this.setProcedureInfoData}
                setShareTaskData={this.setShareTaskData}
                setProcedureFileData={this.setProcedureFileData}
                setSupportingFileData={this.setSupportingFileData}
                approvePageRef={approvePageRef}
                handleReturnSubmitData={this.handleReturnSubmitData}
                closePage={this.closePage}
                downloadFile={downloadSingleFile}
                data={newSupportingTableData}
                serverPathValue={serverPathValue}
                savedInRadioValue={savedInRadioValue}
                ref={approvePageRef}
              />
            )}
            {isReviseComplete && (
              <ReviseNewSupportingFileComplete
                pageProps={{
                  newRejectReason,
                  rejectStatus,
                  actionID: this.actionID,
                  supportingReplaceFileRef,
                  flowCategoryID,
                  procedureStatus,
                  receiveDate,
                  requestByName,
                  isDisableAll: true,
                }}
                sectionProps={{
                  taskInfoData,
                  procedureInfoData,
                  fileTableData,
                  jbProcedureSavedIn,
                  ppoList,
                }}
                params={{
                  ProcedureTaskId: this.taskID,
                  ProcedureID: this.procedureID,
                  ProcedureStepID: this.procedureStepID,
                }}
                shareTaskData={shareTaskData}
                _hideLoading={this._hideLoading}
                isMount={isMount}
                initSupportingTableData={[...this.initSupportingTableData]}
                data={newSupportingTableData}
                setProcedureInfoData={this.setProcedureInfoData}
                setShareTaskData={this.setShareTaskData}
                isCopyPage={isCopyPage}
                setProcedureFileData={this.setProcedureFileData}
                setSupportingFileData={this.setSupportingFileData}
                setTaskInfoData={this.setTaskInfoData}
                supportingRef={supportingRef}
                _showLoading={this._showLoading}
                serverPathValue={serverPathValue}
                savedInRadioValue={savedInRadioValue}
                downloadFile={downloadSingleFile}
              />
            )}
          </>
        }
      />
    );
  }
}
