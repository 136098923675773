import React from "react";
import { Radio } from "antd";
import { savedInRadioValues } from "../../../publicDictionaryValues";
import BaseComponent from "../../../../base/common/BaseComponent";
import styles from "./index.module.scss";

export default class BaseProcedureSavedIn extends BaseComponent {
  render() {
    const { handleRadioChange, handleRadioClick, savedInRadioValue, disabled } =
      this.props;
    return (
      <div className={styles.savedInContainer}>
        <div className={styles.procedureSaveIn}>Procedure Saved In</div>
        <div className={styles.radioBLock}>
          <Radio.Group
            onChange={(e) => handleRadioChange(e)}
            value={savedInRadioValue}
            defaultValue={savedInRadioValue}
            disabled={disabled}
          >
            <Radio
              value={savedInRadioValues.systemOnly}
              onClick={(e) => handleRadioClick(e)}
            >
              PMP System Only
            </Radio>
            <Radio
              value={savedInRadioValues.systemAndClientServer}
              onClick={(e) => handleRadioClick(e)}
            >
              PMP System & Client Server
            </Radio>
            <Radio
              value={savedInRadioValues.serverOnly}
              onClick={(e) => handleRadioClick(e)}
            >
              Client Server Only
            </Radio>
          </Radio.Group>
        </div>
      </div>
    );
  }
}
