import React from "react";
import ProcedureProcessFileThead from "../../approveAndReviseFileHeadFields";
import { fileTags } from "../../../../../publicDictionaryValues";
import BaseFileTable from "../../../../../commonComponents/fileTables/baseFileTable";
import ApplyJbFileTbody from "../../../../applyForJbSection/fields/fileTable/applyJbFileBody";

export default class ApproveApplyJbTable extends BaseFileTable {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: this.props.columns,
      isExpendData: false,
      isExpendNote: false,
      expendHeight: 40,
      isTableBorderError: this.props.isTableBorderError,
    };
    this.approveNumber = 0;
  }

  setNeedApproveDataNumber = (data) => {
    this.approveNumber = 0;
    for (let index in data) {
      const status = data[index].data.find((i) => i.name === "status").value;
      const applyForValue = data[index].data.find(
        (item) => item.name === "applyfor"
      ).value;
      if (
        (status === null || status === "") &&
        data[index].data.find((i) => i.name === "fileName").tagValue !==
          fileTags.deleted &&
        applyForValue === "JB"
      ) {
        this.approveNumber += 1;
      }
    }
  };

  render() {
    const {
      procedureNoteMaxLength,
      procedureNoteSize,
      inputOnChange,
      fileTableRef,
      className,
      optionList,
      isShowReturnReason,
      handleReturnClick,
      handleFileSelect,
      currentRole,
      downloadFile,
      isComplete,
      columns,
      isExpendData,
      readOnly = false,
    } = this.props;
    const { data } = this.state;
    this.setNeedApproveDataNumber(data);
    return (
      <>
        <div className="scrollTable">
          <table className={`${className} table autoHeightChangeContainer`}>
            <ProcedureProcessFileThead columns={columns} />
            <ApplyJbFileTbody
              data={data}
              columns={columns}
              inputOnChange={inputOnChange}
              procedureNoteMaxLength={procedureNoteMaxLength}
              procedureNoteSize={procedureNoteSize}
              fileTableRef={fileTableRef}
              optionList={optionList}
              isShowReturnReason={isShowReturnReason}
              handleReturnClick={handleReturnClick}
              handleFileSelect={handleFileSelect}
              currentRole={currentRole}
              downloadFile={downloadFile}
              isComplete={isComplete}
              isExpendData={isExpendData}
            />
          </table>
        </div>
        {!readOnly && (
          <p
            className={`${this.props.className} fileNumTip`}
            style={{ color: "#FF9921" }}
          >
            <span className="fileNum">{this.approveNumber}</span>{" "}
            {this.setFileText(this.approveNumber)} pending approval
          </p>
        )}
      </>
    );
  }
}
