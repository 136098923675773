import BaseReadOnlyChangeMainTask from "../baseReadOnlyChangeMainTask";
import "../../../commonComponents/readOnlyTable/antReadOnlyTableCustom.scss";

export default class ApproveChangeMainTaskReadOnly extends BaseReadOnlyChangeMainTask {
  constructor(props) {
    super(props);
    this.state = this.initReadOnlyState(false);
  }

  handleReturnReasonLog = () => {
    return this.handleApproveReturnReasonLog();
  };

  handleSubmitReason = () => {
    return this.handleApproveSubmitReason();
  };
}
