import React, { createRef } from "react";
import UpdateSupportingFileManagement from "../fields/supportingFileManagement/updateSupportingFileManagement";
import SupportingFileSection from "../supportingFileSection";

const supportingFileTableRef = createRef();
export default class ReadOnlyUpdateSupportingFileSection extends SupportingFileSection {
  componentDidMount() {
    supportingFileTableRef.current.updateDataList(
      this.props.supportingFileData
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.supportingFileData !== this.props.supportingFileData) {
      supportingFileTableRef.current !== null &&
        supportingFileTableRef.current.updateDataList(
          this.props.supportingFileData
        );
    }
  }

  render() {
    const { supportingFileRef, showHintMsg } = this.props;
    return (
      <UpdateSupportingFileManagement
        fileInputRef={supportingFileRef}
        fileTableRef={supportingFileTableRef}
        title={"Supporting Files"}
        clientPathShow={false}
        isScrollData={true}
        className="supportingFiles"
        isShowExpendIcon={true}
        isShowDownloadBtn={true}
        isShowShareBtn={false}
        isShowActionBtns={false}
        savedInDisabled={true}
        clientPathDisabled={true}
        noteDisabled={true}
        hasSubmit={false}
        showHintMsg={showHintMsg}
        status={3}
        isCopyPage={this.props.isCopyPage}
        isUpdateSection={true}
      />
    );
  }
}
