import React from "react";
import { Modal, Checkbox } from "antd";
import Buttons from "../../../base/basicComponents/button/index";
import BaseComponent from "../../../base/common/BaseComponent";
import styles from "./index.module.scss";
import "./downloadDocument.scss";
import folderIcon from "../../../assets/image/folderIcon.svg";
import fileIcon from "../../../assets/image/fileIcon.svg";

class DownloadDocument extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      checkedProcedureFiles: [],
      checkedSupportingFiles: [],
      isAllProcedureFilesChecked: false,
      isAllSupportingFilesChecked: false,
    };
  }

  initState = () => {
    this.setState({
      checkedProcedureFiles: [],
      checkedSupportingFiles: [],
      isAllProcedureFilesChecked: false,
      isAllSupportingFilesChecked: false,
    });
  };

  isDownloadDocumentFileChecked = (id, fileType) => {
    let checkedFileIds = [];
    if (fileType === "ProcedureFile") {
      checkedFileIds = this.state.checkedProcedureFiles.map(
        (item) => item?.fileId
      );
    } else if (fileType === "SupportingFile") {
      checkedFileIds = this.state.checkedSupportingFiles.map(
        (item) => item?.fileId
      );
    }
    return checkedFileIds.includes(id);
  };

  handleProcedureFileDownloadDocumentCheckedAll = (e) => {
    if (e.target.checked) {
      this.setState({
        checkedProcedureFiles: [...this.props.procedureFiles],
        isAllProcedureFilesChecked: true,
      });
    } else {
      this.setState({
        checkedProcedureFiles: [],
        isAllProcedureFilesChecked: false,
      });
    }
  };

  handleSupportingFileDownloadDocumentCheckedAll = (e) => {
    if (e.target.checked) {
      this.setState({
        checkedSupportingFiles: [...this.props.supportingFiles],
        isAllSupportingFilesChecked: true,
      });
    } else {
      this.setState({
        checkedSupportingFiles: [],
        isAllSupportingFilesChecked: false,
      });
    }
  };

  handleDownloadDocumentCheckedAll = (e, fileType) => {
    if (fileType === "ProcedureFile") {
      this.handleProcedureFileDownloadDocumentCheckedAll(e);
    } else if (fileType === "SupportingFile") {
      this.handleSupportingFileDownloadDocumentCheckedAll(e);
    }
  };

  handleProcedureFileDownloadDocumentChecked = (e, file, filesArr) => {
    if (e.target.checked) {
      filesArr = [...this.state.checkedProcedureFiles, file];
    } else {
      filesArr = this.state.checkedProcedureFiles.filter(
        (item) => item.fileId !== file.fileId
      );
    }
    this.setState({
      checkedProcedureFiles: [...filesArr],
      isAllProcedureFilesChecked:
        filesArr.length === this.props.procedureFiles.length &&
        filesArr.length !== 0,
    });
  };

  handleSupportingFileDownloadDocumentChecked = (e, file, filesArr) => {
    if (e.target.checked) {
      filesArr = [...this.state.checkedSupportingFiles, file];
    } else {
      filesArr = this.state.checkedSupportingFiles.filter(
        (item) => item.fileId !== file.fileId
      );
    }
    this.setState({
      checkedSupportingFiles: [...filesArr],
      isAllSupportingFilesChecked:
        filesArr.length === this.props.supportingFiles.length &&
        filesArr.length !== 0,
    });
  };

  handleDownloadDocumentChecked = (e, file) => {
    let filesArr = [];
    if (file?.procedureFileID) {
      this.handleProcedureFileDownloadDocumentChecked(e, file, filesArr);
    } else {
      this.handleSupportingFileDownloadDocumentChecked(e, file, filesArr);
    }
  };

  handleDownloadDocumentProcedureFilesWrapperCheckbox = () => {
    const { isAllProcedureFilesChecked } = this.state;

    return (
      <div className={styles.files}>
        <Checkbox
          checked={isAllProcedureFilesChecked}
          onChange={(e) =>
            this.handleDownloadDocumentCheckedAll(e, "ProcedureFile")
          }
        />
        <img className={styles.icon} src={folderIcon} alt="Procedure Files" />
        <div className={styles.fileTitle}>Procedure Files</div>
      </div>
    );
  };

  handleDownloadDocumentProcedureFilesWrapperFiles = () => {
    const { procedureFiles } = this.props;

    return (
      <div className={styles.procedureFilesList}>
        {procedureFiles.map((item) => (
          <div className={styles.filesItem} key={item.fileId}>
            <Checkbox
              checked={this.isDownloadDocumentFileChecked(
                item?.fileId,
                "ProcedureFile"
              )}
              onChange={(e) => this.handleDownloadDocumentChecked(e, item)}
            />
            <img className={styles.icon} src={fileIcon} alt="Procedure File" />
            <div className={styles.fileName}>{item.fileName}</div>
          </div>
        ))}
      </div>
    );
  };

  handleDownloadDocumentProcedureFilesWrapper = () => {
    const { procedureFiles } = this.props;

    return (
      <div className={styles.filesWrapper}>
        {this.handleDownloadDocumentProcedureFilesWrapperCheckbox()}
        {procedureFiles.length > 0 &&
          this.handleDownloadDocumentProcedureFilesWrapperFiles()}
      </div>
    );
  };

  handleDownloadDocumentSupportingFilesWrapperCheckbox = () => {
    const { isAllSupportingFilesChecked } = this.state;

    return (
      <div className={styles.files}>
        <Checkbox
          checked={isAllSupportingFilesChecked}
          onChange={(e) =>
            this.handleDownloadDocumentCheckedAll(e, "SupportingFile")
          }
        />
        <img className={styles.icon} src={folderIcon} alt="Supporting Files" />
        <div className={styles.fileTitle}>Supporting Files</div>
      </div>
    );
  };

  handleDownloadDocumentSupportingFilesWrapperFiles = () => {
    const { supportingFiles } = this.props;

    return (
      <div className={styles.supportingFilesList}>
        {supportingFiles.map((item) => (
          <div className={styles.filesItem} key={item.fileId}>
            <Checkbox
              checked={this.isDownloadDocumentFileChecked(
                item?.fileId,
                "SupportingFile"
              )}
              onChange={(e) => this.handleDownloadDocumentChecked(e, item)}
            />
            <img className={styles.icon} src={fileIcon} alt="Supporting File" />
            <div className={styles.fileName}>{item.fileName}</div>
          </div>
        ))}
      </div>
    );
  };

  handleDownloadDocumentSupportingFilesWrapper = () => {
    const { supportingFiles } = this.props;

    return (
      <div className={styles.filesWrapper}>
        {this.handleDownloadDocumentSupportingFilesWrapperCheckbox()}
        {supportingFiles.length > 0 &&
          this.handleDownloadDocumentSupportingFilesWrapperFiles()}
      </div>
    );
  };

  render() {
    const { isModalOpen, onCancel, onSubmit } = this.props;
    const { checkedProcedureFiles, checkedSupportingFiles } = this.state;

    const footer = (
      <div className={styles.buttonDiv}>
        <Buttons color="grey" size="middle" onClick={onCancel}>
          Cancel
        </Buttons>
        <Buttons
          color="blue"
          size="middle"
          disabled={
            checkedProcedureFiles.length === 0 &&
            checkedSupportingFiles.length === 0
          }
          onClick={() =>
            onSubmit([...checkedProcedureFiles, ...checkedSupportingFiles])
          }
        >
          Download
        </Buttons>
      </div>
    );

    return (
      <Modal
        closable={false}
        width="37.25rem"
        className="downloadModal"
        title="Download Document"
        centered
        afterClose={this.initState}
        open={isModalOpen}
        footer={footer}
        onCancel={onCancel}
        maskClosable={false}
      >
        <div className={styles.downloadModalContent}>
          <div className={styles.documentsList}>
            {this.handleDownloadDocumentProcedureFilesWrapper()}
            {this.handleDownloadDocumentSupportingFilesWrapper()}
          </div>
          <div className={styles.coverSheetWrapper}>
            <Checkbox checked={true} disabled={true} />
            <div className={styles.coverSheet}>Cover Sheet</div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default DownloadDocument;
