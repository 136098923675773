import BaseFileManagement from "../../../app/commonComponents/fileManagementFields/baseFileManagement";
import ProcedureListService from "../../../service/procedureList";
import styles from "./index.module.scss";
import { Checkbox, Skeleton } from "antd";
import fileIcon from "../../../assets/image/fileIcon.svg";
import Buttons from "../../../base/basicComponents/button";
import AppSetting from "../../../config/AppSetting";
import ProcedureDetailService from "../../../service/procedureFile/ProcedureDetailService";
import NormalSpin from "../../../base/basicComponents/spin";

export default class ShareDocumentDownloadCheck extends BaseFileManagement {
  constructor(props) {
    super(props);
    this.state = {
      messageCode: null,
      procedureId: null,
      procedureTaskId: null,
      filesList: [],
      checkedFiles: [],
      isAllChecked: false,
      downloadNum: 0,
      allFilesNum: 0,
      loading: false,
    };
    this.shareId = this._getRoutingParam("ShareId");
  }

  handleCheckedAll = (e) => {
    if (e.target.checked) {
      this.setState({
        checkedFiles: [...this.state.filesList],
        isAllChecked: true,
      });
    } else {
      this.setState({
        checkedFiles: [],
        isAllChecked: false,
      });
    }
  };

  handleChecked = (e, file) => {
    let filesArr = [];
    if (e.target.checked) {
      filesArr = [...this.state.checkedFiles, file];
    } else {
      filesArr = this.state.checkedFiles.filter(
        (item) => item.fileId !== file.fileId
      );
    }
    this.setState({
      checkedFiles: [...filesArr],
      isAllChecked:
        filesArr.length !== 0 &&
        filesArr.length === this.state.filesList.length,
    });
  };

  isFileChecked = (id) => {
    const checkedFileIds = this.state.checkedFiles.map((item) => item?.fileId);
    return checkedFileIds.includes(id);
  };

  countDownloads = () => {
    this.setState({
      downloadNum: this.state.downloadNum + 1,
      loading: this.state.downloadNum + 1 !== this.state.allFilesNum,
    });
  };

  downloadFile = (file) => {
    const para = {
      ProductKey: AppSetting.urlPrefix.addFile.ProductKey,
      fileId: file.fileId,
    };
    ProcedureDetailService.downloadFile(
      para,
      (response) => {
        this._downloadFileSuccess(response, file.fileName);
        this.countDownloads();
      },
      (response) => console.log(response)
    );
  };

  _downloadCheckSuccess = (result) => {
    const { messageCode, procedureId, procedureTaskId, shareFiles } = result;
    this.setState({
      messageCode,
      procedureId,
      procedureTaskId,
      filesList: shareFiles || [],
    });
    this._hideLoading();
  };

  /**
   * Check authority before downloading shared files
   */
  downloadCheck = () => {
    const Para = {
      shareId: this.shareId,
    };
    ProcedureListService.downloadCheck(
      Para,
      this._downloadCheckSuccess,
      (response) => console.log(response)
    );
  };

  downloadSharedFiles = () => {
    this.setState({
      loading: true,
      downloadNum: 0,
      allFilesNum: this.state.checkedFiles.length,
    });
    this.state.checkedFiles.forEach((file) => {
      if (file.fileId) {
        this.downloadFile(file);
      } else {
        this.getCoverSheet();
      }
    });
  };

  getCoverSheetSuccess = (result) => {
    this.downloadPDF(result.content, "Cover Sheet.pdf");
    this.countDownloads();
  };

  getCoverSheet = () => {
    const getCoverSheetParam = {
      ProcedureId: this.state.procedureId,
      ProcedureTaskId: this.state.procedureTaskId,
    };
    ProcedureListService.getCoverSheet(
      getCoverSheetParam,
      this.getCoverSheetSuccess,
      (response) => console.log(response)
    );
  };

  componentDidMount() {
    this._showLoading();
    this.downloadCheck();
  }

  render() {
    const {
      messageCode,
      filesList,
      isAllChecked,
      checkedFiles,
      loading,
      downloadNum,
      allFilesNum,
    } = this.state;

    return (
      <>
        {(messageCode === 26 || messageCode === 27) && (
          <div className={styles.messageWrapper}>
            <div className={styles.messages}>
              {messageCode === 26 && (
                <div>You don't have permission to access these files.</div>
              )}
              {messageCode === 27 && <div>The shared file is expired.</div>}
            </div>
          </div>
        )}
        {messageCode !== null &&
          messageCode !== undefined &&
          messageCode !== 26 &&
          messageCode !== 27 && (
            <NormalSpin
              spinning={loading}
              size="large"
              indicator={
                allFilesNum > 0 && (
                  <div className={styles.loadingText}>
                    Downloading{" "}
                    {downloadNum < allFilesNum ? downloadNum + 1 : downloadNum}/
                    {allFilesNum} files
                  </div>
                )
              }
            >
              <div className={styles.shareDocument}>
                <div className={styles.title}>Download Files</div>
                <div className={styles.selectAllWrapper}>
                  <Checkbox
                    checked={isAllChecked}
                    onChange={(e) => this.handleCheckedAll(e)}
                  />
                  <span className={styles.selectAll}>Select All</span>
                </div>
                <div className={styles.documentListDiv}>
                  {filesList.length > 0 && (
                    <div>
                      {filesList.map((item) => (
                        <div className={styles.filesItem} key={item.fileId}>
                          <Checkbox
                            checked={this.isFileChecked(item?.fileId)}
                            onChange={(e) => this.handleChecked(e, item)}
                          />
                          <img
                            className={styles.icon}
                            src={fileIcon}
                            alt="Share File"
                          />
                          <div className={styles.fileName}>{item.fileName}</div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className={styles.buttonDiv}>
                  <Buttons
                    color="blue"
                    size="middle"
                    disabled={checkedFiles.length === 0}
                    onClick={this.downloadSharedFiles}
                  >
                    Download
                  </Buttons>
                </div>
              </div>
            </NormalSpin>
          )}
        {(messageCode === null || messageCode === undefined) && (
          <div className={styles.messageWrapper}>
            <Skeleton active className={styles.skeleton} />
          </div>
        )}
      </>
    );
  }
}
