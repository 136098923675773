import { createRef } from "react";
import ProcedureBlock from "../../../../base/basicComponents/procedureBlock";
import FileSectionBase from "../../../commonComponents/fileBase";
import { timeOutMins } from "../../../../base/basicComponents/modal/controlPageBehavior";
import NormalModal, {
  ModalWithScroll,
} from "../../../../base/basicComponents/modal";
import ProcedureDetailService from "../../../../service/procedureFile/ProcedureDetailService";
import "../../../pages/procedureDetail/createProcedureDetail/style/index.scss";
import ProcedureInfoDetailBase from "./procedureInfoDetailBase";

const initProcedureInfoData = {
  procedureName: null,
  procedureInformationId: null,
  primaryProcedureOwnerId: null,
  primaryProcedureOwnerName: null,
  procedureOwnerId: null,
  procedureStatus: null,
  note: '',
  reason: null,
  ppoChange: false,
  poChange: false,
};
const procedureInfoRef = createRef();
const procedureInfoModalRef = createRef();
export default class ProcedureInfoSection extends FileSectionBase {
  constructor(props) {
    super(props);
    this.state = {
      procedureInfoSubmitData: initProcedureInfoData,
      preProcedureInfoSubmitData: initProcedureInfoData,
      procedureStatus: "",
      ppoList: [],
      isProcedureInfoEditToolTipOpen: false,
      isProcedureInfoModalChange: false,
      isShowProcedureInfoEdit: true,
      PPOmessageCode: null,
      POmessageCode: null,
      isPPODisabled: false,
      isPODisabled: false,
      isScrollModalOpen: false,
      isModalOpen: false,
      modalOkText: "Confirm",
      modalCancelText: "Cancel",
      handleModalConfirm: () => {
        //empty function
      },
      handleModalCancel: () => {
        //empty function
      },
      scrollModalContent: null,
      scrollModalTitle: null,
      modalChildContent: null,
    };
  }

  setProcedureStatus = (result) => {
    this.setState({
      procedureStatus: this.getProcedureStatus(result),
    });
  };

  setPpoList = (result) => {
    this.setState({
      ppoList: result,
    });
  };

  hideAllModal = () => {
    this.setState({
      isModalOpen: false,
      isScrollModalOpen: false,
      scrollModalContent: "",
      scrollModalTitle: "",
      modalChildContent: "",
      handleModalConfirm: () => {
        //there is a empty function
      },
      handleModalCancel: () => {
        //there is a empty function
      },
    });
  };

  createProcedureInfo = (response, ref) => {
    const preProcedureInfoSubmitData = {
      procedureName: null,
      procedureInformationId: null,
      procedureStatus: response.procedureStatus,
      isPMPExcluded: response.isExcluded,
      primaryProcedureOwnerId: response.primaryProcedureOwnerID,
      primaryProcedureOwnerName: response.primaryProcedureOwner,
      procedureOwnerId: response.procedureOwnerID,
      note: response.note || '',
      ppoChange: false,
      poChange: false,
    }
    this.setState({
      procedureInfoSubmitData: {
        procedureName: null,
        procedureInformationId: null,
        procedureStatus: response.procedureStatus,
        isPMPExcluded: response.isExcluded,
        primaryProcedureOwnerId: response.primaryProcedureOwnerID,
        primaryProcedureOwnerName: response.primaryProcedureOwner,
        procedureOwnerId: response.procedureOwnerID,
        note: response.note,
        ppoChange: false,
        poChange: false,
      },
      preProcedureInfoSubmitData: {
        ...preProcedureInfoSubmitData,
      },
      PPOmessageCode: response.checkEditPpo.messageCode,
      POmessageCode: response.checkEditPo.messageCode,
    });
    if (ref.current !== null) {
      ref.current.updatePPO(response.primaryProcedureOwnerID, true);
      ref.current.updatePPOName(response.primaryProcedureOwner, true);
      ref.current.updatePO(response.procedureOwnerID, true);
      ref.current.updatePOName(response.procedureOwner, true);
      ref.current.updateNote(response.note, true);
      ref.current.updateReason(response?.reason, true);
      ref.current.updateProcedureStatus(response.procedureStatusID, true);
      ref.current.updateProcedureExcluded(response.isExcluded, true);
      ref.current.updatePreProcedureInfoSubmitData({...preProcedureInfoSubmitData});
    }
  };

  createProcedureInfoData = (createProcedureInfoRef = procedureInfoRef, propsProcedureAccountId = null) => {
    this.props.showLoading();
    const procedureId = this.props.procedureId;
    const procedureTaskId = this.props.procedureTaskId || null;
    ProcedureDetailService.getProcedureInfoSection(
      { procedureId: procedureId, ProcedureTaskId: procedureTaskId, procedureAccountId: propsProcedureAccountId },
      (response) => {
        this.createProcedureInfo(response, createProcedureInfoRef || procedureInfoRef);
        this.props.hideLoading();
        this.props.setProcedureJbStatusId(response.procedureStatusID);
      },
      (error) => {
        // this._alertError("Get procedure information error!");
        console.log(error);
      }
    );
  };

  _procedureInfoSubmitSuccess = (response, ref) => {
    ref.current.cancelIsChange();
    this.setState({
      preProcedureInfoSubmitData: { ...this.state.procedureInfoSubmitData },
      procedureInfoSubmitData: {
        ...this.state.procedureInfoSubmitData,
        ppoChange: false,
        poChange: false,
      },
    });
    this.props.procedureInfoSubmitSuccess();
    this.handleResetLock("ProcedureInfo");
    this._alertSuccess("Submit Successful");
    this.props.procedureId && this.props.setIsProcedureInfoModalChange();
    this.createProcedureInfoData(ref);
  };

  handleSubmitProcedureInfoModalConfirm = (ref) => {
    this.hideAllModal();
    this.props.showLoading();
    const para = {
      pmpExcluded: true,
      procedureId: this.props.procedureId,
      procedureTaskId: this.props.procedureTaskId,
      oldPrimaryProcedureOwnerId: this.state.preProcedureInfoSubmitData.primaryProcedureOwnerId,
      oldProcedureOwnerId: this.state.preProcedureInfoSubmitData.procedureOwnerId,
      primaryProcedureOwnerId: this.state.procedureInfoSubmitData.ppoChange
        ? this.state.procedureInfoSubmitData.primaryProcedureOwnerId
        : null,
      procedureOwnerId: this.state.procedureInfoSubmitData.poChange
        ? this.state.procedureInfoSubmitData.procedureOwnerId
        : null,
      reason: this.state.procedureInfoSubmitData.reason,
      note: this.state.procedureInfoSubmitData.note,
      returnReason: "",
    };

    ProcedureDetailService.submitProcedureInfo(
      para,
      (response) => this._procedureInfoSubmitSuccess(response, ref),
      this._submitError
    );
  };

  procedureInformationOnSubmit = () => {
    this.setState({
      isModalOpen: true,
      modalOkText: "Confirm",
      modalCancelText: "Cancel",
      modalChildContent: "Are you sure to submit?",
      handleModalConfirm: () =>
        this.handleSubmitProcedureInfoModalConfirm(procedureInfoRef),
      handleModalCancel: () => {
        //there is a empty function
      },
    });
  };

  handleCancelProcedureInfoModalConfirm = (ref) => {
    this.setState({
      procedureInfoSubmitData: {
        ...this.state.preProcedureInfoSubmitData,
        ppoChange: false,
        poChange: false,
      },
    });
    this.hideModal();
    this.hideScrollModal();
    this.handleResetLockWithoutRefresh("ProcedureInfo");
    this.props.setActionType(null);
  };

  procedureInformationOnCancel = () => {
    this.setState({
      isModalOpen: true,
      modalOkText: "Confirm",
      modalCancelText: "Cancel",
      modalChildContent: "Are you sure to quit without saving?",
      handleModalConfirm: () =>
        this.handleCancelProcedureInfoModalConfirm(procedureInfoRef),
      handleModalCancel: () => {
        //there is a empty function
      },
    });
  };

  handlePpoChange = (value, ref) => {
    this.setState({
      procedureInfoSubmitData: {
        ...this.state.procedureInfoSubmitData,
        primaryProcedureOwnerId: value,
        ppoChange: true,
      },
    });
    ref.current.updatePPO(value);
  };

  handlePoChange = (value, ref) => {
    this.setState({
      procedureInfoSubmitData: {
        ...this.state.procedureInfoSubmitData,
        procedureOwnerId: value,
        poChange: true,
      },
    });
    ref.current.updatePO(value);
  };

  handleProStatusChange = (value, ref) => {
    this.setState({
      procedureInfoSubmitData: {
        ...this.state.procedureInfoSubmitData,
        procedureStatus: value,
      },
    });
    ref.current.updateProcedureStatus(value);
  };

  handleProExcludedChange = (value, ref) => {
    this.setState({
      procedureInfoSubmitData: {
        ...this.state.procedureInfoSubmitData,
        procedureExcluded: value,
      },
    });
    ref.current.updateProcedureExcluded(value);
  };

  handleStringTrim = (value) => {
    return value?.trim() ? value.trim() : null;
  };

  changePPOReason = (value, ref) => {
    this.setState({
      procedureInfoSubmitData: {
        ...this.state.procedureInfoSubmitData,
        reason: this.handleStringTrim(value),
      },
    });
    ref.current.updateReason(value);
  };

  changePOReason = (value, ref) => {
    this.setState({
      procedureInfoSubmitData: {
        ...this.state.procedureInfoSubmitData,
        reason: this.handleStringTrim(value),
      },
    });
    ref.current.updateReason(value);
  };

  handleNoteChange = (value, ref) => {
    this.setState({
      procedureInfoSubmitData: {
        ...this.state.procedureInfoSubmitData,
        note: value,
      },
    });
    ref.current.updateNote(value);
  };

  setScrollModalState = (params) => {
    const { PPOmessageCode, POmessageCode, isPPODisabled, isPODisabled } = params;
    this.setState({
      isScrollModalOpen: true,
      isProcedureInfoEditToolTipOpen: false,
      scrollModalContent: (
        <ProcedureBlock
          child={this.handleReturnProcedureInfo({
            procedureInfoSubmitData: this.state.procedureInfoSubmitData,
            procedureStatus: this.state.procedureStatus,
            ppoList: this.state.ppoList,
            isShowEdit: false,
            PPOmessageCode: PPOmessageCode,
            POmessageCode: POmessageCode,
            isPPODisabled: isPPODisabled,
            isPODisabled: isPODisabled,
            isShowActions: true,
            noteDisabled: false,
            reasonDisabled: false,
            ref: procedureInfoModalRef,
            title: "",
            hintMsg: this.props.hintMsg,
          })}
          isShowArrow={false}
          className="processProcedureModalBlock procedureInfoModalBlock"
          isOpen={true}
        />
      ),
      scrollModalTitle: "Procedure Information",
      handleModalConfirm: () => {
        //there is a empty function
      },
      handleModalCancel: () => {
        //there is a empty function
      },
    });
  };

  checkPOEdit = (isLock, lockUser) => {
    const { procedureId, procedureTaskId, procedureAccountId } = this.props;
    const para = {
      procedureId: procedureId,
      procedureAccountId: procedureAccountId,
      procedureTaskId: procedureTaskId,
      procedureOwnerID: this.state.procedureInfoSubmitData.procedureOwnerId,
    };

    ProcedureDetailService.checkPOEdit(
      para,
      (response) => {
        const { result } = response;
        this.checkPPOEdit(isLock, lockUser, !result);
        this.props.hideLoading();
      },
      (response) => {
        console.log(response.message);
      }
    );
  };

  checkPPOEdit = (isLock, lockUser, isPODisabled) => {
    const { procedureId, procedureTaskId, procedureAccountId } = this.props;
    const para = {
      procedureId: procedureId,
      procedureAccountId: procedureAccountId,
      procedureTaskId: procedureTaskId,
      primaryProcedureOwnerID:
        this.state.procedureInfoSubmitData.primaryProcedureOwnerId,
    };

    ProcedureDetailService.checkPPOEdit(
      para,
      (response) => {
        const { result } = response;
        this.setScrollModalState({
          isLock,
          lockUser,
          isPODisabled,
          isPPODisabled: !result,
        });
        this.props.setActionType(this.props.actionType);
        this.createProcedureInfoData(procedureInfoModalRef);
      },
      (response) => {
        console.log(response.message);
      }
    );
  };

  handleProcedureInfoLock = (isLock, lockUser) => {
    this.setScrollModalOpen(true);
    this.handleReturnProcedureInfo({
      procedureInfoSubmitData: this.state.procedureInfoSubmitData,
      procedureStatus: this.state.procedureStatus,
      ppoList: this.state.ppoList,
      isShowEdit: false,
      PPOmessageCode: null,
      POmessageCode: null,
      isPPODisabled: false,
      isPODisabled: false,
      isShowActions: true,
      noteDisabled: false,
      reasonDisabled: false,
      isLock,
      lockUser,
      ref: procedureInfoRef,
      title: "",
      hintMsg: this.props.hintMsg,
    });
  };

  handleProcedureInfoUnlock = (isLock, lockUser) => {
    this.checkPOEdit(isLock, lockUser);
  };

  checkProcedureInfoWhetherLock = (isLock, lockUser) => {
    if (isLock) {
      this.props.setLock(`${lockUser} is editing this section.`);
      this.handleProcedureInfoLock();
    } else {
      this.props.setLock("");
      this.handleProcedureInfoUnlock();
    }
  };

  handleProcedureInfoEditClick = () => {
    const para = {
      procedureId: this.props.procedureId,
      procedureSectionType: this.props.actionType,
      timeOutMins: timeOutMins,
    };
    ProcedureDetailService.checkSectionLock(
      para,
      (response) =>
        this.checkProcedureInfoWhetherLock(
          response.data.isLocked,
          response.data.lockUser
        ),
      (response) => console.log(response.message)
    );
  };

  setScrollModalOpen = (isOpen) => {
    this.setState({
      isProcedureInfoEditToolTipOpen: isOpen,
    });
  };

  closeScrollModal = () => {
    this.handleCancelProcedureInfoModalConfirm(procedureInfoRef);
    this.hideScrollModal();
  };

  handleReturnProcedureInfo = (obj) => {
    const {
      procedureInfoSubmitData,
      procedureStatus,
      ppoList,
      isShowEdit,
      PPOmessageCode,
      POmessageCode,
      isPPODisabled,
      isPODisabled,
      isShowActions,
      noteDisabled,
      reasonDisabled,
      ref,
      title,
      poChange,
      hintMsg,
      isSection,
    } = obj;
    return (
      <ProcedureInfoDetailBase
        ref={ref}
        onSubmit={this.procedureInformationOnSubmit}
        onCancel={this.procedureInformationOnCancel}
        preProcedureInfoSubmitData={this.state.preProcedureInfoSubmitData}
        submitData={procedureInfoSubmitData}
        handlePpoChange={this.handlePpoChange}
        handlePoChange={this.handlePoChange}
        handleProStatusChange={this.handleProStatusChange}
        handleNoteChange={this.handleNoteChange}
        changePPOReason={this.changePPOReason}
        changePOReason={this.changePOReason}
        currentRef={ref}
        isShowActions={isShowActions}
        procedureStatus={procedureStatus}
        ppoList={ppoList}
        PPOmessageCode={PPOmessageCode}
        POmessageCode={POmessageCode}
        isPPODisabled={isPPODisabled}
        isPODisabled={isPODisabled}
        isShowEdit={isShowEdit}
        isEditToolTipOpen={this.state.isProcedureInfoEditToolTipOpen}
        handleEditClick={() => this.handleProcedureInfoEditClick()}
        onBlur={() => this.setScrollModalOpen(false)}
        noteDisabled={noteDisabled}
        reasonDisabled={reasonDisabled}
        title={title}
        poChange={poChange}
        hintMsg={hintMsg}
        isSection={isSection}
      />
    );
  };

  render() {
    const modalWidth =
      document.getElementsByClassName("procedureBlock")[0] !== undefined &&
      document.getElementsByClassName("procedureBlock")[0].offsetWidth;
    return (
      <>
        <ProcedureBlock
          child={this.handleReturnProcedureInfo({
            procedureInfoSubmitData: this.state.procedureInfoSubmitData,
            procedureStatus: this.state.procedureStatus,
            ppoList: this.state.ppoList,
            isShowEdit: this.state.isShowProcedureInfoEdit,
            isPPODisabled: true,
            isPODisabled: true,
            isShowActions: false,
            noteDisabled: true,
            reasonDisabled: true,
            ref: procedureInfoRef,
            title: "Procedure Information",
            hintMsg: this.props.hintMsg,
            PPOmessageCode: this.state.PPOmessageCode,
            POmessageCode: this.state.POmessageCode,
            isSection: true,
          })}
          isShowArrow={false}
          className="processProcedureBlock procedureInfoBlock"
          isOpen={true}
        />
        <NormalModal
          okText={this.state.modalOkText}
          cancelText={this.state.modalCancelText}
          childContent={<p className="fs-16">{this.state.modalChildContent}</p>}
          isModalOpen={this.state.isModalOpen}
          handleOk={this.state.handleModalConfirm}
          handleCancel={() => {
            this.state.handleModalCancel();
            this.hideModal();
          }}
          closable={false}
          className="modalWithScroll"
        />
        <ModalWithScroll
          isModalOpen={this.state.isScrollModalOpen}
          modalContent={this.state.scrollModalContent}
          title={this.state.scrollModalTitle}
          handleCloseClick={() => {
            this.closeScrollModal();
            this.props.setActionType(null);
          }}
          width={modalWidth}
          actionType={this.props.actionType}
        />
      </>
    );
  }
}
