import { Radio, Tooltip } from "antd";
import React, { useState } from "react";
import Buttons from "../../../base/basicComponents/button";
import NormalSpin from "../../../base/basicComponents/spin";
import changeHistoryService from "../../../service/changeHistory";
import ListColumnBase from "../../commonComponents/listBase/listColumnBase";
import changeHistoryFilterMap from "../../commonComponents/listTable/changeHistoryListFilterMap";
import { GetColumnCheckboxGroupFilterDropdown } from "../../commonComponents/listTable/CustomFilter";
import styles from "../procedureList/index.module.scss";
import toDoListStyles from "../toDoList/index.module.scss";
import "../toDoList/index.scss";
import ChangeHistoryListTable from "./changeHistorySection/changeHistoryListTable";
import TopFilter from "./changeHistorySection/topFilter";
import changeHistoryStyle from "./index.module.scss";
import "./index.scss";

export default function ChangeHistory(props) {
  const getRoutingParam = (paramName) => {
    if (props.match === undefined) {
      return props.params[paramName];
    } else if (props.match === null || props.match.params == null) {
      return;
    }
    return props.match.params[paramName];
  };

  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(1);
  const [isExpend, setIsExpend] = useState(false);
  const [topFiterData, setTopFiterData] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [tableSortData, setTableSortData] = useState(null);
  const [listData, setListData] = useState([]);
  const [tPFilterData, setTPFilterData] = useState([]);
  const [cPFilterData, setCPFilterData] = useState([]);

  const procedureTaskId = getRoutingParam("ProcedureTaskId");
  const procedureId =
    getRoutingParam("ProcedureId") === "null"
      ? ""
      : getRoutingParam("ProcedureId");

  const tabOptions = [
    {
      label: "Procedure Change History",
      value: 1,
    },
    {
      label: "PO & Sharing Relationship Change History",
      value: 2,
    },
  ];

  const defaultSort = { sortColumn: "ProcessInitiateDate", isAscending: false };

  const resetState = () => {
    setIsExpend((pre) => false);
    setTopFiterData((pre) => []);
    setTableFilterData((pre) => []);
    setFilteredInfo((pre) => []);
    setTableSortData((pre) => null);
    setListData((pre) => []);
    setTPFilterData((pre) => []);
    setCPFilterData((pre) => []);
  };

  const tabOnChange = ({ target: { value } }) => {
    setTab((pre) => value);
    resetState();
  };

  const getProcessCategory = (processCategoryOption, processCategorySelect) => {
    let filterArr = [];

    filterArr = processCategorySelect.map((x) => x);

    return {
      filterColumn: "ProcessCategory",
      filterValue: filterArr,
    };
  };

  const getProcessStatus = (
    processProcessStatusOption,
    processProcessStatusSelect
  ) => {
    let filterArr = [];

    filterArr = processProcessStatusSelect.map((x) => x);

    return {
      filterColumn: "ProcessStatus",
      filterValue: filterArr,
    };
  };

  const getInitiator = (processInitiatorOption, processInitiatorSelect) => {
    let filterArr = [];

    filterArr = processInitiatorSelect.map((x) => x);

    return {
      filterColumn: "ProcessInitiator",
      filterValue: filterArr,
    };
  };

  const getTopFilter = (filterOptionList, selectOptions) => {
    let topFilterArr = [];
    const topFilterOptionList = JSON.parse(JSON.stringify(filterOptionList));
    const topFilterSelectOptions = JSON.parse(JSON.stringify(selectOptions));

    const processCategory = getProcessCategory(
      topFilterOptionList.processCategory,
      topFilterSelectOptions.processCategory
    );
    topFilterArr.push(processCategory);

    const processStatus = getProcessStatus(
      topFilterOptionList.processStatus,
      topFilterSelectOptions.processStatus
    );
    topFilterArr.push(processStatus);

    const initiator = getInitiator(
      topFilterOptionList.initiator,
      topFilterSelectOptions.initiator
    );
    topFilterArr.push(initiator);

    return topFilterArr;
  };

  const getListSuccess = (result) => {
    setListData(result.data);
    setLoading(false);
  };

  const getListError = (result) => {
    console.log(result);
  };

  const getTriggerProcessFilterSuccess = (result) => {
    setTPFilterData(result.data);
    setTableFilterData([]);
    setTableSortData(null);
    setFilteredInfo({});
  };

  const getCurrentProcessorFilterSuccess = (result) => {
    setCPFilterData(result.data);
    setTableFilterData([]);
    setTableSortData(null);
    setFilteredInfo({});
  };

  const handleTopFilterClick = (filterOptionList, selectOptions) => {
    const topFilterArr = getTopFilter(filterOptionList, selectOptions);
    setLoading(true);
    setTopFiterData(topFilterArr);
    const getListPara = {
      filterFields: topFilterArr,
      sortField: defaultSort,
      procedureTaskId: procedureTaskId,
      procedureIds: [procedureId],
    };

    const para = {
      changeHistoryDto: getListPara,
      isAll: isExpend,
      historyType: 0,
    };
    changeHistoryService.getTriggerProcessFilter(
      para,
      getTriggerProcessFilterSuccess,
      getListError
    );

    const cpPara = {
      changeHistoryDto: getListPara,
      historyType: tab,
    };
    changeHistoryService.getCurrentProcessorFilter(
      cpPara,
      getCurrentProcessorFilterSuccess,
      getListError
    );

    if (tab === 1) {
      isExpend
        ? changeHistoryService.getAllProcedureHistory(
            getListPara,
            getListSuccess,
            getListError
          )
        : changeHistoryService.getCurrentProcedureHistory(
            getListPara,
            getListSuccess,
            getListError
          );
    } else {
      changeHistoryService.getTaskHistory(
        getListPara,
        getListSuccess,
        getListError
      );
    }
  };

  const concatChangeHistoryFilters = (topFilter, tableFilter) => {
    const mergedArray = [...topFilter];

    for (const objB of tableFilter) {
      const filterColumnB = objB.filterColumn;
      const filterValueB = objB.filterValue;
      const existingObjIndex = mergedArray.findIndex(
        (objA) => objA.filterColumn === filterColumnB
      );

      if (existingObjIndex !== -1) {
        const existingObj = mergedArray[existingObjIndex];
        const filterValueA = existingObj.filterValue;
        const intersection = filterValueA.filter((value) =>
          filterValueB.includes(value)
        );
        existingObj.filterValue = intersection.length > 0 ? intersection : [];
      } else {
        mergedArray.push(objB);
      }
    }

    return mergedArray;
  };

  const handleTableFilterSort = (tableFilterPara, tableSortPara, filters) => {
    const topFiter = JSON.parse(JSON.stringify(topFiterData));
    const tableFilter = JSON.parse(JSON.stringify(tableFilterPara));
    const sort = JSON.parse(JSON.stringify(tableSortPara));
    const filterArr = concatChangeHistoryFilters(topFiter, tableFilter);
    setLoading(true);
    setTableFilterData(tableFilter);
    setTableSortData(sort);
    setFilteredInfo(filters);
    const getToDoListPara = {
      filterFields: filterArr,
      sortField: sort !== null ? sort : defaultSort,
      procedureTaskId: procedureTaskId,
      procedureIds: [procedureId],
    };

    getListTableData(tab, getToDoListPara);
  };

  const getListTableData = (tab, getListPara) => {
    if (tab === 1) {
      isExpend
        ? changeHistoryService.getAllProcedureHistory(
            getListPara,
            getListSuccess,
            getListError
          )
        : changeHistoryService.getCurrentProcedureHistory(
            getListPara,
            getListSuccess,
            getListError
          );
    } else {
      changeHistoryService.getTaskHistory(
        getListPara,
        getListSuccess,
        getListError
      );
    }
  };

  const handleExpendButtonClickAction = (currentIsExpend) => {
    const topFiter = JSON.parse(JSON.stringify(topFiterData));
    setLoading(true);
    const getListPara = {
      filterFields: topFiter,
      sortField: defaultSort,
      procedureTaskId: procedureTaskId,
      procedureIds: [procedureId],
    };

    const para = {
      changeHistoryDto: getListPara,
      isAll: currentIsExpend,
      historyType: 0,
    };
    changeHistoryService.getTriggerProcessFilter(
      para,
      getTriggerProcessFilterSuccess,
      getListError
    );

    const cpPara = {
      changeHistoryDto: getListPara,
      historyType: tab,
    };
    changeHistoryService.getCurrentProcessorFilter(
      cpPara,
      getCurrentProcessorFilterSuccess,
      getListError
    );

    if (currentIsExpend) {
      changeHistoryService.getAllProcedureHistory(
        getListPara,
        getListSuccess,
        getListError
      );
    } else {
      changeHistoryService.getCurrentProcedureHistory(
        getListPara,
        getListSuccess,
        getListError
      );
    }
  };

  const handleExpendButtonClick = () => {
    const currentIsExpend = !isExpend;
    setIsExpend(currentIsExpend);
    handleExpendButtonClickAction(currentIsExpend);
  };

  const mapFilter = (filter) => {
    let filterList = [];

    if (filter && filter.length !== 0) {
      filterList = filter.map((item) => ({
        text: item.value,
        value: item.key,
      }));
      filterList.sort((a, b) => {
        const textA = a.text?.toUpperCase();
        const textB = b.text?.toUpperCase();
        if (!textA) return -1;
        if (!textB) return 1;
        if (textA < textB) {
          return -1;
        }
        if (textA > textB) {
          return 1;
        }
        return 0;
      });
    }

    return filterList;
  };

  const handleTableSortOrder = (sortColumnName, tableSortData) => {
    if (tableSortData?.sortColumn === sortColumnName) {
      return tableSortData.isAscending ? "ascend" : "descend";
    }
    return null;
  };

  const { formatDate, generateGUID } = ListColumnBase();

  const columns = [
    {
      title: "",
      dataIndex: "procedureHeader",
      key: "procedureHeader",
      width: 60,
      onCell: (record) => ({
        colSpan: record.procedureHeader !== undefined ? 9 : 1,
      }),
      ellipsis: {
        showTitle: false,
      },
      render: (procedureHeader) => (
        <Tooltip placement="topLeft" title={procedureHeader}>
          {procedureHeader}
        </Tooltip>
      ),
    },
    {
      title: "PMP Transaction ID",
      dataIndex: "transactionID",
      key: "transactionID",
      onCell: (record) => ({
        colSpan: record.procedureHeader !== undefined ? 0 : 1,
      }),
      ellipsis: {
        showTitle: false,
      },
      render: (transactionID) => (
        <Tooltip placement="topLeft" title={transactionID}>
          {transactionID}
        </Tooltip>
      ),
    },
    {
      title: "Process Category",
      dataIndex: "processCategory",
      key: "processCategory",
      onCell: (record) => ({
        colSpan: record.procedureHeader !== undefined ? 0 : 1,
      }),
      dbName: "ProcessCategory",
      sorter: true,
      sortOrder: handleTableSortOrder("ProcessCategory", tableSortData),
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => (
        <Tooltip placement="topLeft" title={text}>
          {text === "Archive Procedure" || text === "Archive Task Info." ? (
            text
          ) : (
            <a
              href={"/ProcessDetail/" + record.processID}
              target="_blank"
              rel="noopener noreferrer"
            >
              {text}
            </a>
          )}
        </Tooltip>
      ),
    },
    {
      title: "Trigger Process",
      dataIndex: "triggerProcess",
      key: "triggerProcess",
      onCell: (record) => ({
        colSpan: record.procedureHeader !== undefined ? 0 : 1,
      }),
      dbName: "TriggerProcess",
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      sortOrder: handleTableSortOrder("TriggerProcess", tableSortData),
      filters: mapFilter(tPFilterData),
      filteredValue: filteredInfo.triggerProcess || null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading: false,
        }),
      hidden: tab === 2,
      render: (triggerProcess, record) => (
        <Tooltip placement="topLeft" title={triggerProcess}>
          <a
            href={"/ProcessDetail/" + record.triggerProcessID}
            target="_blank"
            rel="noopener noreferrer"
          >
            {triggerProcess}
          </a>
        </Tooltip>
      ),
    },
    {
      title: "Process Status",
      dataIndex: "processStatus",
      key: "processStatus",
      onCell: (record) => ({
        colSpan: record.procedureHeader !== undefined ? 0 : 1,
      }),
      ellipsis: {
        showTitle: false,
      },
      dbName: "ProcessStatus",
      sorter: true,
      sortOrder: handleTableSortOrder("ProcessStatus", tableSortData),
      width: "12rem",
      render: (processStatus) => {
        let className;

        switch (processStatus) {
          case "Pending Approval":
            className = "pendingApproval";
            break;
          case "Pending Submission":
            className = "pendingSubmission";
            break;
          case "Rejected":
            className = "reject";
            break;
          case "Reject":
            className = "reject";
            processStatus = "Rejected";
            break;
          case "Approved":
            className = "approval";
            break;
          case "System Termination":
            className = "systemTermination";
            break;
          default:
            className = "";
        }
        return (
          <Tooltip placement="topLeft" title={processStatus}>
            <div className={`MyRequestProcessStatusDiv ${className}`}>
              {processStatus}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Process Initiate Date",
      dataIndex: "processInitiateDate",
      key: "processInitiateDate",
      onCell: (record) => ({
        colSpan: record.procedureHeader !== undefined ? 0 : 1,
      }),
      ellipsis: {
        showTitle: false,
      },
      dbName: "ProcessInitiateDate",
      sorter: true,
      sortOrder: handleTableSortOrder("ProcessInitiateDate", tableSortData),
      render: (processInitiateDate) => (
        <Tooltip placement="topLeft" title={formatDate(processInitiateDate)}>
          {formatDate(processInitiateDate)}
        </Tooltip>
      ),
    },
    {
      title: "Process Initiator",
      dataIndex: "processInitiator",
      key: "processInitiator",
      onCell: (record) => ({
        colSpan: record.procedureHeader !== undefined ? 0 : 1,
      }),
      ellipsis: {
        showTitle: false,
      },
      dbName: "ProcessInitiator",
      sorter: true,
      sortOrder: handleTableSortOrder("ProcessInitiator", tableSortData),
      render: (processInitiator) => (
        <Tooltip placement="topLeft" title={processInitiator}>
          {processInitiator}
        </Tooltip>
      ),
    },
    {
      title: "Current Processor",
      dataIndex: "currentProcessor",
      key: "currentProcessor",
      dbName: "CurrentProcessor",
      sorter: true,
      sortOrder: handleTableSortOrder("CurrentProcessor", tableSortData),
      onCell: (record) => ({
        colSpan: record.procedureHeader !== undefined ? 0 : 1,
      }),
      ellipsis: {
        showTitle: false,
      },
      filters: mapFilter(cPFilterData),
      filteredValue: filteredInfo.currentProcessor || null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading: false,
        }),
      render: (currentProcessor) => (
        <Tooltip placement="topLeft" title={currentProcessor}>
          {currentProcessor}
        </Tooltip>
      ),
    },
    {
      title: "Process Completed Date",
      dataIndex: "processCompletedDate",
      key: "processCompletedDate",
      dbName: "ProcessCompletedDate",
      onCell: (record) => ({
        colSpan: record.procedureHeader !== undefined ? 0 : 1,
      }),
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      sortOrder: handleTableSortOrder("ProcessCompletedDate", tableSortData),
      render: (processCompletedDate) => (
        <Tooltip placement="topLeft" title={formatDate(processCompletedDate)}>
          {formatDate(processCompletedDate)}
        </Tooltip>
      ),
    },
  ];

  const transformData = (data) => {
    if (!data) {
      return;
    }

    if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        transformData(data[i]);
      }
    } else if (typeof data === "object") {
      data.key = generateGUID();

      if (data.procedureHistoryVo && Array.isArray(data.procedureHistoryVo)) {
        data.children = data.procedureHistoryVo;
        delete data.procedureHistoryVo;

        for (let i = 0; i < data.children.length; i++) {
          transformData(data.children[i]);
        }
      }
    }
  };

  transformData(listData);

  return (
    <NormalSpin
      spinning={loading}
      size="large"
      children={
        <div className={styles.procedureListDiv}>
          <div className={styles.procedureTopFilter}>
            <div className={`${toDoListStyles.tabDiv} tabDiv`}>
              <Radio.Group
                options={tabOptions}
                onChange={tabOnChange}
                value={tab}
                optionType="button"
              />
            </div>
            <TopFilter
              tab={tab}
              handleFilterClick={handleTopFilterClick}
              procedureId={procedureId}
              procedureTaskId={procedureTaskId}
            />
          </div>
          <div className={styles.tableDiv}>
            <div className={changeHistoryStyle.chTableDiv}>
              {tab === 1 && (
                <div className={changeHistoryStyle.expendButtonDiv}>
                  <Tooltip
                    placement="left"
                    title="Show active and all archived procedure folders."
                  >
                    <Buttons
                      color="white-blue"
                      size="small"
                      onClick={handleExpendButtonClick}
                    >
                      {isExpend ? "Collapse" : "Show All"}
                    </Buttons>
                  </Tooltip>
                </div>
              )}

              <ChangeHistoryListTable
                columns={columns}
                data={listData}
                tableFilterMap={changeHistoryFilterMap}
                handleFilterSort={handleTableFilterSort}
                handleFilterClick={handleTopFilterClick}
                tab={tab}
              />
            </div>
          </div>
        </div>
      }
    />
  );
}
