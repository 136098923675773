import React, { useRef, useState } from "react";
import { useMount } from "ahooks";
import { Radio, message } from "antd";
import AppSetting from "../../../config/AppSetting";
import {
  downloadSingleFile,
  getDate,
  getTimeZoneString,
  userRoles,
} from "../../publicDictionaryValues";
import NormalSpin from "../../../base/basicComponents/spin";
import TopFilter from "./toDoListSections/topFilter/index";
import SearchAndColumnSelect from "../procedureList/procedureListSections/searchAndColumnSelect";
import ToDoListConlumnSelectOption, {
  ToDoListColumnSelectValue,
} from "./toDoListSections/conlumnSelectOption";
import ListTable from "../../commonComponents/listTable/index";
import ToDoListColumn from "./toDoListSections/toDoListColumn";
import ToDoListTableFilterMap from "../../commonComponents/listTable/toDoListFilterMap.js";
import FooterPagination from "../procedureList/procedureListSections/footerPagination";
import toDoListService from "../../../service/toDoListService/index";
import styles from "../procedureList/index.module.scss";
import toDoListStyles from "./index.module.scss";
import "./index.scss";
import { poll } from "../../commonComponents/polling/polling";

export default function ToDoList() {
  const searchAndColumnSelectRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [taskLabelName, setTaskLabelName] = useState("");
  const [taskId, setTaskId] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [currentTaskLabelName, setCurrentTaskLabelName] = useState("");
  const [currentTaskId, setCurrentTaskId] = useState("");
  const [currentTransactionId, setCurrentTransactionId] = useState("");
  const [currentPage, setCurrentPage] = useState({
    pageNumber: 1,
    pageSize: 30,
  });
  const [totalPage, setTotalPage] = useState(0);
  const [totalRecord, setTotalRecord] = useState(0);
  const [toDoListData, setToDoListData] = useState([]);

  const [topFiterData, setTopFiterData] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [tableSortData, setTableSortData] = useState([]);
  const [toDoListFilterData, setToDoListFilterData] = useState({});
  const [tableColumnFilterParam, setTableColumnFilterParam] = useState(null);

  const [transactionIdShow, setTransactionIdShow] = useState(true);
  const [delegateToOthersShow, setDelegateToOthersShow] = useState(true);
  const [actionCompletedDateShow, setActionCompletedDateShow] = useState(true);
  const [processCategoryShow, setProcessCategoryShow] = useState(true);
  const [actionRequiredShow, setActionRequiredShow] = useState(true);
  const [actionTakenShow, setActionTakenShow] = useState(true);
  const [delegatedByShow, setDelegatedByShow] = useState(true);
  const [delegatedToShow, setDelegatedToShow] = useState(true);
  const [dueDateShow, setDueDateShow] = useState(true);
  const [receiveDateShow, setReceiveDateShow] = useState(true);
  const [requestByShow, setRequestByShow] = useState(true);
  const [processInitiateDateShow, setProcessInitiateDateShow] = useState(false);
  const [processInitiatorShow, setProcessInitiatorShow] = useState(false);
  const [accountAbbrShow, setAccountAbbrShow] = useState(true);
  const [accountNameShow, setAccountNameShow] = useState(false);
  const [taskGroupShow, setTaskGroupShow] = useState(true);
  const [categoryShow, setCategoryShow] = useState(true);
  const [taskIdShow, setTaskIdShow] = useState(false);
  const [taskLabelNameShow, setTaskLabelNameShow] = useState(true);
  const [highlyRegulatedShow, setHighlyRegulatedShow] = useState(false);

  const [toDoListDivHeight, setToDoListDivHeight] = useState([0]);
  const [toDoListTableBodyHeight, setToDoListTableBodyHeight] = useState([0]);
  const [progress, setProgress] = useState(0);
  const [exporting, setExporting] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false);

  const resetState = () => {
    setTaskLabelName((pre) => "");
    setTaskId((pre) => "");
    setTransactionId((pre) => "");
    setCurrentTaskLabelName((pre) => "");
    setCurrentTaskId((pre) => "");
    setCurrentTransactionId((pre) => "");
    setCurrentPage({
      pageNumber: 1,
      pageSize: 30,
    });
    setTotalPage((pre) => 0);
    setTotalRecord((pre) => 0);
    setToDoListData((pre) => []);
    setTopFiterData((pre) => []);
    setTableFilterData((pre) => []);
    setFilteredInfo((pre) => {
      return {};
    });
    setTableSortData((pre) => []);
    setToDoListFilterData((pre) => {
      return {};
    });
    setTransactionIdShow((pre) => true);
    setDelegateToOthersShow((pre) => true);
    setActionCompletedDateShow((pre) => true);
    setProcessCategoryShow((pre) => true);
    setActionRequiredShow((pre) => true);
    setActionTakenShow((pre) => true);
    setDelegatedByShow((pre) => true);
    setDelegatedToShow((pre) => true);
    setDueDateShow((pre) => true);
    setReceiveDateShow((pre) => true);
    setRequestByShow((pre) => true);
    setProcessInitiateDateShow((pre) => false);
    setProcessInitiatorShow((pre) => false);
    setAccountAbbrShow((pre) => true);
    setAccountNameShow((pre) => false);
    setTaskGroupShow((pre) => true);
    setCategoryShow((pre) => true);
    setTaskIdShow((pre) => false);
    setTaskLabelNameShow((pre) => true);
    setHighlyRegulatedShow((pre) => false);
  };

  const handleUserRole = (roleModel) => {
    if (roleModel?.length === undefined || roleModel?.length === 0) {
      return false;
    }
    return roleModel.some((x) => x.roleID === userRoles.Opex);
  };

  const isOpex = handleUserRole(AppSetting.localStorageValue.role.get());

  const tabOnChange = ({ target: { value } }) => {
    setTab((pre) => value);
    resetState();
  };

  const getAccountAbbr = (accountAbbrOption, accountAbbrSelect) => {
    let filterArr = [];
    if (accountAbbrSelect.length === 0) {
      filterArr = accountAbbrOption.map((x) => parseInt(x.value));
    } else {
      filterArr = accountAbbrSelect.map((x) => parseInt(x));
    }

    return {
      filterColumn: "AccountID",
      filterValue: filterArr,
    };
  };

  const getActionRequired = (actionRequiredOption, actionRequiredSelect) => {
    let filterArr = [];
    if (actionRequiredSelect.length === 0) {
      filterArr = actionRequiredOption.map((x) => x.value);
    } else {
      filterArr = actionRequiredSelect.map((x) => x);
    }

    return {
      filterColumn: "ActionRequiredID",
      filterValue: filterArr,
    };
  };

  const getActionTaken = (actionTakenOption, actionTakenSelect) => {
    let filterArr = [];
    if (actionTakenSelect.length === 0) {
      filterArr = actionTakenOption.map((x) => x.value);
    } else {
      filterArr = actionTakenSelect.map((x) => x);
    }

    return {
      filterColumn: "ActionTakenID",
      filterValue: filterArr,
    };
  };

  const getProcessCategory = (processCategoryOption, processCategorySelect) => {
    let filterArr = [];
    if (processCategorySelect.length === 0) {
      filterArr = processCategoryOption.map((x) => x.value);
    } else {
      filterArr = processCategorySelect.map((x) => x);
    }

    return {
      filterColumn: "FlowCategoryGroupID",
      filterValue: filterArr,
    };
  };

  const setDelegatedValueToListData = (list) => {
    setToDoListData(list);
  };

  const getToDoListSuccess = (result) => {
    setLoading(false);
    setToDoListData(result.data);
    setTotalPage(result.totalPage);
    setTotalRecord(result.totalRecord);
  };

  const getToDoListError = (result) => {
    console.log(result);
  };

  const getToDoListFilterSuccess = (result, currentField) => {
    setToDoListFilterData({
      ...toDoListFilterData,
      [currentField]: result?.fields,
    });
    setLoading(false);
    setFilterLoading(false);
  };

  const getToDoListFilterError = (result) => {
    console.log(result);
  };

  const getTopFilterCondition = () => {
    return [
      {
        key: "accountAbbr",
        filterFunction: getAccountAbbr,
      },
      {
        key: "actionRequired",
        tabCondition: tab === 0,
        filterFunction: getActionRequired,
      },
      {
        key: "actionTaken",
        tabCondition: tab !== 0,
        filterFunction: getActionTaken,
      },
      {
        key: "processCategory",
        filterFunction: getProcessCategory,
      },
    ];
  };

  const getTopFilter = (filterOptionList, selectOptions) => {
    const topFilterArr = [];
    const topFilterOptionList = JSON.parse(JSON.stringify(filterOptionList));
    const topFilterSelectOptions = JSON.parse(JSON.stringify(selectOptions));

    const filterConditions = getTopFilterCondition();

    filterConditions.forEach(({ key, tabCondition, filterFunction }) => {
      if (
        topFilterOptionList[key] !== undefined &&
        topFilterOptionList[key].length !== 0 &&
        (tabCondition === undefined || tabCondition)
      ) {
        if (
          (isOpex || tab === 1) &&
          key === "accountAbbr" &&
          topFilterSelectOptions[key].length === 0
        ) {
          return;
        }

        if (key !== "accountAbbr" && topFilterSelectOptions[key].length === 0) {
          return;
        }

        const filteredData = filterFunction(
          topFilterOptionList[key],
          topFilterSelectOptions[key]
        );
        topFilterArr.push(filteredData);
      }
    });

    return topFilterArr;
  };

  const getPendingListTable = (getToDoListPara) => {
    toDoListService.getPendingList(
      getToDoListPara,
      getToDoListSuccess,
      getToDoListError
    );
  };

  const getCompletedListTable = (getToDoListPara) => {
    toDoListService.getCompletedList(
      getToDoListPara,
      getToDoListSuccess,
      getToDoListError
    );
  };

  const handleTopFilterClick = (filterOptionList, selectOptions) => {
    const topFilterArr = getTopFilter(filterOptionList, selectOptions);
    setLoading(true);
    setTopFiterData(topFilterArr);
    setTaskId("");
    setTaskLabelName("");
    setTransactionId("");
    setCurrentTaskLabelName("");
    setCurrentTaskId("");
    setCurrentTransactionId("");
    setTableFilterData([]);
    setTableSortData([]);
    setToDoListFilterData({});
    setFilteredInfo({});
    setCurrentPage({ ...currentPage, pageNumber: 1 });
    const getToDoListPara = {
      filterFields: topFilterArr,
      sortFields: [],
      transactionId: "",
      taskId: "",
      taskLabelName: "",
      pageSize: currentPage.pageSize,
      pageIndex: 1,
    };

    const getToDoListFilterPara = {
      filterFields: topFilterArr,
      transactionId: "",
      taskId: "",
      taskLabelName: "",
    };
    setTableColumnFilterParam({ ...getToDoListFilterPara });

    if (tab === 0) {
      getPendingListTable(getToDoListPara);
    } else {
      getCompletedListTable(getToDoListPara);
    }
  };

  const ShowSearchColumn = () => {
    let searchColumnArr = [];
    if (transactionId.trim() != "") {
      searchColumnArr.push("transactionIdShow");
    }

    if (taskId.trim() != "") {
      searchColumnArr.push("taskIdShow");
    }

    if (taskLabelName.trim() != "") {
      searchColumnArr.push("taskLabelNameShow");
    }

    searchAndColumnSelectRef.current.handleColumnSearchSelect(searchColumnArr);
  };

  const handleSearchClick = () => {
    const topFiter = JSON.parse(JSON.stringify(topFiterData));
    setLoading(true);
    setCurrentPage({ ...currentPage, pageNumber: 1 });
    setCurrentTaskLabelName(taskLabelName.trim());
    setCurrentTaskId(taskId.trim());
    setCurrentTransactionId(transactionId.trim());
    setTableFilterData([]);
    setTableSortData([]);
    setToDoListFilterData({});
    setFilteredInfo({});
    const getToDoListPara = {
      filterFields: topFiter,
      sortFields: [],
      transactionId: transactionId.trim(),
      taskId: taskId.trim(),
      taskLabelName: taskLabelName.trim(),
      pageSize: currentPage.pageSize,
      pageIndex: 1,
    };

    const getToDoListFilterPara = {
      filterFields: topFiterData,
      transactionId: transactionId.trim(),
      taskId: taskId.trim(),
      taskLabelName: taskLabelName.trim(),
    };
    setTableColumnFilterParam({ ...getToDoListFilterPara });

    if (tab === 0) {
      getPendingListTable(getToDoListPara);
    } else {
      getCompletedListTable(getToDoListPara);
    }

    ShowSearchColumn();
  };

  const handleTransactionIdChange = (e) => {
    setTransactionId(e.value);
  };

  const handleTaskLabelNameChange = (e) => {
    setTaskLabelName(e.value);
  };

  const handleTaskIdChange = (e) => {
    setTaskId(e.value);
  };

  const handleConlumnSelectUpdate = (key, value) => {
    const updateFunctions = {
      transactionIdShow: () => setTransactionIdShow(value),
      delegateToOthersShow: () => setDelegateToOthersShow(value),
      actionCompletedDateShow: () => setActionCompletedDateShow(value),
      processCategoryShow: () => setProcessCategoryShow(value),
      actionRequiredShow: () => setActionRequiredShow(value),
      actionTakenShow: () => setActionTakenShow(value),
      delegatedByShow: () => setDelegatedByShow(value),
      delegatedToShow: () => setDelegatedToShow(value),
      dueDateShow: () => setDueDateShow(value),
      receiveDateShow: () => setReceiveDateShow(value),
      requestByShow: () => setRequestByShow(value),
      processInitiateDateShow: () => setProcessInitiateDateShow(value),
      processInitiatorShow: () => setProcessInitiatorShow(value),
      accountAbbrShow: () => setAccountAbbrShow(value),
      accountNameShow: () => setAccountNameShow(value),
      taskGroupShow: () => setTaskGroupShow(value),
      categoryShow: () => setCategoryShow(value),
      taskIdShow: () => setTaskIdShow(value),
      taskLabelNameShow: () => setTaskLabelNameShow(value),
      highlyRegulatedShow: () => setHighlyRegulatedShow(value),
    };

    const updateFunction = updateFunctions[key];
    updateFunction && updateFunction();
  };

  const handleColumnSearchSelect = (valueArr) => {
    for (let value of valueArr) {
      handleConlumnSelectUpdate(value, true);
    }
  };

  const handleColumnSearchDeSelect = (value) => {
    handleConlumnSelectUpdate(value, false);
  };

  const concatProcedureFileFilters = (topFilter, tableFilter) => {
    const mergedArray = [...topFilter];

    for (const objB of tableFilter) {
      const filterColumnB = objB.filterColumn;
      const filterValueB = objB.filterValue;
      const existingObjIndex = mergedArray.findIndex(
        (objA) => objA.filterColumn === filterColumnB
      );

      if (existingObjIndex !== -1) {
        const existingObj = mergedArray[existingObjIndex];
        const filterValueA = existingObj.filterValue;
        const intersection = filterValueA.filter((value) =>
          filterValueB.includes(value)
        );
        existingObj.filterValue = intersection.length > 0 ? intersection : [];
      } else {
        mergedArray.push(objB);
      }
    }

    return mergedArray;
  };

  const getListTableData = (tab, getToDoListPara) => {
    if (tab === 0) {
      toDoListService.getPendingList(
        getToDoListPara,
        getToDoListSuccess,
        getToDoListError
      );
    } else {
      toDoListService.getCompletedList(
        getToDoListPara,
        getToDoListSuccess,
        getToDoListError
      );
    }
  };

  const handlePageChange = (pageSize, pageNumber) => {
    const topFiter = JSON.parse(JSON.stringify(topFiterData));
    const tableFilter = JSON.parse(JSON.stringify(tableFilterData));
    const sort = JSON.parse(JSON.stringify(tableSortData));
    const filterArr = concatProcedureFileFilters(topFiter, tableFilter);
    const getToDoListPara = {
      filterFields: filterArr,
      sortFields: sort,
      taskId: currentTaskId,
      taskLabelName: currentTaskLabelName,
      transactionId: currentTransactionId,
      pageSize: pageSize === null ? currentPage.pageSize : pageSize,
      pageIndex: pageNumber === null ? currentPage.pageNumber : pageNumber,
    };

    getListTableData(tab, getToDoListPara);
  };

  const handlePageNumberChange = (pageNumber, pageSize) => {
    setLoading(true);
    let newPageNumber = pageSize === currentPage.pageSize ? pageNumber : 1;
    setCurrentPage({
      pageSize: pageSize,
      pageNumber: newPageNumber,
    });
    handlePageChange(pageSize, newPageNumber);
  };

  const handleTableFilterSort = (tableFilterPara, tableSortPara, filters) => {
    const topFiter = JSON.parse(JSON.stringify(topFiterData));
    const tableFilter = JSON.parse(JSON.stringify(tableFilterPara));
    const sort = JSON.parse(JSON.stringify(tableSortPara));
    const filterArr = concatProcedureFileFilters(topFiter, tableFilter);
    setLoading(true);
    setCurrentPage({ ...currentPage, pageNumber: 1 });
    setTableFilterData(tableFilter);
    setTableSortData(sort);
    setFilteredInfo(filters);
    const getToDoListPara = {
      filterFields: filterArr,
      sortFields: sort,
      transactionId: currentTransactionId,
      taskId: currentTaskId,
      taskLabelName: currentTaskLabelName,
      pageSize: currentPage.pageSize,
      pageIndex: 1,
    };

    getListTableData(tab, getToDoListPara);
  };

  const handleColumnSelectValue = (value) => {
    if (tab === 0) {
      return value.filter(
        (x) => x !== "actionCompletedDateShow" && x !== "actionTakenShow"
      );
    } else {
      return value.filter(
        (x) =>
          x !== "actionRequiredShow" &&
          x !== "dueDateShow" &&
          x.value !== "delegateToOthersShow"
      );
    }
  };

  const handleColumnSelectOption = (option) => {
    if (tab === 0) {
      return option.filter(
        (x) =>
          x.value !== "actionCompletedDateShow" && x.value !== "actionTakenShow"
      );
    } else {
      return option.filter(
        (x) =>
          x.value !== "actionRequiredShow" &&
          x.value !== "dueDateShow" &&
          x.value !== "delegateToOthersShow"
      );
    }
  };

  let pollStopper = null;
  let exportBatchId = null;

  const checkCompletedToDoExportStatus = async () => {
    try {
      const para = {
        exportBatchId: exportBatchId,
      };
      const statusResponse = await toDoListService.checkCompletedToDoListExport(
        para
      );
      const fileId = statusResponse.fileId;
      const totalCount = statusResponse.totalCount;
      const finishCount = statusResponse.finishCount;
      const finishPercent = Math.floor((finishCount / totalCount) * 100);
      finishPercent > 1 && setProgress(finishPercent > 99 ? 99 : finishPercent);
      return fileId;
    } catch (statusError) {
      return null;
    }
  };

  const downloadCompletedToDoSuccessful = () => {
    setProgress(100);
    setTimeout(() => {
      setProgress(0);
      setStartAnimation(false);
      setExporting(false);
    }, 1000);
  };

  const handleCompletedToDoPollingComplete = (fileId) => {
    setProgress(100);
    const date = getTimeZoneString(getDate(), "MMDDYYYY");
    const fileName = "Individual Completed To Do List - " + date + ".xlsx";
    downloadSingleFile(fileId, fileName, downloadCompletedToDoSuccessful);
  };

  const handleCompletedToDoPollingError = (error) => {
    setProgress(0);
    setStartAnimation(false);
    setExporting(false);
  };

  const startCompletedToDoPolling = () => {
    if (pollStopper) pollStopper(); // Stop any existing polling

    pollStopper = poll(
      checkCompletedToDoExportStatus,
      handleCompletedToDoPollingComplete,
      2000,
      handleCompletedToDoPollingError
    );
  };

  const handleGetCompletedExportBatchIdSuccess = (response) => {
    exportBatchId = response.exportBatchId;
    setStartAnimation(true);
    startCompletedToDoPolling();
    setProgress(1);
  };

  /**
   * success callback about export api
   * @param {object} response
   * @param {boolean} tab flag about pending or completed list
   */
  const handlePendingExportSuccess = (response) => {
    const fileId = response.fileId;
    const date = getTimeZoneString(getDate(), "MMDDYYYY");
    const fileName = "Individual Pending To Do List - " + date + ".xlsx";
    downloadSingleFile(fileId, fileName, setLoading(false));
  };

  /**
   * error callback about export api
   * @param {object} error
   */
  const handleExportError = (error) => {
    tab === 0 ? setLoading(false) : setExporting(false);
    message.error("Export to do list failed!");
    console.log(error);
  };

  /**
   * function about click export button for to do list
   */
  const handleExportClick = () => {
    tab === 0 ? setLoading(true) : setExporting(true);
    const topFiter = JSON.parse(JSON.stringify(topFiterData));
    const tableFilter = JSON.parse(JSON.stringify(tableFilterData));
    const sort = JSON.parse(JSON.stringify(tableSortData));
    const filterArr = concatProcedureFileFilters(topFiter, tableFilter);
    const exportParam = {
      filterFields: filterArr,
      sortFields: sort,
      transactionId: currentTransactionId,
      taskId: currentTaskId,
      taskLabelName: currentTaskLabelName,
      pageSize: currentPage.pageSize,
      pageIndex: currentPage.pageNumber,
      isReport: true,
    };
    if (tab === 0) {
      toDoListService.exportPendingTodoList(
        exportParam,
        (response) => handlePendingExportSuccess(response),
        handleExportError
      );
      return;
    }
    toDoListService.exportCompletedTodoList(
      exportParam,
      (response) => handleGetCompletedExportBatchIdSuccess(response),
      handleExportError
    );
  };

  const handleColumnFilterClick = (isOpen, currentField) => {
    if (!isOpen) return;

    const filterFieldList = Object.keys(toDoListFilterData);

    if (!filterFieldList.includes(currentField)) {
      setFilterLoading(true);
      const getTodoTableFilterPara = {
        ...tableColumnFilterParam,
        CurrentField: currentField,
      };

      if (tab === 0) {
        toDoListService.getPendingListFilter(
          getTodoTableFilterPara,
          (result) => getToDoListFilterSuccess(result, currentField),
          getToDoListFilterError
        );
      } else {
        toDoListService.getCompletedListFilter(
          getTodoTableFilterPara,
          (result) => getToDoListFilterSuccess(result, currentField),
          getToDoListFilterError
        );
      }
    }
  };

  const handleTableWidth = () => {
    const delegateWidth = tab === 0 ? 220 : 0;
    if (
      searchAndColumnSelectRef.current?.state.columnSelectValue === undefined
    ) {
      return columnSelectValue.length * 220 + delegateWidth;
    }

    return (
      searchAndColumnSelectRef.current.state.columnSelectValue.length * 220 +
      delegateWidth
    );
  };

  const tabOptions = [
    {
      label: "Pending",
      value: 0,
    },
    {
      label: "Completed",
      value: 1,
    },
  ];

  const columnSelectValue = ToDoListColumnSelectValue();

  const columnSelectOption = ToDoListConlumnSelectOption();

  const columns = ToDoListColumn({
    tab,
    toDoListFilterData,
    filteredInfo,
    transactionIdShow,
    delegateToOthersShow,
    taskLabelNameShow,
    actionCompletedDateShow,
    processCategoryShow,
    actionRequiredShow,
    delegatedByShow,
    delegatedToShow,
    actionTakenShow,
    dueDateShow,
    receiveDateShow,
    requestByShow,
    processInitiateDateShow,
    processInitiatorShow,
    accountAbbrShow,
    accountNameShow,
    taskGroupShow,
    categoryShow,
    taskIdShow,
    highlyRegulatedShow,
    tableSortData,
    toDoListData,
    setDelegatedValueToListData,
    handleColumnFilterClick,
    filterLoading,
  });

  const calculateToDoListHeight = () => {
    const screenHeight = window.innerHeight;
    const desiredHeight = screenHeight - 114 + 8;
    setToDoListDivHeight(desiredHeight);
    var toDoListTopFilterDiv = document.getElementById("toDoListTopFilter");
    var toDoListTopFilterDivHeight = toDoListTopFilterDiv.offsetHeight;
    const tableContainer = document.querySelector(".ant-table-body");

    if (tableContainer) {
      const toDoListTableBodyHeight =
        desiredHeight - toDoListTopFilterDivHeight - 191 + 8;
      setToDoListTableBodyHeight(toDoListTableBodyHeight);
      tableContainer.style.height = `${toDoListTableBodyHeight}px`;
    }
  };

  useMount(() => {
    setLoading(true);
    calculateToDoListHeight();
    window.addEventListener("resize", calculateToDoListHeight);

    return () => {
      window.removeEventListener("resize", calculateToDoListHeight);
    };
  });

  return (
    <NormalSpin
      spinning={loading}
      size="large"
      children={
        <div
          className={styles.procedureListDiv}
          style={{ height: toDoListDivHeight }}
        >
          <div className={styles.procedureTopFilter} id="toDoListTopFilter">
            <div className={`${toDoListStyles.tabDiv} tabDiv`}>
              <Radio.Group
                options={tabOptions}
                onChange={tabOnChange}
                value={tab}
                optionType="button"
              />
            </div>
            <TopFilter
              tab={tab}
              handleFilterClick={handleTopFilterClick}
              isOpex={isOpex}
            />
            <SearchAndColumnSelect
              ref={searchAndColumnSelectRef}
              tab={tab}
              columnSelectValue={handleColumnSelectValue(columnSelectValue)}
              columnSelectOption={handleColumnSelectOption(columnSelectOption)}
              handleSearch={handleSearchClick}
              handleExport={handleExportClick}
              taskLabelName={taskLabelName}
              taskId={taskId}
              transactionId={transactionId}
              handleTransactionIdChange={handleTransactionIdChange}
              handleTaskLabelNameChange={handleTaskLabelNameChange}
              handleTaskIdChange={handleTaskIdChange}
              handleColumnSearchSelect={handleColumnSearchSelect}
              handleColumnSearchDeSelect={handleColumnSearchDeSelect}
              showExport={true}
              exportDisabled={exporting}
              progress={progress}
              startAnimation={startAnimation}
            />
          </div>
          <div className={styles.tableDiv}>
            <ListTable
              data={toDoListData}
              columns={columns}
              handleFilterSort={handleTableFilterSort}
              tableFilterMap={ToDoListTableFilterMap}
              tableWidth={handleTableWidth()}
              tableHeight={toDoListTableBodyHeight}
            />
            <div className={styles.procedurelistFooter}>
              <FooterPagination
                totalRecord={totalRecord}
                totalPage={totalPage}
                currentPage={currentPage}
                handlePageNumberChange={handlePageNumberChange}
              />
            </div>
          </div>
        </div>
      }
    />
  );
}
