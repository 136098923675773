import React, { useRef, useState } from "react";
import { message } from "antd";
import NormalSpin from "../../../base/basicComponents/spin";
import FooterPagination from "./procedureListSections/footerPagination";
import TopFilter from "./procedureListSections/topFilter";
import SearchAndColumnSelect from "./procedureListSections/searchAndColumnSelect";
import ListTable from "../../commonComponents/listTable";
import ProcedureListService from "../../../service/procedureList";
import ProcedureDetailService from "../../../service/procedureFile/ProcedureDetailService";
import TableFilterMap from "../../commonComponents/listTable/tableFilterMap";
import ProcedureListListColumn from "./procedureListSections/procedureListColumn";
import ProcedureListColumnSelectOption, {
  ProcedureListColumnSelectValue,
} from "./procedureListSections/columnSelectOption";
import styles from "./index.module.scss";
import { useMount } from "ahooks";
import {
  downloadFileSuccess,
  userRoles,
  sortUsersList,
  downloadSingleFile,
  getTimeZoneString,
  getDate,
} from "../../publicDictionaryValues";
import AppSetting from "../../../config/AppSetting";
import DownloadDocument from "../../sections/downloadDocumentSection";
import ShareDocument from "../../sections/shareDocumentSection";
import { poll } from "../../commonComponents/polling/polling";

export default function ProcedureList() {
  const listTableRef = useRef(null);
  const searchAndColumnSelectRef = useRef(null);
  const [topFiterData, setTopFiterData] = useState([]);
  const [procedureListData, setProcedureListData] = useState([]);
  const [procedureListFilterData, setProcedureListFilterData] = useState({});
  const [taskLabelName, setTaskLabelName] = useState("");
  const [taskId, setTaskId] = useState("");
  const [currentTaskLabelName, setCurrentTaskLabelName] = useState("");
  const [currentTaskId, setCurrentTaskId] = useState("");
  const [currentPage, setCurrentPage] = useState({
    pageNumber: 1,
    pageSize: 30,
  });
  const [totalPage, setTotalPage] = useState(0);
  const [totalRecord, setTotalRecord] = useState(0);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [tableSortData, setTableSortData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);

  const [clientAbbrShow, setClientAbbrShow] = useState(false);
  const [clientNameShow, setClientNameShow] = useState(false);
  const [accountAbbrShow, setAccountAbbrShow] = useState(true);
  const [accountNameShow, setAccountNameShow] = useState(true);
  const [accountManagerShow, setAccountManagerShow] = useState(false);
  const [accountSupervisorShow, setAccountSupervisorShow] = useState(false);
  const [accountTeamLeaderShow, setAccountTeamLeaderShow] = useState(true);
  const [taskStatusShow, setTaskStatusShow] = useState(true);
  const [taskGroupNameShow, setTaskGroupNameShow] = useState(true);
  const [categoryNameShow, setCategoryNameShow] = useState(true);
  const [taskLabelNameShow, setTaskLabelNameShow] = useState(true);
  const [taskIdShow, setTaskIdShow] = useState(true);
  const [taskCreatedDateShow, setTaskCreatedDateShow] = useState(true);
  const [highRegulatedShow, setHighRegulatedShow] = useState(true);
  const [turnaroundTimeShow, setTurnaroundTimeShow] = useState(false);
  const [temporaryTaskShow, setTemporaryTaskShow] = useState(true);
  const [sharedTaskShow, setSharedTaskShow] = useState(true);
  const [sharedTaskIdsShow, setSharedTaskIdsShow] = useState(false);
  const [sharedTaskNamesShow, setSharedTaskNamesShow] = useState(false);
  const [procedureStatusShow, setProcedureStatusShow] = useState(true);
  const [pmpExcludedShow, setPmpExcludedShow] = useState(true);
  const [procedureSaveInCategoryShow, setProcedureSaveInCategoryShow] =
    useState(true);
  const [clientServerPathShow, setClientServerPathShow] = useState(false);
  const [primaryProcedureOwnerShow, setPrimaryProcedureOwnerShow] =
    useState(true);
  const [procedureOwnerShow, setProcedureOwnerShow] = useState(true);
  const [procedureFolderCreateDateShow, setProcedureFolderCreateDateShow] =
    useState(true);
  const [lastUpdatedDateShow, setLastUpdatedDateShow] = useState(true);
  const [procedureFolderStatusShow, setProcedureFolderStatusShow] =
    useState(true);

  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [procedureFiles, setProcedureFiles] = useState([]);
  const [supportingFiles, setSupportingFiles] = useState([]);
  const [idsParam, setIdsParam] = useState(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [shareData, setShareData] = useState(null);
  const [activeInternalUsers, setActiveInternalUsers] = useState([]);

  const [procedureListDivHeight, setProcedureListDivHeight] = useState([0]);
  const [procedureListTableBodyHeight, setProcedureListTableBodyHeight] =
    useState([0]);
  const [progress, setProgress] = useState(0);
  const [exporting, setExporting] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false);

  const handleUserRole = (roleModel) => {
    if (roleModel?.length === undefined || roleModel?.length === 0) {
      return false;
    }
    return roleModel.some(
      (x) => x.roleID === userRoles.OpExAndTOO || x.roleID === userRoles.Opex
    );
  };

  const isOpex = handleUserRole(AppSetting.localStorageValue.role.get());

  const getaccountAbbr = (accountAbbrOption, accountAbbrSelect) => {
    let filterArr = [];
    if (accountAbbrSelect.length === 0) {
      filterArr = accountAbbrOption.map((x) => parseInt(x.value));
    } else {
      filterArr = accountAbbrSelect.map((x) => parseInt(x));
    }

    return {
      filterColumn: "AccountID",
      filterValue: filterArr,
    };
  };

  const getAccountStatus = (accountStatusSelect) => {
    return {
      filterColumn: "AccountStatus",
      filterValue: parseInt(accountStatusSelect),
    };
  };

  const getTaskGroup = (taskGroupOption, taskGroupSelect) => {
    let filterArr = [];
    if (taskGroupSelect.length === 0) {
      filterArr = taskGroupOption.map((x) => parseInt(x.value));
    } else {
      filterArr = taskGroupSelect.map((x) => parseInt(x));
    }

    return {
      filterColumn: "TaskGroupID",
      filterValue: filterArr,
    };
  };

  const getCategory = (categoryOption, categorySelect) => {
    let filterArr = [];
    if (categorySelect.length === 0) {
      filterArr = categoryOption.map((x) => x.value);
    } else {
      filterArr = categorySelect.map((x) => x);
    }
    return {
      filterColumn: "Path",
      filterValue: filterArr,
    };
  };

  const getTaskStatus = (taskStatusSelect) => {
    return {
      filterColumn: "TaskStatus",
      filterValue: parseInt(taskStatusSelect),
    };
  };

  const getTopFilter = (filterOptionList, selectOptions) => {
    let topFilterArr = [];
    const topFilterOptionList = JSON.parse(JSON.stringify(filterOptionList));
    const topFilterSelectOptions = JSON.parse(JSON.stringify(selectOptions));

    if (!(isOpex && topFilterSelectOptions.accountAbbr.length === 0)) {
      const accountAbbrArr = getaccountAbbr(
        topFilterOptionList.accountAbbr,
        topFilterSelectOptions.accountAbbr
      );
      topFilterArr.push(accountAbbrArr);
    }

    const accountStatus = getAccountStatus(
      topFilterSelectOptions.accountStatus
    );
    accountStatus.filterValue !== 2 && topFilterArr.push(accountStatus);

    if (
      topFilterOptionList.taskGroup !== undefined &&
      topFilterOptionList.taskGroup.length !== 0
    ) {
      const taskGroup = getTaskGroup(
        topFilterOptionList.taskGroup,
        topFilterSelectOptions.taskGroup
      );
      topFilterArr.push(taskGroup);
    }

    if (
      topFilterOptionList.category !== undefined &&
      topFilterOptionList.category.length !== 0
    ) {
      const category = getCategory(
        topFilterOptionList.category,
        topFilterSelectOptions.category
      );
      topFilterArr.push(category);
    }

    const taskStatus = getTaskStatus(topFilterSelectOptions.taskStatus);
    taskStatus.filterValue !== 2 && topFilterArr.push(taskStatus);

    return topFilterArr;
  };

  const getProcedureListSuccess = (result) => {
    setLoading(false);
    setProcedureListData(result.data);
    setTotalPage(result.totalPage);
    setTotalRecord(result.totalRecord);
  };

  const getProcedureListError = (result) => {
    console.log(result);
  };

  const getProcedureFilterSuccess = (result, currentField) => {
    setProcedureListFilterData({
      ...procedureListFilterData,
      [currentField]: result?.fields,
    });
    setLoading(false);
    setFilterLoading(false);
  };

  const getProcedureFilterError = (result) => {
    console.log(result);
  };

  const handleTopFilterClick = (filterOptionList, selectOptions) => {
    const topFilterArr = getTopFilter(filterOptionList, selectOptions);
    setLoading(true);
    setTopFiterData(topFilterArr);
    setTaskId("");
    setTaskLabelName("");
    setCurrentTaskId("");
    setCurrentTaskLabelName("");
    setTableFilterData([]);
    setTableSortData([]);
    setProcedureListFilterData({});
    setFilteredInfo({});
    setCurrentPage({ ...currentPage, pageNumber: 1 });
    const getProcedureListPara = {
      filterFields: topFilterArr,
      sortFields: [],
      taskId: "",
      taskLabelName: "",
      pageSize: currentPage.pageSize,
      pageIndex: 1,
    };

    ProcedureListService.getProcedureList(
      getProcedureListPara,
      getProcedureListSuccess,
      getProcedureListError
    );
  };

  const handleTableFilterSort = (tableFilterPara, tableSortPara, filters) => {
    const topFiter = JSON.parse(JSON.stringify(topFiterData));
    const tableFilter = JSON.parse(JSON.stringify(tableFilterPara));
    const sort = JSON.parse(JSON.stringify(tableSortPara));
    const filterArr = concatFilters(topFiter, tableFilter);
    setLoading(true);
    setCurrentPage({ ...currentPage, pageNumber: 1 });
    setTableFilterData(tableFilter);
    setTableSortData(sort);
    setFilteredInfo(filters);
    const getProcedureListPara = {
      filterFields: filterArr,
      sortFields: sort,
      taskId: currentTaskId,
      taskLabelName: currentTaskLabelName,
      pageSize: currentPage.pageSize,
      pageIndex: 1,
    };

    ProcedureListService.getProcedureList(
      getProcedureListPara,
      getProcedureListSuccess,
      getProcedureListError
    );
  };

  const ShowSearchColumn = () => {
    let searchColumnArr = [];

    if (taskId.trim() != "") {
      searchColumnArr.push("taskIdShow");
    }

    if (taskLabelName.trim() != "") {
      searchColumnArr.push("taskLabelNameShow");
    }

    searchAndColumnSelectRef.current.handleColumnSearchSelect(searchColumnArr);
  };

  const handleSearchClick = () => {
    const topFiter = JSON.parse(JSON.stringify(topFiterData));
    setLoading(true);
    setCurrentPage({ ...currentPage, pageNumber: 1 });
    setCurrentTaskLabelName(taskLabelName.trim());
    setCurrentTaskId(taskId.trim());
    setTableFilterData([]);
    setTableSortData([]);
    setProcedureListFilterData({});
    setFilteredInfo({});
    const getProcedureListPara = {
      filterFields: topFiter,
      sortFields: [],
      taskId: taskId.trim(),
      taskLabelName: taskLabelName.trim(),
      pageSize: currentPage.pageSize,
      pageIndex: 1,
    };

    ProcedureListService.getProcedureList(
      getProcedureListPara,
      getProcedureListSuccess,
      getProcedureListError
    );

    ShowSearchColumn();
  };

  let pollStopper = null;
  let exportBatchId = null;

  const checkStatus = async () => {
    try {
      const para = {
        exportBatchId: exportBatchId,
      };
      const statusResponse = await ProcedureListService.checkExport(para);
      const fileId = statusResponse.fileId;
      const totalCount = statusResponse.totalCount;
      const finishCount = statusResponse.finishCount;
      const finishPercent = Math.floor((finishCount / totalCount) * 100);
      // setProgress((prev) => (prev < 95 ? prev + 5 : 95));
      finishPercent > 1 && setProgress(finishPercent > 99 ? 99 : finishPercent);
      return fileId;
    } catch (statusError) {
      return null;
    }
  };

  const downloadSuccessful = () => {
    setProgress(100);
    setTimeout(() => {
      setProgress(0);
      setStartAnimation(false);
      setExporting(false);
    }, 1000);
  };

  const handlePollingComplete = (fileId) => {
    setProgress(100);
    const date = getTimeZoneString(getDate(), "MMDDYYYY");
    const fileName = "Procedure Mater List - " + date + ".xlsx";
    downloadSingleFile(fileId, fileName, downloadSuccessful);
  };

  const handlePollingError = (error) => {
    setProgress(0);
    setStartAnimation(false);
    setExporting(false);
  };

  const startPolling = () => {
    if (pollStopper) pollStopper(); // Stop any existing polling

    pollStopper = poll(
      checkStatus,
      handlePollingComplete,
      2000,
      handlePollingError
    );
  };

  /**
   * success callback about export api
   * @param {object} response
   */
  const handleGetExportBatchIdSuccess = (response) => {
    exportBatchId = response.exportBatchId;
    const button = document.getElementById("exportButton");
    const buttonRect = button.getBoundingClientRect();

    document.documentElement.style.setProperty(
      "--button-top",
      `${buttonRect.top}px`
    );
    document.documentElement.style.setProperty(
      "--button-left",
      `${buttonRect.left}px`
    );
    setStartAnimation(true);
    startPolling();
    setProgress(1);
  };

  /**
   * error callback about export api
   * @param {object} error
   */
  const handleExportError = (error) => {
    setExporting(false);
    message.error("Export procedure list failed!");
    console.log(error);
  };

  /**
   * function about click export button for procedure list
   */
  const handleExportClick = () => {
    setExporting(true);
    const topFiter = JSON.parse(JSON.stringify(topFiterData));
    const tableFilter = JSON.parse(JSON.stringify(tableFilterData));
    const sort = JSON.parse(JSON.stringify(tableSortData));
    const filterArr = concatFilters(topFiter, tableFilter);
    const AccountIDList = filterArr.find(
      (item) => item.filterColumn === "AccountID"
    )?.filterValue;
    const exportParam = {
      filterFields: AccountIDList?.length > 0 || isOpex ? filterArr : [],
      sortFields: sort,
      taskId: currentTaskId,
      taskLabelName: currentTaskLabelName,
      pageSize: currentPage.pageSize,
      pageIndex: currentPage.pageNumber,
      isReport: true,
    };
    ProcedureListService.exportProcedureList(
      exportParam,
      handleGetExportBatchIdSuccess,
      handleExportError
    );
  };

  const handleTaskLabelNameChange = (e) => {
    setTaskLabelName(e.value);
  };

  const handleTaskIdChange = (e) => {
    setTaskId(e.value);
  };

  const concatFilters = (topFilter, tableFilter) => {
    const mergedArray = [...topFilter];

    for (const objB of tableFilter) {
      const filterColumnB = objB.filterColumn;
      const filterValueB = objB.filterValue;
      const existingObjIndex = mergedArray.findIndex(
        (objA) => objA.filterColumn === filterColumnB
      );

      if (existingObjIndex !== -1) {
        const existingObj = mergedArray[existingObjIndex];
        const filterValueA = existingObj.filterValue;
        const intersection = filterValueA.filter((value) =>
          filterValueB.includes(value)
        );
        existingObj.filterValue = intersection.length > 0 ? intersection : [];
      } else {
        mergedArray.push(objB);
      }
    }

    return mergedArray;
  };

  const handlePageChange = (pageSize, pageNumber) => {
    const topFiter = JSON.parse(JSON.stringify(topFiterData));
    const tableFilter = JSON.parse(JSON.stringify(tableFilterData));
    const sort = JSON.parse(JSON.stringify(tableSortData));
    const filterArr = concatFilters(topFiter, tableFilter);
    const getProcedureListPara = {
      filterFields: filterArr,
      sortFields: sort,
      taskId: currentTaskId,
      taskLabelName: currentTaskLabelName,
      pageSize: pageSize === null ? currentPage.pageSize : pageSize,
      pageIndex: pageNumber === null ? currentPage.pageNumber : pageNumber,
    };

    ProcedureListService.getProcedureList(
      getProcedureListPara,
      getProcedureListSuccess,
      getProcedureListError
    );
  };

  const handlePageNumberChange = (pageNumber, pageSize) => {
    setLoading(true);
    let newPageNumber = pageSize === currentPage.pageSize ? pageNumber : 1;
    setCurrentPage({
      pageSize: pageSize,
      pageNumber: newPageNumber,
    });
    handlePageChange(pageSize, newPageNumber);
  };

  const handleConlumnSelectUpdate = (key, value) => {
    const updateFunctions = {
      clientAbbrShow: () => setClientAbbrShow(value),
      clientNameShow: () => setClientNameShow(value),
      accountAbbrShow: () => setAccountAbbrShow(value),
      accountNameShow: () => setAccountNameShow(value),
      accountManagerShow: () => setAccountManagerShow(value),
      accountSupervisorShow: () => setAccountSupervisorShow(value),
      accountTeamLeaderShow: () => setAccountTeamLeaderShow(value),
      taskStatusShow: () => setTaskStatusShow(value),
      taskGroupNameShow: () => setTaskGroupNameShow(value),
      categoryNameShow: () => setCategoryNameShow(value),
      taskLabelNameShow: () => setTaskLabelNameShow(value),
      taskIdShow: () => setTaskIdShow(value),
      taskCreatedDateShow: () => setTaskCreatedDateShow(value),
      highRegulatedShow: () => setHighRegulatedShow(value),
      turnaroundTimeShow: () => setTurnaroundTimeShow(value),
      temporaryTaskShow: () => setTemporaryTaskShow(value),
      sharedTaskShow: () => setSharedTaskShow(value),
      sharedTaskIdsShow: () => setSharedTaskIdsShow(value),
      sharedTaskNamesShow: () => setSharedTaskNamesShow(value),
      procedureStatusShow: () => setProcedureStatusShow(value),
      pmpExcludedShow: () => setPmpExcludedShow(value),
      procedureSaveInCategoryShow: () => setProcedureSaveInCategoryShow(value),
      clientServerPathShow: () => setClientServerPathShow(value),
      primaryProcedureOwnerShow: () => setPrimaryProcedureOwnerShow(value),
      procedureOwnerShow: () => setProcedureOwnerShow(value),
      procedureFolderCreateDateShow: () =>
        setProcedureFolderCreateDateShow(value),
      lastUpdatedDateShow: () => setLastUpdatedDateShow(value),
      procedureFolderStatusShow: () => setProcedureFolderStatusShow(value),
    };

    const updateFunction = updateFunctions[key];
    updateFunction && updateFunction();
  };

  const handleColumnSearchSelect = (valueArr) => {
    for (let value of valueArr) {
      handleConlumnSelectUpdate(value, true);
    }
  };

  const handleColumnSearchDeSelect = (value) => {
    handleConlumnSelectUpdate(value, false);
  };

  const convertedTasks = (data) => {
    return data.map(
      ({
        taskStatus,
        highRegulated,
        temporaryTask,
        sharedTask,
        pmpExcluded,
        ...rest
      }) => ({
        ...rest,
        taskStatus: taskStatus ? "Active" : "Inactive",
        highRegulated: highRegulated ? "Yes" : "No",
        temporaryTask: temporaryTask ? "Yes" : "No",
        sharedTask: sharedTask ? "Yes" : "No",
        pmpExcluded: pmpExcluded ? "Yes" : "No",
      })
    );
  };

  const mapStatus = (status) => {
    let statusId;
    switch (status) {
      case "Active":
        statusId = 3;
        break;
      case "Pending Approval":
        statusId = 2;
        break;
      case "Draft":
        statusId = 1;
        break;
      case "Archived":
        statusId = 4;
        break;
      case "Updating":
        statusId = 7;
        break;
      default:
        statusId = 0;
        break;
    }

    return statusId;
  };

  const handleTableWidth = () => {
    if (
      searchAndColumnSelectRef.current?.state.columnSelectValue === undefined
    ) {
      return columnSelectValue.length * 220 + 160;
    }

    return (
      searchAndColumnSelectRef.current.state.columnSelectValue.length * 220 +
      160
    );
  };

  const setProcedureIdToList = (list) => {
    setProcedureListData(list);
  };

  /**
   * function about click confirm in assign ppo select options
   * @param {*} para submit api param
   * @param {*} submitSuccess submit success callback
   * @param {*} submitFaild submit error callback
   */
  const handleSubmitPPO = (para, submitSuccess, submitFaild) => {
    ProcedureListService.assignPrimaryProcedureOwner(para, submitSuccess, () =>
      submitFaild("Assign Primary Procedure Owner Error!")
    );
  };

  /**
   * function about get ppo option list in assign ppo select
   * @param {*} taskId procedureTaskId
   * @param {*} getListSuccess get success callback
   * @param {*} getListFaild get error callback
   */
  const getPpoOptionsList = (taskId, getListSuccess, getListFaild) => {
    ProcedureDetailService.getPrimaryProcedureOwnerDDL(
      taskId,
      getListSuccess,
      () => getListFaild("Get Primary Procedure Owner Failed")
    );
  };

  /**
   * function about control pmp excluded edit icon show and hide
   * @param {object} record row data
   */
  const controlPmpExcludedEditShow = (record) => {
    const loginUserRoles = JSON.parse(localStorage.getItem("user"))?.roles;
    const recordRoles = [];
    loginUserRoles.forEach((item) => {
      if (item.projectID === record.procedureAccountID) {
        recordRoles.push(item.roleID);
      }
    });
    const rolesList = [
      userRoles.Manager,
      userRoles.Opex,
      userRoles.Specialist,
      userRoles.TeamLeader,
      userRoles.Assistant,
    ];
    return (
      recordRoles.findIndex(
        (roleItem) =>
          (rolesList.findIndex((item) => item === roleItem) !== -1 &&
            record.pmpExcluded === "Yes") ||
          (roleItem === userRoles.AccountMember && record.pmpExcluded === "No")
      ) !== -1
    );
  };

  const getActiveInternalUsersSuccess = (result) => {
    const usersArr = [];
    result.map((item) => {
      const value = item.userID;
      const name = item.employeeFullName;
      usersArr.push({
        value,
        name,
      });
    });
    setActiveInternalUsers([...sortUsersList(usersArr)]);
    setLoading(false);
  };

  const getActiveInternalUsers = () => {
    ProcedureListService.getActiveInternalUserInfos(
      getActiveInternalUsersSuccess,
      () => console.log("Get Procedure Document List Failed")
    );
  };

  const getProcedureDocumentListSuccess = (result) => {
    const { procedureFiles, supportingFiles } = result;
    setProcedureFiles(procedureFiles);
    setSupportingFiles(supportingFiles);
    getActiveInternalUsers();
  };

  const downloadPDF = (base64String, filename) => {
    const link = document.createElement("a");
    link.href = `data:application/pdf;base64,${base64String}`;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getCoverSheetSuccess = (result) => {
    downloadPDF(result.content, "Cover Sheet.pdf");
  };

  const getCoverSheet = () => {
    ProcedureListService.getCoverSheet(idsParam, getCoverSheetSuccess, () =>
      console.log("Get Cover Sheet Failed")
    );
  };

  const getProcedureDocumentList = (para) => {
    ProcedureListService.getProcedureDocumentList(
      para,
      getProcedureDocumentListSuccess,
      () => console.log("Get Procedure Document List Failed")
    );
  };

  const handleDownloadClick = (record) => {
    const { procedureId, procedureTaskId } = record;
    const para = {
      ProcedureId: procedureId,
      ProcedureTaskId: procedureTaskId,
    };
    setIdsParam(para);
    setLoading(true);
    getProcedureDocumentList(para);
    setIsDownloadModalOpen(true);
  };

  const handleDownloadSubmit = (fileList) => {
    fileList.map((data) => {
      const para = {
        ProductKey: AppSetting.urlPrefix.addFile.ProductKey,
        fileId: data.fileId,
      };
      ProcedureDetailService.downloadFile(
        para,
        (response) => downloadFileSuccess(response, data.fileName),
        (response) => console.log(response)
      );
    });
    getCoverSheet();
    setIsDownloadModalOpen(false);
  };

  const handleShareClick = (record) => {
    const { procedureId, procedureTaskId } = record;
    const para = {
      ProcedureId: procedureId,
      ProcedureTaskId: procedureTaskId,
    };
    setLoading(true);
    getProcedureDocumentList(para);
    setShareData(record);
    setIsShareModalOpen(true);
  };

  const handleDownloadCancel = () => {
    setIsDownloadModalOpen(false);
    setProcedureFiles([]);
    setSupportingFiles([]);
  };

  const handleShareCancel = () => {
    setIsShareModalOpen(false);
    setProcedureFiles([]);
    setSupportingFiles([]);
  };

  const handleColumnFilterClick = (isOpen, currentField) => {
    if (!isOpen) return;

    const filterFieldList = Object.keys(procedureListFilterData);

    if (!filterFieldList.includes(currentField)) {
      setFilterLoading(true);
      const getProcedureFilterPara = {
        filterFields: topFiterData,
        taskId: taskId.trim(),
        taskLabelName: taskLabelName.trim(),
        CurrentField: currentField,
      };

      ProcedureListService.getProcedureFilter(
        getProcedureFilterPara,
        (result) => getProcedureFilterSuccess(result, currentField),
        getProcedureFilterError
      );
    }
  };

  const columnSelectValue = ProcedureListColumnSelectValue();

  const columnSelectOption = ProcedureListColumnSelectOption();

  const columns = ProcedureListListColumn({
    procedureListFilterData,
    filteredInfo,
    clientAbbrShow,
    clientNameShow,
    accountAbbrShow,
    accountNameShow,
    accountManagerShow,
    accountSupervisorShow,
    accountTeamLeaderShow,
    taskStatusShow,
    taskGroupNameShow,
    categoryNameShow,
    taskLabelNameShow,
    taskIdShow,
    taskCreatedDateShow,
    highRegulatedShow,
    turnaroundTimeShow,
    temporaryTaskShow,
    sharedTaskShow,
    sharedTaskIdsShow,
    sharedTaskNamesShow,
    procedureStatusShow,
    pmpExcludedShow,
    procedureSaveInCategoryShow,
    clientServerPathShow,
    primaryProcedureOwnerShow,
    procedureOwnerShow,
    procedureFolderCreateDateShow,
    lastUpdatedDateShow,
    procedureFolderStatusShow,
    handleSubmitPPO,
    setProcedureIdToList,
    getPpoOptionsList,
    controlPmpExcludedEditShow,
    procedureListData,
    mapStatus,
    tableSortData,
    handleDownloadClick,
    handleShareClick,
    handleColumnFilterClick,
    filterLoading,
  });

  const calculateHeight = () => {
    const screenHeight = window.innerHeight;
    const desiredHeight = screenHeight - 114 + 8;
    setProcedureListDivHeight(desiredHeight);
    var procedureTopFilterDiv = document.getElementById("procedureTopFilter");
    var procedureTopFilterDivHeight = procedureTopFilterDiv.offsetHeight;
    const tableContainer = document.querySelector(".ant-table-body");

    if (tableContainer) {
      const procedureListTableBodyHeight =
        desiredHeight - procedureTopFilterDivHeight - 191 + 8;
      setProcedureListTableBodyHeight(procedureListTableBodyHeight);
      tableContainer.style.height = `${procedureListTableBodyHeight}px`;
    }
  };

  useMount(() => {
    setLoading(true);
    calculateHeight();
    window.addEventListener("resize", calculateHeight);

    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  });

  return (
    <NormalSpin
      spinning={loading}
      size="large"
      children={
        <div
          className={styles.procedureListDiv}
          style={{ height: procedureListDivHeight }}
        >
          <div className={styles.procedureTopFilter} id="procedureTopFilter">
            <TopFilter
              // ref={topFilterRef}
              handleFilterClick={handleTopFilterClick}
              isOpex={isOpex}
            />
            <SearchAndColumnSelect
              ref={searchAndColumnSelectRef}
              columnSelectValue={columnSelectValue}
              columnSelectOption={columnSelectOption}
              handleSearch={handleSearchClick}
              handleExport={handleExportClick}
              taskLabelName={taskLabelName}
              taskId={taskId}
              handleTaskLabelNameChange={handleTaskLabelNameChange}
              handleTaskIdChange={handleTaskIdChange}
              handleColumnSearchSelect={handleColumnSearchSelect}
              handleColumnSearchDeSelect={handleColumnSearchDeSelect}
              showExport={true}
              exportDisabled={exporting}
              progress={progress}
              startAnimation={startAnimation}
            />
          </div>
          <div className={styles.tableDiv}>
            <ListTable
              ref={listTableRef}
              data={convertedTasks(procedureListData)}
              columns={columns}
              handleFilterSort={handleTableFilterSort}
              tableFilterMap={TableFilterMap}
              tableWidth={handleTableWidth()}
              tableHeight={procedureListTableBodyHeight}
            />
            <div className={styles.procedurelistFooter}>
              <FooterPagination
                totalRecord={totalRecord}
                totalPage={totalPage}
                currentPage={currentPage}
                handlePageNumberChange={handlePageNumberChange}
              />
            </div>
          </div>
          <DownloadDocument
            isModalOpen={isDownloadModalOpen}
            onSubmit={handleDownloadSubmit}
            onCancel={handleDownloadCancel}
            procedureFiles={procedureFiles}
            supportingFiles={supportingFiles}
          />
          <ShareDocument
            isModalOpen={isShareModalOpen}
            onCancel={handleShareCancel}
            procedureFiles={procedureFiles}
            supportingFiles={supportingFiles}
            shareData={shareData}
            activeInternalUsers={activeInternalUsers}
          />
        </div>
      }
    />
  );
}
