import BaseReadOnlyShareTask from "../baseReadOnlyShareTask";
import "../../../commonComponents/readOnlyTable/antReadOnlyTableCustom.scss";

export default class ApproveShareTaskReadOnly extends BaseReadOnlyShareTask {
  constructor(props) {
    super(props);
    this.state = this.initReadOnlyState(false);
  }

  handleReturnReasonLog = () => {
    return this.handleApproveReturnReasonLog();
  };

  handleSubmitReason = () => {
    return this.handleApproveSubmitReason();
  };
}
