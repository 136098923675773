import React, { createRef } from "react";
import NormalSpin from "../../../base/basicComponents/spin";
import {
  dataStatus,
  actions,
  downloadSingleFile,
} from "../../publicDictionaryValues";
import reasonEditDefaultIcon from "../../../assets/image/reasonEditIcon.png";
import styles from "../procedureFileProcess/style/index.module.scss";
import ShareProcessBase from "../../commonComponents/shareProcessBase";
import ReviseChangeMainTaskComplete from "./reviseChangeMainTaskComplete";
import ApproveChangeMainTaskProcess from "./approveChangeMainTask";
import ApproveChangeMainTaskComplete from "./approveChangeMainTaskComplete";
import SubmitChangeMainTaskComplete from "./submitChangeMainTaskComplete";
import ReviseChangeMainTaskProcess from "./reviseChangeMainTask";
import changeMainTaskService from "../../../service/changeMainTask/changeMainTaskService";

const procedureReplaceFileRef = createRef();
const procedureRef = createRef();
const procedureServerPathRef = createRef();
const procedureSavedInRef = createRef();
const approvePageRef = createRef();

export default class ChangeMainTaskProcess extends ShareProcessBase {
  constructor(props) {
    super(props);
    this.setGlobalVariables();
    this.initChangeMainTaskProcedureFileState();
    this.procedureFileStepID = null;
    this.savedInReturnReasonLog = [];
    this.serverPathReturnReasonLog = [];
    this.initProcedureTableData = [];
  }

  handleChangeMainTaskPageState = () => {
    return {
      requestByID: null,
      requestByName: null,
      receiveDate: null,
      procedureID: null,
      procedureStepID: null,
      procedureStatus: null,
      isSaveDisabled: false,
      isCancelDisabled: false,
      isSubmitDisabled: false,
      isRejectDisabled: false,
      procedureFolder: {},
      rejectReason: null,
      rejectStatus: null,
      currentRole: null,
      actionName: null,
      loading: false,
      procedureStepStatus: null,
      uploadProcedureFileNum: 0,
      procedureFileNum: 0,
      procedureNote: "",
    };
  };

  initChangeMainTaskProcedureFileState = () => {
    this.state = {
      pageState: this.handleChangeMainTaskPageState(),
      initTableDate: {},
      savedInSectionState: this.initSavedInSectionState,
      serverPathSectionState: this.initServerPathSectionState,
      procedureFileTableState: this.initProcedureFileTableState,
      isReturnReasonLogOpen: false,
      fileTableData: [],
      supportingTableData: [],
      taskInfoData: {},
      procedureInfoData: {},
      procedureFileData: {},
      shareTaskData: {},
      ppoList: [],
      jbProcedureSavedIn: null,
    };
  };

  handleChangeMainTaskSavedInSectionState = (result) => {
    return {
      ...this.state.savedInSectionState,
      savedInReturnReasonInputValue:
        result.procedureFolder?.procedureSaveInCategoryReturnReason || null,
      savedInRadioValue:
        result.procedureFolder?.procedureSaveInCategoryID || null,
      isSavedInReturned:
        result.procedureFolder?.procedureSaveInCategoryStatusID ===
        dataStatus.return,
      procedureSaveInCategoryStatusID:
        result.procedureFolder?.procedureSaveInCategoryStatusID || null,
      isSavedInApproveBtnDisabled:
        result.procedureFolder?.procedureSaveInCategoryStatusID ===
        dataStatus.approval,
      isSavedInReturnBtnDisabled:
        result.procedureFolder?.procedureSaveInCategoryStatusID ===
        dataStatus.return,
    };
  };

  handleChangeMainTaskServerPathSectionStateState = (result) => {
    return {
      ...this.state.serverPathSectionState,
      serverPathValue: result.procedureFolder?.clientServerPath || "",
      isServerPathReturned:
        result.procedureFolder?.clientServerPathStatusID === dataStatus.return,
      serverPathReturnReasonInputValue:
        result.procedureFolder?.clientServerPathReturnReason || null,
      clientServerPathStatusID:
        result.procedureFolder?.clientServerPathStatusID || null,
      clientServerPathStatusName:
        result.procedureFolder?.clientServerPathStatusName,
      clientServerPath: result.procedureFolder?.clientServerPath || "",
      isServerPathApproveBtnDisabled:
        result.procedureFolder?.clientServerPathStatusID ===
        dataStatus.approval,
      isServerPathReturnBtnDisabled:
        result.procedureFolder?.clientServerPathStatusID === dataStatus.return,
    };
  };

  setChangeMainTaskSavedInServerPathState = (result) => {
    this.setState({
      savedInSectionState: this.handleChangeMainTaskSavedInSectionState(result),
      serverPathSectionState:
        this.handleChangeMainTaskServerPathSectionStateState(result),
    });
  };

  setChangeMainTaskSavedInReturnReasonLog = (item, index) => {
    if (
      item.procedureSaveInCategoryStatusID === dataStatus.return &&
      item.actionID === actions.return
    ) {
      this.savedInReturnReasonLog.push({
        key: index,
        returnedBy: item.auditedByName,
        returnDate: this._formateDate(item.modifiedDate),
        returnReasonColumn: item.procedureSaveInCategoryReturnReason,
      });
    }
  };

  setChangeMainTaskServerPathReturnReasonLog = (item, index) => {
    if (
      item.clientServerPathStatusID === dataStatus.return &&
      item.actionID === actions.return
    ) {
      this.serverPathReturnReasonLog.push({
        key: index,
        returnedBy: item.auditedByName,
        returnDate: this._formateDate(item.modifiedDate),
        returnReasonColumn: item.clientServerPathReturnReason,
      });
    }
  };

  setChangeMainTaskSavedInAndServerPathReturnReasonLog = (list) => {
    list.sort((a, b) => {
      return b.modifiedDate.localeCompare(a.modifiedDate);
    });
    list.forEach((item, index) => {
      this.setChangeMainTaskSavedInReturnReasonLog(item, index);
      this.setChangeMainTaskServerPathReturnReasonLog(item, index);
    });
  };

  initChangeMainTaskServerPathSectionState = (result) => {
    this.initServerPathSectionState = {
      ...this.initServerPathSectionState,
      ...this.buildChangeMainTaskServerPathSectionState(result.procedureFolder),
    };
  };

  initChangeMainTaskSavedInSectionState = (result) => {
    this.initSavedInSectionState = {
      ...this.initSavedInSectionState,
      ...this.buildChangeMainTaskSavedInSectionState(result.procedureFolder),
    };
  };

  initChangeMainTaskPrimitiveServerPathSectionState = (lastStepHistoryData) => {
    this.primitiveServerPathSectionState = {
      ...this.primitiveServerPathSectionState,
      ...this.buildChangeMainTaskServerPathSectionState(lastStepHistoryData),
    };
  };

  initChangeMainTaskPrimitiveSavedInSectionState = (lastStepHistoryData) => {
    this.primitiveSavedInSectionState = {
      ...this.primitiveSavedInSectionState,
      ...this.buildChangeMainTaskSavedInSectionState(lastStepHistoryData),
    };
  };

  setInitChangeMainTaskProcedureTableData = () => {
    this.procedureTableData.forEach((item) => {
      this.initProcedureTableData.push({
        data: JSON.parse(JSON.stringify(item.data)),
        checked: item.checked,
        isApproveDisabled: item.isApproveDisabled,
        isReturnDisabled: item.isReturnDisabled,
        isReturned: item.isReturned,
        isError: item.isError,
        replaceRef: item.replaceRef,
        attachmentEditIcon: reasonEditDefaultIcon,
        isDelete: false,
        isUpdate: false,
      });
    });
  };

  setChangeMainTaskProcedureFileData = (result) => {
    if (result.procedureFolder !== null) {
      const historyList = result.procedureFolder?.stepHistoryList.sort((x, y) =>
        y.modifiedDate.localeCompare(x.modifiedDate)
      );

      this.initChangeMainTaskServerPathSectionState(result);

      this.initChangeMainTaskSavedInSectionState(result);

      if (historyList.length !== 0) {
        const lastStepHistoryData = historyList[0];
        this.initChangeMainTaskPrimitiveServerPathSectionState(
          lastStepHistoryData
        );
        this.initChangeMainTaskPrimitiveSavedInSectionState(
          lastStepHistoryData
        );
      }

      this.procedureFileStepID = result.procedureFolder.procedureFileStepID;
      this.setProcedureTableData(result.procedureFolder);
      this.setInitChangeMainTaskProcedureTableData();

      this.setState({
        fileTableData: this.procedureTableData,
        jbProcedureSavedIn: result.procedureFolder.jbProcedureSaveIn,
      });

      this.setChangeMainTaskSavedInAndServerPathReturnReasonLog(
        result.procedureFolder.stepHistoryList
      );
      this.setChangeMainTaskSavedInServerPathState(result);
    }
  };

  setProcedureFileData = (result) => {
    this.setChangeMainTaskProcedureFileData(result);
  };

  /**
   * Build SaveIn Section Data From ProcedureFolder
   * @param {*} data
   * @param {*} isRevise
   * @returns
   */
  buildChangeMainTaskSavedInSectionState = (data) => {
    return {
      savedInReturnReasonInputValue: data.procedureSaveInCategoryReturnReason,
      savedInRadioValue: data.procedureSaveInCategoryID,
      isSavedInReturned:
        data.procedureSaveInCategoryStatusID === dataStatus.return,
      procedureSaveInCategoryStatusID: data.procedureSaveInCategoryStatusID,
      isSavedInApproveBtnDisabled:
        data.procedureSaveInCategoryStatusID === dataStatus.approval,
      isSavedInReturnBtnDisabled:
        data.procedureSaveInCategoryStatusID === dataStatus.return,
    };
  };

  /**
   * Build ServerPath Section Data From ProcedureFolder
   * @param {*} data
   * @param {*} isRevise
   * @returns
   */
  buildChangeMainTaskServerPathSectionState = (data) => {
    return {
      ...this.primitiveServerPathSectionState,
      serverPathValue: data.clientServerPath,
      isServerPathReturned: data.clientServerPathStatusID === dataStatus.return,
      serverPathReturnReasonInputValue: data.clientServerPathReturnReason,
      clientServerPathStatusID: data.clientServerPathStatusID,
      clientServerPathStatusName: data.clientServerPathStatusName,
      clientServerPath: data.clientServerPath,
      isServerPathApproveBtnDisabled:
        data.clientServerPathStatusID === dataStatus.approval,
      isServerPathReturnBtnDisabled:
        data.clientServerPathStatusID === dataStatus.return,
    };
  };

  componentDidMount() {
    this.getInitData(
      changeMainTaskService.getChangeMainTaskStepItem,
      "Get Change Main Tasks Failed"
    );
  }

  /* function about get data from procedure file table */
  changeMainTaskReturnFileDataValue = (name, index) => {
    const procedureFileData = procedureRef.current.getDataList();
    let deleteList = procedureRef.current.getDeleteList();
    let combineDatalist = procedureFileData.concat(deleteList);
    return combineDatalist[index].data.find((item) => item.name === name);
  };

  setChangeMainTaskServerPathObj = () => {
    let serverPathValue = null;
    let serverPathStatus = null;
    let serverPathReturnReasonInputValue = null;

    if (procedureServerPathRef.current !== null) {
      const serverPathState =
        procedureServerPathRef.current.state.serverPathSectionState;
      serverPathValue = serverPathState.serverPathValue;
      serverPathStatus = serverPathState.serverPathStatus;
      serverPathReturnReasonInputValue =
        serverPathState.serverPathReturnReasonInputValue;
    }

    return {
      serverPathValue: serverPathValue,
      serverPathStatus: serverPathStatus,
      serverPathReturnReasonInputValue: serverPathReturnReasonInputValue,
    };
  };

  changeMainTaskSubmitDataPara = (fileDtos, actionID, actionName) => {
    const serverPathObj = this.setChangeMainTaskServerPathObj();

    const {
      savedInRadioValue,
      procedureSaveInCategoryStatusID,
      savedInReturnReasonInputValue,
    } = procedureSavedInRef.current.state.savedInSectionState;
    return {
      actionName: actionName,
      actionID: actionID,
      accountID: null,
      procedureId: this.procedureId,
      workflowProcessInstanceId: this.workflowProcessInstanceId,
      flowCategoryID: this.flowCategoryID,
      rejectReason:
        approvePageRef.current === null
          ? null
          : approvePageRef.current.state.pageState.rejectReason,
      procedureStepID: this.procedureStepID,
      isInitial: this.isRevisePage,
      procedureFiles: fileDtos,
      procedureFileStepID: this.procedureFileStepID,
      procedureSaveInCategoryID: savedInRadioValue,
      procedureSaveInCategoryStatusID: procedureSaveInCategoryStatusID,
      procedureSaveInCategoryReturnReason: savedInReturnReasonInputValue,
      clientServerPath: serverPathObj.serverPathValue,
      clientServerPathStatusID: serverPathObj.serverPathStatus,
      clientServerPathReturnReason:
        serverPathObj.serverPathReturnReasonInputValue,
    };
  };

  /* function about return submit data to use in integrate trigger api */
  changeMainTaskHandleReturnSubmitData = (actionID, actionName) => {
    const fileDtos = [];
    if (procedureRef.current !== null) {
      const procedureFileData = procedureRef.current.getDataList();
      let deleteList = procedureRef.current.getDeleteList();
      let combineDatalist = procedureFileData.concat(deleteList);
      combineDatalist.forEach((item, index) =>
        fileDtos.push({
          fileID:
            this.changeMainTaskReturnFileDataValue("newVersion", index)
              .fileId === null
              ? this.changeMainTaskReturnFileDataValue("fileId", index).value
              : this.changeMainTaskReturnFileDataValue("newVersion", index)
                  .fileId,
          createdDate: this.changeMainTaskReturnFileDataValue(
            "uploadedDate",
            index
          ).value,
          uploadBy: this.changeMainTaskReturnFileDataValue("uploadById", index)
            .value,
          uploadByName: this.changeMainTaskReturnFileDataValue(
            "uploadByName",
            index
          ).value,
          fileName:
            this.changeMainTaskReturnFileDataValue("newVersion", index)
              .value === null
              ? this.changeMainTaskReturnFileDataValue("fileName", index).value
              : this.changeMainTaskReturnFileDataValue("newVersion", index)
                  .value,
          fileKey: this.changeMainTaskReturnFileDataValue("fileKey", index)
            .value,
          dataStatusID: this.changeMainTaskReturnFileDataValue("status", index)
            .value,
          attachmentFileID: this.changeMainTaskReturnFileDataValue(
            "attachment",
            index
          ).fileId,
          attachmentName: this.changeMainTaskReturnFileDataValue(
            "attachment",
            index
          ).value,
          returnReason: this.changeMainTaskReturnFileDataValue(
            "returnReason",
            index
          ).value,
          fileTagID: this.changeMainTaskReturnFileDataValue("fileName", index)
            .tagValue,
          procedureFileStepID: this.changeMainTaskReturnFileDataValue(
            "procedureFileStepID",
            index
          ).value,
          procedureFileID: this.changeMainTaskReturnFileDataValue(
            "procedureFileID",
            index
          ).value,
          note: this.changeMainTaskReturnFileDataValue("note", index).value,
          oldFileID:
            this.changeMainTaskReturnFileDataValue("newVersion", index)
              .fileId === null
              ? null
              : this.changeMainTaskReturnFileDataValue("fileId", index).value,
          oldFileName:
            this.changeMainTaskReturnFileDataValue("newVersion", index)
              .value === null
              ? null
              : this.changeMainTaskReturnFileDataValue("fileName", index).value,
          isDelete: item.isDelete,
          isUpdate: item.isUpdate,
        })
      );
    }

    return this.changeMainTaskSubmitDataPara(fileDtos, actionID, actionName);
  };

  setSupportingFileData = (result) => {
    this.setSupportingTableData(result);
    this.setState({
      supportingTableData: this.supportingTableData,
    });
  };

  setChangeMainTaskPageType = () => {
    this.setSharePageType();
  };

  handleChangeMainTaskPageProps = () => {
    const {
      receiveDate,
      requestByName,
      procedureStepStatus,
      procedureStatus,
      rejectStatus,
      currentRole,
      rejectReason,
    } = this.state.pageState;
    return {
      receiveDate,
      requestByName,
      procedureStepStatus,
      procedureStatus,
      rejectStatus,
      currentRole,
      procedureReplaceFileRef,
      rejectReason,
      actionID: this.actionID,
    };
  };

  handleChangeMainTaskSectionProps = () => {
    const { taskInfoData, procedureInfoData } = this.state;

    return {
      taskInfoData,
      procedureInfoData,
      ppoList: this.state.ppoList,
    };
  };

  handleChangeMainTaskSavedInProps = () => {
    const {
      savedInReturnReasonInputValue,
      savedInReturnReasonValue,
      savedInReasonStatus,
      procedureSaveInCategoryStatusID,
      savedInRadioValue,
    } = this.state.savedInSectionState;

    return {
      savedInReturnReasonInputValue,
      savedInReturnReasonValue,
      savedInReasonStatus,
      procedureSaveInCategoryStatusID,
      savedInRadioValue,
      initSavedInStatus:
        this.initSavedInSectionState.procedureSaveInCategoryStatusID,
      savedInReturnReasonLog: this.savedInReturnReasonLog,
    };
  };

  handleChangeMainTaskServerPathProps = () => {
    const {
      clientServerPathStatusName,
      serverPathReturnReasonValue,
      serverPathReasonStatus,
      serverPathReturnReasonInputValue,
      clientServerPathStatusID,
      serverPathValue,
    } = this.state.serverPathSectionState;

    return {
      clientServerPathStatusName,
      serverPathReturnReasonValue,
      serverPathReasonStatus,
      serverPathReturnReasonInputValue,
      serverPathReturnReasonLog: this.serverPathReturnReasonLog,
      clientServerPathStatusID,
      serverPathValue,
    };
  };

  handleChangeMainTaskServerParams = () => {
    return {
      ProcedureTaskId: this.taskID,
      ProcedureID: this.procedureID,
      ProcedureStepID: this.procedureStepID,
      ProcedureFileStepID: this.procedureFileStepID,
      WorkflowProcessInstanceId: this.workflowProcessInstanceId,
      FlowCategoryID: this.flowCategoryID,
    };
  };

  handleChangeMainTaskRevisePendingPage = (procedureTableData) => {
    const { shareTaskData, jbProcedureSavedIn } = this.state;

    const { savedInRadioValue } = this.state.savedInSectionState;
    const { serverPathValue } = this.state.serverPathSectionState;

    return (
      <ReviseChangeMainTaskProcess
        pageProps={this.handleChangeMainTaskPageProps()}
        params={this.handleChangeMainTaskServerParams()}
        sectionProps={this.handleChangeMainTaskSectionProps()}
        savedInProps={this.handleChangeMainTaskSavedInProps()}
        serverPathProps={this.handleChangeMainTaskServerPathProps()}
        initSavedInSectionState={this.initSavedInSectionState}
        initServerPathSectionState={this.initServerPathSectionState}
        initProcedureFileTableState={this.initProcedureFileTableState}
        initProcedureTableData={[...this.initProcedureTableData]}
        procedureRef={procedureRef}
        procedureSavedInRef={procedureSavedInRef}
        procedureServerPathRef={procedureServerPathRef}
        _showLoading={this._showLoading}
        _hideLoading={this._hideLoading}
        data={procedureTableData}
        supportingTableData={this.state.supportingTableData}
        shareTaskData={shareTaskData}
        updateProcedureFileNum={this.updateProcedureFileNum}
        serverPathValue={serverPathValue}
        savedInRadioValue={savedInRadioValue}
        fileTableData={procedureTableData}
        jbProcedureSavedIn={jbProcedureSavedIn}
        downloadFile={downloadSingleFile}
        returnFileDataValue={this.changeMainTaskReturnFileDataValue}
        handleReturnSubmitData={this.changeMainTaskHandleReturnSubmitData}
        primitiveSavedInSectionState={this.primitiveSavedInSectionState}
        primitiveServerPathSectionState={this.primitiveServerPathSectionState}
        closePage={this.closePage}
        setProcedureFileData={this.setProcedureFileData}
        setSupportingFileData={this.setSupportingFileData}
        setTaskInfoData={this.setTaskInfoData}
        setProcedureInfoData={this.setProcedureInfoData}
        setShareTaskData={this.setShareTaskData}
      />
    );
  };

  handleChangeMainTaskReviseCompletePage = (procedureTableData) => {
    const {
      taskInfoData,
      procedureInfoData,
      shareTaskData,
      jbProcedureSavedIn,
    } = this.state;

    const {
      receiveDate,
      requestByName,
      procedureStatus,
      rejectStatus,
      rejectReason,
    } = this.state.pageState;

    const { savedInRadioValue } = this.state.savedInSectionState;
    const { serverPathValue } = this.state.serverPathSectionState;
    return (
      <ReviseChangeMainTaskComplete
        serverPathValue={serverPathValue}
        savedInRadioValue={savedInRadioValue}
        fileTableData={procedureTableData}
        pageProps={{
          receiveDate,
          requestByName,
          procedureStatus,
          rejectReason,
          rejectStatus,
          actionID: this.actionID,
        }}
        sectionProps={{
          taskInfoData,
          procedureInfoData,
          ppoList: this.state.ppoList,
        }}
        params={this.handleChangeMainTaskServerParams()}
        supportingTableData={this.state.supportingTableData}
        shareTaskData={shareTaskData}
        jbProcedureSavedIn={jbProcedureSavedIn}
        setProcedureFileData={this.setProcedureFileData}
        setSupportingFileData={this.setSupportingFileData}
        setTaskInfoData={this.setTaskInfoData}
        setProcedureInfoData={this.setProcedureInfoData}
        setShareTaskData={this.setShareTaskData}
      />
    );
  };

  handleChangeMainTaskApprovePendingPage = (procedureTableData) => {
    const { shareTaskData, jbProcedureSavedIn } = this.state;

    const { savedInRadioValue } = this.state.savedInSectionState;
    const { serverPathValue } = this.state.serverPathSectionState;

    return (
      <ApproveChangeMainTaskProcess
        ref={approvePageRef}
        sectionProps={this.handleChangeMainTaskSectionProps()}
        pageProps={this.handleChangeMainTaskPageProps()}
        serverPathProps={this.handleChangeMainTaskServerPathProps()}
        savedInProps={this.handleChangeMainTaskSavedInProps()}
        params={{
          ProcedureTaskId: this.taskID,
          ProcedureID: this.procedureID,
          ProcedureStepID: this.procedureStepID,
          ProcedureFileStepID: this.procedureFileStepID,
          WorkflowProcessInstanceId: this.workflowProcessInstanceId,
          FlowCategoryID: this.flowCategoryID,
        }}
        data={procedureTableData}
        supportingTableData={this.state.supportingTableData}
        initProcedureTableData={[...this.initProcedureTableData]}
        closePage={this.closePage}
        serverPathValue={serverPathValue}
        savedInRadioValue={savedInRadioValue}
        fileTableData={procedureTableData}
        jbProcedureSavedIn={jbProcedureSavedIn}
        shareTaskData={shareTaskData}
        procedureRef={procedureRef}
        procedureSavedInRef={procedureSavedInRef}
        procedureServerPathRef={procedureServerPathRef}
        _showLoading={this._showLoading}
        _hideLoading={this._hideLoading}
        updateProcedureFileNum={this.updateProcedureFileNum}
        downloadFile={downloadSingleFile}
        returnFileDataValue={this.changeMainTaskReturnFileDataValue}
        handleReturnSubmitData={this.changeMainTaskHandleReturnSubmitData}
        initProcedureFileTableState={this.initProcedureFileTableState}
        initSavedInSectionState={this.initSavedInSectionState}
        initServerPathSectionState={this.initServerPathSectionState}
        setProcedureFileData={this.setProcedureFileData}
        setSupportingFileData={this.setSupportingFileData}
        setTaskInfoData={this.setTaskInfoData}
        setProcedureInfoData={this.setProcedureInfoData}
        setShareTaskData={this.setShareTaskData}
      />
    );
  };

  handleChangeMainTaskApproveCompletePage = (procedureTableData) => {
    const {
      taskInfoData,
      procedureInfoData,
      shareTaskData,
      jbProcedureSavedIn,
    } = this.state;

    const {
      receiveDate,
      requestByName,
      procedureStatus,
      rejectStatus,
      rejectReason,
    } = this.state.pageState;

    const { savedInRadioValue } = this.state.savedInSectionState;
    const { serverPathValue } = this.state.serverPathSectionState;

    return (
      <ApproveChangeMainTaskComplete
        serverPathValue={serverPathValue}
        savedInRadioValue={savedInRadioValue}
        fileTableData={procedureTableData}
        pageProps={{
          receiveDate,
          requestByName,
          procedureStatus,
          rejectReason,
          rejectStatus,
          actionID: this.actionID,
        }}
        sectionProps={{
          taskInfoData,
          procedureInfoData,
          ppoList: this.state.ppoList,
        }}
        params={this.handleChangeMainTaskServerParams()}
        supportingTableData={this.state.supportingTableData}
        shareTaskData={shareTaskData}
        jbProcedureSavedIn={jbProcedureSavedIn}
        setProcedureFileData={this.setProcedureFileData}
        setSupportingFileData={this.setSupportingFileData}
        setTaskInfoData={this.setTaskInfoData}
        setProcedureInfoData={this.setProcedureInfoData}
        setShareTaskData={this.setShareTaskData}
      />
    );
  };

  handleChangeMainTaskSubmitCompletePage = (procedureTableData) => {
    const {
      taskInfoData,
      procedureInfoData,
      shareTaskData,
      jbProcedureSavedIn,
    } = this.state;

    const {
      receiveDate,
      requestByName,
      procedureStatus,
      rejectStatus,
      rejectReason,
    } = this.state.pageState;

    const { savedInRadioValue } = this.state.savedInSectionState;
    const { serverPathValue } = this.state.serverPathSectionState;

    return (
      <SubmitChangeMainTaskComplete
        serverPathValue={serverPathValue}
        savedInRadioValue={savedInRadioValue}
        fileTableData={procedureTableData}
        pageProps={{
          receiveDate,
          requestByName,
          procedureStatus,
          rejectReason,
          rejectStatus,
          actionID: this.actionID,
        }}
        sectionProps={{
          taskInfoData,
          procedureInfoData,
          ppoList: this.state.ppoList,
        }}
        params={this.handleChangeMainTaskServerParams()}
        shareTaskData={shareTaskData}
        supportingTableData={this.state.supportingTableData}
        jbProcedureSavedIn={jbProcedureSavedIn}
        setProcedureFileData={this.setProcedureFileData}
        setSupportingFileData={this.setSupportingFileData}
        setTaskInfoData={this.setTaskInfoData}
        setProcedureInfoData={this.setProcedureInfoData}
        setShareTaskData={this.setShareTaskData}
      />
    );
  };

  setChangeMainTaskProcedureTableData = () => {
    return JSON.parse(JSON.stringify(this.state.fileTableData));
  };

  render() {
    const procedureTableData = this.setChangeMainTaskProcedureTableData();

    this.setChangeMainTaskPageType();

    return (
      <NormalSpin
        spinning={this.state.pageState.loading}
        size="large"
        indicator={
          this.state.pageState.procedureFileNum > 0 ? (
            <p className={styles.loadingText}>
              uploading {this.state.pageState.uploadProcedureFileNum}/
              {this.state.pageState.procedureFileNum} file
            </p>
          ) : undefined
        }
        children={
          <>
            {this.isRevisePendingPage &&
              this.handleChangeMainTaskRevisePendingPage(procedureTableData)}
            {this.isReviseCompletePage &&
              this.handleChangeMainTaskReviseCompletePage(procedureTableData)}
            {this.isApprovePendingPage &&
              this.handleChangeMainTaskApprovePendingPage(procedureTableData)}
            {this.isApproveCompletePage &&
              this.handleChangeMainTaskApproveCompletePage(procedureTableData)}
            {this.isSubmitCompletePage &&
              this.handleChangeMainTaskSubmitCompletePage(procedureTableData)}
          </>
        }
      />
    );
  }
}
