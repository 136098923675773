import { createRef } from "react";
import { withRouter } from "react-router-dom";
import StatusDiv from "../../../../base/basicComponents/status/index";
import NormalSpin from "../../../../base/basicComponents/spin";
import ProcedureBlock from "../../../../base/basicComponents/procedureBlock";
import ProcedureFileSection from "../../../sections/procedureFileSections/procedureFileSection";
import SupportingFileSection from "../../../sections/supportingFileSections/supportingFileSection";
import ProcedureInfoSection from "../../../sections/procedureInfoSections/procedureInfoSection";
import TaskInformation from "../../../sections/taskInfoSections";
import ShareTask from "../../../sections/sharedTaskSections";
import ProcedureDetailService from "../../../../service/procedureFile/ProcedureDetailService";
import styles from "./style/index.module.scss";
import "./style/index.scss";
import RoutingComponent from "../../../../base/common/RoutingComponent";

const procedureRef = createRef();
const supportingRef = createRef();
const procedureInfoRef = createRef();
class CreateProcedureDetail extends RoutingComponent {
  constructor(props) {
    super(props);
    this.state = {
      status: 0,
      isMounted: false,
      procedureFlientPathDisabled: false,
      taskInfoData: [],
      closable: false,
      taskInformationData: {},
      loading: false,
      hintMsg: "",
      timer: 0,
      isShareTaskEditToolTipOpen: false,
      uploadProcedureFileNum: 0,
      uploadSupportingFileNum: 0,
      procedureId:
        this.props.procedureId ?? this._getRoutingParam("ProcedureId"),
      procedureTaskId:
        this.props.procedureTaskId ?? this._getRoutingParam("ProcedureTaskId"),
      openSection:
        this.props.openSection ?? this._getRoutingParam("OpenSection"),
      procedureAccountId: null,
      isProcedureFileModalChange: false,
      isSupportingFileModalChange: false,
      isProcedureInfoModalChange: false,
      fileLoading: { currentNum: 0, allNum: 0 },
      procedureJbStatusId: null,
    };

    this.procedureFileNum = 0;
    this.supportingFileNum = 0;
    this.restoreTime = false;
    this.actionType = null;
  }

  setProcedureJbStatusId = (procedureJbStatusId) => {
    this.setState({
      procedureJbStatusId: procedureJbStatusId,
    });
  };

  handleProcedureFileUploadLoading = () => {
    if (this.state.uploadProcedureFileNum === this.procedureFileNum) {
      this.procedureFileNum = 0;
      this.setState({
        uploadProcedureFileNum: 0,
        loading: false,
      });
    }
  };

  setUploadProcedureFileNum = () => {
    this.setState({
      uploadProcedureFileNum: this.state.uploadProcedureFileNum + 1,
    });
  };

  setProcedureFileNum = () => {
    this.procedureFileNum += 1;
  };

  handleSupportingFileUploadLoading = () => {
    if (this.state.uploadSupportingFileNum === this.supportingFileNum) {
      this.supportingFileNum = 0;
      this.setState({
        uploadSupportingFileNum: 0,
        loading: false,
      });
    }
  };

  setUploadSupportingFileNum = () => {
    this.setState({
      uploadSupportingFileNum: this.state.uploadSupportingFileNum + 1,
    });
  };

  setSupportingFileNum = () => {
    this.supportingFileNum += 1;
  };

  _showLoading = () => {
    this.setState({
      loading: true,
    });
  };

  _hideLoading = () => {
    this.setState({
      loading: false,
    });
  };

  _getTaskInfoSuccess = (result) => {
    const data = result;
    this.setState({
      taskInformationData: data,
      procedureAccountId: data.procedureAccountID,
    });
    this.initSection(data.procedureAccountID);
  };

  _getTaskInfoFailed = (result) => {
    console.log(result);
  };

  setStatus = (status) => {
    this.setState({
      isMounted: true,
      status: status,
    });
  };

  _procedureSaveSuccess = (result) => {
    this.setState({
      procedureId: result.procedureId,
      procedureFileStepId: result.procedureFileStepId,
      isMounted: true,
      loading: false,
    });
    this._alertSuccess("Save Successful");
    this.setStatus(result.procedureFolderStatus);
  };

  _procedureSubmitSuccess = (result) => {
    this.setState({
      isMounted: true,
      status: result.procedureFolderStatus,
      loading: false,
      procedureId: this.props.procedureId
        ? this.props.procedureId
        : result.procedureId,
    });
    this._alertSuccess("Submit Successful");
  };

  _supportingSaveSuccess = (result) => {
    this.setState({
      loading: false,
      procedureId: result.procedureId,
    });
  };

  _supportingSubmitSuccess = (result) => {
    this.setState({
      loading: false,
      procedureId: this.props.procedureId
        ? this.props.procedureId
        : result.procedureId,
    });
  };

  /* callback when call submit information api success */
  _procedureInfoSubmitSuccess = (response) => {
    this.setState({
      procedureId: this.props.procedureId
        ? this.props.procedureId
        : response.procedureId,
    });
  };

  _getPrimaryProcedureOwnerDDLSuccess = (result) => {
    procedureInfoRef.current.setPpoList(result);
  };

  _getPrimaryProcedureOwnerDDLFailed = () => {
    console.log("Get Primary Procedure Owner Failed");
  };

  setLock = (msg) => {
    this.setState({
      hintMsg: msg,
    });
  };

  getProcedureSectionError = () => {
    console.log("Get procedure section data error!");
  };

  getTaskInfo = () => {
    ProcedureDetailService.getTaskInfo(
      this.state.procedureTaskId,
      this._getTaskInfoSuccess,
      this._getTaskInfoFailed
    );
  };

  getPrimaryProcedureOwnerDDL = () => {
    ProcedureDetailService.getPrimaryProcedureOwnerDDL(
      this.state.procedureTaskId,
      this._getPrimaryProcedureOwnerDDLSuccess,
      this._getPrimaryProcedureOwnerDDLFailed
    );
  };

  getProcedureProcessStatus = () => {
    ProcedureDetailService.getProcedureProcessStatus(
      { procedureId: this.state.procedureId },
      (response) => this.setStatus(response.procedureProcessStatus),
      () => console.log("Get status error!")
    );
  };

  pageEvent = () => {
    window.onbeforeunload = () => {
      if (this.actionType === null) {
        return;
      }
      this._setLock(this.state.procedureId, this.actionType, false);
    };
  };

  initSection = (procedureAccountId) => {
    procedureRef.current !== null && procedureRef.current.createFiles();
    supportingRef.current !== null && supportingRef.current.createFiles();
    procedureInfoRef.current !== null &&
      procedureInfoRef.current.createProcedureInfoData(
        null,
        procedureAccountId
      );
  };

  componentDidMount() {
    this.getTaskInfo();
    this.getPrimaryProcedureOwnerDDL();
    if (this.state.procedureId) {
      this.getProcedureProcessStatus();
    }
    this.pageEvent();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.isProcedureFileModalChange !==
      prevState.isProcedureFileModalChange
    ) {
      procedureRef.current.createFiles();
    } else if (
      this.state.isSupportingFileModalChange !==
      prevState.isSupportingFileModalChange
    ) {
      supportingRef.current.createFiles();
    } else if (
      this.state.isProcedureInfoModalChange !==
      prevState.isProcedureInfoModalChange
    ) {
      procedureInfoRef.current.createProcedureInfoData();
    }
  }

  handleUploadingFileNum = (
    uploadProcedureFileNum,
    uploadSupportingFileNum
  ) => {
    if (uploadProcedureFileNum > 0) {
      return uploadProcedureFileNum;
    }

    return uploadSupportingFileNum > 0 ? uploadSupportingFileNum : 0;
  };

  handleUploadingFileList = (
    uploadProcedureFileNum,
    uploadSupportingFileNum
  ) => {
    if (uploadProcedureFileNum > 0) {
      return procedureRef.current.getFileList();
    }

    return uploadSupportingFileNum > 0
      ? supportingRef.current.getFileList()
      : [];
  };

  handleReturnShareTask = () => (
    <ShareTask
      actionType="ShareTask"
      isEditToolTipOpen={this.state.isShareTaskEditToolTipOpen}
      onBlur={() => this.setScrollModalOpen(false)}
      procedureId={this.props.procedureId}
      procedureTaskId={this.props.procedureTaskId}
      showLoading={this._showLoading}
      hideLoading={this._hideLoading}
      setActionType={this.setActionType}
    />
  );

  checkShareTaskWhetherLock = (isLock, lockUser) => {
    if (isLock) {
      this.setScrollModalOpen(true);
      this.setState({
        isLocked: true,
        lockUser: lockUser,
      });
    } else {
      this.setState({
        isShareTaskEditToolTipOpen: false,
      });
    }
  };

  setActionType = (actionType) => {
    this.actionType = actionType;
  };

  setIsProcedureFileModalChange = () => {
    this.setState({
      isProcedureFileModalChange: true,
    });
  };

  setIsSupportingFileModalChange = () => {
    this.setState({
      isSupportingFileModalChange: true,
    });
  };

  setIsProcedureInfoModalChange = () => {
    this.setState({
      isProcedureInfoModalChange: true,
    });
  };

  /**
   * Change update attachment loading ui
   * @param {*} fileLoading
   */
  updateFileNumLoading = (fileLoading) => {
    this.setState({
      fileLoading: fileLoading,
      loading: fileLoading.allNum !== fileLoading.currentNum,
    });
  };

  render() {
    const {
      isMounted,
      status,
      taskInformationData,
      loading,
      uploadProcedureFileNum,
      uploadSupportingFileNum,
      hintMsg,
      isShowSharedTaskEdit,
      procedureId,
      procedureTaskId,
      openSection,
      procedureAccountId,
    } = this.state;

    const { title, pageType, procedureFilesRef } = this.props;

    const isShowTable = procedureFilesRef !== undefined ? isMounted : true;

    const uploadingFileNum = this.handleUploadingFileNum(
      uploadProcedureFileNum,
      uploadSupportingFileNum
    );

    const fileList = this.handleUploadingFileList(
      uploadProcedureFileNum,
      uploadSupportingFileNum
    );

    const currentPageType =
      pageType !== undefined ? pageType : "createProcedure";

    return (
      <NormalSpin
        spinning={loading}
        size="large"
        indicator={
          uploadingFileNum > 0 ? (
            <p className="loadingText">
              uploading {uploadingFileNum}/{fileList.length} file
            </p>
          ) : this.state.fileLoading.allNum > 0 ? (
            <p className="loadingText">
              uploading {this.state.fileLoading.currentNum}/
              {this.state.fileLoading.allNum} file
            </p>
          ) : undefined
        }
        children={
          <>
            <div className={styles.body}>
              <div className={styles.header}>
                <div className={styles.headerTitleDiv}>
                  <div className={styles.title}>
                    {title ? title : "Create Procedure Detail"}
                  </div>
                  {isMounted && <StatusDiv status={status}></StatusDiv>}
                </div>
              </div>
              <ProcedureBlock
                child={<TaskInformation data={taskInformationData} />}
                isShowArrow={false}
                className="processProcedureBlock"
                isOpen={true}
              />
              <ProcedureFileSection
                ref={procedureRef}
                isMounted={isMounted}
                isShowTable={isShowTable}
                hintMsg={hintMsg}
                procedureId={procedureId}
                procedureTaskId={procedureTaskId}
                procedureAccountId={procedureAccountId}
                openSection={openSection}
                showLoading={this._showLoading}
                hideLoading={this._hideLoading}
                actionType="ProcedureFile"
                setActionType={this.setActionType}
                setIsProcedureFileModalChange={
                  this.setIsProcedureFileModalChange
                }
                procedureSubmitSuccess={this._procedureSubmitSuccess}
                procedureSaveSuccess={this._procedureSaveSuccess}
                setLock={this.setLock}
                handleUploadLoading={this.handleProcedureFileUploadLoading}
                setUploadFileNum={this.setUploadProcedureFileNum}
                setFileNum={this.setProcedureFileNum}
                pageType={currentPageType}
                procedureStatus={status}
                isHighlyRequlatedTask={taskInformationData.highRegulated}
                procedureAccountID={taskInformationData.procedureAccountID}
                updateFileNumLoading={this.updateFileNumLoading}
                taskInformationData={taskInformationData}
              />
              <SupportingFileSection
                ref={supportingRef}
                isMounted={isMounted}
                isShowTable={isShowTable}
                hintMsg={hintMsg}
                procedureId={procedureId}
                procedureTaskId={procedureTaskId}
                procedureAccountId={procedureAccountId}
                showLoading={this._showLoading}
                hideLoading={this._hideLoading}
                actionType="SupportingFile"
                setActionType={this.setActionType}
                setIsSupportingFileModalChange={
                  this.setIsSupportingFileModalChange
                }
                submitSuccess={this._supportingSubmitSuccess}
                saveSuccess={this._supportingSaveSuccess}
                setLock={this.setLock}
                handleUploadLoading={this.handleSupportingFileUploadLoading}
                setUploadFileNum={this.setUploadSupportingFileNum}
                setFileNum={this.setSupportingFileNum}
                pageType={currentPageType}
                taskInformationData={taskInformationData}
              />
              <ProcedureInfoSection
                ref={procedureInfoRef}
                isMounted={isMounted}
                hintMsg={hintMsg}
                procedureId={procedureId}
                procedureTaskId={procedureTaskId}
                procedureAccountId={procedureAccountId}
                setLock={this.setLock}
                showLoading={this._showLoading}
                hideLoading={this._hideLoading}
                actionType="ProcedureInfo"
                setActionType={this.setActionType}
                setIsProcedureInfoModalChange={
                  this.setIsProcedureInfoModalChange
                }
                procedureInfoSubmitSuccess={this._procedureInfoSubmitSuccess}
                setProcedureJbStatusId={this.setProcedureJbStatusId}
              />
              <ProcedureBlock
                child={this.handleReturnShareTask(
                  isShowSharedTaskEdit,
                  "Shared Tasks"
                )}
                isShowArrow={false}
                className="processProcedureBlock"
                isOpen={true}
              />
            </div>
          </>
        }
      />
    );
  }
}

export default withRouter(CreateProcedureDetail);
