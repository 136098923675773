import React from "react";
import { Modal, Input } from "antd";
import Buttons from "../../../../base/basicComponents/button/index";
import ShareTaskTable from "../shareTaskTable";
import BaseComponent from "../../../../base/common/BaseComponent";
import AddNewShareTask from "../addNewShareTask";
import requiredWarning from "../../../../assets/image/required-warning.png";
import styles from "./index.module.scss";
import controlPageBehavior from "../../../../base/basicComponents/modal/controlPageBehavior";
import "../share.scss";
import fullScreenIcon from "../../../../assets/image/fullScreen.png";
import fullScreenHoverIcon from "../../../../assets/image/fullScreenHover.png";
import miniScreenIcon from "../../../../assets/image/miniScreen.png";
import miniScreenHoverIcon from "../../../../assets/image/miniScreenHover.png";

const { TextArea } = Input;

class ShareTaskModal extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      saveDisabled: true,
      submitDisabled: true,
      isExpendData: false,
      iconSrc: fullScreenIcon,
    };
  }

  handleDefaultExpendIcon = () => {
    this.setState({
      iconSrc: this.state.isExpendData ? miniScreenIcon : fullScreenIcon,
    });
  };

  handleHoverExpendIcon = () => {
    this.setState({
      iconSrc: this.state.isExpendData
        ? miniScreenHoverIcon
        : fullScreenHoverIcon,
    });
  };

  handleExpendFlag = () => {
    const flag = !this.state.isExpendData;
    this.setState({ isExpendData: flag });
  };

  setFileText = (num) => (num <= 1 ? "task" : "tasks");

  sharedTaskTableListControlIcon = () => {
    const { isExpendData, iconSrc } = this.state;
    return (
      <p className="listControlIcon">
        <img
          src={iconSrc}
          alt={isExpendData ? "pick up" : "show all"}
          onMouseEnter={this.handleHoverExpendIcon}
          onMouseLeave={this.handleDefaultExpendIcon}
          onClick={this.handleExpendFlag}
        />
      </p>
    );
  };

  initTableExpendStatus = () => {
    this.setState({
      isExpendData: false,
      iconSrc: fullScreenIcon,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isModalOpen !== this.props.isModalOpen) {
      this.initTableExpendStatus();
      if (this.props.isModalOpen) {
        const modalElement = document.getElementsByClassName(
          "manage-shared-tasks-modal"
        )[0];
        controlPageBehavior.addEleListener(modalElement);
        controlPageBehavior.resetListener(modalElement, this.props.refreshPage);
      } else {
        controlPageBehavior.clearListener();
      }
    }
  }

  render() {
    const {
      isModalOpen,
      onSubmit,
      onCancel,
      tableData,
      tableColumn,
      accountSelectOption,
      accountSelectValue,
      accountSelectOnChange,
      taskTreePathOption,
      taskTreePathValue,
      taskTreePathOnSearch,
      taskTreePathOnChange,
      addTaskOnClick,
      clearAddTaskOnClick,
      isAddNewShareTaskShow,
      showAddNewShareTask,
      submitDisabled,
      reseanValue,
      reseanOnChange,
      hasDeleteTag,
      reasonInputStatus,
      zIndex,
    } = this.props;

    const footer = (
      <div className={styles.buttonDiv}>
        <Buttons color="grey" size="middle" onClick={onCancel}>
          Cancel
        </Buttons>
        <Buttons
          color="blue"
          disabled={submitDisabled}
          size="middle"
          onClick={onSubmit}
        >
          Submit for Approval
        </Buttons>
      </div>
    );

    return (
      <Modal
        title="Manage Shared Tasks"
        centered
        open={isModalOpen}
        footer={footer}
        closable={false}
        maskClosable={false}
        width="100rem"
        zIndex={zIndex}
        className="manage-shared-tasks-modal antModal"
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
      >
        {tableData.length > 4 && (
          <div className="sharedTaskTableTop">
            {this.sharedTaskTableListControlIcon()}
          </div>
        )}
        <ShareTaskTable
          data={tableData}
          columns={tableColumn}
          scroll={{
            y:
              this.state.isExpendData || tableData.length <= 4
                ? null
                : "13.75rem",
          }}
        />
        <p className="fileNumTip" style={{ color: "#333" }}>
          <span className="fileNum">{tableData.length}</span>{" "}
          {this.setFileText(tableData.length)} in total
        </p>
        <AddNewShareTask
          isAddNewShareTaskShow={isAddNewShareTaskShow}
          showAddNewShareTask={showAddNewShareTask}
          accountSelectOption={accountSelectOption}
          accountSelectValue={accountSelectValue}
          accountSelectOnChange={accountSelectOnChange}
          taskTreePathOption={taskTreePathOption}
          taskTreePathValue={taskTreePathValue}
          taskTreePathOnSearch={taskTreePathOnSearch}
          taskTreePathOnChange={taskTreePathOnChange}
          addTaskOnClick={addTaskOnClick}
          clearAddTaskOnClick={clearAddTaskOnClick}
        />
        <p className={`${styles.reasonTitle} fs-14`}>
          Reason
          {hasDeleteTag && (
            <img
              src={requiredWarning}
              alt="required"
              className={styles.requiredWarning}
            />
          )}
        </p>
        <div className={styles.reasonInputDiv}>
          <TextArea
            className="antTextare"
            autoSize={{
              minRows: 4,
              maxRows: 6,
            }}
            maxLength={1000}
            showCount={true}
            value={reseanValue}
            onChange={reseanOnChange}
            status={reasonInputStatus}
          />
        </div>
      </Modal>
    );
  }
}

export default ShareTaskModal;
