import React from "react";
import BaseComponent from "../../../base/common/BaseComponent";
import styles from "./index.module.scss";

export default class NoPermissionPage extends BaseComponent {
    
    render() {
        return (
            <div className={styles.messageWrapper}>
                <div className={styles.messages}>
                    <div>You don't have permission to access this page.</div>
                </div>
            </div>
        );
    }
}