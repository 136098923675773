import { Tooltip, Switch, Popover } from "antd";
import BaseReviseShare from "../baseReviseShare";
import ShareTaskTable from "../shareTaskTable/index";
import AddNewShareTask from "../addNewShareTask";
import InputLabel from "../../../../base/basicComponents/inputLabel";
import { ChangeMainTaskTag } from "../../../../base/basicComponents/status";
import changeMainTaskService from "../../../../service/changeMainTask/changeMainTaskService";
import styles from "../index.module.scss";
import approveStyles from "../approveShareTask/approveShareTask.module.scss";
import reviseStyles from "./reviseChangeMainTask.module.scss";
import "../../../commonComponents/readOnlyTable/antReadOnlyTableCustom.scss";
import redTipIcon from "../../../../assets/image/redTipIcon.png";

export default class ReviseChangeMainTask extends BaseReviseShare {
  constructor(props) {
    super(props);
    this.deleteData = [];
    this.state = this.initState(true, true);
  }

  ReviseChangeMainTaskModalTableOnRevoke = (record) => {
    let data = JSON.parse(JSON.stringify(this.state.reviseData));
    const index = data.findIndex((x) => x.taskId === record.taskId);
    data[index] = { ...data[index], isDeleted: false, isCurrentChange: true };
    this.setState({
      reviseData: data,
    });
  };

  ModalTableOnDelete = (record) => {
    let data = JSON.parse(JSON.stringify(this.state.reviseData));
    const index = data.findIndex((x) => x.taskId === record.taskId);
    if (record.isNew) {
      if (record.isCurrentLoad) {
        this.deleteData.push(data[index]);
      }
      data.splice(index, 1);
      let oldMainTask = data.find((x) => x.isOldMainTask === true);
      if (oldMainTask) {
        oldMainTask.isOldMainTask = false;
        oldMainTask.isMainTask = true;
        oldMainTask.isDeleted = false;
        oldMainTask.isCurrentChange = true;
        this.setState({
          reviseData: data,
          addNewSelectDisable: false,
          switchDisable: false,
        });
      }
    } else {
      data[index] = { ...data[index], isDeleted: true, isCurrentChange: true };
      this.setState({
        reviseData: data,
      });
    }
  };

  CheckHasNew = (data) => {
    return data.some((item) => item.isNew === true);
  };

  ShowAddNewShareTask = () => {
    if (this.state.addNewSelectDisable) {
      return;
    }
    this.setState({
      isAddNewShareTaskShow: true,
    });
  };

  AddTaskOnClickOnSuccess = (result) => {
    const newLine = { ...result, isNew: true, isCurrentChange: true };
    let currentModalTableData = JSON.parse(
      JSON.stringify(this.state.reviseData)
    );
    const duplicateLineIndex = currentModalTableData.findIndex(
      (x) => x.taskId === newLine.taskId
    );
    if (duplicateLineIndex > -1) {
      this._alertError("This Task already exists.");
      this.setState({
        taskTreePathValue: null,
      });
      return;
    }
    currentModalTableData.push(newLine);
    currentModalTableData = this.HandleSwitchMainTaskTableData(
      currentModalTableData,
      newLine.taskId
    );
    this.setState({
      reviseData: currentModalTableData,
      addNewSelectDisable: true,
      switchDisable: true,
      isAddNewShareTaskShow: false,
      taskTreePathValue: null,
      accountSelectValue: this.state.accountSelectOption[0].value,
    });
    this.setTaskTreePathDDL(this.state.accountSelectOption[0].value);
  };

  AddTaskOnClickOnError = (result) => {};

  AddTaskOnClick = () => {
    const para = this.state.taskTreePathValue;
    changeMainTaskService.getRowData(
      para,
      this.AddTaskOnClickOnSuccess,
      this.AddTaskOnClickOnError
    );
  };

  GetAccountDDl = () => {
    const accountPara = {
      procedureTaskId: this.props.procedureTaskId,
      procedureStepId: null,
    };
    changeMainTaskService.getAccountDDL(
      accountPara,
      this.GetAccountDDLOnSuccess,
      this.GetAccountDDLOnError
    );
  };

  updateReasonInputErrorStatus = () => {
    this.setState({
      reasonInputStatus: "error",
    });
  };

  resetReviseData = () => {
    this.deleteData = [];
    this.setState({
      reviseData: this.state.initReviseData,
      isAddNewShareTaskShow: false,
      accountSelectValue: null,
      taskTreePathValue: null,
      reasonValue: "",
      resonRequired: true,
      dataHasChange: false,
      reasonInputStatus: "",
    });
  };

  HandleSwitchMainTaskTableData = (tableData, taskId) => {
    const newMainTask = tableData.find((item) => item.isMainTask === true);

    if (newMainTask) {
      newMainTask.isMainTask = false;

      if (
        newMainTask.hasOwnProperty("isNewMainTask") &&
        newMainTask.isNewMainTask === true
      ) {
        newMainTask.isNewMainTask = false;
        newMainTask.isOldMainTask = null;
        newMainTask.isCurrentChange = true;
      } else {
        newMainTask.isOldMainTask = true;
        newMainTask.isCurrentChange = true;
      }
    }

    const selectedTask = tableData.find((item) => item.taskId === taskId);

    if (selectedTask) {
      selectedTask.isMainTask = true;

      if (
        selectedTask.hasOwnProperty("isOldMainTask") &&
        selectedTask.isOldMainTask === true
      ) {
        selectedTask.isOldMainTask = false;
        selectedTask.isDeleted = false;
        selectedTask.isCurrentChange = true;
      } else {
        selectedTask.isNewMainTask = true;
        selectedTask.isOldMainTask = false;
        selectedTask.isCurrentChange = true;
      }
    }

    return tableData;
  };

  SwitchMainTask = (checked, e, record) => {
    if (!checked) {
      return;
    }
    let tableData = JSON.parse(JSON.stringify(this.state.reviseData));
    const currentMainTask = tableData.find((item) => item.isMainTask === true);

    if (currentMainTask && currentMainTask.taskId === record.taskId) {
      return;
    }

    tableData = this.HandleSwitchMainTaskTableData(tableData, record.taskId);

    this.setState({
      reviseData: tableData,
    });
  };

  reviseCHangeMainTaskDataHasChange = () => {
    let tableData = JSON.parse(JSON.stringify(this.state.reviseData));
    let initData = JSON.parse(JSON.stringify(this.state.initReviseData));
    if (tableData.length !== initData.length) {
      return true;
    }

    tableData.sort((a, b) => a.taskId - b.taskId);
    initData.sort((a, b) => a.taskId - b.taskId);

    for (let i = 0; i < tableData.length; i++) {
      const item1 = tableData[i];
      const item2 = initData[i];

      if (
        item1.isMainTask !== item2.isMainTask ||
        item1.isNew !== item2.isNew ||
        item1.isOldMainTask !== item2.isOldMainTask ||
        item1.isNewMainTask !== item2.isNewMainTask ||
        item1.isDeleted !== item2.isDeleted
      ) {
        return true;
      }
    }

    return false;
  };

  dataHasChange = () => {
    return this.reviseCHangeMainTaskDataHasChange();
  };

  checkHasNewTag = (reviseData) => {
    let initData = JSON.parse(JSON.stringify(reviseData));
    const newDataIndex = initData.findIndex((item) => item.isNew === true);
    if (newDataIndex > -1) {
      this.setState({
        addNewSelectDisable: true,
        switchDisable: true,
        isAddNewShareTaskShow: false,
        taskTreePathValue: null,
      });
    }
  };

  componentDidMount() {
    this.GetAccountDDl();
    this.checkHasNewTag(this.props.shareTaskData.shareTasks);
  }

  componentDidUpdate(prevProps) {
    if (this.props.shareTaskData !== prevProps.shareTaskData) {
      this.checkHasNewTag(this.props.shareTaskData.shareTasks);
      this.setState({
        isShowReturnReasonLogIcon:
          this.props.shareTaskData.returnReasonLogs?.length > 0,
        returnReasonLog: this.transformReturnReasonLogData(
          this.props.shareTaskData.returnReasonLogs
        ),
        requestReasonLog: this.transformRequestReasonLogData(
          this.props.shareTaskData.submitReasonLogs
        ),
        initReviseData: this.transformPendingApprovalData(
          this.props.shareTaskData.shareTasks
        ),
        reviseData: this.transformPendingApprovalData(
          this.props.shareTaskData.shareTasks
        ),
        reason: this.props.shareTaskData.reason,
      });
    }
  }

  reviseChangeMainTaskSwitch = (record, switchDisable) => {
    return (
      <Tooltip
        placement="topLeft"
        title={
          switchDisable
            ? "Please delete the New Task if you want to select other Tasks as Main Task."
            : ""
        }
      >
        <Switch
          checked={record.isMainTask}
          disabled={switchDisable}
          onChange={(checked, e) => this.SwitchMainTask(checked, e, record)}
        />
      </Tooltip>
    );
  };

  reviseChangeMainTaskRedTipIcon = (record) => {
    return (
      <Popover
        content={
          <span className={`${styles.errorPopContent} fs-14`}>
            {record.errorMessage}
          </span>
        }
        title={null}
        trigger="click"
      >
        <img
          src={redTipIcon}
          alt="error"
          className={
            record.hasError ? styles.redTipIconShow : styles.redTipIconHide
          }
        />
      </Popover>
    );
  };

  reviseChangeMainTaskChangeMainTaskTag = (record) => {
    return (
      <div className={styles.fileStatus}>
        <ChangeMainTaskTag status={this.HandleChangeMainTaskTag(record)} />
      </div>
    );
  };

  reviseChangeMainTaskAccount = (account) => {
    return (
      <Tooltip placement="topLeft" title={account}>
        <span className={`${styles.tableText} fs-14`}>{account}</span>
      </Tooltip>
    );
  };

  reviseChangeMainTaskTaskTreePath = (taskTreePath) => {
    return (
      <Tooltip placement="topLeft" title={taskTreePath}>
        <span className={`${styles.tableText} fs-14`}>{taskTreePath}</span>
      </Tooltip>
    );
  };

  reviseChangeMainTaskTaskLabelName = (taskLabelName) => {
    return (
      <Tooltip placement="topLeft" title={taskLabelName}>
        <span className={`${styles.tableText} fs-14`}>{taskLabelName}</span>
      </Tooltip>
    );
  };

  reviseChangeMainTaskTaskId = (taskId) => {
    return (
      <Tooltip placement="topLeft" title={taskId}>
        <span className={`${styles.tableText} fs-14`}>{taskId}</span>
      </Tooltip>
    );
  };

  reviseChangeMainTaskPpo = (ppo) => {
    return (
      <Tooltip placement="bottomLeft" title={ppo}>
        <span className={`${styles.tableText} fs-14`}>{ppo}</span>
      </Tooltip>
    );
  };

  reviseChangeMainTaskPo = (po) => {
    return (
      <Tooltip placement="bottomLeft" title={po}>
        <span className={`${styles.tableText} fs-14`}>{po}</span>
      </Tooltip>
    );
  };

  reviseChangeMainTaskProcedureSavedIn = (procedureSavedIn) => {
    return (
      <Tooltip placement="topLeft" title={procedureSavedIn}>
        <span className={`${styles.tableText} fs-14`}>{procedureSavedIn}</span>
      </Tooltip>
    );
  };

  reviseChangeMainTaskProcedureClientServerPath = (clientServerPath) => {
    return (
      <Tooltip placement="topLeft" title={clientServerPath}>
        <span className={`${styles.tableText} fs-14`}>{clientServerPath}</span>
      </Tooltip>
    );
  };

  reviseChangeMainTaskProcedureAction = (record) => {
    if (!(record.isOldMainTask || record.isNew)) {
      return;
    }

    if (record.isDeleted) {
      return (
        <div
          className={`${styles.modalTableActionIcon} ${styles.sectionRevokeIcon}`}
          onClick={() => this.ReviseChangeMainTaskModalTableOnRevoke(record)}
        />
      );
    }

    return (
      <div
        className={`${styles.modalTableActionIcon} ${styles.sectionDeleteIcon}`}
        onClick={() => this.ModalTableOnDelete(record)}
      />
    );
  };

  handleReviseChangeMainTaskShareTitle = () => {
    return (
      <div className={approveStyles.approveShareInfoTop}>
        <div className={approveStyles.approveShareTitleDiv}>
          <span className={`${styles.shareTitle} fs-18`}>Shared Tasks</span>
        </div>
      </div>
    );
  };

  handleReviseChangeMainTaskReturnReason = () => {
    const { isShowReturnReasonLogIcon } = this.state;

    return (
      <div className={reviseStyles.reviseShareInfoTop}>
        <div className={approveStyles.approveShareTitleDiv}>
          <span className={`${styles.shareTitle} fs-14`}>Return Reason</span>
        </div>
        {this.handleReasonLog(
          "View previous Return Reason",
          false,
          isShowReturnReasonLogIcon,
          false
        )}
      </div>
    );
  };

  handleReviseChangeMainTaskSharedTaskArea = (reviseDataColumn) => {
    const {
      reviseData,
      isAddNewShareTaskShow,
      accountSelectOption,
      accountSelectValue,
      taskTreePathOption,
      taskTreePathValue,
      addNewSelectDisable,
    } = this.state;

    return (
      <div className={styles.sharedTaskArea}>
        <ShareTaskTable data={reviseData} columns={reviseDataColumn} />
        <AddNewShareTask
          isAddNewShareTaskShow={isAddNewShareTaskShow}
          showAddNewShareTask={this.ShowAddNewShareTask}
          accountSelectOption={accountSelectOption}
          accountSelectValue={accountSelectValue}
          accountSelectOnChange={this.AccountSelectOnChange}
          taskTreePathOption={taskTreePathOption}
          taskTreePathValue={taskTreePathValue}
          taskTreePathOnSearch={this.TaskTreePathOnSearch}
          taskTreePathOnChange={this.TaskTreePathOnChange}
          addTaskOnClick={this.AddTaskOnClick}
          clearAddTaskOnClick={this.ClearAddTaskOnClick}
          showWarning={true}
          selectDisable={addNewSelectDisable}
        />
      </div>
    );
  };

  handleReviseChangeMainTaskSubmitReason = () => {
    const { isShowReturnReasonLogIcon, resonRequired } = this.state;

    return (
      <div className={approveStyles.approveShareInfoTop}>
        <div className={approveStyles.approveShareTitleDiv}>
          <InputLabel
            text="Reason"
            required={resonRequired}
            className={styles.modalText}
          />
        </div>
        {this.handleReasonLog(
          "View previous Reason",
          true,
          isShowReturnReasonLogIcon,
          true
        )}
      </div>
    );
  };

  render() {
    const {
      isReasonLogModalOpen,
      returnReasonLog,
      isRequestReasonLogModalOpen,
      requestReasonLog,
      reasonValue,
      reasonInputStatus,
      switchDisable,
      reason,
    } = this.state;

    const reviseDataColumn = [
      {
        title: "",
        dataIndex: "switchMainTask",
        render: (text, record) => (
          <div>
            {this.reviseChangeMainTaskRedTipIcon(record)}
            {this.reviseChangeMainTaskSwitch(record, switchDisable)}
            {record.isMainTask ? (
              <span className={styles.switchTextMainTask}>Main Task</span>
            ) : (
              <span className={styles.switchTextSharedTask}>Shared Task</span>
            )}
          </div>
        ),
        width: "11%",
      },
      {
        title: "",
        dataIndex: "changeMainTaskTag",
        render: (text, record) => {
          return this.reviseChangeMainTaskChangeMainTaskTag(record);
        },
        width: "8.4%",
      },
      {
        title: "Account",
        dataIndex: "account",
        ellipsis: {
          showTitle: false,
        },
        width: "6.8%",
        render: (account, record) => {
          return this.reviseChangeMainTaskAccount(account);
        },
      },
      {
        title: "Task Tree Path",
        dataIndex: "taskTreePath",
        ellipsis: {
          showTitle: false,
        },
        render: (taskTreePath) => {
          return this.reviseChangeMainTaskTaskTreePath(taskTreePath);
        },
      },
      {
        title: "Task Label Name",
        dataIndex: "taskLabelName",
        ellipsis: {
          showTitle: false,
        },
        render: (taskLabelName, record) => {
          return this.reviseChangeMainTaskTaskLabelName(taskLabelName);
        },
        width: "10.4%",
      },
      {
        title: "Task ID",
        dataIndex: "taskId",
        ellipsis: {
          showTitle: false,
        },
        width: "6.4%",
        render: (taskId) => {
          return this.reviseChangeMainTaskTaskId(taskId);
        },
      },
      {
        title: "Primary Procedure Owner",
        dataIndex: "ppo",
        ellipsis: {
          showTitle: false,
        },
        width: "12.8%",
        render: (ppo) => {
          return this.reviseChangeMainTaskPpo(ppo);
        },
      },
      {
        title: "Procedure Owner",
        dataIndex: "po",
        ellipsis: {
          showTitle: false,
        },
        render: (po) => {
          return this.reviseChangeMainTaskPo(po);
        },
      },
      {
        title: "Procedure Saved In",
        dataIndex: "procedureSavedIn",
        ellipsis: {
          showTitle: false,
        },
        render: (procedureSavedIn) => {
          return this.reviseChangeMainTaskProcedureSavedIn(procedureSavedIn);
        },
        width: "10.3%",
      },
      {
        title: "Client Server Path",
        dataIndex: "clientServerPath",
        ellipsis: {
          showTitle: false,
        },
        render: (clientServerPath) => {
          return this.reviseChangeMainTaskProcedureClientServerPath(
            clientServerPath
          );
        },
        width: "10.3%",
      },
      this.tagColumn("6.4%"),
      {
        title: "Action",
        dataIndex: "action",
        align: "center",
        width: "6.5%",
        render: (text, record) => {
          return this.reviseChangeMainTaskProcedureAction(record);
        },
      },
    ];

    return (
      <div className={styles.shareInformation}>
        {this.handleReviseChangeMainTaskShareTitle()}
        {this.handleReviseChangeMainTaskReturnReason()}
        {this.handleReadOnlyReasonInput(reason)}
        {this.handleReviseChangeMainTaskSharedTaskArea(reviseDataColumn)}
        {this.handleReviseChangeMainTaskSubmitReason()}
        {this.handleReasonInput(reasonValue, reasonInputStatus)}
        {this.handleReasonLogModal(
          isReasonLogModalOpen,
          returnReasonLog,
          isRequestReasonLogModalOpen,
          requestReasonLog
        )}
      </div>
    );
  }
}
