import React from "react";
import moment from "moment";
import { Modal, Checkbox, DatePicker } from "antd";
import { NormalTextArea } from "../../../base/basicComponents/input";
import { ScrollSelect } from "../../../base/basicComponents/select";
import NormalModal from "../../../base/basicComponents/modal";
import Buttons from "../../../base/basicComponents/button/index";
import BaseComponent from "../../../base/common/BaseComponent";
import styles from "./index.module.scss";
import "./shareDocument.scss";
import folderIcon from "../../../assets/image/folderIcon.svg";
import fileIcon from "../../../assets/image/fileIcon.svg";
import ProcedureListService from "../../../service/procedureList";
import { getDateString, getTimeZoneString } from "../../publicDictionaryValues";

class ShareDocument extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      checkedProcedureFiles: [],
      checkedSupportingFiles: [],
      isAllProcedureFilesChecked: false,
      isAllSupportingFilesChecked: false,
      recipients: [],
      activeInternalUsersKey: 0,
      toDate: null,
      note: null,
      recipientsSelectStatus: null,
      modalState: {
        isNormalModalOpen: false,
        modalOkText: "Confirm",
        modalCancelText: "Cancel",
        modalChildContent: "",
        handleModalConfirm: () => {},
        handleModalCancel: () => {},
      },
    };
  }

  initState = () => {
    this.setState({
      checkedProcedureFiles: [],
      checkedSupportingFiles: [],
      isAllProcedureFilesChecked: false,
      isAllSupportingFilesChecked: false,
      recipients: [],
      toDate: this.getDate(14),
      note: null,
      recipientsSelectStatus: null,
      modalState: {
        isNormalModalOpen: false,
        modalOkText: "Confirm",
        modalCancelText: "Cancel",
        modalChildContent: "",
        handleModalConfirm: () => {},
        handleModalCancel: () => {},
      },
    });
  };

  getDate = (days) => {
    let afterDay = new Date(Date.now() + 86400000 * days);
    const toDate = `${afterDay.getFullYear()}-${
      afterDay.getMonth() + 1
    }-${afterDay.getDate()}`;
    return toDate;
  };

  disabledDate = (current) => {
    const today = moment(new Date(), "YYYY-MM-DD");
    const tooLate = current.diff(today, "days") > 28;
    return !!tooLate;
  };

  handleExpirationDate = (date) => {
    const dateFormat = "YYYY-MM-DD";
    const toDate = date[1]?.format(dateFormat);
    this.setState({
      toDate,
    });
  };

  isFileChecked = (id, fileType) => {
    let checkedFileIds = [];
    if (fileType === "ProcedureFile") {
      checkedFileIds = this.state.checkedProcedureFiles.map(
        (item) => item?.fileId
      );
    } else if (fileType === "SupportingFile") {
      checkedFileIds = this.state.checkedSupportingFiles.map(
        (item) => item?.fileId
      );
    }
    return checkedFileIds.includes(id);
  };

  handleCheckedAll = (e, fileType) => {
    if (fileType === "ProcedureFile") {
      if (e.target.checked) {
        this.setState({
          checkedProcedureFiles: [...this.props.procedureFiles],
          isAllProcedureFilesChecked: true,
        });
      } else {
        this.setState({
          checkedProcedureFiles: [],
          isAllProcedureFilesChecked: false,
        });
      }
    } else if (fileType === "SupportingFile") {
      if (e.target.checked) {
        this.setState({
          checkedSupportingFiles: [...this.props.supportingFiles],
          isAllSupportingFilesChecked: true,
        });
      } else {
        this.setState({
          checkedSupportingFiles: [],
          isAllSupportingFilesChecked: false,
        });
      }
    }
  };

  handleChecked = (e, file) => {
    let filesArr = [];
    if (file?.procedureFileID) {
      if (e.target.checked) {
        filesArr = [...this.state.checkedProcedureFiles, file];
      } else {
        filesArr = this.state.checkedProcedureFiles.filter(
          (item) => item.fileId !== file.fileId
        );
      }
      this.setState({
        checkedProcedureFiles: [...filesArr],
        isAllProcedureFilesChecked:
          filesArr.length === this.props.procedureFiles.length &&
          filesArr.length !== 0,
      });
    } else {
      if (e.target.checked) {
        filesArr = [...this.state.checkedSupportingFiles, file];
      } else {
        filesArr = this.state.checkedSupportingFiles.filter(
          (item) => item.fileId !== file.fileId
        );
      }
      this.setState({
        checkedSupportingFiles: [...filesArr],
        isAllSupportingFilesChecked:
          filesArr.length === this.props.supportingFiles.length &&
          filesArr.length !== 0,
      });
    }
  };

  toggleNormalModal = (isOpen) => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isNormalModalOpen: isOpen,
      },
    });
  };

  handleCancelConfirm = () => {
    this.toggleNormalModal(false);
    this.props.onCancel();
  };

  handleCancelClick = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isNormalModalOpen: true,
        modalChildContent: "Are you sure to quit without saving?",
        handleModalConfirm: this.handleCancelConfirm,
        handleModalCancel: () => this.toggleNormalModal(false),
      },
    });
  };

  handleSendConfirm = () => {
    const {
      checkedProcedureFiles,
      checkedSupportingFiles,
      recipients,
      toDate,
      note,
    } = this.state;
    const {
      procedureId,
      procedureTaskId,
      accountID,
      accountAbbr,
      accountName,
      taskId,
      taskLabelName,
    } = this.props.shareData;

    const files = [...checkedProcedureFiles, ...checkedSupportingFiles];
    const shareFiles = [];
    files.forEach((item) => {
      shareFiles.push({
        fileId: item.fileId,
        fileName: item.fileName,
      });
    });

    const Para = {
      procedureId,
      procedureTaskId,
      accountId: accountID,
      accountAbbr,
      accountName,
      taskId,
      taskLabelName,
      notes: note,
      toDate,
      userIds: recipients,
      shareFiles: this.props.shareFiles || shareFiles,
    };

    this.toggleNormalModal(false);

    ProcedureListService.shareDocument(
      Para,
      () => this._alertSuccess("Share Documents Successful"),
      () => this._alertError("Share Documents Failed")
    );

    this.props.onCancel();
  };

  handleSendClick = () => {
    if (this.state.recipients.length > 0) {
      this.setState({
        recipientsSelectStatus: null,
        modalState: {
          ...this.state.modalState,
          isNormalModalOpen: true,
          modalChildContent: "Are you sure to share the documents?",
          handleModalConfirm: this.handleSendConfirm,
          handleModalCancel: () => this.toggleNormalModal(false),
        },
      });
    } else {
      this.setState({
        recipientsSelectStatus: "error",
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.activeInternalUsers !== this.props.activeInternalUsers) {
      this.setState({
        activeInternalUsersKey: this.state.activeInternalUsersKey + 1,
      });
    }
  }

  componentDidMount() {
    if (!this.state.toDate) {
      const toDate = this.getDate(14);
      this.setState({
        toDate,
      });
    }
  }

  render() {
    const {
      isModalOpen,
      procedureFiles,
      supportingFiles,
      activeInternalUsers,
    } = this.props;
    const {
      checkedProcedureFiles,
      checkedSupportingFiles,
      isAllProcedureFilesChecked,
      isAllSupportingFilesChecked,
      recipients,
      toDate,
      note,
      recipientsSelectStatus,
      modalState,
      activeInternalUsersKey,
    } = this.state;
    const {
      isNormalModalOpen,
      modalOkText,
      modalCancelText,
      modalChildContent,
      handleModalConfirm,
      handleModalCancel,
    } = modalState;

    const dateFormat = "YYYY/MM/DD";

    const expirationDateFrom = getTimeZoneString(getDateString(new Date()));
    const expirationDateTo = getTimeZoneString(toDate);

    const isSendDisabled = () => {
      if (this.props.shareFiles) return this.props.shareFiles.length === 0;
      return (
        checkedProcedureFiles.length === 0 &&
        checkedSupportingFiles.length === 0
      );
    };

    const handleOptionList = (inputValue, option) =>
      (option?.title || "").toLowerCase().includes(inputValue.toLowerCase());

    const footer = (
      <div className={styles.buttonDiv}>
        <Buttons color="grey" size="middle" onClick={this.handleCancelClick}>
          Cancel
        </Buttons>
        <Buttons
          color="blue"
          size="middle"
          disabled={isSendDisabled()}
          onClick={this.handleSendClick}
        >
          Send
        </Buttons>
      </div>
    );

    return (
      <>
        <Modal
          title="Share Document"
          centered
          open={isModalOpen}
          footer={footer}
          closable={false}
          width="40rem"
          className="shareModal"
          onCancel={this.handleCancelClick}
          afterClose={this.initState}
          maskClosable={false}
        >
          {!this.props.isDetailPage && (
            <div className={styles.shareModalContent}>
              <div className={styles.documentsList}>
                <div className={styles.filesWrapper}>
                  <div className={styles.files}>
                    <Checkbox
                      checked={isAllProcedureFilesChecked}
                      onChange={(e) =>
                        this.handleCheckedAll(e, "ProcedureFile")
                      }
                    />
                    <img
                      className={styles.icon}
                      src={folderIcon}
                      alt="Procedure Files"
                    />
                    <div className={styles.fileTitle}>Procedure Files</div>
                  </div>
                  {procedureFiles.length > 0 && (
                    <div className={styles.procedureFilesList}>
                      {procedureFiles.map((item) => (
                        <div className={styles.filesItem} key={item.fileId}>
                          <Checkbox
                            checked={this.isFileChecked(
                              item?.fileId,
                              "ProcedureFile"
                            )}
                            onChange={(e) => this.handleChecked(e, item)}
                          />
                          <img
                            className={styles.icon}
                            src={fileIcon}
                            alt="Procedure File"
                          />
                          <div className={styles.fileName}>{item.fileName}</div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className={styles.filesWrapper}>
                  <div className={styles.files}>
                    <Checkbox
                      checked={isAllSupportingFilesChecked}
                      onChange={(e) =>
                        this.handleCheckedAll(e, "SupportingFile")
                      }
                    />
                    <img
                      className={styles.icon}
                      src={folderIcon}
                      alt="Supporting Files"
                    />
                    <div className={styles.fileTitle}>Supporting Files</div>
                  </div>
                  {supportingFiles.length > 0 && (
                    <div className={styles.supportingFilesList}>
                      {supportingFiles.map((item) => (
                        <div className={styles.filesItem} key={item.fileId}>
                          <Checkbox
                            checked={this.isFileChecked(
                              item?.fileId,
                              "SupportingFile"
                            )}
                            onChange={(e) => this.handleChecked(e, item)}
                          />
                          <img
                            className={styles.icon}
                            src={fileIcon}
                            alt="Supporting File"
                          />
                          <div className={styles.fileName}>{item.fileName}</div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className={styles.shareContent}>
            <div className={styles.coverSheetWrapper}>
              <Checkbox checked={true} disabled={true} />
              <div className={styles.coverSheet}>Cover Sheet</div>
            </div>
            <ScrollSelect
              key={activeInternalUsersKey}
              placeholder="Add a name"
              className={styles.recipientsSelect}
              status={recipientsSelectStatus}
              value={recipients}
              optionList={activeInternalUsers}
              mode="multiple"
              handleChange={(e) => {
                this.setState({
                  recipientsSelectStatus: null,
                  recipients: e,
                });
              }}
              showSearch={true}
              handleFilterOption={handleOptionList}
            />
            <NormalTextArea
              placeholder="Add a message"
              value={note}
              onChange={(e) => {
                this.setState({
                  note: e.target.value,
                });
              }}
            />
            <div className={styles.expirationDate}>
              <div className={styles.expirationDateTitle}>Expiration Date:</div>
              <DatePicker.RangePicker
                value={[
                  moment(expirationDateFrom, "YYYY-MM-DD"),
                  moment(expirationDateTo, "YYYY-MM-DD"),
                ]}
                onChange={this.handleExpirationDate}
                format={dateFormat}
                allowClear={false}
                disabled={[true, false]}
                disabledDate={this.disabledDate}
                className={styles.datePicker}
              />
              <div className={styles.expirationDateDescription}>
                The maximum period is 30 days.
              </div>
            </div>
          </div>
        </Modal>
        <NormalModal
          isModalOpen={isNormalModalOpen}
          okText={modalOkText}
          cancelText={modalCancelText}
          childContent={<p className="fs-16">{modalChildContent}</p>}
          handleOk={handleModalConfirm}
          handleCancel={handleModalCancel}
          closable={false}
        />
      </>
    );
  }
}

export default ShareDocument;
