import React from "react";
import moment from "moment";
import { DatePicker } from "antd";
import { useMount, useSetState } from "ahooks";
import TopFilterBase from "../../../commonComponents/listBase/TopFilterBase";
import InputLabel from "../../../../base/basicComponents/inputLabel";
import NormalSelect from "../../../../base/basicComponents/select";
import Buttons from "../../../../base/basicComponents/button";
import myRequestListService from "../../../../service/myRequestListService/index";
import styles from "./index.module.scss";
import "../../../../assets/css/style.css";

export default function TopFilter(props) {
  const {
    handleFilterClick,
    isOpex,
    processInitiateDateArr,
    setProcessInitiateDateArr,
    defaultDateValue,
    setAccountAbbrData,
    setProcessStatusData,
  } = props;

  const {
    handleOptionList,
    sortList,
    handleChange,
    handleDeSelect,
    handleFilterOption,
    sortProcessStatusList,
  } = TopFilterBase();

  /* state about selects' data */
  const [filterOptionList, setFilterOptionList] = useSetState({
    processInitiateDateFrom: processInitiateDateArr[0],
    processInitiateDateTo: processInitiateDateArr[1],
    initiatedBy: [],
    accountAbbr: [],
    processCategory: [],
    processStatus: [],
  });

  /* state about select's selected data */
  const [selectOptions, setSelectOptions] = useSetState({
    processInitiateDateFrom: defaultDateValue[0],
    processInitiateDateTo: defaultDateValue[1],
    initiatedBy: 1,
    accountAbbr: [],
    processCategory: [],
    processStatus: [],
  });

  const [defaultValue, setDefaultValue] = useSetState({
    processInitiateDateFrom: defaultDateValue[0],
    processInitiateDateTo: defaultDateValue[1],
    initiatedBy: 1,
    accountAbbr: [],
    processCategory: [],
    processStatus: [],
  });

  const onSuccess = (response, type) => {
    let option;
    switch (type) {
      case "topFilterData":
        const initiatedByOption = handleOptionList(response.initiatedBy, false);
        const processStatusOption = handleOptionList(
          response.processStatus,
          false,
          true,
        );
        setFilterOptionList({
          processInitiateDateFrom:
            processInitiateDateArr[0] || defaultDateValue[0],
          processInitiateDateTo:
            processInitiateDateArr[1] || defaultDateValue[1],
          initiatedBy: initiatedByOption,
          processStatus: processStatusOption,
        });
        let processStatusValueArr = [];
        processStatusOption.forEach((item) =>
          processStatusValueArr.push(item.value)
        );
        setProcessStatusData(processStatusValueArr);
        break;
      case "accountAbbr":
        option = handleOptionList(response, true);
        const filterOptionPara = {
          accountAbbr: option,
        };
        const selectOptionsPara = {
          accountAbbr: [],
        };
        handleFilterClick(filterOptionPara, selectOptionsPara);
        setFilterOptionList({ accountAbbr: option });
        let accountAbbrValueArr = [];
        option.forEach((item) => accountAbbrValueArr.push(item.value));
        setAccountAbbrData(accountAbbrValueArr);
        break;
      case "processCategory":
        option = handleOptionList(response, false);
        setFilterOptionList({ processCategory: option });
        break;
      default:
        break;
    }
  };

  /* callback about when call api error */
  const onError = (error) => {
    console.log(error);
  };

  const handleFilterBtnClick = () => {
    handleFilterClick(filterOptionList, selectOptions);
  };

  const handleClearClick = () => {
    setProcessInitiateDateArr(defaultDateValue);
    const defaultOption = {
      processInitiateDateFrom: defaultValue.processInitiateDateFrom,
      processInitiateDateTo: defaultValue.processInitiateDateTo,
      initiatedBy: defaultValue.initiatedBy,
      accountAbbr: defaultValue.accountAbbr,
      processCategory: defaultValue.processCategory,
      processStatus: defaultValue.processStatus,
    };
    setSelectOptions(defaultOption);
    handleFilterClick(filterOptionList, defaultOption);
  };

  const getAccountAbbr = () => {
    if (isOpex) {
      myRequestListService.getAllAccountAbbreviations(
        2,
        onSuccess,
        onError,
        "accountAbbr"
      );
    } else {
      myRequestListService.getAccountAbbrList(
        onSuccess,
        onError,
        "accountAbbr"
      );
    }
  };

  const getProcessCategory = () => {
    myRequestListService.getProcessCategoryDDL(
      onSuccess,
      onError,
      "processCategory"
    );
  };

  const getMyRequestTopFilter = () => {
    myRequestListService.getMyRequestTopFilter(
      onSuccess,
      onError,
      "topFilterData"
    );
  };

  const handleDatePickerChange = (date) => {
    const dateFormat = "YYYY-MM-DD";
    const processInitiateDateFrom = date[0]?.format(dateFormat);
    const processInitiateDateTo = date[1]?.format(dateFormat);
    setSelectOptions({
      processInitiateDateFrom,
      processInitiateDateTo,
    });
    setProcessInitiateDateArr([processInitiateDateFrom, processInitiateDateTo]);
  };

  /* function about when page init get filter selects option list */
  useMount(() => {
    getMyRequestTopFilter();
    getAccountAbbr();
    getProcessCategory();
  });

  const dateFormat = "MM/DD/YYYY";

  return (
    <div className={styles.topFilterContainer}>
      <div className={styles.topFilterSelects}>
        <div className={styles.topFilterSelectItem}>
          <InputLabel
            text="Process Initiate Date"
            className={`${styles.topFilterSelectLabel} fs-14`}
          />
          <DatePicker.RangePicker
            value={[
              moment(processInitiateDateArr[0], "YYYY-MM-DD"),
              moment(processInitiateDateArr[1], "YYYY-MM-DD"),
            ]}
            onChange={handleDatePickerChange}
            format={dateFormat}
            allowClear={false}
            className="topFilterDatePicker"
            popupStyle={{ zIndex: "100000" }}
          />
        </div>
        <div className={styles.topFilterSelectItem}>
          <InputLabel
            text="Initiated by"
            className={`${styles.topFilterSelectLabel} fs-14`}
          />
          <NormalSelect
            value={selectOptions.initiatedBy}
            optionList={filterOptionList.initiatedBy}
            className="topFilterSelectInput"
            showArrow={true}
            maxTagCount="responsive"
            handleChange={(e) => {
              const optionList = filterOptionList.initiatedBy;
              setSelectOptions({
                initiatedBy: e,
              });
              setFilterOptionList({
                initiatedBy: [
                  ...handleChange(optionList, e),
                  ...optionList.sort((s, t) => sortList(s, t)),
                ],
              });
            }}
          />
        </div>
        <div className={styles.topFilterSelectItem}>
          <InputLabel
            text="Account Code"
            className={`${styles.topFilterSelectLabel} fs-14`}
          />
          <NormalSelect
            value={selectOptions.accountAbbr}
            optionList={filterOptionList.accountAbbr}
            className="topFilterSelectInput"
            mode="multiple"
            showArrow={true}
            maxTagCount="responsive"
            handleChange={(e) => {
              const optionList = filterOptionList.accountAbbr;
              setSelectOptions({
                accountAbbr: e,
              });
              setFilterOptionList({
                accountAbbr: [
                  ...handleChange(optionList, e),
                  ...optionList.sort((s, t) => sortList(s, t)),
                ],
              });
            }}
            handleFilterOption={(input, option) =>
              handleFilterOption(input, option)
            }
          />
        </div>
        <div className={styles.topFilterSelectItem}>
          <InputLabel
            text="Process Category"
            className={`${styles.topFilterSelectLabel} fs-14`}
          />
          <NormalSelect
            value={selectOptions.processCategory}
            optionList={filterOptionList.processCategory}
            className="topFilterSelectInput"
            mode="multiple"
            showArrow={true}
            maxTagCount="responsive"
            handleChange={(e) => {
              const optionList = filterOptionList.processCategory;
              setSelectOptions({
                processCategory: e,
              });
              setFilterOptionList({
                processCategory: [
                  ...handleChange(optionList, e),
                  ...optionList.sort((s, t) => sortList(s, t)),
                ],
              });
            }}
            handleDeSelect={(e) => {
              const selectList = [...selectOptions.processCategory];
              setSelectOptions({
                processCategory: handleDeSelect(e, selectList),
              });
            }}
            handleFilterOption={(input, option) =>
              handleFilterOption(input, option)
            }
          />
        </div>
        <div className={styles.topFilterSelectItem}>
          <InputLabel
            text="Process Status"
            className={`${styles.topFilterSelectLabel} fs-14`}
          />
          <NormalSelect
            value={selectOptions.processStatus}
            optionList={filterOptionList.processStatus}
            className="topFilterSelectInput"
            mode="multiple"
            showArrow={true}
            maxTagCount="responsive"
            handleChange={(e) => {
              const optionList = filterOptionList.processStatus;
              setSelectOptions({
                processStatus: e,
              });
              setFilterOptionList({
                processStatus: [
                  ...handleChange(optionList, e, true),
                  ...optionList.sort((s, t) => sortProcessStatusList(s, t)),
                ],
              });
            }}
            handleDeSelect={(e) => {
              const selectList = [...selectOptions.processStatus];
              setSelectOptions({
                processStatus: handleDeSelect(e, selectList),
              });
            }}
            handleFilterOption={(input, option) =>
              handleFilterOption(input, option)
            }
          />
        </div>
      </div>
      <div className={styles.topFilterBtns}>
        <Buttons color="blue" size="middle" onClick={handleFilterBtnClick}>
          Filter
        </Buttons>
        <Buttons color="grey" size="middle" onClick={handleClearClick}>
          Clear
        </Buttons>
      </div>
    </div>
  );
}
