import {
  dataStatus,
  deepClone,
  fileTags,
  proposedChangeTags,
} from "../../../../../publicDictionaryValues";
import ReviseProcedureFileTable from "../../../../procedureFileSections/fields/procedureFileTableFields/reviseProcedureFileTable";
import ReviseUpdateSupportingFileTbody from "../../supportingFileTbody/reviseUpdateSupportingFileTbody";

export default class ReviseUpdateSupportingFileTable extends ReviseProcedureFileTable {
  /* update file table data */
  updateDataList = (dataList) => {
    const list = deepClone(dataList);
    if (
      this.props.initProcedureTableData !== undefined &&
      !this.props.isComplete
    ) {
      this.controlIsTableChange(list);
    }
    if (dataList.length === 0) {
      this.setState({ isExpendData: false });
    }
    this.setState({
      data: dataList,
    });
    this.handleDefaultExpendIcon();
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.initProcedureTableData.length !==
      this.props.initProcedureTableData.length
    ) {
      this.updateDataList(this.props.data);
    }
  }

  /* sort procedure files in revise page */
  sortRevisePageFiles = (dataList) => {
    const returnList = [];
    const approvedList = [];
    const emptyList = [];
    dataList.forEach((item, index) => {
      const status = item.data.find(
        (dataItem) => dataItem.name === "status"
      ).value;

      const tag = item.data.find(
        (dataItem) => dataItem.name === "fileName"
      )?.tagValue;

      if (
        status === dataStatus.return ||
        (status === null && tag === fileTags.deleted)
      ) {
        returnList.push(item);
      } else if (status === dataStatus.approval) {
        approvedList.push(item);
      } else {
        emptyList.push(item);
      }
    });
    const returnNewList = this.sortDataList(returnList);
    const approvedNewList = this.sortDataList(approvedList);
    const emptyNewList = this.sortDataList(emptyList);
    return [...returnNewList, ...approvedNewList, ...emptyNewList];
  };

  /* function about set return file number in revise page */
  setReturnDataNumber = (data) => {
    const initDataList = this.sortRevisePageFiles(
      this.props.initProcedureTableData
    );
    const currentList = this.sortRevisePageFiles(data);
    this.returnNumber = 0;
    if (!this.props.isComplete) {
      for (let index in data) {
        const isNochange =
          initDataList[index] !== undefined &&
          currentList[index].data.find((i) => i.name === "fileName")
            .tagValue ===
            initDataList[index].data.find((i) => i.name === "fileName")
              .tagValue &&
          currentList[index].data.find((i) => i.name === "newVersion").value ===
            null &&
          currentList[index].data.find((i) => i.name === "status").value ===
            dataStatus.return &&
          !(
            initDataList[index].data.find((i) => i.name === "fileName")
              .tagValue === fileTags.deleted &&
            initDataList[index].data.find((i) => i.name === "proposedChange")
              .value === proposedChangeTags.new
          ) &&
          !currentList[index].data.find((i) => i.name === "isReviewed").value;
        if (isNochange) {
          this.returnNumber += 1;
        }
      }
    }
  };

  handleReturnTableTbody = (obj) => {
    const {
      data,
      columns,
      inputOnChange,
      procedureNoteMaxLength,
      fileSectionDisabled,
      procedureNoteSize,
      hasSubmit,
      fileTableRef,
      optionList,
      handleReturnClick,
      handleFileSelect,
      currentRole,
      downloadFile,
      isSupportingFile,
      isComplete,
      isExpendData,
    } = obj;
    return (
      <ReviseUpdateSupportingFileTbody
        data={data}
        columns={columns}
        inputOnChange={inputOnChange}
        procedureNoteMaxLength={procedureNoteMaxLength}
        fileSectionDisabled={fileSectionDisabled}
        procedureNoteSize={procedureNoteSize}
        hasSubmit={hasSubmit}
        fileTableRef={fileTableRef}
        optionList={optionList}
        handleReturnClick={handleReturnClick}
        handleFileSelect={handleFileSelect}
        currentRole={currentRole}
        downloadFile={downloadFile}
        isSupportingFile={isSupportingFile}
        isComplete={isComplete}
        handleChangeTableHeight={this.handleChangeTableHeight}
        isExpendData={isExpendData}
      />
    );
  };
}
