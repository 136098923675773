import React, { createRef } from "react";
import { SectionStatusDiv } from "../../../../../../base/basicComponents/status/index";
import ReturnReasonInput from "../../../../../../base/basicComponents/returnReasonInput";
import { ExpendTextArea } from "../../../../../../base/basicComponents/input";
import { ModalWithComponent } from "../../../../../../base/basicComponents/modal";
import { dataStatus } from "../../../../../publicDictionaryValues";
import ApproveClientServerPath from "../approveSeverPath";
import reasonEditIcon from "../../../../../../assets/image/reasonEditIcon.png";
import returnReasonLogIcon from "../../../../../../assets/image/returnReasonLogIcon.png";
import styles from "../index.module.scss";

const serverPathReturnRef = createRef();
export default class ReviseProcedureClientServerPath extends ApproveClientServerPath {
  constructor(props) {
    super(props);
    this.state = {
      serverPathSectionState: {
        serverPathReturnReasonValue: this.props.clientServerPathReturnReason,
        serverPathStatus: this.props.serverPathStatusID,
        isServerPathReturnBtnDisabled: this.props.isServerPathReturnBtnDisabled,
        isServerPathApproveBtnDisabled:
          this.props.isServerPathApproveBtnDisabled,
        isServerPathShowEditBtn: false,
        serverPathReasonEditIcon: reasonEditIcon,
        isServerPathReturnReasonDisabled:
          this.props.isServerPathReturnReasonDisabled,
        serverPathReasonLogIcon: returnReasonLogIcon,
        serverPathHide: false,
        isServerPathError: false,
        serverPathReasonStatus: null,
        serverPathReturnReasonInputValue:
          this.props.serverPathReturnReasonInputValue,
        serverPathValue: this.props.serverPathValue,
        isServerPathSectionChange: this.props.isServerPathSectionChange,
      },
      modalState: {
        isModalWithComponentOpen: false,
        modalTitle: "",
        okText: "",
        cancelText: "Cancel",
        textAreaPlacement: "",
        textAreaMaxLength: 0,
        modalLabel: "",
        isShowTextArea: false,
        closable: false,
        modalWidth: 520,
        handleOk: null,
        handleCancel: null,
        isShowSelect: false,
      },
    };
  }

  /* funtion about client server path change */
  handleServerPathChange = (e) => {
    this.setState({
      serverPathSectionState: {
        ...this.state.serverPathSectionState,
        serverPathValue: e.target.value,
        isServerPathError: false,
      },
    });
    this.props.updateServerPathValue(e.target.value);
  };

  render() {
    const { handleServerPathReturnClick, isDisableAll } = this.props;
    const {
      serverPathStatus,
      isServerPathReturnReasonDisabled,
      isServerPathShowEditBtn,
      isServerPathError,
      serverPathReturnReasonValue,
      serverPathReasonStatus,
      serverPathReturnReasonInputValue,
      serverPathValue,
      isServerPathSectionChange,
    } = this.state.serverPathSectionState;
    const {
      isModalWithComponentOpen,
      modalTitle,
      okText,
      cancelText,
      textAreaPlacement,
      textAreaMaxLength,
      modalLabel,
      isShowTextArea,
      closable,
      modalWidth,
      handleOk,
      handleCancel,
    } = this.state.modalState;

    return (
      <div className={styles.procedureFileTop}>
        <div
          className={styles.clientServerPath}
          style={{ borderColor: isServerPathError ? "red" : "#b3b3b3" }}
        >
          <div className={styles.serverPathTop}>
            <div className={styles.procedureServerPathLabel}>
              Client Server Path:
            </div>
            <ExpendTextArea
              className={styles.procedureServerPathValue}
              value={serverPathValue}
              placeholder="Please enter Client Server Path"
              disabled={
                serverPathStatus === dataStatus.approval || isDisableAll
              }
              onChange={(value) => this.handleServerPathChange(value)}
              maxLength={500}
            />
          </div>
          <div className={styles.serverPathContent}>
            <div className={styles.statusBlock}>
              <div className={styles.statusLabel}>Status</div>
              <div className={styles.statusContent}>
                {isServerPathSectionChange !== 'ServerPathNoChange' && (
                  <SectionStatusDiv status={serverPathStatus} />
                )}
              </div>
            </div>
            <div className={styles.returnReasonBlock}>
              <div className={styles.returnReasonLabel}>Return Reason</div>
              <ReturnReasonInput
                inputRef={serverPathReturnRef}
                value={serverPathReturnReasonInputValue}
                isDisabled={isServerPathReturnReasonDisabled || isDisableAll}
                isShowEditBtn={isServerPathShowEditBtn}
                handleReturnEditClick={() => handleServerPathReturnClick()}
                isShowReasonLogIcon={
                  this.props.serverPathReturnReasonLog.length > 0
                }
                reasonLog={this.props.serverPathReturnReasonLog}
              />
            </div>
            <div className={styles.actionBtns}></div>
          </div>
        </div>
        <ModalWithComponent
          isModalOpen={isModalWithComponentOpen}
          closable={closable}
          okText={okText}
          cancelText={cancelText}
          title={modalTitle}
          className={styles.approvePageModal}
          handleOk={handleOk}
          handleCancel={handleCancel}
          isShowTextArea={isShowTextArea}
          textAreaValue={serverPathReturnReasonValue}
          textAreaOnChange={this.handleReturnTextAreaMethod}
          textAreaPlacement={textAreaPlacement}
          textAreaMaxLength={textAreaMaxLength}
          textAreaStatus={serverPathReasonStatus}
          label={modalLabel}
          modalWidth={modalWidth}
        />
      </div>
    );
  }
}
