import { Pagination } from "antd";
import BaseComponent from "../../common/BaseComponent";
import "./index.scss";

const itemRender = (_, type, originalElement) => {
  if (type === "prev") {
    return <span>{"<    "}Previous</span>;
  }
  if (type === "next") {
    return <span>Next{"    >"}</span>;
  }
  return originalElement;
};

export default class NormalPagination extends BaseComponent {
  render() {
    const {
      current,
      disabled,
      pageSize,
      pageSizeOptions = [10, 30, 50],
      total,
      onChange,
      showTotal,
    } = this.props;
    return (
      <div className="customPagination">
        <Pagination
          current={current}
          disabled={disabled}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          total={total}
          onChange={onChange}
          showSizeChanger
          itemRender={itemRender}
          showTotal={showTotal}
        />
      </div>
    );
  }
}
