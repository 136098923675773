import React, { createRef } from "react";
import {
  flowCategoryId,
  procedureJbStatus,
  handleSortFileByNameAndCreateDate,
} from "../../../publicDictionaryValues";
import ApplyForJbSubmitReadOnlySection from "../../../sections/applyForJbSection/applyForJbReadOnlySection/submitSection";
import ActiveShareTaskReadOnly from "../../../sections/sharedTaskSections/activeShareTaskReadOnly";
import BaseApplyJb from "../baseApplyJb";

const procedureFileRef = createRef();

export default class SubmitApplyForJbComplete extends BaseApplyJb {
  constructor(props) {
    super(props);
    const { rejectReason, rejectStatus } = this.props.pageProps;
    this.state = {
      pageState: {
        rejectReason,
        rejectStatus,
        isCancelDisabled: false,
        isSubmitDisabled: false,
        isRejectDisabled: false,
        isTableBorderError: false,
        isChange: false,
      },
      loading: false,
    };
  }

  handleReturnPageName = () => {
    if (this.props.pageProps.flowCategoryID === flowCategoryId.copyJBStatus) {
      return "Copy JB Status";
    }

    return "Apply for JB Status";
  };

  handleReturnShareTaskSection = () => {
    const { shareTaskData } = this.props;

    return <ActiveShareTaskReadOnly shareTaskData={shareTaskData} />;
  };

  handleFileData = (files) => {
    const dataList = [];
    if (files === null || files === undefined) {
      return;
    }
    const newFiles = handleSortFileByNameAndCreateDate(
      files,
      this._formateDate
    );
    newFiles.forEach((item) => {
      const lineId = this._generateGUID();
      const applyForValue = item.jbProcdure !== item.applyfor
        ? procedureJbStatus[item.applyfor]
        : ""
      dataList.push({
        data: [
          { name: "lineId", value: lineId },
          { name: "fileId", value: item.fileID },
          { name: "fileKey", value: item.fileKey },
          {
            name: "fileName",
            value: item.fileName,
          },
          {
            name: "uploadedDate",
            value: this._formateDate(item.createdDate),
          },
          { name: "uploadByName", value: item.uploadByName },
          { name: "uploadBy", value: item.uploadedBy },
          {
            name: "approveDate",
            value: this._formateDate(item.modifiedDate),
          },
          { name: "fileOperate", value: 1 },
          { name: "status", value: "" },
          { name: "previousStatus", value: "" },
          { name: "jbProcedure", 
            value: (this.props.isCopyPage && applyForValue) 
             ? applyForValue 
             : procedureJbStatus[item.jbProcdure]
          },
          {
            name: "applyfor",
            value: applyForValue,
            optionList: [],
          },
          { name: "isDelete", value: item.isDelete },
          { name: "rejectReason", value: item.rejectReason },
          { name: "attachmentFileID", value: item.attachmentFileID },
          { name: "attachment", value: item.attachmentName },
        ],
        checked: false,
      });
    });
    return dataList;
  };

  handleReturnProcedureFileSection = () => {
    const { applyJBStatus, isCopyPage } = this.props;

    return (
      <ApplyForJbSubmitReadOnlySection
        showHintMsg={false}
        procedureFileRef={procedureFileRef}
        serverPathValue={applyJBStatus.clientServerPath}
        savedInRadioValue={applyJBStatus.procedureSaveInCategoryID}
        procedureJbStatusId={applyJBStatus.jbProcedureSavedIn}
        applyfor={
          applyJBStatus.applyfor !== applyJBStatus.jbProcedureSavedIn
            ? applyJBStatus.applyfor
            : ""
        }
        procedureFileData={this.handleFileData(
          applyJBStatus.applyJBStatusFilesList
        )}
        rejectReason={applyJBStatus.rejectReason}
        isCopyPage={isCopyPage}
      />
    );
  };
}
