import React from "react";
import BaseComponent from "../../common/BaseComponent";
import { ExpendTextArea, ExpendTextAreaByProps } from "../input";
import NormalToolTip from "../toolTip";
import ReasonLogIcon from "../reasonLogIcon";
import EditIcon from "../EditIcon";
import ReturnReasonLogModal from "../returnReasonLogModal";
import "./index.scss";

export default class ReturnReasonInput extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      showToolTip: false,
      returnReasonLog: this.props.reasonLog,
      isReasonLogModalOpen: false,
    };
  }

  openToolTip = () => {
    this.setState({ showToolTip: true });
  };

  closeToolTip = () => {
    this.setState({ showToolTip: false });
  };

  handleReturnReasonLog = () => {
    this.setState({
      isReasonLogModalOpen: !this.state.isReasonLogModalOpen,
      returnReasonLog: this.state.isReasonLogModalOpen
        ? []
        : this.props.reasonLog,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.reasonLog !== this.props.reasonLog) {
      this.setState({ returnReasonLog: this.props.reasonLog });
    }
  }

  render() {
    const {
      value,
      isDisabled,
      isShowEditBtn,
      handleReturnEditClick,
      isShowReasonLogIcon,
      rowindex,
      inputRef,
      isTable,
    } = this.props;
    return isTable ? (
      <div className="returnReasonInput">
        <ExpendTextAreaByProps
          value={value}
          maxLength={1000}
          isExpend={true}
          readonly="readonly"
          className="ReasonInput"
          iconClassName="expendIcon"
          disabled={isDisabled}
          rowindex={rowindex}
          ref={inputRef}
        />
        {isShowEditBtn && (
          <EditIcon
            className="reasonEditIcon"
            onClick={handleReturnEditClick}
            rowindex={rowindex}
            altText="Edit Return Reason"
          />
        )}
        {isShowReasonLogIcon && (
          <NormalToolTip
            title={<span>View previous Return Reason</span>}
            element={
              <div
                onMouseEnter={() => this.openToolTip()}
                onMouseLeave={() => this.closeToolTip()}
              >
                <ReasonLogIcon
                  className="returnReasonLogIcon"
                  onClick={() => this.handleReturnReasonLog()}
                  altText="Return Reason Log"
                  rowindex={rowindex}
                />
              </div>
            }
            placement="right"
            color="#3E6CB5"
            rowindex={rowindex}
          />
        )}
        <ReturnReasonLogModal
          isReasonLogModalOpen={this.state.isReasonLogModalOpen}
          handleReturnReasonLog={this.handleReturnReasonLog}
          returnReasonLogData={this.state.returnReasonLog}
        />
      </div>
    ) : (
      <div className="returnReasonInput">
        <ExpendTextArea
          value={value}
          maxLength={1000}
          isExpend={true}
          readonly="readonly"
          className="ReasonInput"
          iconClassName="expendIcon"
          disabled={isDisabled}
          rowindex={rowindex}
          ref={inputRef}
        />
        {isShowEditBtn && (
          <EditIcon
            className="reasonEditIcon"
            onClick={handleReturnEditClick}
            rowindex={rowindex}
            altText="Edit Return Reason"
          />
        )}
        {isShowReasonLogIcon && (
          <NormalToolTip
            title={<span>View previous Return Reason</span>}
            element={
              <div
                onMouseEnter={() => this.openToolTip()}
                onMouseLeave={() => this.closeToolTip()}
              >
                <ReasonLogIcon
                  className="returnReasonLogIcon"
                  onClick={() => this.handleReturnReasonLog()}
                  altText="Return Reason Log"
                  rowindex={rowindex}
                />
              </div>
            }
            placement="right"
            color="#3E6CB5"
            rowindex={rowindex}
          />
        )}
        <ReturnReasonLogModal
          isReasonLogModalOpen={this.state.isReasonLogModalOpen}
          handleReturnReasonLog={this.handleReturnReasonLog}
          returnReasonLogData={this.state.returnReasonLog}
        />
      </div>
    );
  }
}
