import React from "react";
import InputLabel from "../../../../base/basicComponents/inputLabel";
import Input, { ExpendTextArea } from "../../../../base/basicComponents/input";
import NormalSelect from "../../../../base/basicComponents/select";
import BaseProcedureInformation from "../baseProcedureInfoSection";
import styles from "../index.module.scss";

export default class ReadOnlyProcedureInformation extends BaseProcedureInformation {
  render() {
    const { noteDisabled, title, submitData, ppoList, procedureData } =
      this.props;

    const sortPPOList = () => {
      const PPOList = ppoList.map((item) => {
        return {
          name: item.name,
          value: item.id,
        };
      });

      const sortList = (s, t) => {
        let a = s.name.toLowerCase();
        let b = t.name.toLowerCase();
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      };
      return PPOList.sort((s, t) => sortList(s, t));
    };
    const handleOptionList = (inputValue, option) =>
      (option?.title || "").toLowerCase().includes(inputValue.toLowerCase());

    const PPOValue = () => {
      const currentPPO = submitData?.primaryProcedureOwner || procedureData?.primaryProcedureOwner;
      if(currentPPO?.primaryProcedureOwnerName) {
        return currentPPO?.primaryProcedureOwnerName;
      }
      const PPO = sortPPOList().find((item) => item.value === currentPPO) ? currentPPO : null;
      return PPO;
    }

    const POValue = () => {
      const currentPO = submitData?.procedureOwner || procedureData?.procedureOwner;
      if(currentPO?.procedureOwnerName) {
        return currentPO?.procedureOwnerName;
      }
      const PO = sortPPOList().find((item) => item.value === currentPO) ? currentPO : null;
      return PO;
    }

    return (
      <div className={styles.ProcedureInformation}>
        <div className={styles.procedureInfoTop}>
          <div className={styles.informationTitle}>
            <span className={`${styles.titleText} fs-18`}>{title}</span>
          </div>
        </div>
        <div className={styles.informationContent}>
          <div className={styles.informationItem}>
            <InputLabel
              text="Procedure Status"
              className={styles.informationLabel}
            />
            <Input value={this.getProcedureStatus(submitData?.procedureStatus)} disabled={true} />
          </div>
          <div className={styles.informationItem}>
            <InputLabel
              text="PMP Excluded"
              className={styles.informationLabel}
            />
            <Input value={submitData?.isExcluded} disabled={true} />
          </div>
          <div className={styles.informationItem}>
            <InputLabel
              text="Primary Procedure Owner"
              className={styles.informationLabel}
            />
            <NormalSelect
              optionList={sortPPOList()}
              value={PPOValue()}
              showSearch={true}
              handleFilterOption={handleOptionList}
              disabled={true}
            />
          </div>
          <div className={styles.informationItem}>
            <InputLabel
              text="Procedure Owner"
              className={styles.informationLabel}
            />
            <NormalSelect
              optionList={sortPPOList()}
              value={POValue()}
              showSearch={true}
              handleFilterOption={handleOptionList}
              disabled={true}
            />
          </div>
          <div className={styles.informationItem}>
            <InputLabel text="Note" className={styles.informationLabel} />
            <ExpendTextArea
              className={styles.informationExpendTextArea}
              value={submitData?.procedureNote}
              disabled={noteDisabled}
            />
          </div>
        </div>
      </div>
    );
  }
}
