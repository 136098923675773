import { createRef } from "react";
import createSupportingFileService from "../../../../../service/supportingFile/createSupportingFileService";
import {
  actions,
  dataStatus,
  fileTags,
  proposedChangeTags,
} from "../../../../publicDictionaryValues";
import UpdateSupportingFileApproveSection from "../../../../sections/supportingFileSections/updateSupportingFileApporveSection";
import ApproveNewSupportingFilePending from "../../createSupportingFileProcess/approveNewSupportingFilePending";

const supportingFileTableRef = createRef();
const reasonRef = createRef();
const noteRef = createRef();
export default class ApproveUpdateSupportingFilePending extends ApproveNewSupportingFilePending {
  /* function about click submit button in approve page */
  handleApproveSupportingSubmitClickCheck = (
    procedureFileData,
    tableErrorList
  ) => {
    let isTableError = false;
    const errorIndexList = [];
    procedureFileData.forEach((fileData, index) => {
      const statusData = fileData.data.find((item) => item.name === "status");
      if (
        statusData.value === null &&
        fileData.data.find((item) => item.name === "fileName").tagValue !== null
      ) {
        procedureFileData[index].isError = true;
        isTableError = true;
        errorIndexList.push(index);
        supportingFileTableRef.current.updateDataList(procedureFileData);
        tableErrorList.push(statusData);
      }
    });
    if (isTableError) {
      const firstErrorTr = document.getElementById(
        `table-tr-${errorIndexList[0]}`
      );
      this.handleApproveSupportingControlErrorScroll(
        firstErrorTr.offsetTop + firstErrorTr.clientHeight
      );
    } else {
      this.handleCreateActionClickSectionCheckModal(
        "",
        this.modalContentLabels.submitConfirmModalLabel,
        this.handleSubmitOkClick,
        this.hideModal
      );
    }
  };

  /* function about click submit button in submit modal */
  handleSubmitOkClick = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        isSubmitDisabled: true,
      },
    });
    this.props._showLoading();
    this.hideModal();
    let isTableDataReturn = false;
    const fileList =
      this.props.supportingRef.current === null
        ? []
        : this.props.supportingRef.current.getDataList();
    try {
      fileList.forEach((fileData, index) => {
        const statusData = fileData.data.find((item) => item.name === "status");
        const proposedChangeValue = fileData.data.find(
          (item) => item.name === "proposedChange"
        ).value;
        const file = fileData.data.find((item) => item.name === "fileName");
        isTableDataReturn =
          statusData.value === dataStatus.return &&
          !(
            file.tagValue === fileTags.deleted &&
            proposedChangeValue === proposedChangeTags.new
          );
        if (isTableDataReturn) {
          throw new Error();
        }
      });
    } catch (e) {
      console.log(e);
    }

    if (isTableDataReturn) {
      this.para = this.props.handleReturnSubmitData(
        actions.return,
        "Return",
        this.props.supportingRef,
        this.props.approvePageRef
      );
    } else {
      this.para = this.props.handleReturnSubmitData(
        actions.approve,
        "Approve",
        this.props.supportingRef,
        this.props.approvePageRef
      );
    }
    this.createSupportingFileTriggerAction();
  };

  handleReturnTitle = () => {
    return "Approve Update Supporting File";
  };

  /* function about click submit or save button to trigger api */
  createSupportingFileTriggerAction = () => {
    createSupportingFileService.updateSupportingFileTriggerApprovePageAction(
      this.para,
      this._createSupportingFiletriggerSuccess,
      this._createSupportingFiletriggerError
    );
  };

  handleReturnSupportingFileSection = (obj) => {
    const {
      currentRole,
      isDisableAll,
      procedureReplaceFileRef,
      isTableBorderError,
    } = obj;
    return (
      <UpdateSupportingFileApproveSection
        sectionProps={{ currentRole, isDisableAll }}
        initSupportingTableData={this.props.initSupportingTableData}
        refs={{
          supportingRef: this.props.supportingRef,
          supportingFileTableRef,
          reasonRef,
          noteRef,
          procedureReplaceFileRef,
        }}
        data={this.props.data}
        isTableBorderError={isTableBorderError}
        downloadFile={this.props.downloadFile}
        isMount={this.props.isMount}
      />
    );
  };
}
