import ApproveProcedureFileSection from "../approveProcedureFileSection";
import {
  dataStatus,
  savedInRadioValues,
} from "../../../publicDictionaryValues";
import ApproveUpdateProcedureFileManagement from "../fields/procedureFileManagementFields/approveUpdateProcedureFileManagement";
import styles from "../index.module.scss";
import ApprovedSavedIn from "../fields/savedInFields/approvedSavedIn";
import ApproveClientServerPath from "../fields/serverPathFields/approveSeverPath";

export default class UpdateProcedureFileApproveSection extends ApproveProcedureFileSection {
  /**
   * return saved in section
   * @param {boolean} isDisableAll
   * @returns
   */
  handleReturnSavedIn = (isDisableAll) => {
    const {
      savedInReturnReasonInputValue,
      procedureSaveInCategoryStatusID,
      savedInRadioValue,
      isSavedInApproveBtnDisabled,
      isSavedInReturnBtnDisabled,
      initSavedInStatus,
      savedInReturnReasonLog,
      isSavedInSectionChange,
    } = this.props.savedInProps;
    const { procedureSavedInRef } = this.props.refs;
    return (
      <ApprovedSavedIn
        ref={procedureSavedInRef}
        savedInReturnReasonInputValue={savedInReturnReasonInputValue}
        procedureSaveInCategoryStatusID={procedureSaveInCategoryStatusID}
        isSavedInReturnBtnDisabled={isSavedInReturnBtnDisabled}
        isSavedInApproveBtnDisabled={isSavedInApproveBtnDisabled}
        isSavedInReturnReasonDisabled={
          savedInReturnReasonInputValue === null ||
          procedureSaveInCategoryStatusID === dataStatus.approval
        }
        savedInRadioValue={savedInRadioValue}
        initSavedInStatus={initSavedInStatus}
        savedInReturnReasonLog={savedInReturnReasonLog}
        isDisableAll={isDisableAll}
        isSavedInSectionChange={isSavedInSectionChange}
      />
    );
  };

  /**
   * return server path section
   * @param {boolean} isDisableAll
   * @returns
   */
  handleReturnServerPath = (isDisableAll) => {
    const { savedInRadioValue } = this.props.savedInProps;
    const {
      clientServerPathStatusName,
      clientServerPathStatusID,
      serverPathReturnReasonInputValue,
      isServerPathApproveBtnDisabled,
      isServerPathReturnBtnDisabled,
      clientServerPathReturnReason,
      serverPathReturnReasonLog,
      serverPathValue,
      isServerPathSectionChange,
    } = this.props.serverPathProps;
    const { procedureServerPathRef } = this.props.refs;
    const { currentRole } = this.props.sectionProps;
    return (
      savedInRadioValue !== savedInRadioValues.systemOnly &&
      savedInRadioValue !== null && (
        <ApproveClientServerPath
          clientServerPathReturnReason={clientServerPathReturnReason}
          serverPathStatusID={clientServerPathStatusID}
          isServerPathReturnBtnDisabled={isServerPathReturnBtnDisabled}
          isServerPathApproveBtnDisabled={isServerPathApproveBtnDisabled}
          isServerPathReturnReasonDisabled={
            serverPathReturnReasonInputValue === null ||
            clientServerPathStatusID === dataStatus.approval
          }
          initServerPathStatus={
            this.props.initServerPathSectionState.clientServerPathStatusID
          }
          serverPathReturnReasonInputValue={serverPathReturnReasonInputValue}
          isServerPathReturned={clientServerPathStatusName === "Return"}
          currentRole={currentRole}
          ref={procedureServerPathRef}
          serverPathReturnReasonLog={serverPathReturnReasonLog}
          serverPathValue={serverPathValue}
          isDisableAll={isDisableAll}
          isServerPathSectionChange={isServerPathSectionChange}
        />
      )
    );
  };

  render() {
    const { savedInRadioValue } = this.props.savedInProps;
    const { currentRole, isDisableAll } = this.props.sectionProps;
    const {
      procedureRef,
      procedureFileTableRef,
      reasonRef,
      noteRef,
      procedureFileRef,
      procedureReplaceFileRef,
    } = this.props.refs;

    return (
      <div className={styles.fileSection}>
        <div className={styles.fileSectionTitle}>Procedure Files</div>
        <div className={styles.fileSectionTop}>
          {this.handleReturnSavedIn(isDisableAll)}
          {this.handleReturnServerPath(isDisableAll)}
        </div>
        <div
          className={`${styles.fileSectionTable} ${
            this.state.procedureFileTableState.isTableBorderError
              ? styles.border_error
              : ""
          }`}
          hidden={savedInRadioValue === savedInRadioValues.serverOnly}
        >
          {savedInRadioValue !== savedInRadioValues.serverOnly &&
            savedInRadioValue !== null && (
              <ApproveUpdateProcedureFileManagement
                data={this.props.data}
                ptParams={this.props.ptParams}
                ref={procedureRef}
                procedureRef={procedureRef}
                fileTableRef={procedureFileTableRef}
                handleApprovedClick={(e) =>
                  this.handleProcedureFileTableApprovedClick(
                    e.target.attributes.rowindex.value
                  )
                }
                handleReturnClick={(e) =>
                  this.handleProcedureFileTableReturnClick(
                    e.target.attributes.rowindex.value
                  )
                }
                className="approveProcedureTable"
                pageType="approvePage"
                reasonRef={reasonRef}
                noteRef={noteRef}
                fileInputRef={procedureFileRef}
                tableReplaceFileInputRef={procedureReplaceFileRef}
                currentRole={currentRole}
                handleFileSelect={(e) => this.handleReplaceFile(e, false)}
                handleReplaceFileSelect={this.handleRowReplaceFileSelect}
                downloadFile={this.props.downloadFile}
                isShowExpendIcon={false}
                initProcedureTableData={[...this.props.initProcedureTableData]}
                isDisableAll={isDisableAll}
                isTableChange={this.state.procedureFileTableState.isChange}
                isTableBorderError={
                  this.state.procedureFileTableState.isTableBorderError
                }
                isLevelCChange={this.props.isLevelCChange}
              />
            )}
        </div>
      </div>
    );
  }
}
