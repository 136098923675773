import React from "react";
import BaseComponent from "../../common/BaseComponent";
import { Table } from "antd";
import { RetrunReasonLogInput } from "../input";
import "./style/index.scss";

export default class ReturnReasonLogTable extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
    };
    this.columns = [
      {
        title: "Return Date",
        dataIndex: "returnDate",
        key: "returnDate",
      },
      {
        title: "Returned By",
        dataIndex: "returnedBy",
        key: "returnedBy",
      },
      {
        title: "Return Reason",
        dataIndex: "returnReasonColumn",
        key: "returnReasonColumn",
        className: "returnReasonColumn",
        render: (text) => (
          <RetrunReasonLogInput
            value={text}
            ref={this.props.reasonRef}
            readonly="readonly"
          />
        ),
      },
    ];
  }
  render() {
    return (
      <div className="returnReasonLogTable">
        <Table
          className="antTable"
          dataSource={this.props.dataSource}
          columns={this.columns}
          pagination={false}
        />
      </div>
    );
  }
}
