export default function ListColumnBase() {
  const mapFilter = (
    filter,
    needFormat = false,
    formatFunction = null,
    isMyRequestCategory = false
  ) => {
    let filterList = [];
    if (filter && filter.length !== 0) {
      if (isMyRequestCategory) {
        filterList = filter.map((item) =>
          needFormat
            ? {
                text: formatFunction(item.name),
                value: formatFunction(item.name),
              }
            : {
                text: item.name,
                value: item.name,
              }
        );
      } else {
        filterList = filter.map((item) =>
          needFormat
            ? {
                text: formatFunction(item.name),
                value: formatFunction(item.id),
              }
            : {
                text: item.name,
                value: item.id,
              }
        );
      }
    }

    if(filterList.length > 0) {
      filterList.sort((a, b) => {
        const textA = a.text?.toUpperCase();
        const textB = b.text?.toUpperCase();
        if (textA < textB) {
          return -1;
        }
        if (textA > textB) {
          return 1;
        }
        return 0;
      });
    }

    return filterList;
  };

  const handleFilteredValue = (value) => {
    return value || null;
  };

  const handleWidth = (isHide) => {
    return isHide ? 0 : 220;
  };

  const handleTableSortOrder = (sortColumnName, tableSortData) => {
    if (tableSortData[0]?.sortColumn === sortColumnName) {
      return tableSortData[0].isAscending ? "ascend" : "descend";
    }
    return null;
  };

  const convertedFilter = (data) => {
    return data?.map(({ name, ...rest }) => ({
      ...rest,
      name: name === "true" ? "Yes" : "No",
    }));
  };

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    const formattedDate = `${month}/${day}/${year} ${hours}:${minutes}`;
    return formattedDate;
  };

  const formatDate = (dateTimeString) => {
    if (dateTimeString === null) {
      return null;
    }
    const date = new Date(dateTimeString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  };

  const generateGUID = () => {
    let crypto = window.crypto || window.msCrypto;
    if (!crypto) {
      console.log("Crypto API not supported");
      return;
    }
    let buf = new Uint16Array(8);
    crypto.getRandomValues(buf);
    return (
      buf[0].toString(16) +
      buf[1].toString(16) +
      "-" +
      buf[2].toString(16) +
      "-" +
      buf[3].toString(16) +
      "-" +
      buf[4].toString(16) +
      "-" +
      buf[5].toString(16) +
      buf[6].toString(16) +
      buf[7].toString(16)
    );
  };

  return {
    mapFilter,
    handleFilteredValue,
    handleWidth,
    handleTableSortOrder,
    convertedFilter,
    formatDateTime,
    formatDate,
    generateGUID,
  };
}
