import { message } from "antd";
import ProcessBaseFn from "../../../commonComponents/processBaseFn";
import { actions } from "../../../publicDictionaryValues";

export default class BaseShare extends ProcessBaseFn {
  handleApproveShareRejectOkClick = (triggerActionFn, ref) => {
    const rejectReasonValue = this.state.pageState.rejectReason;
    const reason =
      rejectReasonValue !== null ? rejectReasonValue.trim() : rejectReasonValue;
    if (reason === null || reason === "") {
      this.setState({
        pageState: { ...this.state.pageState, rejectStatus: "error" },
      });
    } else {
      this.setState({
        pageState: {
          ...this.state.pageState,
          rejectStatus: null,
          isRejectDisabled: true,
        },
      });
      this.props._showLoading();
      this.hideModal();
      const para = this.handleSubmitPara(actions.reject, "Reject", ref);
      triggerActionFn(para, this.rejectSuccess, this.rejectFailed);
    }
  };

  rejectSuccess = () => {
    this.props._hideLoading();
    this._alertSuccess("Reject Successful");
    this.props.closePage();
  };

  rejectFailed = () => {
    this.props._hideLoading();
    this._alertError("Reject Failed");
  };

  handleSubmitPara = (actionID, actionName, ref) => {
    return {
      procedureProcessId: this.props.shareTaskData.procedureProcessID,
      workflowProcessInstanceId: this.props.params.WorkflowProcessInstanceId,
      flowCategoryID: this.props.params.FlowCategoryID,
      actionName: actionName,
      actionID: actionID,
      rejectReason: this.state.pageState.rejectReason,
      procedureStepID: this.props.params.ProcedureStepID,
      shareTaskProcedureStepId:
        this.props.shareTaskData.shareTaskProcedureStepID,
      reason: this.state.returnOrRejectInputValue,
      tasks: ref.current.state.pendingApprovalData,
    };
  };

  handleApproveShareRejectCancelClick = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        rejectReason: null,
        rejectStatus: null,
      },
    });
    this.hideModal();
  };

  approveSuccess = () => {
    this.props._hideLoading();
    this._alertSuccess("Approve Successful");
    this.props.closePage();
  };

  approveFailed = () => {
    this.props._hideLoading();
    this._alertError("Approve Failed");
  };

  handleApproveConfirm = () => {
    // virtual function
  };

  checkHasPendingSignOffConfirm = () => {
    this.setState({
      isApproveComfirmOpen: true,
      confirmModalText: "Are you sure to approve this request?",
      handleApproveComfirm: this.handleApproveConfirm,
    });
  };

  checkHasPendingSignOffSuccess = (result) => {
    if (result) {
      this.setState({
        isApproveComfirmOpen: true,
        confirmModalText:
          "There is a pending Upload Sign-off Confirmation process of the added/removed task. Please deal with the process timely or system will automatically terminate it after this process completed.",
        handleApproveComfirm: this.checkHasPendingSignOffConfirm,
      });
    } else {
      this.setState({
        isApproveComfirmOpen: true,
        confirmModalText: "Are you sure to approve this request?",
        handleApproveComfirm: this.handleApproveConfirm,
      });
    }
  };

  checkHasPendingSignOffFail = () => {
    this._alertError("Check Failed");
  };

  checkUploadSignOff = () => {
    // virtual function
  };

  getCheckUploadSignOffPara = (ref) => {
    const currentData = JSON.parse(
      JSON.stringify(ref.current.state.pendingApprovalData)
    );
    const para = {
      procedureStepId: this.props.params.ProcedureStepID,
      taskList: currentData,
    };

    return para;
  };

  checkApproveSuccess = (result) => {
    if (!result.result) {
      const responseData = result.resultList;
      let inactiveIds = "";

      for (let item of responseData) {
        if (!item.checkResult && item.msgCode === 29) {
          inactiveIds += `${item.taskId}, `;
        }
      }

      if (inactiveIds !== "") {
        const regex = /,\s*$/;
        inactiveIds = inactiveIds.replace(regex, "");
        let msg = `${inactiveIds} task(s) is inactive, please return or reject the request`;
        message.error({
          content: msg,
          className: "inactive-Task-Message",
          duration: 6,
        });
      }
    } else {
      this.checkUploadSignOff();
    }
  };

  checkApproveError = () => {
    this._alertError("Check Approve Fail");
  };

  checkApprove = (ref, checkApproveFn) => {
    const currentData = JSON.parse(
      JSON.stringify(ref.current.state.pendingApprovalData)
    );
    const para = {
      procedureId: this.props.params.ProcedureID,
      taskList: currentData,
      procedureStepId: this.props.params.ProcedureStepID,
    };
    checkApproveFn(para, this.checkApproveSuccess, this.checkApproveError);
  };
}
