import { Table } from "antd";
import BaseComponent from "../../../../base/common/BaseComponent";
import "../../../../app/commonComponents/listTable/antTableCustom.scss";
class ShareTaskTable extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, columns } = this.props;

    return (
      <div>
        <Table
          className="antTable"
          columns={columns}
          rowKey={this._generateGUID()}
          dataSource={data}
          pagination={false}
          rowClassName={(record) => (record.isCurrentTask ? "bold" : "")}
          scroll={this.props.scroll}
        />
      </div>
    );
  }
}

export default ShareTaskTable;
