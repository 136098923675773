import { createRef } from "react";
import UpdateProcedureFileReviseSection from "../../../../sections/procedureFileSections/updateProcedureFileReviseSection";
import styles from "../../style/index.module.scss";
import ProcedureBlock from "../../../../../base/basicComponents/procedureBlock";
import ReadOnlySupportingFileSection from "../../../../sections/supportingFileSections/readOnlySupportingFileSection";
import {
  actions,
  dataStatus,
  deepClone,
  fileTags,
  handleRevisionInfoManatoryCheck,
  proposedChangeTags,
  revisionCategories,
  revisionInfoMandatoryCheck,
  savedInRadioValues,
} from "../../../../publicDictionaryValues";
import DetailRevisionInformation from "../../../../sections/procedureFileSections/fields/revisionInformationFields/detailRevisionInformation";
import ProcedureDetailService from "../../../../../service/procedureFile/ProcedureDetailService";
import ReviseNewProcedurePending from "../../createNewProcedureProcess/reviseNewProcedurePending";
import AppSetting from "../../../../../config/AppSetting";
import StepService from "../../../../../service/stepBase/StepService";
import StepBusinessType from "../../../../../service/stepBase/stepBusinessType";

const procedureFileTableRef = createRef();
const reasonRef = createRef();
const noteRef = createRef();
const procedureFileRef = createRef();
const supportingFileRef = createRef();
const supportingFileTableRef = createRef();
export default class ReviseUpdateProcedurePending extends ReviseNewProcedurePending {
  /* function about control save button disable when procedure file table change */
  setIsRevisionInfoChange = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        isRevisionInfoChange: true,
      },
    });
  };

  /* function about control save button disable when procedure file table change */
  cancelIsRevisionInfoChange = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        isRevisionInfoChange: false,
      },
    });
  };

  /**
   * function about return page name
   * @returns
   */
  handleReturnPageName = () => {
    return "Revise Update Procedure";
  };

  /**
   * function about update saved in value state
   * @param {string} value current saved in value
   * @param {string} prevValue last saved in value
   */
  updateSavedInRadioValue = (value, prevValue) => {
    if (
      (value === savedInRadioValues.serverOnly || value === null) &&
      prevValue !== savedInRadioValues.serverOnly &&
      prevValue !== null
    ) {
      const dataList =
        this.props.procedureRef.current !== null
          ? deepClone(this.props.procedureRef.current.getDataList())
          : [];
      this.prevProcedureDataList = dataList;
    }
    this.setState({
      savedInState: { ...this.state.savedInState, savedInRadioValue: value },
    });
    if (value !== prevValue) {
      this.isTableBorderError = false;
      this.setState({
        pageState: { ...this.state.pageState, isTableBorderError: false },
      });
    }
  };

  /**
   * function about return revision information section
   * @returns
   */
  handleReturnRevisionInformation = () => {
    return (
      <ProcedureBlock
        className={`${styles.approveProcedureFile} revisionInfoSection`}
        child={
          <DetailRevisionInformation
            title="Revision Information"
            updateFileNumLoading={this.props.updateFileNumLoading}
            openLoading={this.props._showLoading}
            hideLoading={this.props._hideLoading}
            accountId={this.props.pageProps.procedureAccountID}
            ref={this.props.revisionInfoRef}
            isDisabledAll={false}
            revisionInfoData={this.props.revisionInfoData}
            initRevisionInfoState={this.props.initRevisionInfoState}
            setIsRevisionInfoChange={this.setIsRevisionInfoChange}
            cancelIsRevisionInfoChange={this.cancelIsRevisionInfoChange}
          />
        }
        isShowArrow={true}
        isOpen={true}
      />
    );
  };

  /**
   * function about return procedure file section
   * @param {object} obj
   * @returns
   */
  handleReturnProcedureFileSection = () => {
    const { currentRole, isDisableAll, procedureReplaceFileRef } =
      this.props.pageProps;
    const {
      data,
      downloadFile,
      isMount,
      initSavedInSectionState,
      initServerPathSectionState,
      initProcedureTableData,
      updateFileNumLoading,
      procedureSavedInRef,
      procedureServerPathRef,
      procedureRef,
    } = this.props;
    return (
      <ProcedureBlock
        className={styles.approveProcedureFile}
        child={
          <UpdateProcedureFileReviseSection
            sectionProps={{ currentRole, isDisableAll }}
            refs={{
              procedureSavedInRef: procedureSavedInRef,
              procedureServerPathRef: procedureServerPathRef,
              procedureRef: procedureRef,
              procedureFileTableRef,
              reasonRef,
              noteRef,
              procedureFileRef,
              procedureReplaceFileRef,
            }}
            data={data}
            isTableBorderError={this.isTableBorderError}
            downloadFile={downloadFile}
            isMount={isMount}
            savedInProps={this.state.savedInState}
            serverPathProps={this.state.serverPathState}
            initSavedInSectionState={initSavedInSectionState}
            initServerPathSectionState={initServerPathSectionState}
            initProcedureTableData={initProcedureTableData}
            cancelIsTableChange={this.cancelIsTableChange}
            setIsTableChange={this.setIsTableChange}
            updateProcedureFileNum={updateFileNumLoading}
            updateSavedInRadioValue={this.updateSavedInRadioValue}
            updateServerPathValue={this.updateServerPathValue}
            _showLoading={this.props._showLoading}
            _hideLoading={this.props._hideLoading}
          />
        }
        isShowArrow={true}
        isOpen={true}
      />
    );
  };

  handleReturnSupportingFileSection = (obj) => {
    return (
      <ProcedureBlock
        className={styles.approveSupportingFile}
        child={
          <ReadOnlySupportingFileSection
            supportingFileData={this.props.supportingTableData}
            showHintMsg={true}
            supportingFileRef={supportingFileRef}
            supportingFileTableRef={supportingFileTableRef}
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.props.SetSupportingData,
            this.setError,
            [StepBusinessType.supportingFile]
          );
        }}
      />
    );
  };

  handleSubmitTip = () => {
    const isProcedureStorageChange =
      this.props.revisionInfoRef.current.state.currentCategory ===
      revisionCategories.storageChange;
    if (isProcedureStorageChange) {
      this.handleCreateActionClickSectionCheckModal(
        "",
        this.modalContentLabels.submitWithStorageChangeLabel,
        this.handleSubmitOkClick,
        this.hideModal
      );
    } else {
      this.handleCreateActionClickSectionCheckModal(
        "",
        this.modalContentLabels.submitConfirmModalLabel,
        this.handleSubmitOkClick,
        this.hideModal
      );
    }
  };

  handleRevisionInfoCheck = () => {
    const isRevisonInfoError = handleRevisionInfoManatoryCheck(
      this.props.revisionInfoRef
    );
    if (isRevisonInfoError) {
      const revisionInfo = document.getElementsByClassName(
        "revisionInfoSection"
      )[0];
      revisionInfoMandatoryCheck(this.props.revisionInfoRef, revisionInfo);
      this.handleControlErrorScroll(revisionInfo.offsetTop);
    }
  };

  /* function about click submit button in revise page to scroll page if table has error */
  constrolTableScrollWhenSubmit = (
    isTableError,
    procedureFileData,
    errorIndexList,
    ref
  ) => {
    if (isTableError) {
      if (this.isTableBorderError) {
        procedureFileData.forEach((item, index) => {
          procedureFileData[index].isError = true;
        });
        const tableEle = document.getElementsByClassName(
          "reviseProcedureTable"
        )[0];
        tableEle !== undefined &&
          this.handleControlErrorScroll(
            tableEle.offsetTop + tableEle.clientHeight
          );
        this.isTableBorderError = true;
        this.setState({
          pageState: { ...this.state.pageState, isTableBorderError: true },
        });
      } else {
        const firstErrorTr = document.getElementById(
          `table-tr-${errorIndexList[0]}`
        );
        const revisionInfo = document.getElementsByClassName(
          "revisionInfoSection"
        )[0];
        this.handleControlErrorScroll(
          firstErrorTr.offsetTop +
            firstErrorTr.clientHeight +
            revisionInfo.clientHeight
        );
      }
      procedureFileTableRef.current.updateDataList(procedureFileData);
    } else {
      const isRevisonInfoError = handleRevisionInfoManatoryCheck(
        this.props.revisionInfoRef
      );
      if (isRevisonInfoError) {
        this.handleRevisionInfoCheck();
      } else {
        this.handleSubmitTip();
      }
    }
  };

  /* function about click submit button in revise page if returned files don't do change */
  handleCheckFileTableHighLight = (
    procedureFileData,
    tableErrorList,
    isTableError
  ) => {
    let errorIndexList = [];
    let deleteList = [];
    procedureFileData.map((fileData, index) => {
      const isReviewed = fileData.data.find(
        (file) => file.name === "isReviewed"
      ).value;
      const proposedChange = fileData.data.find(
        (file) => file.name === "proposedChange"
      ).value;
      const fileTag = fileData.data.find(
        (file) => file.name === "fileName"
      ).tagValue;
      const status = fileData.data.find((file) => file.name === "status").value;
      if (
        !isReviewed &&
        status !== dataStatus.approval &&
        !(
          proposedChange === null ||
          (proposedChange === proposedChangeTags.new &&
            fileTag === fileTags.deleted) ||
          (status === null && proposedChange === proposedChangeTags.new)
        )
      ) {
        errorIndexList = [];
        tableErrorList.push(procedureFileData[index]);
        errorIndexList.push(index);
        isTableError = true;
        procedureFileData[index].isError = true;
      }
    });
    if (
      tableErrorList.length === procedureFileData.length ||
      deleteList.length === procedureFileData.length
    ) {
      this.isTableBorderError = true;
      this.setState({
        pageState: { ...this.state.pageState, isTableBorderError: true },
      });
      isTableError = true;
    }

    this.constrolTableScrollWhenSubmit(
      isTableError,
      procedureFileData,
      errorIndexList
    );
  };

  /* function about click submit button in revise page */
  handleReviseSubmitClickCheck = (procedureFileData, tableErrorList) => {
    let isTableError = false;
    const isRevisonInfoError = handleRevisionInfoManatoryCheck(
      this.props.revisionInfoRef
    );
    if (this.state.savedInState.savedInRadioValue === null) {
      if (isRevisonInfoError) {
        this.handleRevisionInfoCheck();
      } else {
        this.handleSubmitTip();
      }
    } else if (
      this.state.savedInState.savedInRadioValue ===
      savedInRadioValues.systemOnly
    ) {
      this.handleRevisionInfoCheck();
      this.handleCheckFileTableHighLight(
        procedureFileData,
        tableErrorList,
        isTableError
      );
    } else if (
      this.state.savedInState.savedInRadioValue ===
      savedInRadioValues.systemAndClientServer
    ) {
      if (
        this.state.serverPathState.serverPathValue === null ||
        this.state.serverPathState.serverPathValue.trim() === ""
      ) {
        this.handleCheckServerPathHighLight();
      } else if (isRevisonInfoError) {
        this.handleRevisionInfoCheck();
      } else {
        this.handleCheckFileTableHighLight(
          procedureFileData,
          tableErrorList,
          isTableError
        );
      }
    } else if (
      this.state.savedInState.savedInRadioValue ===
      savedInRadioValues.serverOnly
    ) {
      if (
        this.state.serverPathState.serverPathValue === null ||
        this.state.serverPathState.serverPathValue.trim() === ""
      ) {
        this.handleCheckServerPathHighLight();
      } else if (isRevisonInfoError) {
        this.handleRevisionInfoCheck();
      } else {
        this.handleSubmitTip();
      }
    }
  };

  /* function about click submit or save button to trigger api */
  createProcedureFileTriggerAction = () => {
    ProcedureDetailService.updateProcedureTriggerRevisePageAction(
      this.para,
      this._triggerSuccess,
      this._triggerError
    );
  };

  handleSaveOrSubmitClickAdjustDeleteFile = (actionType, actionName) => {
    const deleteIds = this.props.revisionInfoRef.current.state.deleteIds;
    if (deleteIds.length > 0) {
      deleteIds.forEach((item, index) => {
        let dto = {
          ProductKey: AppSetting.urlPrefix.deleteFile.ProductKey,
          StorageFileID: item,
        };
        ProcedureDetailService.deleteFile(
          dto,
          () => {
            this.props.revisionInfoRef.current.state.currentAttachment.splice(
              index,
              1
            );
            this.props.revisionInfoRef.current.handleSetAttachmentFromProps(
              this.props.revisionInfoRef.current.state.currentAttachment
            );
            this.para = this.props.handleReturnSubmitData(
              actionType,
              actionName,
              this.props.procedureSavedInRef,
              this.props.procedureServerPathRef,
              this.props.procedureRef,
              undefined,
              this.props.revisionInfoRef,
              this.props.isLevelCChange,
            );
            this.createProcedureFileTriggerAction();
          },
          (response) => {
            this._alertError(response);
          },
          item
        );
      });
    } else {
      this.para = this.props.handleReturnSubmitData(
        actionType,
        actionName,
        this.props.procedureSavedInRef,
        this.props.procedureServerPathRef,
        this.props.procedureRef,
        undefined,
        this.props.revisionInfoRef,
        this.props.isLevelCChange,
      );
      this.createProcedureFileTriggerAction();
    }
  };

  /* function about click save button */
  handleSaveClick = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        actionID: actions.save,
      },
    });
    this.isSaveDisabled = true;
    this.props._showLoading();
    this.handleSaveOrSubmitClickAdjustDeleteFile(actions.save, "Save");
  };

  /* function about click submit button in submit modal */
  handleSubmitOkClick = (actionType, actionName) => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        isSubmitDisabled: true,
      },
    });
    this.props._showLoading();
    this.hideModal();
    this.handleSaveOrSubmitClickAdjustDeleteFile(actions.revise, "Revise");
  };

  /**
   * function about control save as draft button disabled
   * @param {*} isChange flag about is table change
   * @param {*} savedInRadioValue the value of saved in radio
   */
  controlIsSaveDisabled = (isChange, savedInRadioValue) => {
    if (this.props.procedureSavedInRef.current !== null) {
      const initSavedInRadioValue =
        this.props.initSavedInSectionState.savedInRadioValue;
      const { isRevisionInfoChange } = this.state.pageState;
      if (savedInRadioValue === savedInRadioValues.systemAndClientServer) {
        const currentServerPath = this.state.serverPathState.serverPathValue;
        const initServerPath =
          this.props.initServerPathSectionState.serverPathValue;
        this.isSaveDisabled =
          !isChange &&
          savedInRadioValue === initSavedInRadioValue &&
          currentServerPath === initServerPath &&
          !isRevisionInfoChange;
      } else if (savedInRadioValue === savedInRadioValues.systemOnly) {
        this.isSaveDisabled =
          !isChange &&
          savedInRadioValue === initSavedInRadioValue &&
          !isRevisionInfoChange;
      } else if (savedInRadioValue === null) {
        this.isSaveDisabled =
          savedInRadioValue === initSavedInRadioValue && !isRevisionInfoChange;
      } else {
        const currentServerPath = this.state.serverPathState.serverPathValue;
        const initServerPath =
          this.props.initServerPathSectionState.serverPathValue;
        this.isSaveDisabled =
          savedInRadioValue === initSavedInRadioValue &&
          currentServerPath === initServerPath &&
          !isRevisionInfoChange;
      }
    }
  };
}
