import React, { createRef } from "react";
import ProcessBaseFn from "../../../../commonComponents/processBaseFn";
import ProcedureBlock from "../../../../../base/basicComponents/procedureBlock";
import Buttons from "../../../../../base/basicComponents/button";
import NormalToolTip from "../../../../../base/basicComponents/toolTip";
import { ModalWithComponent } from "../../../../../base/basicComponents/modal";
import InputLabel from "../../../../../base/basicComponents/inputLabel";
import PageHeader from "../../../../commonComponents/pageHeaders/PageHeader";
import TaskInformation from "../../../../sections/taskInfoSections";
import ProcedureInformation from "../../../../sections/procedureInfoSections/readOnlyProcedureInfoSection";
import ApproveProcedureFileSection from "../../../../sections/procedureFileSections/approveProcedureFileSection";
import ReadOnlySupportingFileSection from "../../../../sections/supportingFileSections/readOnlySupportingFileSection";
import {
  actions,
  dataStatus,
  fileTags,
  savedInRadioValues,
} from "../../../../publicDictionaryValues";
import redTipIcon from "../../../../../assets/image/redTipIcon.png";
import styles from "../../style/index.module.scss";
import ActiveShareTaskReadOnly from "../../../../sections/sharedTaskSections/activeShareTaskReadOnly";
import ProcedureDetailService from "../../../../../service/procedureFile/ProcedureDetailService";
import appService from "../../../../../service/app";
import LostPermissionModal from "../../../../sections/lostPermissionModal";
import StepService from "../../../../../service/stepBase/StepService";
import StepBusinessType from "../../../../../service/stepBase/stepBusinessType";

const procedureFileTableRef = createRef();
const reasonRef = createRef();
const noteRef = createRef();
const procedureFileRef = createRef();
const supportingFileRef = createRef();
const supportingFileTableRef = createRef();

export default class ApproveNewProcedurePending extends ProcessBaseFn {
  constructor(props) {
    super(props);
    this.state = {
      pageState: this.handleApproveNewProcedurePendingPageState(),
      modalState: this.handleApproveNewProcedurePendingModalState(),
      noPermission: false,
    };
    this.modalContentLabels =
      this.handleApproveNewProcedurePendingModalContentLabels();
    this.para = null;
    this.params = {
      TaskId: this.props.params.ProcedureTaskId,
      ProcedureID: this.props.params.ProcedureID,
      ProcedureStepID: this.props.params.ProcedureStepID,
    };
  }

  handleApproveNewProcedurePendingPageState = () => {
    const { newRejectReason, rejectStatus } = this.props.pageProps;

    return {
      rejectReason: newRejectReason,
      rejectStatus,
      isCancelDisabled: false,
      isSubmitDisabled: false,
      isRejectDisabled: false,
      isTableBorderError: false,
    };
  };

  handleApproveNewProcedurePendingModalState = () => {
    return {
      modalTitle: "",
      modalLabel: "",
      okText: "",
      isModalWithComponentOpen: false,
      isShowTextArea: false,
      closable: false,
      isOkBtnDisabled: false,
      isCancelBtnDisabled: false,
      maskClosable: false,
      textAreaPlacement: "",
      cancelText: "",
      actionType: "",
      textAreaMaxLength: 0,
      textAreaClassName: "",
      isShowSelect: false,
      fileIds: [],
      fileNames: [],
      isShowTextAreaAndUploadDragger: false,
      uploadDraggerFileType: "",
      rowIndex: 0,
      modalWidth: 520,
    };
  };

  handleApproveNewProcedurePendingModalContentLabels = () => {
    return {
      cancelConfimModalLabel: (
        <span className={styles.modalText}>
          Are you sure to quit without saving?
        </span>
      ),
      rejectModalWarningLabel: (
        <>
          <div className={styles.rejectModalWarning}>
            Are you sure to reject this request? Reject means that the process
            will be terminated. The status will be changed back to the original
            status before this initiation.
          </div>
          <InputLabel text="Reject Reason" required={true} />
        </>
      ),
      submitConfirmModalLabel: (
        <span className={styles.modalText}>Are you sure to submit?</span>
      ),
    };
  };

  /* success callback about click submit or save button to trigger api */
  _triggerSuccess = (response) => {
    if (response.succeeded === false) {
      this._alertError(response.message);
    } else {
      this.props.closePage();
    }
    this._hideLoading();
  };

  /* error callback about click submit or save button to trigger api */
  _triggerError = (response) => {
    this._alertError(response);
  };

  /* function about click submit or save button to trigger api */
  createProcedureFileTriggerAction = () => {
    ProcedureDetailService.createProcedureTriggerApprovePageAction(
      this.para,
      this._triggerSuccess,
      this._triggerError
    );
  };

  setApproveNewProcedurePendingSubmitDisabled = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        isSubmitDisabled: true,
      },
    });
  };

  setApproveNewProcedurePendingIsSavedInReturn = () => {
    return (
      this.props.procedureSavedInRef.current.state.savedInSectionState
        .procedureSaveInCategoryStatusID === dataStatus.return
    );
  };

  setApproveNewProcedurePendingIsServerPathReturn = () => {
    return (
      this.props.procedureServerPathRef.current !== null &&
      this.props.procedureServerPathRef.current.state.serverPathSectionState
        .serverPathStatus === dataStatus.return
    );
  };

  setApproveNewProcedurePendingFileList = () => {
    return this.props.procedureRef.current === null
      ? []
      : this.props.procedureRef.current.getDataList();
  };

  /* function about click submit button in submit modal */
  handleApproveNewProcedurePendingSubmitOkClick = () => {
    this.setApproveNewProcedurePendingSubmitDisabled();
    this.props._showLoading();
    this.hideModal();
    let isApproveNewProcedurePendingTableDataReturn = false;
    const isSavedInReturn = this.setApproveNewProcedurePendingIsSavedInReturn();
    const isServerPathReturn =
      this.setApproveNewProcedurePendingIsServerPathReturn();
    const fileList = this.setApproveNewProcedurePendingFileList();
    try {
      fileList.forEach((fileData, index) => {
        const statusData = fileData.data.find((item) => item.name === "status");
        const file = fileData.data.find((item) => item.name === "fileName");
        isApproveNewProcedurePendingTableDataReturn =
          statusData.value === dataStatus.return &&
          file.tagValue !== fileTags.deleted;
        if (isApproveNewProcedurePendingTableDataReturn) {
          throw new Error();
        }
      });
    } catch (e) {
      console.log(e);
    }

    if (
      isApproveNewProcedurePendingTableDataReturn ||
      isSavedInReturn ||
      isServerPathReturn
    ) {
      this.para = this.props.handleReturnSubmitData(
        actions.return,
        "Return",
        this.props.procedureSavedInRef,
        this.props.procedureServerPathRef,
        this.props.procedureRef,
        this.props.approvePageRef,
        this.props.revisionInfoRef
      );
    } else {
      this.para = this.props.handleReturnSubmitData(
        actions.approve,
        "Approve",
        this.props.procedureSavedInRef,
        this.props.procedureServerPathRef,
        this.props.procedureRef,
        this.props.approvePageRef,
        this.props.revisionInfoRef
      );
    }
    this.createProcedureFileTriggerAction();
  };

  handleApproveNewProcedurePendingRejectSubmit = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        rejectStatus: null,
        isRejectDisabled: true,
      },
    });
    this.props._showLoading();
    this.hideModal();
    this.para = this.props.handleReturnSubmitData(
      actions.reject,
      "Reject",
      this.props.procedureSavedInRef,
      this.props.procedureServerPathRef,
      this.props.procedureRef,
      this.props.approvePageRef,
      this.props.revisionInfoRef
    );
    this.createProcedureFileTriggerAction();
  };

  /* function about click submit button in reject modal */
  handleRejectOkClick = () => {
    const rejectReasonValue = this.state.pageState.rejectReason;
    const reason =
      rejectReasonValue !== null ? rejectReasonValue.trim() : rejectReasonValue;
    if (reason === null || reason === "") {
      this.setState({
        pageState: { ...this.state.pageState, rejectStatus: "error" },
      });
    } else {
      this.handleApproveNewProcedurePendingRejectSubmit();
    }
  };

  /* function about click submit button in cancel action modal */
  handleCancelOkClick = () => {
    this.setState({
      pageState: { ...this.state.pageState, isCancelDisabled: true },
    });
    this.hideModal();
    this.props.closePage();
  };

  /* function about click cancel button in reject modal */
  handleApproveNewProcedureRejectCancelClick = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        rejectReason: null,
        rejectStatus: null,
      },
    });
    this.hideModal();
  };

  handleControlErrorScroll = (top) => window.scrollTo(0, top || 0);

  /* function about click submit button in revise page if server path doesn't enter required field*/
  handleCheckServerPathHighLight = () => {
    this.props.procedureServerPathRef.current.setServerPathError();
    this.props.procedureServerPathRef.current !== null &&
      this.handleControlErrorScroll(
        this.props.procedureServerPathRef.current.offsetTop
      );
  };

  handleApproveSubmitClickCheckRequired = () => {
    if (
      this.props.procedureSavedInRef.current.state.savedInSectionState
        .procedureSaveInCategoryStatusID === null
    ) {
      this.props.procedureSavedInRef.current.setSavedInError();
      this.handleControlErrorScroll(
        this.props.procedureSavedInRef.current.offsetTop
      );
    }
    if (
      this.props.procedureServerPathRef.current !== null &&
      this.props.procedureServerPathRef.current.state.serverPathSectionState
        .serverPathStatus === null &&
      this.props.savedInProps.savedInRadioValue !==
        savedInRadioValues.systemOnly
    ) {
      this.handleCheckServerPathHighLight();
    }
  };

  /* function about click submit button in approve page */
  handleApproveSubmitClickCheck = (procedureFileData, tableErrorList) => {
    let isTableError = false;
    const errorIndexList = [];
    procedureFileData.forEach((fileData, index) => {
      const statusData = fileData.data.find((item) => item.name === "status");
      if (
        statusData.value === null &&
        fileData.data.find((item) => item.name === "fileName").tagValue !==
          fileTags.deleted
      ) {
        procedureFileData[index].isError = true;
        isTableError = true;
        errorIndexList.push(index);
        procedureFileTableRef.current.updateDataList(procedureFileData);
        tableErrorList.push(statusData);
      }
    });
    if (
      this.props.procedureSavedInRef.current.state.savedInSectionState
        .procedureSaveInCategoryStatusID === null ||
      (this.props.savedInProps.savedInRadioValue !==
        savedInRadioValues.systemOnly &&
        this.props.procedureServerPathRef.current !== null &&
        this.props.procedureServerPathRef.current.state.serverPathSectionState
          .serverPathStatus === null)
    ) {
      this.handleApproveSubmitClickCheckRequired();
    } else if (
      isTableError &&
      this.props.savedInProps.savedInRadioValue !==
        savedInRadioValues.serverOnly
    ) {
      const firstErrorTr = document.getElementById(
        `table-tr-${errorIndexList[0]}`
      );
      this.handleControlErrorScroll(
        firstErrorTr.offsetTop + firstErrorTr.clientHeight
      );
    } else {
      this.handleCreateActionClickSectionCheckModal(
        "",
        this.modalContentLabels.submitConfirmModalLabel,
        this.handleApproveNewProcedurePendingSubmitOkClick,
        this.hideModal
      );
    }
  };

  _checkStepPermissionSuccess = (result, actionType) => {
    this.props._hideLoading();
    const { statusCode } = result;
    const noPermission = statusCode !== 200;
    this.setState({
      noPermission,
    });
    if (noPermission) return;
    if (actionType === "submit") {
      this.handleSubmitClick();
    } else if (actionType === "reject") {
      this.handleApproveNewProcedureRejectClick();
    }
  };

  checkStepPermission = (actionType) => {
    this.props._showLoading();
    const param = {
      taskID: this.props.params.ProcedureTaskId,
      procedureID: this.props.params.ProcedureID,
      procedureStepID: this.props.params.ProcedureStepID,
      parentPage: null,
    };
    appService.checkStepPermission(
      param,
      (response) => this._checkStepPermissionSuccess(response, actionType),
      (response) => this._alertError(response.message)
    );
  };

  /* function about click submit button in approve page */
  handleSubmitClick = () => {
    const procedureFileData =
      this.props.procedureRef.current === null
        ? []
        : this.props.procedureRef.current.getDataList();
    let tableErrorList = [];
    this.handleApproveSubmitClickCheck(procedureFileData, tableErrorList);
  };

  /* function about click reject button */
  handleApproveNewProcedureRejectClick = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: true,
        modalTitle: <span className="fs-18">Reject</span>,
        isShowTextArea: true,
        modalLabel: this.modalContentLabels.rejectModalWarningLabel,
        textAreaMaxLength: 1000,
        textAreaClassName: styles.rejectReasonInput,
        okText: "Confirm",
        cancelText: "Cancel",
        actionType: "reject",
        modalWidth: 520,
        handleCancel: this.handleApproveNewProcedureRejectCancelClick,
        handleOk: this.handleRejectOkClick,
      },
    });
  };

  /* function about click submit button in revise page to show the tip modal */
  handleCreateActionClickSectionCheckModal = (
    modalTitle,
    modalLabel,
    okMethod,
    cancelMethod
  ) => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: true,
        okText: "Confirm",
        cancelText: "Cancel",
        handleOk: okMethod,
        handleCancel: cancelMethod,
        modalWidth: 520,
        modalTitle: modalTitle,
        modalLabel: modalLabel,
      },
    });
  };

  /**
   * function about enter reject reason in reject modal
   * @param {object} e
   */
  handleApproveNewProcedurePendingSetRejectValue = (e) => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        rejectReason: e.target.value,
        rejectStatus: null,
      },
    });
  };

  /**
   * function about click cancel button
   */
  handleCancelClick = () =>
    this.handleCreateActionClickSectionCheckModal(
      "",
      this.modalContentLabels.cancelConfimModalLabel,
      this.handleCancelOkClick,
      this.hideModal
    );

  /**
   * function about return cancel button
   * @param {boolean} isCancelDisabled
   * @returns
   */
  handleReturnCancelButton = (isCancelDisabled) => (
    <Buttons
      color="grey"
      size="middle"
      onClick={this.handleCancelClick}
      disabled={isCancelDisabled}
      btnClassName={styles.approveActionBtn}
    >
      Cancel
    </Buttons>
  );

  /**
   * function about return submit button
   * @param {boolean} isSubmitDisabled
   * @returns
   */
  handleReturnSubmitButton = (isSubmitDisabled) => (
    <Buttons
      color="blue"
      size="middle"
      onClick={() => this.checkStepPermission("submit")}
      disabled={isSubmitDisabled}
      btnClassName={styles.approveActionSubmitBtn}
    >
      Submit
    </Buttons>
  );

  /**
   * function about return reject button
   * @param {boolean} isRejectDisabled
   * @returns
   */
  handleReturnRejectButton = (isRejectDisabled) => (
    <div className={styles.rejectBtn}>
      <Buttons
        color="red"
        size="middle"
        onClick={() => this.checkStepPermission("reject")}
        disabled={isRejectDisabled}
      >
        Reject
      </Buttons>
      <NormalToolTip
        element={
          <div className={styles.rejectTipIcon}>
            <img src={redTipIcon} alt="Reject" />
          </div>
        }
        title="Reject means that the process will be terminated. The status will be changed back to the original status before this initiation."
        color="#FF5858"
        overlayClassName={styles.rejectTip}
      />
    </div>
  );

  /**
   * function about return procedure file section
   * @param {obj} obj
   * @returns
   */
  handleReturnProcedureFileSection = (obj) => {
    const {
      currentRole,
      isTableBorderError,
      isDisableAll,
      procedureReplaceFileRef,
    } = obj;
    return (
      <ApproveProcedureFileSection
        savedInProps={this.props.savedInProps}
        sectionProps={{ currentRole, isDisableAll }}
        initSavedInSectionState={this.props.initSavedInSectionState}
        initProcedureTableData={this.props.initProcedureTableData}
        initServerPathSectionState={this.props.initServerPathSectionState}
        serverPathProps={this.props.serverPathProps}
        data={this.props.data}
        isTableBorderError={isTableBorderError}
        refs={{
          procedureSavedInRef: this.props.procedureSavedInRef,
          reasonRef,
          noteRef,
          procedureServerPathRef: this.props.procedureServerPathRef,
          procedureReplaceFileRef,
          procedureRef: this.props.procedureRef,
          procedureFileTableRef,
          procedureFileRef,
        }}
        downloadFile={this.props.downloadFile}
      />
    );
  };

  /**
   * function about return revision information section
   * @returns
   */
  handleReturnRevisionInformation = () => {
    return null;
  };

  /**
   * function about return page name
   * @returns
   */
  handleReturnPageName = () => {
    return "Approve New Procedure";
  };

  handleApproveNewProcedurePendingProcedureFileSection = () => {
    const { currentRole, procedureReplaceFileRef, isDisableAll } =
      this.props.pageProps;

    const { isTableBorderError } = this.state.pageState;

    return (
      <ProcedureBlock
        className={styles.approveProcedureFile}
        child={this.handleReturnProcedureFileSection({
          currentRole,
          isDisableAll,
          procedureReplaceFileRef,
          isTableBorderError,
        })}
        isShowArrow={true}
        isOpen={true}
      />
    );
  };

  handleApproveNewProcedurePendingSupportingFileSection = () => {
    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={
          <ReadOnlySupportingFileSection
            supportingFileData={this.props.supportingTableData}
            showHintMsg={true}
            supportingFileRef={supportingFileRef}
            supportingFileTableRef={supportingFileTableRef}
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.props.setSupportingFileData,
            this.setError,
            [StepBusinessType.supportingFile]
          );
        }}
      />
    );
  };

  handleApproveNewProcedurePendingProcedureInformationSection = () => {
    const { procedureStatus } = this.props.pageProps;
    const { procedureInfoData, ppoList } = this.props.sectionProps;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={
          <ProcedureInformation
            isShowActions={false}
            noteDisabled={true}
            ppoList={ppoList}
            procedureStatus={procedureStatus}
            isPPODisabled={true}
            showHintMsg={true}
            submitData={procedureInfoData}
            title="Procedure Information"
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.props.setProcedureInfoData,
            this.setError,
            [
              StepBusinessType.procedureOwner,
              StepBusinessType.pmpExcluded,
              StepBusinessType.primaryProcedureOwner,
            ]
          );
        }}
      />
    );
  };

  handleApproveNewProcedurePendingShareTaskSection = () => {
    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={
          <ActiveShareTaskReadOnly
            showHintMsg={true}
            shareTaskData={this.props.shareTaskData}
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.props.setShareTaskData,
            this.setError,
            [StepBusinessType.shareTask]
          );
        }}
      />
    );
  };

  handleApproveNewProcedurePendingModalWithComponent = () => {
    const { rejectReason, rejectStatus } = this.state.pageState;
    const {
      modalTitle,
      isShowTextArea,
      textAreaPlacement,
      okText,
      isOkBtnDisabled,
      isCancelBtnDisabled,
      maskClosable,
      textAreaClassName,
      modalWidth,
      isModalWithComponentOpen,
      textAreaMaxLength,
      cancelText,
      closable,
      handleOk,
      handleCancel,
      modalLabel,
    } = this.state.modalState;

    return (
      <ModalWithComponent
        textAreaOnChange={this.handleApproveNewProcedurePendingSetRejectValue}
        textAreaStatus={rejectStatus}
        isModalOpen={isModalWithComponentOpen}
        closable={closable}
        title={modalTitle}
        handleOk={handleOk}
        handleCancel={handleCancel}
        textAreaPlacement={textAreaPlacement}
        textAreaMaxLength={textAreaMaxLength}
        isOkDisabled={isOkBtnDisabled}
        isCancelDisabled={isCancelBtnDisabled}
        maskClosable={maskClosable}
        modalWidth={modalWidth}
        isShowTextArea={isShowTextArea}
        textAreaValue={rejectReason}
        textAreaClassName={textAreaClassName}
        label={modalLabel}
        okText={okText}
        cancelText={cancelText}
      />
    );
  };

  render() {
    const { receiveDate, requestByName } = this.props.pageProps;
    const { isCancelDisabled, isSubmitDisabled, isRejectDisabled } =
      this.state.pageState;
    const { taskInfoData } = this.props.sectionProps;
    return (
      <div className={styles.approveNewPro}>
        <PageHeader
          title={this.handleReturnPageName()}
          receiveDate={this._formateDate(receiveDate)}
          requestBy={requestByName}
          status="Pending"
        />
        <ProcedureBlock
          className={styles.approveProcedureBlock}
          child={<TaskInformation data={taskInfoData} />}
          isShowArrow={true}
          isOpen={false}
        />
        {this.handleReturnRevisionInformation()}
        {this.handleApproveNewProcedurePendingProcedureFileSection()}
        {this.handleApproveNewProcedurePendingSupportingFileSection()}
        {this.handleApproveNewProcedurePendingProcedureInformationSection()}
        {this.handleApproveNewProcedurePendingShareTaskSection()}
        <div className={styles.actionBtns}>
          <div className={styles.normalBtns}>
            {this.handleReturnCancelButton(isCancelDisabled)}
            {this.handleReturnSubmitButton(isSubmitDisabled)}
          </div>
          {this.handleReturnRejectButton(isRejectDisabled)}
        </div>
        {this.handleApproveNewProcedurePendingModalWithComponent()}
        <LostPermissionModal isModalOpen={this.state.noPermission} />
      </div>
    );
  }
}
