import Buttons from "../../../../../../base/basicComponents/button";
import BaseFileManagement from "../../../../../commonComponents/fileManagementFields/baseFileManagement";
import styles from "../../../../../commonComponents/fileManagementFields/baseFileManagement/index.module.scss";
import ProcedureDetailService from "../../../../../../service/procedureFile/ProcedureDetailService";
import ProcedureListService from "../../../../../../service/procedureList/index";
import AppSetting from "../../../../../../config/AppSetting";
import DetailFileTable from "../../../../procedureFileSections/fields/procedureFileTableFields/detailFileTable";
import DetailSupportingFileModalTable from "../../supportingFileTable/detailSupportingFileModalTable";

export default class BaseSupportingFileManagement extends BaseFileManagement {
  downloadBaseSupportingFileManagementFilesInterface = (downloadList) => {
    downloadList.map((data, index) => {
      const fileID = data.data.find((item) => item.name === "fileId").value;
      const para = {
        ProductKey: AppSetting.urlPrefix.addFile.ProductKey,
        fileId: fileID,
      };
      ProcedureDetailService.downloadFile(
        para,
        (response) =>
          this._downloadFileSuccess(
            response,
            data.data.find((item) => item.name === "fileName").value
          ),
        (response) => console.log(response)
      );
    });
  };

  downloadBaseSupportingFileManagementFiles = () => {
    const dataList = this.props.fileTableRef.current.state.data;
    let downloadList = dataList.filter((x) => {
      return x.checked === true;
    });
    this.downloadBaseSupportingFileManagementFilesInterface(downloadList);
    this.props.fileTableRef.current.updateDataList(dataList);
  };

  getBaseSupportingFileManagementCoverSheetSuccess = (result) => {
    this.downloadPDF(result.content, "Cover Sheet.pdf");
    this.downloadBaseSupportingFileManagementFiles();
  };

  downloadBaseSupportingFileManagementFile = () => {
    if (this.props.isProcedureDetailSection && !this.props.inModal) {
      const getCoverSheetParam = {
        ProcedureId: this.props.procedureId,
        ProcedureTaskId: this.props.procedureTaskId,
      };
      ProcedureListService.getCoverSheet(
        getCoverSheetParam,
        this.getBaseSupportingFileManagementCoverSheetSuccess,
        () => console.log("Get Cover Sheet Failed")
      );
    } else {
      this.downloadBaseSupportingFileManagementFiles();
    }
  };

  downloadFile = () => {
    this.downloadBaseSupportingFileManagementFile();
  };

  handleBaseSupportingFileManagementFileTable = (obj) => {
    const {
      handleReturnClick,
      handleReplaceFileSelect,
      fileTableRef,
      isScrollData,
      className,
      optionList,
      currentRole,
      isShowExpendIcon,
      columns,
      hasSubmit,
      fileTableStatus,
      downloadFile,
      isSupportingFile,
    } = obj;

    return (
      <DetailFileTable
        columns={columns}
        handleFileSelect={handleReplaceFileSelect}
        currentRole={currentRole}
        downloadFile={downloadFile}
        procedureNoteMaxLength={1000}
        fileSectionDisabled={hasSubmit}
        hasSubmit={hasSubmit}
        isScrollData={isScrollData}
        isSupportingFile={isSupportingFile}
        isShowExpendIcon={isShowExpendIcon}
        fileTableRef={fileTableRef}
        className={className}
        isShowTotalFileNum={true}
        ref={fileTableRef}
        optionList={optionList}
        handleReturnClick={handleReturnClick}
        fileTableStatus={fileTableStatus}
        procedureNoteSize="small"
        inputOnChange={this.inputOnChange}
      />
    );
  };

  handleBaseSupportingFileManagementFileModalTable = (obj) => {
    const {
      hasSubmit,
      currentRole,
      downloadFile,
      optionList,
      handleReturnClick,
      isSupportingFile,
      isScrollData,
      className,
      handleReplaceFileSelect,
      isShowExpendIcon,
      fileTableStatus,
      fileTableRef,
      columns,
    } = obj;

    return (
      <DetailSupportingFileModalTable
        isScrollData={isScrollData}
        isShowExpendIcon={isShowExpendIcon}
        ref={fileTableRef}
        currentRole={currentRole}
        downloadFile={downloadFile}
        isSupportingFile={isSupportingFile}
        procedureNoteMaxLength={1000}
        hasSubmit={hasSubmit}
        fileSectionDisabled={hasSubmit}
        procedureNoteSize="small"
        inputOnChange={this.inputOnChange}
        optionList={optionList}
        handleReturnClick={handleReturnClick}
        fileTableStatus={fileTableStatus}
        columns={columns}
        fileTableRef={fileTableRef}
        className={className}
        handleFileSelect={handleReplaceFileSelect}
        isShowTotalFileNum={true}
      />
    );
  };

  /**
   * function about return table
   * @param {*} obj
   * @returns
   */
  handleReturnProcedureFileTable = (obj) => {
    return this.props.className === "supportingFiles"
      ? this.handleBaseSupportingFileManagementFileTable(obj)
      : this.handleBaseSupportingFileManagementFileModalTable(obj);
  };

  handleReturnActionBtns = (obj) => {
    const {
      saveDisabled,
      procedureMandatoryCheck,
      onSave,
      onSubmit,
      onCancel,
      submitDisabled,
    } = obj;
    return (
      <div className={styles.buttonDiv}>
        <Buttons
          color="grey"
          disabled={saveDisabled}
          size="middle"
          onClick={() => {
            onSave();
          }}
        >
          Save as Draft
        </Buttons>
        <Buttons color="grey" disabled={false} size="middle" onClick={onCancel}>
          Cancel
        </Buttons>
        <Buttons
          color="blue"
          disabled={submitDisabled}
          size="middle"
          onClick={() => {
            const shouldShowErrorServer = this.getDataList().length === 0;
            if (shouldShowErrorServer) {
              procedureMandatoryCheck();
            } else {
              onSubmit();
            }
          }}
        >
          Submit for Approval
        </Buttons>
      </div>
    );
  };
}
