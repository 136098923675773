import React, { createRef } from "react";
import { Tooltip, Dropdown, Menu, Popover } from "antd";
import lodash from "lodash";
import BaseShareOnModal from "./baseShareOnModal";
import SectionEditIcon from "../../../base/basicComponents/sectionEditIcon";
import NormalToolTip from "../../../base/basicComponents/toolTip";
import ShareTaskTable from "./shareTaskTable";
import ShareTaskModal from "./shareTaskModal";
import NormalModal from "../../../base/basicComponents/modal";
import TaskInformation from "../taskInfoSections";
import shareTaskService from "../../../service/shareTask";
import ProcedureDetailService from "../../../service/procedureFile/ProcedureDetailService";
import { shareTaskMassage } from "../../publicDictionaryValues";
import noSharedTaskImg from "../../../assets/image/noSharedTask.png";
import redTipIcon from "../../../assets/image/redTipIcon.png";
import styles from "./index.module.scss";
import "./share.scss";
import ChangeMainTaskModal from "./changeMainTaskModal";
import fullScreenIcon from "../../../assets/image/fullScreen.png";
import fullScreenHoverIcon from "../../../assets/image/fullScreenHover.png";
import miniScreenIcon from "../../../assets/image/miniScreen.png";
import miniScreenHoverIcon from "../../../assets/image/miniScreenHover.png";

const changeMainTaskRef = createRef();

export default class ShareTask extends BaseShareOnModal {
  constructor(props) {
    super(props);
    this.state = {
      isShowEdit: false,
      editCheckFail: false,
      editCheckMessage: "",
      sharingProcessStatus: 0,
      hasActiveShardTask: true,
      data: [],
      isEdit: false,
      editDropdownShow: false,
      isLocked: false,
      lockName: "",
      isEditToolTipOpen: false,
      isModalOpen: false,
      initModalTableData: [],
      modalTableData: [],
      accountSelectOption: [],
      accountSelectValue: null,
      taskTreePathOption: [],
      taskTreePathValue: null,
      isAddNewShareTaskShow: false,
      submitDisabled: true,
      taskInformationData: {},
      isTaskInfoModalOpen: false,
      reseanValue: "",
      hasDeleteTag: false,
      reasonInputStatus: "",
      isComfirmOpen: false,
      confirmModalText: "",
      handleComfirm: () => {
        //this is a empty function
      },
      isExpendData: false,
      iconSrc: fullScreenIcon,
    };
  }

  ReleaseLock = () => {
    const para = {
      procedureId: this.props.procedureId,
      procedureSectionType: "ShareTask",
      setLock: false,
    };
    ProcedureDetailService.setSectionLock(
      para,
      () => {
        //this.is an empty function
      },
      (response) => {
        console.log(response.message);
      }
    );
  };

  compareArrays = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      const obj1 = arr1[i];
      const obj2 = arr2[i];

      for (let key in obj1) {
        if (key !== "errorMessage" && key !== "hasError") {
          if (obj1[key] !== obj2[key]) {
            return false;
          }
        }
      }
    }

    return true;
  };

  CheckSubmitDisable = (currentData) => {
    const modalTableData = JSON.parse(JSON.stringify(currentData));
    const initModalTableData = JSON.parse(
      JSON.stringify(this.state.initModalTableData)
    );
    const eq = this.compareArrays(modalTableData, initModalTableData);
    if (eq) {
      this.setState({
        submitDisabled: true,
      });
    } else {
      this.setState({
        submitDisabled: false,
      });
    }
  };

  checkDeleteTag = (currentData) => {
    const deleteTagDataIndex = currentData.findIndex(
      (x) => x.isDeleted !== undefined && x.isDeleted === true
    );
    if (deleteTagDataIndex > -1) {
      this.setState({
        hasDeleteTag: true,
      });
    } else {
      this.setState({
        hasDeleteTag: false,
      });
    }
  };

  ModalTableOnDelete = (record) => {
    let data = JSON.parse(JSON.stringify(this.state.modalTableData));
    const index = data.findIndex((x) => x.taskId === record.taskId);
    if (record.isNew) {
      data.splice(index, 1);
      this.setState({
        modalTableData: data,
      });
    } else {
      data[index] = { ...data[index], isDeleted: true };
      this.setState({
        modalTableData: data,
        hasDeleteTag: true,
      });
    }

    this.CheckSubmitDisable(data);
  };

  ModalTableOnRevoke = (record) => {
    let data = JSON.parse(JSON.stringify(this.state.modalTableData));
    const index = data.findIndex((x) => x.taskId === record.taskId);
    delete data[index]["isDeleted"];
    this.setState({
      modalTableData: data,
    });
    this.CheckSubmitDisable(data);
    this.checkDeleteTag(data);
  };

  ShowAddNewShareTask = () => {
    this.setState({
      isAddNewShareTaskShow: true,
    });
  };

  ModalCheckSubmitOnSuccess = (result) => {
    let currentData = JSON.parse(JSON.stringify(this.state.modalTableData));
    if (!result.result) {
      const responseData = result.resultList;
      for (let index = 0; index < currentData.length; index++) {
        const responseIndex = responseData.findIndex(
          (x) => x.taskId === currentData[index].taskId && !x.checkResult
        );
        if (responseIndex > -1) {
          currentData[index] = {
            ...currentData[index],
            hasError: true,
            errorMessage: shareTaskMassage[responseData[responseIndex].msgCode],
          };
        }
      }
      this.setState({
        modalTableData: currentData,
      });
    } else {
      const data = currentData.map((item) => {
        if (item.hasError === true) {
          return {
            ...item,
            hasError: false,
            errorMessage: "",
          };
        }

        return item;
      });
      this.setState({
        modalTableData: data,
        isComfirmOpen: true,
        confirmModalText: "Are you sure to submit?",
        handleComfirm: this.HandleComfirm,
      });
    }
  };

  refreshPage = () => {
    const para = {
      procedureId: this.props.procedureId,
      procedureSectionType: "ShareTask",
      setLock: false,
    };
    ProcedureDetailService.setSectionLock(
      para,
      () => {
        window.location.reload();
      },
      (response) => {
        //empty function
      }
    );
  };

  ModalSubmitOnSuccess = () => {
    this.props.hideLoading();
    this._alertSuccess("Submit Successful");
    this.refreshPage();
  };

  HandleComfirm = () => {
    this.props.showLoading();
    this.setState({
      isComfirmOpen: false,
      isModalOpen: false,
    });
    const currentData = JSON.parse(JSON.stringify(this.state.modalTableData));
    const para = {
      tasks: currentData,
      reason: this.state.reseanValue,
      procedureId: this.props.procedureId,
      proceduretaskId: this.props.procedureTaskId,
    };
    shareTaskService.submitForApproval(
      para,
      this.ModalSubmitOnSuccess,
      this.ModalOnSubmitOnError
    );
  };

  HandleComfirmCancel = () => {
    this.setState({
      isComfirmOpen: false,
    });
  };

  ModalOnSubmitOnError = () => {
    this.props.hideLoading();
    this._alertError("Submit Failed");
    this.setState({
      isComfirmOpen: false,
    });
  };

  ModalCheckSubmitOnError = () => {
    this._alertError("Check Failed");
  };

  ModalOnSubmit = () => {
    let currentData = JSON.parse(JSON.stringify(this.state.modalTableData));
    if (this.state.hasDeleteTag) {
      if (this.state.reseanValue.trim() === "") {
        this.setState({
          reasonInputStatus: "error",
        });
        return;
      }
    }
    const para = {
      procedureId: this.props.procedureId,
      taskList: currentData,
    };
    shareTaskService.checkSubmit(
      para,
      this.ModalCheckSubmitOnSuccess,
      this.ModalCheckSubmitOnError
    );
  };

  HandleCancle = () => {
    this.setState({
      isComfirmOpen: false,
      isModalOpen: false,
      modalTableData: [],
      accountSelectValue: this.state.accountSelectOption[0].value,
      taskTreePathValue: null,
      isAddNewShareTaskShow: false,
      submitDisabled: true,
      hasDeleteTag: false,
      reseanValue: "",
      reasonInputStatus: "",
    });
    this.ReleaseLock();
    this.props.setActionType(null);
  };

  ModalOnCancle = () => {
    this.setState({
      isComfirmOpen: true,
      confirmModalText: "Are you sure to quit without saving?",
      handleComfirm: this.HandleCancle,
    });
  };

  AddTaskOnClickOnSuccess = (result) => {
    const newLine = { ...result, isNew: true, isMainTask: false };
    const currentModalTableData = JSON.parse(
      JSON.stringify(this.state.modalTableData)
    );
    const duplicateLineIndex = currentModalTableData.findIndex(
      (x) => x.taskId === newLine.taskId
    );
    if (duplicateLineIndex > -1) {
      this._alertError("This Task already exists.");
      this.setState({
        taskTreePathValue: null,
      });
      return;
    }
    currentModalTableData.push(newLine);
    this.setState({
      modalTableData: currentModalTableData,
      taskTreePathValue: null,
    });
    this.CheckSubmitDisable(currentModalTableData);
  };

  AddTaskOnClickOnError = (result) => {
    this.props.hideLoading();
    console.log("Add Task Failed");
  };

  AddTaskOnClick = () => {
    this._showLoading();
    shareTaskService.getRowData(
      this.state.taskTreePathValue,
      this.AddTaskOnClickOnSuccess,
      this.AddTaskOnClickOnError
    );
  };

  CheckShowEditOnSuccess = (result) => {
    this.setState({
      isShowEdit: result,
    });
  };

  CheckShowEditOnError = (result) => {
    console.log(result);
  };

  CheckShowEdit = () => {
    const para = {
      procedureId: this.props.procedureId,
      proceduretaskId: this.props.procedureTaskId,
    };
    shareTaskService.isShowEdit(
      para,
      this.CheckShowEditOnSuccess,
      this.CheckShowEditOnError
    );
  };

  GetModalTableDataSuccess = (result) => {
    this._setLock(this.props.procedureId, "ShareTask", true);
    this.props.setActionType("ShareTask");
    this.props.hideLoading();
    this.setState({
      isModalOpen: true,
      modalTableData: result,
      initModalTableData: result,
    });
  };

  GetModalTableDataFail = (result) => {
    console.log(result);
  };

  checkManageShareTaskWhetherLock = (isLock, lockUser) => {
    if (isLock) {
      this.props.hideLoading();
      this.setState({
        isLocked: true,
        lockName: lockUser,
        isEditToolTipOpen: true,
      });
    } else {
      this.setState({
        isLocked: false,
        isEditToolTipOpen: false,
      });
      const para = {
        procedureId: this.props.procedureId,
        isCreate: false,
      };
      shareTaskService.getMainTaskInfo(
        para,
        this.GetModalTableDataSuccess,
        this.GetModalTableDataFail
      );
    }
  };

  ManageSharedTasksOnClick = () => {
    this.actionType = "ShareTask";
    const para = {
      procedureId: this.props.procedureId,
      procedureSectionType: this.actionType,
      timeOutMins: 20,
    };
    ProcedureDetailService.checkSectionLock(
      para,
      (response) =>
        this.checkManageShareTaskWhetherLock(
          response.data.isLocked,
          response.data.lockUser
        ),
      (response) => {
        this.props.hideLoading();
        console.log(response.message);
      }
    );
  };

  checkChangeMainTaskWhetherLock = (isLock, lockUser) => {
    if (isLock) {
      this.props.hideLoading();
      this.setState({
        isLocked: true,
        lockName: lockUser,
        isEditToolTipOpen: true,
      });
    } else {
      this.setState({
        isLocked: false,
        isEditToolTipOpen: false,
      });
      changeMainTaskRef.current.ShowModal();
    }
  };

  ManageChangeMainTaskOnClick = () => {
    this.actionType = "ShareTask";
    const para = {
      procedureId: this.props.procedureId,
      procedureSectionType: this.actionType,
      timeOutMins: 20,
    };
    ProcedureDetailService.checkSectionLock(
      para,
      (response) =>
        this.checkChangeMainTaskWhetherLock(
          response.data.isLocked,
          response.data.lockUser
        ),
      (response) => {
        this.props.hideLoading();
        console.log(response.message);
      }
    );
  };

  GetCheckEditSharingStatusOnSuccess = (result) => {
    if (result === 4) {
      this.ReleaseLock();
      this.props.hideLoading();
      this.setState({
        isLocked: false,
        isEditToolTipOpen: false,
        editDropdownShow: true,
        isEdit: true,
      });
    } else {
      this.setState({
        isLocked: false,
        isEditToolTipOpen: false,
      });
      const para = {
        procedureId: this.props.procedureId,
        isCreate: true,
      };
      shareTaskService.getMainTaskInfo(
        para,
        this.GetModalTableDataSuccess,
        this.GetModalTableDataFail
      );
    }
    const accountPara = {
      procedureTaskId: this.props.procedureTaskId,
      procedureStepId: null,
    };
    shareTaskService.getAccountDDL(
      accountPara,
      this.GetAccountDDLOnSuccess,
      this.GetAccountDDLOnError
    );
  };

  GetCheckEditSharingStatusOnError = () => {
    this.props.hideLoading();
  };

  shareTaskUnLock = () => {
    const para = {
      procedureId: this.props.procedureId,
      proceduretaskId: this.props.procedureTaskId,
    };
    shareTaskService.getSharingProcessStatus(
      para,
      this.GetCheckEditSharingStatusOnSuccess,
      this.GetCheckEditSharingStatusOnError
    );
  };

  checkShareTaskWhetherLock = (isLock, lockUser) => {
    if (isLock) {
      this.props.hideLoading();
      this.setState({
        isLocked: true,
        lockName: lockUser,
        isEditToolTipOpen: true,
      });
    } else {
      this.shareTaskUnLock();
    }
  };

  CheckLock = () => {
    this.actionType = "ShareTask";
    const para = {
      procedureId: this.props.procedureId,
      procedureSectionType: this.actionType,
      timeOutMins: 20,
    };
    ProcedureDetailService.checkSectionLock(
      para,
      (response) =>
        this.checkShareTaskWhetherLock(
          response.data.isLocked,
          response.data.lockUser
        ),
      (response) => {
        this.props.hideLoading();
        console.log(response.message);
      }
    );
  };

  CheckEditOnSuccess = (result) => {
    if (result.result === true) {
      this.CheckLock();
    } else {
      this.setState({
        editCheckFail: true,
        editCheckMessage: shareTaskMassage[result.messageCode],
      });
      this.props.hideLoading();
    }
  };

  CheckEditOnError = (result) => {
    this.props.hideLoading();
    console.log(result);
  };

  HandleEditClick = () => {
    this.props.showLoading();
    const para = {
      procedureId: this.props.procedureId,
      procedureTaskId: this.props.procedureTaskId,
    };
    shareTaskService.checkEdit(
      para,
      this.CheckEditOnSuccess,
      this.CheckEditOnError
    );
  };

  HandleEditOnBlur = () => {
    setTimeout(() => {
      this.setState({
        editCheckFail: false,
        isEditToolTipOpen: false,
        editDropdownShow: false,
      });
    }, 300);
  };

  GetActiveShareTaskListOnSuccess = (result) => {
    this.setState({
      data: result,
      isExpendData: result.length === 0,
    });
    this.handleDefaultExpendIcon();
  };

  GetActiveShareTaskListOnError = (result) => {
    console.log(result);
  };

  NoActiveShardTask = () => {
    this.setState({
      hasActiveShardTask: false,
    });
  };

  SetIsEdit = () => {
    this.setState({
      isEdit: true,
    });
  };

  GetSharingProcessStatusOnSuccess = (result) => {
    this.setState({
      sharingProcessStatus: result,
    });
    if (result === 1 || result === 2) {
      this.setState({
        hasActiveShardTask: false,
      });
    } else {
      const para = {
        procedureId: this.props.procedureId,
        procedureTaskId: this.props.procedureTaskId,
      };
      shareTaskService.getActiveShareTaskList(
        para,
        this.GetActiveShareTaskListOnSuccess,
        this.GetActiveShareTaskListOnError
      );
    }

    this.CheckShowEdit();
    if (result === 1) {
      this.NoActiveShardTask();
    }

    if (result === 2) {
      this.NoActiveShardTask();
    }

    if (result === 4) {
      this.SetIsEdit();
    }
  };

  GetSharingProcessStatusOnError = (result) => {
    console.log(result);
  };

  GetSharingProcessStatus = () => {
    const para = {
      procedureId: this.props.procedureId,
      proceduretaskId: this.props.procedureTaskId,
    };
    shareTaskService.getSharingProcessStatus(
      para,
      this.GetSharingProcessStatusOnSuccess,
      this.GetSharingProcessStatusOnError
    );
  };

  componentDidMount() {
    this.GetSharingProcessStatus();
  }

  handleEditCheckMessage = (
    editCheckFail,
    editCheckMessage,
    isLocked,
    lockName
  ) => {
    if (editCheckFail) {
      return editCheckMessage;
    } else if (isLocked) {
      return `${lockName} is editing this section.`;
    }
    return editCheckMessage;
  };

  openConfirmModal = (handleComfirm, message) => {
    this.setState({
      isComfirmOpen: true,
      confirmModalText: message,
      handleComfirm: handleComfirm,
    });
  };

  handleDefaultExpendIcon = () => {
    this.setState({
      iconSrc: this.state.isExpendData ? miniScreenIcon : fullScreenIcon,
    });
  };

  handleHoverExpendIcon = () => {
    this.setState({
      iconSrc: this.state.isExpendData
        ? miniScreenHoverIcon
        : fullScreenHoverIcon,
    });
  };

  handleExpendFlag = () => {
    const flag = !this.state.isExpendData;
    this.setState({ isExpendData: flag });
  };

  sharedTaskTableListControlIcon = () => {
    const { isExpendData, iconSrc } = this.state;
    return (
      <p className="listControlIcon">
        <img
          src={iconSrc}
          alt={isExpendData ? "pick up" : "show all"}
          onMouseEnter={this.handleHoverExpendIcon}
          onMouseLeave={this.handleDefaultExpendIcon}
          onClick={this.handleExpendFlag}
        />
      </p>
    );
  };

  render() {
    const {
      isEditToolTipOpen,
      isShowEdit,
      editCheckFail,
      editCheckMessage,
      sharingProcessStatus,
      hasActiveShardTask,
      data,
      isEdit,
      editDropdownShow,
      isLocked,
      lockName,
      isModalOpen,
      modalTableData,
      accountSelectOption,
      accountSelectValue,
      taskTreePathOption,
      taskTreePathValue,
      isAddNewShareTaskShow,
      submitDisabled,
      taskInformationData,
      isTaskInfoModalOpen,
      reseanValue,
      hasDeleteTag,
      reasonInputStatus,
      isExpendData,
    } = this.state;
    const column = [
      this.isMainTaskColumn,
      ...this.commonShareDataColumn,
      this.effectiveDateColumn,
    ];
    const modalTableColumn = [
      {
        title: "",
        dataIndex: "isMainTask",
        width: 120,
        render: (text, record) => (
          <div>
            <Popover
              content={
                <span className={`${styles.errorPopContent} fs-14`}>
                  {record.errorMessage}
                </span>
              }
              title={null}
              trigger="click"
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode.parentNode.parentNode.parentNode
              }
              overlayClassName="shareTooltip"
            >
              <img
                src={redTipIcon}
                alt="error"
                className={
                  record.hasError
                    ? styles.redTipIconShow
                    : styles.redTipIconHide
                }
              />
            </Popover>
            <span
              className={`${
                record.isMainTask ? styles.mainTaskText : styles.tableText
              } fs-14`}
            >
              {record.isMainTask ? "Main Task" : "Shared Task"}
            </span>
          </div>
        ),
      },
      {
        title: "Account",
        dataIndex: "account",
        ellipsis: {
          showTitle: false,
        },
        width: 100,
        render: (accountName, record) => (
          <Tooltip
            placement="bottomLeft"
            title={accountName}
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode.parentNode.parentNode
            }
            overlayClassName="shareTooltip"
          >
            <span className={`${styles.tableText} fs-14`}>{accountName}</span>
          </Tooltip>
        ),
      },
      {
        title: "Task Tree Path",
        dataIndex: "taskTreePath",
        ellipsis: {
          showTitle: false,
        },
        width: 140,
        render: (taskTreePath) => (
          <Tooltip
            placement="bottomLeft"
            title={taskTreePath}
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode.parentNode.parentNode
            }
            overlayClassName="shareTooltip"
          >
            <span className={`${styles.tableText} fs-14`}>{taskTreePath}</span>
          </Tooltip>
        ),
      },
      {
        title: "Task Label Name",
        dataIndex: "taskLabelName",
        ellipsis: {
          showTitle: false,
        },
        width: 140,
        render: (taskLableName) => (
          <Tooltip
            placement="bottomLeft"
            title={taskLableName}
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode.parentNode.parentNode
            }
            overlayClassName="shareTooltip"
          >
            <span className={`${styles.tableText} fs-14`}>{taskLableName}</span>
          </Tooltip>
        ),
      },
      {
        title: "Task ID",
        dataIndex: "taskId",
        ellipsis: {
          showTitle: false,
        },
        width: 90,
        render: (taskId) => (
          <Tooltip
            placement="bottomLeft"
            title={taskId}
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode.parentNode.parentNode
            }
            overlayClassName="shareTooltip"
          >
            <span className={`${styles.tableText} fs-14`}>{taskId}</span>
          </Tooltip>
        ),
      },
      {
        title: "Primary Procedure Owner",
        dataIndex: "ppo",
        ellipsis: {
          showTitle: false,
        },
        render: (ppo) => (
          <Tooltip
            placement="bottomLeft"
            title={ppo}
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode.parentNode.parentNode
            }
            overlayClassName="shareTooltip"
          >
            <span className={`${styles.tableText} fs-14`}>{ppo}</span>
          </Tooltip>
        ),
      },
      {
        title: "Procedure Owner",
        dataIndex: "po",
        ellipsis: {
          showTitle: false,
        },
        width: 150,
        render: (po) => (
          <Tooltip
            placement="bottomLeft"
            title={po}
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode.parentNode.parentNode
            }
            overlayClassName="shareTooltip"
          >
            <span className={`${styles.tableText} fs-14`}>{po}</span>
          </Tooltip>
        ),
      },
      {
        title: "Procedure Saved In",
        dataIndex: "procedureSavedIn",
        ellipsis: {
          showTitle: false,
        },
        width: 160,
        render: (procedureSavedIn) => (
          <Tooltip
            placement="bottomLeft"
            title={procedureSavedIn}
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode.parentNode.parentNode
            }
            overlayClassName="shareTooltip"
          >
            <span className={`${styles.tableText} fs-14`}>
              {procedureSavedIn}
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Client Server Path",
        dataIndex: "clientServerPath",
        ellipsis: {
          showTitle: false,
        },
        render: (clientServerPath) => (
          <Tooltip
            placement="bottomLeft"
            title={clientServerPath}
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode.parentNode.parentNode
            }
            overlayClassName="shareTooltip"
          >
            <span className={`${styles.tableText} fs-14`}>
              {clientServerPath}
            </span>
          </Tooltip>
        ),
      },
      this.tagColumn("6.5%"),
      {
        title: "Action",
        dataIndex: "action",
        align: "center",
        width: 110,
        render: (text, record) => {
          if (record.isMainTask) {
            return;
          }

          if (record.isDeleted) {
            return (
              <div
                className={`${styles.modalTableActionIcon} ${styles.sectionRevokeIcon}`}
                onClick={() => this.ModalTableOnRevoke(record)}
              />
            );
          }

          return (
            <div
              className={`${styles.modalTableActionIcon} ${styles.sectionDeleteIcon}`}
              onClick={() => this.ModalTableOnDelete(record)}
            />
          );
        },
      },
    ];
    const editDropdownMenu = (
      <Menu>
        <Menu.Item
          onClick={this.ManageChangeMainTaskOnClick}
          key="changeMainTask"
        >
          Change Main Task
        </Menu.Item>
        <Menu.Item
          onClick={this.ManageSharedTasksOnClick}
          key="manageSharedTasks"
        >
          Manage Shared Tasks
        </Menu.Item>
      </Menu>
    );
    return (
      <div className={styles.shareInformation}>
        <div className={styles.shareInfoTop}>
          <div>
            <span className={`${styles.shareTitle} fs-18`}>Shared Tasks</span>
            <span className={`${styles.sharingProcessStatus} fs-14`}>
              {sharingProcessStatus === 2 || sharingProcessStatus === 3
                ? "This section is pending approval."
                : ""}
            </span>
          </div>

          {isShowEdit && (
            <Dropdown
              overlay={editDropdownMenu}
              trigger={["click"]}
              visible={isEdit && editDropdownShow}
              arrow={true}
              placement="bottomRight"
              overlayClassName={`antDropDown ${styles.editDropdownMenu}`}
              getPopupContainer={(triggerNode) => triggerNode}
            >
              <NormalToolTip
                element={
                  <SectionEditIcon
                    handleEditClick={lodash.debounce(this.HandleEditClick, 500)}
                    onBlur={this.HandleEditOnBlur}
                    showDownArrow={isEdit}
                    className={styles.sectionEdit}
                  />
                }
                title={this.handleEditCheckMessage(
                  editCheckFail,
                  editCheckMessage,
                  isLocked,
                  lockName
                )}
                trigger="click"
                open={isEditToolTipOpen || editCheckFail}
                placement="left"
                color="#3E6CB5"
              />
            </Dropdown>
          )}
        </div>
        <div className={styles.sharedTaskArea}>
          {!hasActiveShardTask && (
            <div className={styles.noSharedTaskDiv}>
              <img
                alt="no shared task"
                src={noSharedTaskImg}
                className={styles.noSharedTaskImg}
              />
              <p className={`${styles.noSharedTaskMassage} fs-16`}>
                No Shared Task Yet
              </p>
            </div>
          )}
          {hasActiveShardTask && (
            <>
              {data.length > 4 && (
                <div className="sharedTaskTableTop">
                  {this.sharedTaskTableListControlIcon()}
                </div>
              )}
              <ShareTaskTable
                data={data}
                columns={column}
                scroll={{
                  y: isExpendData || data.length <= 4 ? null : "13.75rem",
                }}
              />
              <p className="fileNumTip" style={{ color: "#333" }}>
                <span className="fileNum">{data.length}</span>{" "}
                {this.setFileText(data.length)} in total
              </p>
            </>
          )}
        </div>
        <ShareTaskModal
          isModalOpen={isModalOpen}
          tableColumn={modalTableColumn}
          tableData={modalTableData}
          onSubmit={this.ModalOnSubmit}
          onCancel={this.ModalOnCancle}
          accountSelectOption={accountSelectOption}
          accountSelectValue={accountSelectValue}
          accountSelectOnChange={this.AccountSelectOnChange}
          taskTreePathOption={taskTreePathOption}
          taskTreePathValue={taskTreePathValue}
          taskTreePathOnSearch={this.TaskTreePathOnSearch}
          taskTreePathOnChange={this.TaskTreePathOnChange}
          addTaskOnClick={this.AddTaskOnClick}
          clearAddTaskOnClick={this.ClearAddTaskOnClick}
          isAddNewShareTaskShow={isAddNewShareTaskShow}
          showAddNewShareTask={this.ShowAddNewShareTask}
          submitDisabled={submitDisabled}
          reseanValue={reseanValue}
          reseanOnChange={(e) => this.ReseanOnChange(e.target)}
          hasDeleteTag={hasDeleteTag}
          reasonInputStatus={reasonInputStatus}
          zIndex={990}
          refreshPage={this.refreshPage}
        />
        <ChangeMainTaskModal
          ref={changeMainTaskRef}
          releaseLock={this.ReleaseLock}
          procedureTaskId={this.props.procedureTaskId}
          procedureId={this.props.procedureId}
          openConfirmModal={this.openConfirmModal}
          cancelConfilmModal={this.HandleComfirmCancel}
          refreshPage={this.refreshPage}
          zIndex={990}
          setActionType={this.props.setActionType}
          showLoading={this.props.showLoading}
          hideLoading={this.props.hideLoading}
        />
        <NormalModal
          centered
          footer={null}
          title="Task Information"
          isModalOpen={isTaskInfoModalOpen}
          handleCancel={this.HandleTaskInfoModalClose}
          width="93rem"
          childContent={
            <TaskInformation data={taskInformationData} isInModal={true} />
          }
          zIndex={990}
          className="taskInfoModal"
          closable={true}
        />
        <NormalModal
          okText="Confirm"
          cancelText="Cancel"
          childContent={<p className="fs-16">{this.state.confirmModalText}</p>}
          isModalOpen={this.state.isComfirmOpen}
          handleOk={this.state.handleComfirm}
          handleCancel={this.HandleComfirmCancel}
        />
      </div>
    );
  }
}
