import BaseComponent from "../../../../base/common/BaseComponent";
import { procedureStatusValues } from "../../../publicDictionaryValues";

export default class BaseProcedureInformation extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentProcedureStatus: this.getProcedureStatus(
        this.props.submitData.procedureStatus
      ),
      currentPMPExcluded: this.props.submitData.isPMPExcluded,
      currentPPO: this.props.submitData.primaryProcedureOwnerId,
      currentPPOName:
        this.props.submitData.primaryProcedureOwnerName !== null
          ? this.props.submitData.primaryProcedureOwnerName
          : null,
      currentPO: this.props.submitData.procedureOwnerId,
      currentPMPExcludedReasonSelect: this.props.submitData.pmpReasonSelect,
      currentPMPExcludedReasonInput: this.props.submitData.pmpReasonInput,
      currentPMPExcludedReasonInputStatus: "",
      currentNote: this.props.submitData.note,
      currentReason: this.props.submitData.reason,
      isChange: false,
      isPOChange: false,
      isPPOChange: false,
      isChangeList: [],
      preProcedureInfoSubmitData: this.props.preProcedureInfoSubmitData,
    };
  }

  getProcedureStatus = (statusId) => {
    return procedureStatusValues[statusId];
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.submitData) !==
      JSON.stringify(this.props.submitData)
    ) {
      this.updateState(this.props.submitData);
    }
  }

  setIsChange = () => {
    this.setState({ isChange: true });
  }

  cancelIsChange = () => {
    this.setState({ isChange: false });
  }

  checkIsChange = (ele, value) => {
    let isChangeList = [];

    if (
      this.state.preProcedureInfoSubmitData === undefined || 
      this.state.preProcedureInfoSubmitData[ele] === value ||
      (!value && ele !== 'note')
    ) {
      isChangeList = this.state.isChangeList.filter(item => item !== ele);
    } else {
      isChangeList = this.state.isChangeList.includes(ele) ? this.state.isChangeList : [...this.state.isChangeList, ele];
    }
    if(isChangeList.filter(item => item !== 'reason').length === 0) {
      isChangeList = [];
    }

    const isChange = isChangeList.length > 0;
    this.setState({
      currentReason: null,
      isChangeList: isChangeList,
      isPOChange: isChangeList.includes('procedureOwnerId'),
      isPPOChange: isChangeList.includes('primaryProcedureOwnerId'),
      isChange: isChange,
    })
  };

  updateState = (data) => {
    this.setState({
      currentPMPExcluded: data.isPMPExcluded,
      currentPPO: data.primaryProcedureOwnerId,
      currentPO: data.procedureOwnerId,
      currentPMPExcludedReasonSelect: data.pmpReasonSelect,
      currentPMPExcludedReasonInput: data.pmpReasonInput,
      currentNote: data.note,
      currentReason: data.reason,
    });
  };

  updatePreProcedureInfoSubmitData = (value) => {
    this.setState({ 
      preProcedureInfoSubmitData: value,
    });
  }

  updateProcedureStatus = (value, isInit=false) => {
    const status = this.getProcedureStatus(value);
    this.setState({ currentProcedureStatus: status });
    !isInit && this.checkIsChange("procedureStatus", value);
  };

  updateProcedureExcluded = (value, isInit=false) => {
    this.setState({ currentPMPExcluded: value });
    !isInit && this.checkIsChange("procedureExcluded", value);
  };

  updatePPO = (value, isInit=false) => {
    this.setState({ currentPPO: value });
    !isInit && this.checkIsChange("primaryProcedureOwnerId", value);
  };

  updatePPOName = (value, isInit=false) => {
    this.setState({ currentPPOName: value });
    !isInit && this.checkIsChange("primaryProcedureOwnerName", value);
  };

  updatePO = (value, isInit=false) => {
    this.setState({ currentPO: value });
    !isInit && this.checkIsChange("procedureOwnerId", value);
  };

  updatePOName = (value, isInit=false) => {
    this.setState({ currentPOName: value });
    !isInit && this.checkIsChange("procedureOwnerName", value);
  };

  updateReason = (value, isInit=false) => {
    this.setState({ currentReason: value });
    !isInit && this.checkIsChange("reason", value);
  };

  updateNote = (value, isInit=false) => {
    this.setState({ currentNote: value });
    !isInit && this.checkIsChange("note", value);
  };

  updatePMPExcludedReasonSelect = (value, isInit=false) => {
    this.setState({ currentPMPExcludedReasonSelect: value });
    !isInit && this.checkIsChange("pmpReasonSelect", value);
  };

  updatePMPExcludedReasonInput = (value, isInit=false) => {
    this.setState({ currentPMPExcludedReasonInput: value });
    !isInit && this.checkIsChange("pmpReasonInput", value);
  };

  updatePMPExcludedReasonInputStatus = (value, isInit=false) => {
    this.setState({ currentPMPExcludedReasonInputStatus: value });
    !isInit && this.checkIsChange("pmpReasonInputStatus", value);
  };
}
