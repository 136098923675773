import { createRef } from "react";
import ProcedureBlock from "../../../../../base/basicComponents/procedureBlock";
import { flowCategoryId } from "../../../../publicDictionaryValues";
import DetailRevisionInformation from "../../../../sections/procedureFileSections/fields/revisionInformationFields/detailRevisionInformation";
import ReadOnlyUpdateProcedureFileSection from "../../../../sections/procedureFileSections/readOnlyUpdateProcedureFileSection";
import BaseProcedureComplete from "../../baseProcedure/baseProcedureComplete";
import styles from "../../style/index.module.scss";

const procedureFileRef = createRef();
export default class SubmitUpdateProcedureComplete extends BaseProcedureComplete {
  /**
   * function about return procedure file section
   */
  handleReturnProcedureFileSection = () => {
    const { serverPathValue, savedInRadioValue, fileTableData } = this.props;
    return (
      <ReadOnlyUpdateProcedureFileSection
        showHintMsg={false}
        procedureFileRef={procedureFileRef}
        clientServerPath={serverPathValue}
        savedInRadioValue={savedInRadioValue}
        procedureFileData={fileTableData !== undefined ? fileTableData : []}
      />
    );
  };

  /**
   * function about return revision information section
   */
  handleReturnRevisionInformation = () => {
    return (
      <ProcedureBlock
        className={styles.approveProcedureFile}
        child={
          <DetailRevisionInformation
            title="Revision Information"
            updateFileNumLoading={this.props.updateFileNumLoading}
            openLoading={this.props.showLoading}
            hideLoading={this.props.hideLoading}
            accountId={this.props.pageProps.procedureAccountID}
            ref={this.props.revisionInfoRef}
            isDisabledAll={true}
            revisionInfoData={this.props.revisionInfoData}
          />
        }
        isShowArrow={true}
        isOpen={true}
      />
    );
  };

  /**
   * function about return page name
   */
  handleReturnPageName = () => {
    if (this.props.pageProps.flowCategoryID === flowCategoryId.copyProcedure) {
      return "Copy Procedure";
    }

    return "Update Procedure";
  };
}
