import React, { createRef } from "react";
import BaseComponent from "../../../../base/common/BaseComponent";
import ProcedureBlock from "../../../../base/basicComponents/procedureBlock";
import PageHeader from "../../../commonComponents/pageHeaders/PageHeader";
import TaskInformation from "../../../sections/taskInfoSections";
import ProcedureInformation from "../../../sections/procedureInfoSections/readOnlyProcedureInfoSection";
import ReadOnlySupportingFileSection from "../../../sections/supportingFileSections/readOnlySupportingFileSection";
import styles from "../../procedureFileProcess/style/index.module.scss";
import StepService from "../../../../service/stepBase/StepService";
import StepBusinessType from "../../../../service/stepBase/stepBusinessType";

const supportingFileRef = createRef();
const supportingFileTableRef = createRef();
export default class BaseChangMainTaskComplete extends BaseComponent {
  handleReturnProcedureFileSection = () => {
    // this is an empty function
  };

  handleReturnShareTaskSection = () => {
    // this is an empty function
  };

  handleReturnRejectReason = () => {
    // this.is an empty function
  };

  handleReturnPageName = () => {
    // this is an empty function
  };

  handleChangeMainCompletePageHeader = () => {
    const { receiveDate, requestByName } = this.props.pageProps;

    return (
      <PageHeader
        title={this.handleReturnPageName()}
        receiveDate={this._formateDate(receiveDate)}
        requestBy={requestByName}
        status="Complete"
      />
    );
  };

  handleChangeMainCompleteTaskInformation = (params) => {
    const { taskInfoData } = this.props.sectionProps;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={<TaskInformation data={taskInfoData} />}
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            params,
            this.props.setTaskInfoData,
            this.setError,
            [StepBusinessType.taskInfo]
          );
        }}
      />
    );
  };

  handleChangeMainCompleteProcedureFileSection = (params) => {
    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={this.handleReturnProcedureFileSection()}
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            params,
            this.props.setProcedureFileData,
            this.setError,
            [StepBusinessType.procedureFile]
          );
        }}
      />
    );
  };

  handleChangeMainCompleteSupportingFileSection = (params) => {
    const { supportingTableData } = this.props;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={
          <ReadOnlySupportingFileSection
            showHintMsg={true}
            supportingFileRef={supportingFileRef}
            supportingFileTableRef={supportingFileTableRef}
            supportingFileData={supportingTableData}
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            params,
            this.props.setSupportingFileData,
            this.setError,
            [StepBusinessType.supportingFile]
          );
        }}
      />
    );
  };

  handleChangeMainCompleteProcedureInformationSection = (params) => {
    const { procedureStatus } = this.props.pageProps;
    const { procedureInfoData, ppoList } = this.props.sectionProps;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={
          <ProcedureInformation
            isShowActions={false}
            noteDisabled={true}
            ppoList={ppoList}
            procedureStatus={procedureStatus}
            isPPODisabled={true}
            showHintMsg={true}
            submitData={procedureInfoData}
            title="Procedure Information"
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            params,
            this.props.setProcedureInfoData,
            this.setError,
            [
              StepBusinessType.procedureOwner,
              StepBusinessType.pmpExcluded,
              StepBusinessType.primaryProcedureOwner,
            ]
          );
        }}
      />
    );
  };

  handleChangeMainCompleteShareTaskSection = () => {
    return (
      <ProcedureBlock
        className={styles.approveProcedureFile}
        child={this.handleReturnShareTaskSection()}
        isShowArrow={true}
        isOpen={true}
      />
    );
  };

  render() {
    const params = {
      TaskId: this.props.params.ProcedureTaskId,
      ProcedureID: this.props.params.ProcedureID,
      ProcedureStepID: this.props.params.ProcedureStepID,
    };
    return (
      <div className={styles.approveNewPro}>
        {this.handleChangeMainCompletePageHeader()}
        {this.handleChangeMainCompleteTaskInformation(params)}
        {this.handleChangeMainCompleteProcedureFileSection(params)}
        {this.handleChangeMainCompleteSupportingFileSection(params)}
        {this.handleChangeMainCompleteProcedureInformationSection(params)}
        {this.handleChangeMainCompleteShareTaskSection()}
        {this.handleReturnRejectReason()}
      </div>
    );
  }
}
