import React from "react";
import BaseProcedureInformation from "../baseProcedureInfoSection";
import InputLabel from "../../../../base/basicComponents/inputLabel";
import Input, { NormalTextArea, ExpendTextArea } from "../../../../base/basicComponents/input";
import NormalSelect from "../../../../base/basicComponents/select";
import {
  pmpExcludedReasonSelectOptions,
  pmpExcludedReasonSelects,
} from "../../../publicDictionaryValues";
import styles from "../index.module.scss";

export default class PMPExcludedProcedureInformation extends BaseProcedureInformation {
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.submitData.isPMPExcluded !== this.props.submitData.isPMPExcluded
    ) {
      this.updateState(this.props.submitData);
    }
  }

  excludedSelectOptions = [
    { value: false, name: "No" },
    { value: true, name: "Yes" },
  ];

  render() {
    const {
      noteDisabled,
      ppoList,
      procedureStatus,
      title,
      isDisabledAll = false,
    } = this.props;

    const {
      currentPPO,
      currentPO,
      currentNote,
      currentPMPExcluded,
      currentPMPExcludedReasonSelect,
      currentPMPExcludedReasonInput,
      currentPMPExcludedReasonInputStatus,
    } = this.state;

    const infoList = {
      PPOList: ppoList.map((item) => {
        return {
          name: item.name,
          value: item.id,
        };
      }),
      POList: [],
      status: procedureStatus,
      notes: "",
    };

    const newCurrentPPO =
      infoList.PPOList.find((item) => item.value === currentPPO) === undefined
        ? null
        : currentPPO;

    return (
      <div className={styles.ProcedureInformation}>
        <div className={styles.procedureInfoTop}>
          <div className={styles.informationTitle}>
            <span className={`${styles.titleText} fs-18`}>{title}</span>
          </div>
        </div>
        <div className={styles.informationContent}>
          <div className={styles.informationLeft}>
            <div className={styles.informationList}>
              <div className={styles.informationItem}>
                <InputLabel
                  text="Procedure Status"
                  className={styles.informationLabel}
                />
                <div>
                  <Input value={procedureStatus} disabled={true} />
                </div>
              </div>
              <div className={styles.informationItem}>
                <InputLabel
                  text="PMP Excluded"
                  className={styles.informationLabel}
                />
                <NormalSelect
                  value={currentPMPExcluded}
                  disabled={true}
                  optionList={this.excludedSelectOptions}
                />
              </div>
              {currentPMPExcluded && (
                <div className={styles.informationItem}>
                  <InputLabel
                    text="Reason"
                    className={styles.informationLabel}
                    required={true}
                  />
                  <NormalSelect
                    optionList={pmpExcludedReasonSelectOptions}
                    value={currentPMPExcludedReasonSelect}
                    handleChange={(e) => {
                      this.updatePMPExcludedReasonSelect(e);
                      this.updatePMPExcludedReasonInputStatus("");
                    }}
                    disabled={isDisabledAll}
                  />
                </div>
              )}
              {((currentPMPExcluded &&
                currentPMPExcludedReasonSelect ===
                  pmpExcludedReasonSelects.otherReason) ||
                !currentPMPExcluded) && (
                <div className={styles.informationItem}>
                  <InputLabel text="" className={styles.informationLabel} />
                  <NormalTextArea
                    value={currentPMPExcludedReasonInput}
                    width="31.8125"
                    onChange={(e) => {
                      this.updatePMPExcludedReasonInput(e.target.value);
                      this.updatePMPExcludedReasonInputStatus("");
                    }}
                    disabled={isDisabledAll}
                    status={currentPMPExcludedReasonInputStatus}
                  />
                </div>
              )}
              <div className={styles.informationItem}>
                <InputLabel
                  text="Primary Procedure Owner"
                  className={styles.informationLabel}
                />
                <NormalSelect
                  optionList={infoList.PPOList}
                  value={newCurrentPPO}
                  disabled={true}
                />
              </div>
              <div className={styles.informationItem}>
                <InputLabel
                  text="Procedure Owner"
                  className={styles.informationLabel}
                />
                <NormalSelect
                  optionList={infoList.PPOList}
                  value={currentPO}
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className={styles.informationItem}>
            <InputLabel text="Note" className={styles.informationLabel} />
            <ExpendTextArea
              className={styles.informationExpendTextArea}
              value={currentNote}
              disabled={noteDisabled}
            />
          </div>
        </div>
      </div>
    );
  }
}
