import React from "react";
import BaseComponent from "../../../common/BaseComponent";
import { Layout, Menu, Dropdown, Avatar } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import "./topMenu.scss";
import { useMsal } from "@azure/msal-react";
import { openAdfs, loginRequest } from "../../../../app/pages/login/authConfig";
import NavLog from "../../../../assets/image/PMPlogo-white.png";
import styles from "../leftMenu/style/index.module.scss";
import userGuideIcon from "../../../../assets/image/userGuide.svg";
import appService from "../../../../service/app";
import AppSetting from "../../../../config/AppSetting";

const { Header } = Layout;

function MsalLogoutComponent(props) {
  const { logout } = props;
  const { instance }  = useMsal();

  const adfsLogout = () => {
    logout();
    if(openAdfs) {
      instance.logout(loginRequest).catch((error) => console.log('loginRequest', error));
    } else {
      window.location.reload();
    }
  }

  return (
    <Menu.Item onClick={adfsLogout}>
      Logout <LogoutOutlined />
    </Menu.Item>
  );  
}

export default class TopMenu extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      userGuides: []
    };
  }

  _logout = () => {
    this.appSetting.localStorageValue.setUnauthenticated();
  };

  _getGuidelinesSuccess = (response) => {
    const userGuides = [];
    if(response?.length > 0) {
      response.forEach(item => {
        userGuides.push({
          name: item.menuName,
          link: item.menuUrl,
        })
      })
    }

    AppSetting.localStorageValue.guidelines.set(userGuides);
    this.setState({
      userGuides: [...userGuides],
    })
  }

  componentDidMount() {    
    const guidelines=AppSetting.localStorageValue.guidelines.get();
    if(guidelines!==undefined&&guidelines.length>0){
      this.setState({
        userGuides: [...guidelines],
      })
      return;
    }

    appService.getGuidelines(
      this._getGuidelinesSuccess,
      () => this._alertError('Get Guide lines Failed'),
    )
  }

  render() {
    const user = this.appSetting.localStorageValue.user.get();
    const ColorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];

    if(!user?.data) {
      window.location.reload();
      return;
    }

    const menu = (
      <Menu>
        <Menu.Item disabled>{user.identityUserInfo.englishName}</Menu.Item>
        <MsalLogoutComponent
          logout={this._logout}
        />
      </Menu>
    );

    const guideMenu = (
      <Menu>
        {this.state.userGuides.map((item,index) => (
          <Menu.Item key={"menu_"+index}>
            <a href={item.link}>
              {item.name}
            </a>
          </Menu.Item>
        ))}
      </Menu>
    );

    const userGuideComponent = () => (
      <div className={styles.userGuideWrapper}>
        <img
          alt="User Guide"
          src={userGuideIcon}
        />
      </div>
    );
    
    return (
      <Header
        className="up-site-layout-background"
      >
        <div className="logo">
          <img className={styles.nav_logo} src={NavLog} />
        </div>
        {this.state.userGuides.length > 0 ? (
          <Dropdown 
          overlay={guideMenu} 
            placement="bottomRight"
            overlayClassName="avatar-dropdown"
          >
            {userGuideComponent()}
          </Dropdown>
          ) : (
          <>
            {userGuideComponent()}
          </>
        )}
        <Dropdown overlay={menu} overlayClassName="avatar-dropdown">
          <Avatar
            style={{
              backgroundColor: ColorList[user.identityUserInfo.englishName.length % 4],
              verticalAlign: "middle",
              width: 32,
            }}
            gap={1}
          >
            {user.identityUserInfo.englishName[0]}
          </Avatar>
        </Dropdown>
      </Header>
    );
  }
}
