import React from "react";
import ReviseProcedureFileTbody from "../../procedureFileTbodyFields/reviseProcedureFileTbody";
import ProcedureProcessFileThead from "../../approveAndReviseFileHeadFields";
import {
  dataStatus,
  deepClone,
  fileTags,
} from "../../../../../publicDictionaryValues";
import tablePublicMethods from "../../../../../commonComponents/fileManagementFields/tablePublicMethods";
import BaseFileTable from "../../../../../commonComponents/fileTables/baseFileTable";

export default class ReviseProcedureFileTable extends BaseFileTable {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  /* control table change to control save button whether disabled in approve and revise page */
  controlIsTableChange = (dataList) => {
    const newDataList = deepClone(dataList);
    const list = this.sortDataList(newDataList);
    const initList = this.sortDataList(this.props.initProcedureTableData);
    let flag = false;
    flag = !tablePublicMethods.compaireTwoCircular(list, initList);
    if (flag) {
      this.props.setIsTableChange();
    } else {
      this.props.cancelIsTableChange();
    }
  };

  /* update file table data */
  updateDataList = (dataList) => {
    if (
      this.props.initProcedureTableData !== undefined &&
      this.props.initProcedureTableData.length !== 0 &&
      !this.props.isComplete
    ) {
      this.controlIsTableChange(dataList);
    }
    if (dataList.length === 0) {
      this.setState({ isExpendData: false });
    }
    this.setState({
      data: dataList,
    });
    this.handleDefaultExpendIcon();
  };

  /* function about set return file number in revise page */
  setReturnDataNumber = (data) => {
    this.returnNumber = 0;
    for (let index in data) {
      if (
        data[index].data.find((i) => i.name === "fileName").tagValue !==
          fileTags.deleted &&
        data[index].data.find((i) => i.name === "status").value ===
          dataStatus.return &&
        data[index].data.find((i) => i.name === "newVersion").value === null
      ) {
        this.returnNumber += 1;
      }
    }
  };

  handleReturnTableTbody = (obj) => {
    const {
      data,
      columns,
      inputOnChange,
      procedureNoteMaxLength,
      fileSectionDisabled,
      procedureNoteSize,
      hasSubmit,
      fileTableRef,
      optionList,
      handleReturnClick,
      handleFileSelect,
      currentRole,
      downloadFile,
      isSupportingFile,
      isComplete,
      isExpendData,
    } = obj;
    return (
      <ReviseProcedureFileTbody
        data={data}
        columns={columns}
        inputOnChange={inputOnChange}
        procedureNoteMaxLength={procedureNoteMaxLength}
        fileSectionDisabled={fileSectionDisabled}
        procedureNoteSize={procedureNoteSize}
        hasSubmit={hasSubmit}
        fileTableRef={fileTableRef}
        optionList={optionList}
        handleReturnClick={handleReturnClick}
        handleFileSelect={handleFileSelect}
        currentRole={currentRole}
        downloadFile={downloadFile}
        isSupportingFile={isSupportingFile}
        isComplete={isComplete}
        handleChangeTableHeight={this.handleChangeTableHeight}
        isExpendData={isExpendData}
      />
    );
  };

  render() {
    const {
      procedureNoteMaxLength,
      fileSectionDisabled,
      procedureNoteSize,
      inputOnChange,
      hasSubmit,
      fileTableRef,
      className,
      optionList,
      handleReturnClick,
      handleFileSelect,
      pageType,
      currentRole,
      downloadFile,
      isSupportingFile,
      isComplete,
      isExpendData,
      columns,
    } = this.props;
    const { data } = this.state;
    this.setReturnDataNumber(data);

    return (
      <>
        <div className="scrollTable">
          <table className={`${className} table autoHeightChangeContainer`}>
            <ProcedureProcessFileThead
              columns={columns}
              hasSubmit={hasSubmit}
              pageType={pageType}
            />
            {this.handleReturnTableTbody({
              data,
              columns,
              inputOnChange,
              procedureNoteMaxLength,
              fileSectionDisabled,
              procedureNoteSize,
              hasSubmit,
              fileTableRef,
              optionList,
              handleReturnClick,
              handleFileSelect,
              currentRole,
              downloadFile,
              isSupportingFile,
              isComplete,
              isExpendData,
            })}
          </table>
        </div>
        <p
          className={`${this.props.className} fileNumTip`}
          style={{ color: "#FF9921" }}
        >
          <span className="fileNum">{this.returnNumber}</span>{" "}
          {this.setFileText(this.returnNumber)} returned for revision
        </p>
      </>
    );
  }
}
