import React, { createRef } from "react";
import BaseFileManagement from "../../../../../commonComponents/fileManagementFields/baseFileManagement";
import { ModalWithComponent } from "../../../../../../base/basicComponents/modal";
import styles from "./index.module.scss";
import ReviseProcedureFileTable from "../../procedureFileTableFields/reviseProcedureFileTable";
import AppSetting from "../../../../../../config/AppSetting";
import ProcedureDetailService from "../../../../../../service/procedureFile/ProcedureDetailService";
import downloadIcon from "../../../../../../assets/image/downloadIcon.png";
import shareIcon from "../../../../../../assets/image/detailShare.png";
import reasonEditDefaultIcon from "../../../../../../assets/image/reasonEditIcon.png";
import {
  fileTags,
  getTimeZoneString,
  handleAlertUploadFileError,
  handleUploadErrorCode,
} from "../../../../../publicDictionaryValues";
import NormalSpin from "../../../../../../base/basicComponents/spin";
const procedureReplaceFileRef = createRef();
const procedureReturnAddFileRef = createRef();
export default class ReviseProcedureFileManagement extends BaseFileManagement {
  constructor(props) {
    super(props);
    this.state = {
      modalState: {
        isModalWithComponentOpen: false,
        closable: false,
        okText: "Confirm",
        cancelText: "Cancel",
        modalTitle: "",
        modalLabel: "",
        handleOk: null,
        handleCancel: null,
        isOkBtnDisabled: false,
        isCancelBtnDisabled: false,
        maskClosable: false,
        isShowTextArea: false,
        textAreaPlacement: "",
        textAreaMaxLength: 0,
        textAreaClassName: "",
        rowIndex: 0,
        fileIds: [],
        fileNames: [],
        modalWidth: 520,
      },
      deleteData: [],
      isTableBorderError: this.props.isTableBorderError,
      downloadFileDisabled: true,
      downloadIcon: downloadIcon,
      shareIcon: shareIcon,
      loading: false,
      initOrLastSaveData: this.props.initProcedureDataList,
    };
    this.replaceList = [];
    this.fileList = [];
    this.deleteList = [];
    this.uploadProcedureFileNum = 0;
    this.procedureFileNum = 0;
    this.user = this.appSetting.localStorageValue.user.get();
  }

  componentDidMount() {
    if (
      this.props.fileTableRef !== undefined &&
      this.props.fileTableRef.current !== null
    ) {
      this.props.fileTableRef.current.updateDataList(this.props.data);
      this.setInitSaveSubmitData(this.props.data);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isTableBorderError !== this.props.isTableBorderError) {
      this.setState({ isTableBorderError: this.props.isTableBorderError });
    }
  }

  /**
   * function about control row's checkbox when check table header checkbox
   * @param {*} ele table header checkbox element
   */
  _headOnCheck = (ele) => {
    const dataList = this.props.fileTableRef.current.state.data;
    if (ele.checked) {
      for (let index = 0; index < dataList.length; index++) {
        dataList[index] = { ...dataList[index], checked: true };
        const tagValue = this.handleGetDataEle(index, "fileName", "tagValue");
        const status = this.handleGetDataEle(index, "status", "value");
      }
    } else {
      for (let index = 0; index < dataList.length; index++) {
        dataList[index] = { ...dataList[index], checked: false };
      }
    }
    this.openOrDisableDeleteButton(dataList);
    this.props.fileTableRef.current.updateDataList(dataList);
  };

  handleReviseProcedureFileManagementLineIdValue = (
    dataList,
    currentRowIndex
  ) => {
    return dataList[currentRowIndex].data.find((item) => item.name === "lineId")
      .value;
  };

  handleReviseProcedureFileManagementExistsInB = (
    lineIdValue,
    initOrLastSaveData
  ) => {
    return initOrLastSaveData.some((objB) => {
      const lineIdBValue = objB.data.find(
        (item) => item.name === "lineId"
      ).value;
      return lineIdBValue === lineIdValue;
    });
  };

  handleReviseProcedureFileManagementDeleteFile = (
    dataList,
    currentRowIndex
  ) => {
    const fileId = dataList[currentRowIndex].data.find(
      (x) => x.name === "fileId"
    ).value;
    let dto = {
      ProductKey: AppSetting.urlPrefix.deleteFile.ProductKey,
      StorageFileID: fileId,
    };
    ProcedureDetailService.deleteFile(
      dto,
      this._deleteFileOnSuccess,
      this._deleteFileOnFail,
      fileId
    );
  };

  handleReviseProcedureFileManagementSetDataListAfterDelete = (
    dataList,
    currentRowIndex
  ) => {
    dataList.splice(currentRowIndex, 1);
    this.props.fileTableRef.current.updateDataList(dataList);
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: false,
        modalLabel: "",
        handleOk: null,
        handleCancel: null,
      },
      deleteData: this.deleteList,
    });
  };

  /**
   * function about click confim when click delete each row
   * @param {*} currentRowIndex current row index
   */
  handleReviseProcedureFileManagementRowFileDeleteModalConfirm = (
    currentRowIndex
  ) => {
    const dataList = this.getDataList();
    const lineIdValue = this.handleReviseProcedureFileManagementLineIdValue(
      dataList,
      currentRowIndex
    );
    const initOrLastSaveData = this.state.initOrLastSaveData;
    const existsInB = this.handleReviseProcedureFileManagementExistsInB(
      lineIdValue,
      initOrLastSaveData
    );
    if (existsInB) {
      this.deleteList.push({ ...dataList[currentRowIndex], isDelete: true });
    } else {
      this.handleReviseProcedureFileManagementDeleteFile(
        dataList,
        currentRowIndex
      );
    }
    this.handleReviseProcedureFileManagementSetDataListAfterDelete(
      dataList,
      currentRowIndex
    );
    if (dataList.length === 0) {
      this.setState({ isTableBorderError: true });
    }
  };

  /**
   * function about hide modal
   */
  hideModal = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: false,
        modalLabel: "",
        handleOk: null,
        handleCancel: null,
      },
    });
  };

  /**
   * delete procedure when click each row's delete button in revise page
   * @param {*} e
   */
  deleteRowFile = (e) => {
    const dataList = this.getDataList();
    const index = e.target.attributes.rowindex.nodeValue;
    const isNew =
      dataList[index].data.find((item) => item.name === "fileName").tagValue ===
      fileTags.new;
    const hasStatus =
      dataList[index].data.find((item) => item.name === "status").value !==
      null;
    dataList[index].data.find((item) => item.name === "newVersion").value =
      null;
    dataList[index].data.find((item) => item.name === "newVersion").fileId =
      null;
    if (isNew && !hasStatus) {
      this.setState({
        modalState: {
          ...this.state.modalState,
          isModalWithComponentOpen: true,
          modalLabel: (
            <span className={styles.modalText}>
              Are you sure to delete this file?
            </span>
          ),
          handleOk: () =>
            this.handleReviseProcedureFileManagementRowFileDeleteModalConfirm(
              e.target.attributes.rowindex.nodeValue
            ),
          handleCancel: () => this.hideModal(),
        },
      });
    } else {
      dataList[index].data.find((item) => item.name === "fileName").tagValue =
        fileTags.deleted;
      dataList[index].isUpdate = false;
      dataList[index].isReturnDisabled = true;
      dataList[index].isApproveDisabled = false;
      if (dataList[index].isError) {
        dataList[index].isError = false;
      }
      dataList[index].data.find(
        (item) => item.name === "isReviewed"
      ).value = true;
      dataList[index].data.find(
        (item) => item.name === "note"
      ).isDisabled = true;
      this.props.fileTableRef.current.updateDataList(dataList);
    }
  };

  /**
   * click replace button in revise page
   * @param {*} e
   */
  replaceRowFile = (e) => {
    e.target.nextSibling.click();
  };

  /* rollback for procedure file table replace success */
  _replaceRowFileOnSuccess = (result, obj) => {
    this.props._hideLoading();
    const { index, fileName } = obj;
    const tableDataList = this.props.procedureRef.current.getDataList();
    tableDataList[index].data.find(
      (item) => item.name === "fileName"
    ).tagValue = fileTags.replaced;
    tableDataList[index].data.find(
      (item) => item.name === "newVersion"
    ).fileId = result.storageFileID;
    tableDataList[index].data.find((item) => item.name === "newVersion").value =
      fileName;
    const replaceUserId = tableDataList[index].data.find((item) => item.name === "replaceUserId");
    const replaceUserName = tableDataList[index].data.find((item) => item.name === "replaceUserName");
    if(replaceUserId && replaceUserName) {
      tableDataList[index].data.find((item) => item.name === "replaceUserId").value = this.user.identityUserInfo.internalUserID;
      tableDataList[index].data.find((item) => item.name === "replaceUserName").value = this.user.identityUserInfo.englishName;
    } else {
      tableDataList[index].data.push({name: "replaceUserId", value: this.user.identityUserInfo.internalUserID});
      tableDataList[index].data.push({name: "replaceUserName", value: this.user.identityUserInfo.englishName});
    }
    tableDataList[index].isReturnDisabled = false;
    tableDataList[index].isUpdate = true;
    if (tableDataList[index].isError) {
      tableDataList[index].isError = false;
    }
    tableDataList[index].data.find(
      (item) => item.name === "isReviewed"
    ).value = true;
    tableDataList[index].data.find(
      (item) => item.name === "note"
    ).isDisabled = false;
    this.props.fileTableRef.current.updateDataList(tableDataList);
  };

  /* rollback for procedure file table replace error */
  _replaceReviseProcedureFileManagementRowFileOnError = (error) => {
    console.log(error);
    this._alertError("Replace failed");
    this.props._hideLoading();
  };

  handleReviseProcedureFileManagementShowDuplicateModal = (tableList, item) => {
    tableList.forEach((data) => {
      if (
        data.data.find((i) => i.name === "fileName").value === item.name ||
        data.data.find((i) => i.name === "newVersion").value === item.name
      ) {
        this._alertError(`${item.name} already exists.`);
        this.isFileDuplicate = true;
      }
    });
  };

  handleReviseProcedureFileManagementRowReplaceFileAddFile = (
    returnPara,
    item
  ) => {
    this.props._showLoading();
    let fileForm = new FormData();
    let addFileDto = this.getAddFileDto(item);
    fileForm.append("formFile", item);
    fileForm.append("addFileDto", JSON.stringify(addFileDto));
    ProcedureDetailService.addFile(
      fileForm,
      (response) => {
        this._replaceRowFileOnSuccess(response, returnPara);
      },
      this._replaceReviseProcedureFileManagementRowFileOnError
    );
  };

  /* function about procedure file table replace button */
  handleReviseProcedureFileManagementRowReplaceFileSelect = (event, index) => {
    this.isFileDuplicate = false;
    const files = [...event.target.files];
    const tableData = this.getDataList();
    const tableList = [...tableData];
    tableList.splice(index, 1);
    for (let item of files) {
      this.handleReviseProcedureFileManagementShowDuplicateModal(
        tableList,
        item
      );
      if (handleAlertUploadFileError(files)) {
        return;
      }
      const returnPara = { index: index, fileName: item.name };
      if (!this.isFileDuplicate) {
        this.handleReviseProcedureFileManagementRowReplaceFileAddFile(
          returnPara,
          item
        );
      } else {
        return;
      }
    }
  };

  /**
   * Check File Duplicate
   * @param {*} index
   * @param {*} dataList
   * @param {*} tableDataList
   * @returns
   */
  fileCheck = (index, dataList, tableDataList) => {
    this.uploadFileIndex = index;
    this.uploadDataList = dataList;
    this.tableDataList = tableDataList;
    const isDuplicateWithNewVersion =
      this.fileList[index] !== undefined &&
      tableDataList.findIndex(
        (item) =>
          item.data.find((dataItem) => dataItem.name === "newVersion").value ===
          this.fileList[index].name
      ) !== -1;

    if (index >= this.fileList.length) {
      if (this.replaceList.length) {
        this.handleReplaceModalConfirm(this.replaceList);
        this.replaceList = [];
      }
      return true;
    }
    if (
      dataList.findIndex(
        (dataItem) => dataItem.value === this.fileList[index].name
      ) !== -1 ||
      isDuplicateWithNewVersion
    ) {
      this._alertError(`${this.fileList[index].name} already exists.`);
      return false;
    } else {
      return this.fileCheck(index + 1, dataList, tableDataList);
    }
  };

  /* function about add data in table when upload files */
  createLine = (dataList, fileId, fileName) => {
    const lineId = this._generateGUID();
    const fileKey = this._generateGUID();
    const newFileItme = {
      data: [
        { name: "lineId", value: lineId },
        { name: "fileId", value: fileId },
        { name: "procedureFileID", value: null },
        {
          name: "procedureFileStepID",
          value: null,
        },
        { name: "fileKey", value: fileKey },
        {
          name: "fileName",
          value: fileName,
          tagValue: fileTags.new,
        },
        {
          name: "uploadedDate",
          value: this._formateDate(getTimeZoneString(this._getDate())),
        },
        { name: "uploadByName", value: this.user.identityUserInfo.englishName },
        {
          name: "uploadById",
          value: this.user.identityUserInfo.internalUserID,
        },
        { name: "note", value: null, isDisabled: false },
        { name: "status", value: null },
        {
          name: "returnReason",
          value: null,
          isDisabled: true,
          isShowReasonLog: false,
        },
        {
          name: "attachment",
          value: null,
          isDisabled: false,
          link: null,
          fileId: null,
        },
        { name: "newVersion", value: null, link: null, fileId: null },
        {
          name: "previousStatus",
          value: null,
        },
        {
          name: "previousFileTag",
          value: null,
        },
        {
          name: "isReviewed",
          value: false,
        },
      ],
      checked: false,
      isApproveDisabled: false,
      isReturnDisabled: false,
      isReturned: false,
      isError: false,
      replaceRef: procedureReplaceFileRef,
      attachmentEditIcon: reasonEditDefaultIcon,
      isDelete: false,
      isUpdate: false,
    };
    dataList.unshift(newFileItme);
    this.setState({ isTableBorderError: false });
  };

  /* function about show loading when upload file */
  handleUploadLoadiing = async () => {
    this.uploadProcedureFileNum += 1;
    await this.props.updateProcedureFileNum(
      this.procedureFileNum,
      this.uploadProcedureFileNum
    );
    if (this.uploadProcedureFileNum === this.procedureFileNum) {
      this.uploadProcedureFileNum = 0;
      this.procedureFileNum = 0;
      await this.props.updateProcedureFileNum(0, 0);
      this.props._hideLoading();
    }
  };

  /**
   * callback about upload file api success
   * @param {*} result
   * @param {*} preRequestData
   */
  _uploadProcedureFileOnSuccess = (result, preRequestData) => {
    this.setState({ isTableBorderError: false });
    handleUploadErrorCode(result.errorCode);
    const procedureFileData = this.getDataList();
    this.createLine(
      procedureFileData,
      result.storageFileID,
      preRequestData.fileName
    );
    if (preRequestData.fileList.length > 0) {
      this.handleFileSelect(preRequestData.fileList);
    }
    this.props.fileTableRef.current.updateDataList(procedureFileData);
    this.handleUploadLoadiing();
  };

  /* callback about upload file api failed */
  _uploadProcedureFileOnError = () => {
    this.handleUploadLoadiing();
  };

  handleChangeUploadTip = async () => {
    await this.props.updateProcedureFileNum(
      this.procedureFileNum,
      this.uploadProcedureFileNum
    );
  };

  /* function about upload file */
  handleFileSelect = (files) => {
    if (files.length === 0) {
      return;
    } else {
      if (handleAlertUploadFileError(this.fileList)) {
        return;
      }
      const item = files.splice(0, 1)[0];
      this.handleChangeUploadTip();
      let fileForm = new FormData();
      let addFileDto = this.getAddFileDto(item);
      fileForm.append("formFile", item);
      fileForm.append("addFileDto", JSON.stringify(addFileDto));
      ProcedureDetailService.addFile(
        fileForm,
        this._uploadProcedureFileOnSuccess,
        this._uploadProcedureFileOnError,
        { fileName: item.name, fileList: files }
      );
    }
  };

  /* function about upload deuplicate file */
  FileProcess = (index, dataList, tableDataList) => {
    if (!this.fileCheck(index, dataList, tableDataList)) {
      return;
    }
    this.handleFileSelect(this.fileList);
  };

  /* function about upload depulicate file when upload file */
  handleReplaceFile = (event) => {
    this.fileList = [...event.target.files];
    this.procedureFileNum = this.fileList.length;
    if (handleAlertUploadFileError(this.fileList)) {
      return;
    }
    const tableDataList = this.getDataList();
    let dataList = [];
    tableDataList.forEach((data) => {
      const item = data.data.find((dataItem) => dataItem.name === "fileName");
      dataList.push(item);
    });

    this.FileProcess(0, dataList, tableDataList);
  };

  /* control confirm button disabled in the modal */
  _openModalBtnsDisabled = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isOkBtnDisabled: true,
        isCancelBtnDisabled: true,
      },
    });
  };

  /* control cancel button disabled in the modal */
  _hideModalBtnsDisabled = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isOkBtnDisabled: false,
        isCancelBtnDisabled: false,
      },
    });
  };

  handleReturnColumns = () => {
    const { isDisableAll, noteRef, status, noteDisabled, reasonRef } =
      this.props;
    return [
      {
        name: "ProcedureId",
        dataName: "procedureId",
        type: "text",
        isShow: false,
      },
      {
        name: "checkbox",
        dataName: "checkbox",
        type: "checkbox",
        isShow: true,
        check: this._check,
        onCheck: this._onCheck,
        headCheck: this._headCheck,
        headOnCheck: this._headOnCheck,
      },
      {
        name: "File Name",
        dataName: "fileName",
        type: "fileName",
        isShow: true,
        isDisabled: isDisableAll,
      },
      {
        name: "Uploaded Date",
        dataName: "uploadedDate",
        type: "text",
        isShow: true,
      },
      {
        name: "Uploaded By",
        dataName: "uploadByName",
        type: "text",
        isShow: true,
      },
      {
        name: "Note",
        dataName: "note",
        type: "input",
        ref: noteRef,
        isShow: status !== 3 && !this.props.isCopyPage,
        isDisabled: isDisableAll || noteDisabled,
      },
      {
        name: "Approval Date",
        dataName: "approveDate",
        type: "text",
        isShow: status === 3 || this.props.isCopyPage,
      },
      {
        name: "Status",
        dataName: "status",
        type: "tag",
        isShow: !this.props.isCopyPage,
      },
      {
        name: "Return Reason",
        dataName: "returnReason",
        type: "input",
        ref: reasonRef,
        isShow: !this.props.isCopyPage,
        showEdit: false,
        isDisabled: isDisableAll,
      },
      {
        name: "Attachment",
        dataName: "attachment",
        type: "link",
        isShow: !this.props.isCopyPage,
        showEdit: false,
      },
      {
        name: "New Version",
        dataName: "newVersion",
        type: "link",
        isShow: !this.props.isCopyPage,
      },
      {
        name: "Action",
        dataName: "action",
        type: "button",
        isApproveDisabled: false,
        isReturnDisabled: false,
        approveText: "Replace",
        returnText: "Delete",
        approveColor: "blue",
        returnColor: "greyRed",
        onApproveClick: this.replaceRowFile,
        onReturnClick: () => this.deleteRowFile,
        isShow: !isDisableAll && !this.props.isCopyPage,
      },
    ];
  };

  handleReturnFileTable = (obj) => {
    const {
      fileTableRef,
      columns,
      className,
      currentRole,
      downloadFile,
      isDisableAll,
      initProcedureTableData,
      setIsTableChange,
      cancelIsTableChange,
      fileTableStatus,
      optionList,
      isTableBorderError,
    } = obj;

    return (
      <div className={`${isTableBorderError ? styles.border_error : ""}`}>
        <ReviseProcedureFileTable
          ref={fileTableRef}
          columns={columns}
          procedureNoteMaxLength={1000}
          procedureNoteSize="small"
          inputOnChange={this.inputOnChange}
          fileTableRef={fileTableRef}
          className={className}
          currentRole={currentRole}
          downloadFile={downloadFile}
          isComplete={isDisableAll}
          initProcedureTableData={initProcedureTableData}
          isTableBorderError={isTableBorderError}
          setIsTableChange={setIsTableChange}
          cancelIsTableChange={cancelIsTableChange}
          fileTableStatus={fileTableStatus}
          handleFileSelect={
            this.handleReviseProcedureFileManagementRowReplaceFileSelect
          }
          optionList={optionList}
        />
      </div>
    );
  };

  handleReviseProcedureFileManagementModalWithComponent = () => {
    const {
      modalTitle,
      closable,
      isCancelBtnDisabled,
      isModalWithComponentOpen,
      modalLabel,
      rowIndex,
      fileIds,
      okText,
      cancelText,
      fileNames,
      modalWidth,
      handleOk,
      handleCancel,
      maskClosable,
      isOkBtnDisabled,
    } = this.state.modalState;

    return (
      <ModalWithComponent
        isModalOpen={isModalWithComponentOpen}
        closable={closable}
        okText={okText}
        fileIds={fileIds}
        fileNames={fileNames}
        handleOk={handleOk}
        handleCancel={handleCancel}
        isOkDisabled={isOkBtnDisabled}
        isCancelDisabled={isCancelBtnDisabled}
        maskClosable={maskClosable}
        rowindex={rowIndex}
        hideLoading={this._hideModalBtnsDisabled}
        label={modalLabel}
        fileUploadRef={procedureReplaceFileRef}
        fileUploadInputRef={procedureReturnAddFileRef}
        openLoading={this._openModalBtnsDisabled}
        isLoading={isOkBtnDisabled}
        modalWidth={modalWidth}
        cancelText={cancelText}
        title={modalTitle}
      />
    );
  };

  render() {
    const {
      noteRef,
      reasonRef,
      currentRole,
      isDisableAll,
      status,
      noteDisabled,
      fileTableRef,
      className,
      downloadFile,
      initProcedureTableData,
      fileTableStatus,
      optionList,
      setIsTableChange,
      cancelIsTableChange,
    } = this.props;

    const columns = this.handleReturnColumns({
      isDisableAll,
      noteRef,
      status,
      noteDisabled,
      reasonRef,
    });

    return (
      <NormalSpin
        spinning={this.state.loading}
        size="large"
        children={
          <div>
            <div className={styles.fileManagementDiv}>
              <>
                <div className={styles.buttonDiv}>
                  {this.handleReturnDownloadBtn(
                    true,
                    this.state.downloadFileDisabled
                  )}
                  {this.handleReturnShareBtn(
                    false,
                    this.state.downloadFileDisabled
                  )}
                  {this.handleReturnUploadBtn(
                    !isDisableAll,
                    false,
                    this.handleReplaceFile
                  )}
                </div>
                {this.handleReturnFileTable({
                  fileTableRef,
                  columns,
                  className,
                  currentRole,
                  downloadFile,
                  isDisableAll,
                  initProcedureTableData,
                  setIsTableChange,
                  cancelIsTableChange,
                  fileTableStatus,
                  optionList,
                  isTableBorderError: this.state.isTableBorderError,
                })}
              </>
            </div>
            {this.handleReviseProcedureFileManagementModalWithComponent()}
          </div>
        }
      />
    );
  }
}
