import AppSetting from "../../config/AppSetting";

export default class RequestOption {
  /**
   * @param {string} method
   * @param {obj} headers example
   *   headers: {
   *       'Content-Type': 'application/json',
   *       'Authorization': 'Bearer my-token',
   *       'My-Custom-Header': 'foobar'
   *   }
   * @param {obj} body
   * @param {boolean} requireAuth
   * @param {function} onSuccess
   * @param {function} onError
   */
  constructor(obj) {
    const {
      method,
      headers,
      body,
      requireAuth,
      onSuccess,
      onError,
      returnPara,
      keepalive,
    } = obj;
    this.method = method;
    this.headers = headers;
    this.body = body;
    this.requireAuth = requireAuth;
    this.onError = onError;
    this.onSuccess = onSuccess;
    this.returnPara = returnPara;
    this.keepalive = keepalive;
  }

  checkRequireAuth() {
    if (
      this.requireAuth &&
      AppSetting.localStorageValue.isAuthenticated.get()
    ) {
      return;
    }

    throw Error("Have not authenticated!");
  }
}
