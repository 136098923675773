import React, { createRef } from "react";
import BaseComponent from "../../../../../base/common/BaseComponent";
import ProcedureBlock from "../../../../../base/basicComponents/procedureBlock";
import PageHeader from "../../../../commonComponents/pageHeaders/PageHeader";
import TaskInformation from "../../../../sections/taskInfoSections";
import ProcedureInformation from "../../../../sections/procedureInfoSections/readOnlyProcedureInfoSection";
import ReadOnlyProcedureFileSection from "../../../../sections/procedureFileSections/readOnlyProcedureFileSection";
import ActiveShareTaskReadOnly from "../../../../sections/sharedTaskSections/activeShareTaskReadOnly";
import styles from "../../style/index.module.scss";
import StepService from "../../../../../service/stepBase/StepService";
import StepBusinessType from "../../../../../service/stepBase/stepBusinessType";

const procedureFileRef = createRef();
export default class BaseNewSupportingFileComplete extends BaseComponent {
  handleReturnSupportingFileSection = () => {
    // this is an empty function
  };

  handleReturnRejectReason = () => {
    // this.is an empty function
  };

  handleReturnPageName = () => {
    // this is an empty function
  };

  handleSupportingFilePageHeader = () => {
    const { receiveDate, requestByName } = this.props.pageProps;

    return (
      <PageHeader
        title={this.handleReturnPageName()}
        receiveDate={this._formateDate(receiveDate)}
        requestBy={requestByName}
        status="Complete"
      />
    );
  };

  handleSupportingFileTaskInformationSection = (params) => {
    const { taskInfoData } = this.props.sectionProps;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={<TaskInformation data={taskInfoData} />}
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            params,
            this.props.setTaskInfoData,
            this.setError,
            [StepBusinessType.taskInfo]
          );
        }}
      />
    );
  };

  handleSupportingFileProcedureFileSection = (params) => {
    const { fileTableData, jbProcedureSavedIn } = this.props.sectionProps;

    return (
      <ProcedureBlock
        child={
          <ReadOnlyProcedureFileSection
            procedureFileRef={procedureFileRef}
            clientServerPath={this.props.serverPathValue}
            savedInRadioValue={this.props.savedInRadioValue}
            procedureFileData={fileTableData !== undefined ? fileTableData : []}
            showHintMsg={true}
            jbProcedureSavedIn={jbProcedureSavedIn}
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            params,
            this.props.setProcedureFileData,
            this.setError,
            [StepBusinessType.procedureFile]
          );
        }}
      />
    );
  };

  handleSupportingFileSection = () => {
    return (
      <ProcedureBlock
        className={styles.approveSupportingFile}
        child={this.handleReturnSupportingFileSection()}
        isShowArrow={true}
        isOpen={true}
      />
    );
  };

  handleSupportingFileProcedureInformationSection = (params) => {
    const { procedureStatus } = this.props.pageProps;
    const { procedureInfoData, ppoList } = this.props.sectionProps;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={
          <ProcedureInformation
            isShowActions={false}
            noteDisabled={true}
            ppoList={ppoList}
            procedureStatus={procedureStatus}
            isPPODisabled={true}
            showHintMsg={true}
            submitData={procedureInfoData}
            title="Procedure Information"
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            params,
            this.props.setProcedureInfoData,
            this.setError,
            [
              StepBusinessType.procedureOwner,
              StepBusinessType.pmpExcluded,
              StepBusinessType.primaryProcedureOwner,
            ]
          );
        }}
      />
    );
  };

  handleSupportingFileShareTaskSection = (params) => {
    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={
          <ActiveShareTaskReadOnly
            showHintMsg={true}
            shareTaskData={this.props.shareTaskData}
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            params,
            this.props.setShareTaskData,
            this.setError,
            [StepBusinessType.shareTask]
          );
        }}
      />
    );
  };

  render() {
    const params = {
      TaskId: this.props.params.ProcedureTaskId,
      ProcedureID: this.props.params.ProcedureID,
      ProcedureStepID: this.props.params.ProcedureStepID,
    };
    return (
      <div className={styles.approveNewPro}>
        {this.handleSupportingFilePageHeader()}
        {this.handleSupportingFileTaskInformationSection(params)}
        {this.handleSupportingFileProcedureFileSection(params)}
        {this.handleSupportingFileSection()}
        {this.handleSupportingFileProcedureInformationSection(params)}
        {this.handleSupportingFileShareTaskSection(params)}
        {this.handleReturnRejectReason()}
      </div>
    );
  }
}
