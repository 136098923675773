import React, { createRef } from "react";
import NormalSpin from "../../../../base/basicComponents/spin";
import {
  downloadSingleFile,
  handleUpdateReviseSortFile,
  reviseUpdateSortFilesFollowFileTag,
} from "../../../publicDictionaryValues";
import CreateNewProcedureProcess from "../createNewProcedureProcess";
import styles from "../style/index.module.scss";
import ApproveUpdateProcedureComplete from "./approveUpdateProcedureComplete";
import ApproveUpdateProcedurePending from "./approveUpdateProcedurePending";
import ReviseUpdateProcedureComplete from "./reviseUpdateProcedureComplete";
import ReviseUpdateProcedurePending from "./reviseUpdateProcedurePending";
import SubmitUpdateProcedureComplete from "./submitUpdateProcedureComplete";

const procedureReplaceFileRef = createRef();
const procedureRef = createRef();
const procedureServerPathRef = createRef();
const procedureSavedInRef = createRef();
const approvePageRef = createRef();
const revisionInfoRef = createRef();
export default class UpdateProcedureProcess extends CreateNewProcedureProcess {
  /* sort procedure files follow status in revise page */
  handleSortFile = (result) => {
    const tiggerTimes = result.sequence;
    let isRevise = false;
    if (
      result.workflowProcessStepInfo !== null &&
      result.workflowProcessStepInfo.annotations.length !== 0
    ) {
      isRevise =
        result.workflowProcessStepInfo.annotations.find(
          (item) => (item.name = "IsRevise")
        ).value === "1";
    }
    if (tiggerTimes > 1) {
      return handleUpdateReviseSortFile(
        result.procedureFolder?.procedureFiles,
        this._formateDate,
        isRevise
      );
    }
    return reviseUpdateSortFilesFollowFileTag(
      result.procedureFolder?.procedureFiles,
      this._formateDate
    );
  };

  /**
   * Change update attachment loading ui
   * @param {*} fileLoading
   */
  updateFileNumLoading = (fileLoading) => {
    this.setState({
      fileLoading: fileLoading,
      pageState: {
        ...this.state.pageState,
        loading: fileLoading.allNum !== fileLoading.currentNum,
      },
    });
  };

  handleUpdateProcedureProcessPageProps = () => {
    const {
      receiveDate,
      requestByName,
      procedureStatus,
      rejectStatus,
      currentRole,
      procedureAccountID,
      flowCategoryID,
    } = this.state.pageState;

    return {
      receiveDate,
      requestByName,
      procedureStatus,
      rejectStatus,
      currentRole,
      procedureReplaceFileRef,
      procedureAccountID,
      flowCategoryID,
    };
  };

  handleUpdateProcedureProcessSectionProps = () => {
    const { taskInfoData, procedureInfoData } = this.state;

    return {
      taskInfoData,
      procedureInfoData,
      ppoList: this.state.ppoList,
    };
  };

  handleUpdateProcedureProcessSavedInProps = () => {
    const {
      savedInReturnReasonInputValue,
      savedInReturnReasonValue,
      savedInReasonStatus,
      procedureSaveInCategoryStatusID,
      savedInRadioValue,
      isSavedInSectionChange,
    } = this.state.savedInSectionState;

    return {
      savedInReturnReasonInputValue,
      savedInReturnReasonValue,
      savedInReasonStatus,
      procedureSaveInCategoryStatusID,
      savedInRadioValue,
      initSavedInStatus:
        this.initSavedInSectionState.procedureSaveInCategoryStatusID,
      savedInReturnReasonLog: this.savedInReturnReasonLog,
      isSavedInSectionChange,
    };
  };

  handleUpdateProcedureProcessServerPathProps = () => {
    const {
      clientServerPathStatusName,
      serverPathReturnReasonValue,
      serverPathReasonStatus,
      serverPathReturnReasonInputValue,
      clientServerPathStatusID,
      serverPathValue,
      isServerPathSectionChange,
    } = this.state.serverPathSectionState;

    return {
      clientServerPathStatusName,
      serverPathReturnReasonValue,
      serverPathReasonStatus,
      serverPathReturnReasonInputValue,
      serverPathReturnReasonLog: this.serverPathReturnReasonLog,
      clientServerPathStatusID,
      serverPathValue,
      isServerPathSectionChange,
    };
  };

  handleUpdateProcedureProcessParams = () => {
    return {
      ProcedureTaskId: this.taskID,
      ProcedureID: this.procedureID,
      ProcedureStepID: this.procedureStepID,
      ProcedureFileStepID: this.procedureFileStepID,
      WorkflowProcessInstanceId: this.workflowProcessInstanceId,
      FlowCategoryID: this.flowCategoryID,
    };
  };

  handleUpdateProcedureProcessReviseUpdateProcedurePending = (
    procedureTableData
  ) => {
    const { shareTaskData, supportingTableData, revisionInfoState } =
      this.state;

    return (
      <ReviseUpdateProcedurePending
        pageProps={{
          ...this.handleUpdateProcedureProcessPageProps(),
          isDisableAll: false,
        }}
        sectionProps={this.handleUpdateProcedureProcessSectionProps()}
        savedInProps={this.handleUpdateProcedureProcessSavedInProps()}
        serverPathProps={this.handleUpdateProcedureProcessServerPathProps()}
        initSavedInSectionState={this.initSavedInSectionState}
        initServerPathSectionState={this.initServerPathSectionState}
        initProcedureTableData={[...this.initProcedureTableData]}
        initRevisionInfoState={this.initRevisionInfoState}
        initProcedureFileTableState={this.initProcedureFileTableState}
        data={procedureTableData}
        params={this.handleUpdateProcedureProcessParams()}
        _showLoading={this._showLoading}
        _hideLoading={this._hideLoading}
        updateFileNumLoading={this.updateFileNumLoading}
        downloadFile={downloadSingleFile}
        returnFileDataValue={this.returnFileDataValue}
        handleReturnSubmitData={this.handleReturnSubmitData}
        supportingTableData={supportingTableData}
        revisionInfoData={revisionInfoState}
        procedureRef={procedureRef}
        procedureServerPathRef={procedureServerPathRef}
        revisionInfoRef={revisionInfoRef}
        procedureSavedInRef={procedureSavedInRef}
        shareTaskData={shareTaskData}
        sortDataList={this.sortDataList}
        primitiveSavedInSectionState={this.primitiveSavedInSectionState}
        primitiveServerPathSectionState={this.primitiveServerPathSectionState}
        closePage={this.closePage}
        setShareTaskData={this.setShareTaskData}
        setProcedureInfoData={this.setProcedureInfoData}
        setSupportingFileData={this.setSupportingFileData}
        isLevelCChange={this.isLevelCChange}
      />
    );
  };

  handleUpdateProcedureProcessReviseUpdateProcedureComplete = (
    procedureTableData
  ) => {
    const { shareTaskData, supportingTableData, revisionInfoState } =
      this.state;

    return (
      <ReviseUpdateProcedureComplete
        pageProps={{
          ...this.handleUpdateProcedureProcessPageProps(),
          isDisableAll: true,
        }}
        sectionProps={this.handleUpdateProcedureProcessSectionProps()}
        savedInProps={this.handleUpdateProcedureProcessSavedInProps()}
        serverPathProps={this.handleUpdateProcedureProcessServerPathProps()}
        initSavedInSectionState={this.initSavedInSectionState}
        initServerPathSectionState={this.initServerPathSectionState}
        initProcedureFileTableState={this.initProcedureFileTableState}
        initProcedureTableData={[...this.initProcedureTableData]}
        data={procedureTableData}
        params={this.handleUpdateProcedureProcessParams()}
        procedureRef={procedureRef}
        shareTaskData={shareTaskData}
        sortDataList={this.sortDataList}
        supportingTableData={supportingTableData}
        revisionInfoData={revisionInfoState}
        procedureSavedInRef={procedureSavedInRef}
        procedureServerPathRef={procedureServerPathRef}
        revisionInfoRef={revisionInfoRef}
        _showLoading={this._showLoading}
        returnFileDataValue={this.returnFileDataValue}
        handleReturnSubmitData={this.handleReturnSubmitData}
        primitiveSavedInSectionState={this.primitiveSavedInSectionState}
        primitiveServerPathSectionState={this.primitiveServerPathSectionState}
        closePage={this.closePage}
        _hideLoading={this._hideLoading}
        downloadFile={downloadSingleFile}
        setShareTaskData={this.setShareTaskData}
        setProcedureInfoData={this.setProcedureInfoData}
        setSupportingFileData={this.setSupportingFileData}
      />
    );
  };

  handleUpdateProcedureProcessApproveUpdateProcedurePending = (
    procedureTableData,
    newRejectReason
  ) => {
    const { shareTaskData, supportingTableData, revisionInfoState } =
      this.state;

    return (
      <ApproveUpdateProcedurePending
        ref={approvePageRef}
        approvePageRef={approvePageRef}
        pageProps={{
          ...this.handleUpdateProcedureProcessPageProps(),
          newRejectReason,
          actionID: this.actionID,
          isDisableAll: false,
        }}
        sectionProps={this.handleUpdateProcedureProcessSectionProps()}
        savedInProps={this.handleUpdateProcedureProcessSavedInProps()}
        serverPathProps={this.handleUpdateProcedureProcessServerPathProps()}
        initSavedInSectionState={this.initSavedInSectionState}
        initServerPathSectionState={this.initServerPathSectionState}
        initProcedureTableData={[...this.initProcedureTableData]}
        initProcedureFileTableState={this.initProcedureFileTableState}
        params={{
          ...this.handleUpdateProcedureProcessParams(),
          CategoryID: this.categoryID,
          AccountID: this.accountID,
          TaskGroupID: this.taskGroupID,
          TaskLabelID: this.taskLabelID,
        }}
        data={procedureTableData}
        shareTaskData={shareTaskData}
        procedureRef={procedureRef}
        revisionInfoRef={revisionInfoRef}
        _showLoading={this._showLoading}
        _hideLoading={this._hideLoading}
        updateFileNumLoading={this.updateFileNumLoading}
        downloadFile={downloadSingleFile}
        revisionInfoData={revisionInfoState}
        returnFileDataValue={this.returnFileDataValue}
        handleReturnSubmitData={this.handleReturnSubmitData}
        closePage={this.closePage}
        procedureSavedInRef={procedureSavedInRef}
        procedureServerPathRef={procedureServerPathRef}
        supportingTableData={supportingTableData}
        setShareTaskData={this.setShareTaskData}
        setProcedureInfoData={this.setProcedureInfoData}
        setSupportingFileData={this.setSupportingFileData}
        isLevelCChange={this.isLevelCChange}
      />
    );
  };

  handleUpdateProcedureProcessApproveUpdateProcedureComplete = (
    procedureTableData,
    newRejectReason
  ) => {
    const { shareTaskData, supportingTableData, revisionInfoState } =
      this.state;

    return (
      <ApproveUpdateProcedureComplete
        ref={approvePageRef}
        approvePageRef={approvePageRef}
        pageProps={{
          ...this.handleUpdateProcedureProcessPageProps(),
          newRejectReason,
          actionID: this.actionID,
          isDisableAll: true,
        }}
        sectionProps={this.handleUpdateProcedureProcessSectionProps()}
        initSavedInSectionState={this.initSavedInSectionState}
        initServerPathSectionState={this.initServerPathSectionState}
        initProcedureTableData={[...this.initProcedureTableData]}
        initProcedureFileTableState={this.initProcedureFileTableState}
        data={procedureTableData}
        shareTaskData={shareTaskData}
        params={{
          ...this.handleUpdateProcedureProcessParams(),
          CategoryID: this.categoryID,
          AccountID: this.accountID,
          TaskGroupID: this.taskGroupID,
          TaskLabelID: this.taskLabelID,
        }}
        closePage={this.closePage}
        supportingTableData={supportingTableData}
        revisionInfoData={revisionInfoState}
        procedureRef={procedureRef}
        procedureSavedInRef={procedureSavedInRef}
        procedureServerPathRef={procedureServerPathRef}
        savedInProps={this.handleUpdateProcedureProcessSavedInProps()}
        serverPathProps={this.handleUpdateProcedureProcessServerPathProps()}
        revisionInfoRef={revisionInfoRef}
        _showLoading={this._showLoading}
        returnFileDataValue={this.returnFileDataValue}
        handleReturnSubmitData={this.handleReturnSubmitData}
        isLevelCChange={this.isLevelCChange}
        _hideLoading={this._hideLoading}
        downloadFile={downloadSingleFile}
        setShareTaskData={this.setShareTaskData}
        setProcedureInfoData={this.setProcedureInfoData}
        setSupportingFileData={this.setSupportingFileData}
      />
    );
  };

  handleUpdateProcedureProcessSubmitUpdateProcedureComplete = (
    procedureTableData
  ) => {
    const { shareTaskData, supportingTableData, revisionInfoState } =
      this.state;

    const { savedInRadioValue } = this.state.savedInSectionState;
    const { serverPathValue } = this.state.serverPathSectionState;

    return (
      <SubmitUpdateProcedureComplete
        serverPathValue={serverPathValue}
        shareTaskData={shareTaskData}
        savedInRadioValue={savedInRadioValue}
        fileTableData={procedureTableData}
        supportingTableData={supportingTableData}
        revisionInfoData={revisionInfoState}
        pageProps={this.handleUpdateProcedureProcessPageProps()}
        sectionProps={this.handleUpdateProcedureProcessSectionProps()}
        setShareTaskData={this.setShareTaskData}
        setProcedureInfoData={this.setProcedureInfoData}
        setSupportingFileData={this.setSupportingFileData}
        params={this.handleUpdateProcedureProcessParams()}
      />
    );
  };

  render() {
    const { loading, procedureStepStatus, rejectReason } = this.state.pageState;

    const procedureTableData = JSON.parse(
      JSON.stringify(this.state.fileTableData)
    );

    const isApprovePendingPage =
      this.isApprovePage && procedureStepStatus === "Pending";
    const isApproveCompletePage =
      this.isApprovePage && procedureStepStatus === "Complete";
    const isRevisePendingPage =
      this.isRevisePage && procedureStepStatus === "Pending";
    const isReviseCompletePage =
      this.isRevisePage &&
      procedureStepStatus === "Complete" &&
      this.triggerTimes > 1;
    const isSubmitCompletePage =
      this.isRevisePage &&
      procedureStepStatus === "Complete" &&
      this.triggerTimes === 1;
    const replaceRegex = /(\n\r|\r\n|\r|\n)/g;
    let newRejectReason = "";
    if (rejectReason !== undefined && rejectReason !== null) {
      newRejectReason = rejectReason.replace(replaceRegex, "<br/>");
    } else {
      newRejectReason = rejectReason;
    }

    return (
      <NormalSpin
        spinning={loading}
        size="large"
        indicator={
          this.state.fileLoading.allNum > 0 ? (
            <p className={styles.loadingText}>
              uploading {this.state.fileLoading.currentNum}/
              {this.state.fileLoading.allNum} file
            </p>
          ) : undefined
        }
        children={
          <>
            {isRevisePendingPage &&
              this.handleUpdateProcedureProcessReviseUpdateProcedurePending(
                procedureTableData
              )}
            {isReviseCompletePage &&
              this.handleUpdateProcedureProcessReviseUpdateProcedureComplete(
                procedureTableData
              )}
            {isApprovePendingPage &&
              this.handleUpdateProcedureProcessApproveUpdateProcedurePending(
                procedureTableData,
                newRejectReason
              )}

            {isApproveCompletePage &&
              this.handleUpdateProcedureProcessApproveUpdateProcedureComplete(
                procedureTableData,
                newRejectReason
              )}

            {isSubmitCompletePage &&
              this.handleUpdateProcedureProcessSubmitUpdateProcedureComplete(
                procedureTableData
              )}
          </>
        }
      />
    );
  }
}
