import React from "react";
import BaseComponent from "../../../../base/common/BaseComponent";
import ReviseSupportingFileManagement from "../fields/supportingFileManagement/reviseSupportingFileManagement";
import styles from "../index.module.scss";

export default class CreateSupportingFileReviseSection extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  _hideLoading = () => this.setState({ loading: false });

  _showLoading = () => this.setState({ loading: true });

  render() {
    const { currentRole, isDisableAll } = this.props.sectionProps;
    const {
      reasonRef,
      noteRef,
      supportingFileRef,
      supportingReplaceFileRef,
      supportingRef,
      supportingFileTableRef,
    } = this.props.refs;
    const {
      setIsTableChange,
      cancelIsTableChange,
      initSupportingTableData,
      isTableBorderError,
    } = this.props;

    return (
      <div className={styles.fileSection}>
        <div className={styles.fileSectionTitle}>Supporting Files</div>

        <div
          className={`${styles.fileSectionTable} ${
            isTableBorderError ? styles.border_error : ""
          }`}
        >
          <ReviseSupportingFileManagement
            data={this.props.data}
            ref={supportingRef}
            procedureRef={supportingRef}
            fileTableRef={supportingFileTableRef}
            className="reviseSupportingTable"
            reasonRef={reasonRef}
            noteRef={noteRef}
            fileInputRef={supportingFileRef}
            tableReplaceFileInputRef={supportingReplaceFileRef}
            currentRole={currentRole}
            downloadFile={this.props.downloadFile}
            isDisableAll={isDisableAll}
            initProcedureTableData={[...initSupportingTableData]}
            setIsTableChange={setIsTableChange}
            cancelIsTableChange={cancelIsTableChange}
            isTableBorderError={isTableBorderError}
            _showLoading={this.props._showLoading}
            _hideLoading={this.props._hideLoading}
            updateUploadProcedureFileNum={
              this.props.updateUploadProcedureFileNum
            }
            updateProcedureFileNum={this.props.updateSupportingFileNum}
            isCopyPage={this.props.isCopyPage}
          />
        </div>
      </div>
    );
  }
}
