import RoutingComponent from "../../../base/common/RoutingComponent";
import styles from "./index.module.scss";
import reportCenterIcon from "../../../assets/image/reportCenterIcon.png";
import arrowIcon from "../../../assets/image/arrow.png";
import ReportIFrame from "./reportIFrame";

export default class ReportCenter extends RoutingComponent {
  reportItemClick = (parameters) => {
    window.open(`/ReportCenter/ReportDetail/${parameters}`, "_blank");
  };

  render() {
    const reportItemComponent = (title, description, link) => {
      return (
        <div
          className={styles.reportItem}
          onClick={() => this.reportItemClick(link)}
        >
          <img className={styles.icon} src={reportCenterIcon} alt="" />
          <div className={styles.reportItemContent}>
            <div className={styles.itemTitle}>{title}</div>
            <div className={styles.itemDescription}>{description}</div>
          </div>
          <img className={styles.arrowIcon} src={arrowIcon} alt="" />
        </div>
      );
    };

    return (
      <div className={styles.reportCenter}>
        <div className={styles.pageHead}>
          <div className={styles.title}>REPORT CENTER</div>
          <div className={styles.description}>
            Access to the listed reports depends on user's PMP account
            permissions. Please refer to the system guideline for the
            information on PMP account permissions.
          </div>
        </div>
        <div className={styles.pageContent}>
          {reportItemComponent(
            "Procedure Master List",
            `This report displays procedure information on specific tasks, providing key details such as task info., procedure status, procedure storage, procedure owner, shared tasks, latest version, etc.`,
            "ProcedureMasterList"
          )}
          {reportItemComponent(
            "Procedure Update Tracking Log",
            `This report displays all requests for procedure changes, providing key details such as the status of procedure updates and audit, and client's sign-off info., etc.`,
            "ProcedureUpdateTrackingLog"
          )}
          {/* {reportItemComponent(
            "Team Leader Procedure Review Tracking Log",
            `This report displays details of procedure reviews by team leaders, providing key details such as reviewer, review date, and review results, etc.`,
            "parameters"
          )}
          {reportItemComponent(
            "Sharing Relationship Tracking Log",
            `This report displays all changes in sharing relationship, providing key details such as change date, change specifics, and the tasks within the sharing relationship following the change, etc.`,
            "parameters"
          )} */}
        </div>
      </div>
    );
  }
}
