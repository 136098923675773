import { Tooltip, Popover } from "antd";
import BaseReviseShare from "../baseReviseShare";
import ShareTaskTable from "../shareTaskTable/index";
import AddNewShareTask from "../addNewShareTask";
import InputLabel from "../../../../base/basicComponents/inputLabel";
import { FileStatusDiv } from "../../../../base/basicComponents/status";
import reviseShareService from "../../../../service/shareTask/reviseShareService";
import redTipIcon from "../../../../assets/image/redTipIcon.png";
import styles from "../index.module.scss";
import approveStyles from "../approveShareTask/approveShareTask.module.scss";
import reviseStyles from "./reviseShareTask.module.scss";
import "../../../commonComponents/readOnlyTable/antReadOnlyTableCustom.scss";

export default class ReviseShareTask extends BaseReviseShare {
  constructor(props) {
    super(props);
    this.reasonRequired = this.initReasonRequired();
    this.state = this.initState(true, this.reasonRequired);
    this.deleteData = [];
  }

  initReasonRequired = () => {
    const shareTasks = this.props.shareTaskData?.shareTasks ?? [];

    if (shareTasks.length === 0) {
      return false;
    }

    return shareTasks.some((task) => task.isDel === true);
  };

  ModalTableOnRevoke = (record) => {
    let data = JSON.parse(JSON.stringify(this.state.reviseData));
    const index = data.findIndex((x) => x.taskId === record.taskId);
    data[index] = { ...data[index], isDeleted: false, isCurrentChange: true };
    this.setState({
      reviseData: data,
    });
    this.CheckDataChange(data);
  };

  dataHasChange = () => {
    let tableData = JSON.parse(JSON.stringify(this.state.reviseData));
    let initData = JSON.parse(JSON.stringify(this.state.initReviseData));
    if (tableData.length !== initData.length) {
      return true;
    }

    tableData.sort((a, b) => a.taskId - b.taskId);
    initData.sort((a, b) => a.taskId - b.taskId);

    for (let i = 0; i < tableData.length; i++) {
      const item1 = tableData[i];
      const item2 = initData[i];

      if (
        item1.isMainTask !== item2.isMainTask ||
        item1.isNew !== item2.isNew ||
        item1.isDeleted !== item2.isDeleted
      ) {
        return true;
      }
    }

    return false;
  };

  CheckDataChange = (dataSource) => {
    const deleteIndex = dataSource.findIndex(
      (x) => x.isDeleted !== undefined && x.isDeleted === true
    );

    if (deleteIndex > -1) {
      this.setState({
        resonRequired: true,
      });
    } else {
      this.setState({
        resonRequired: false,
        reasonInputStatus: "",
      });
    }
  };

  ModalTableOnDelete = (record) => {
    let data = JSON.parse(JSON.stringify(this.state.reviseData));
    const index = data.findIndex((x) => x.taskId === record.taskId);
    if (record.isNew) {
      if (record.isCurrentLoad) {
        this.deleteData.push(data[index]);
      }
      data.splice(index, 1);
    } else {
      data[index] = { ...data[index], isDeleted: true, isCurrentChange: true };
    }
    this.setState({
      reviseData: data,
    });
    this.CheckDataChange(data);
  };

  ShowAddNewShareTask = () => {
    this.setState({
      isAddNewShareTaskShow: true,
      taskTreePathValue: null,
    });
  };

  AddTaskOnClickOnSuccess = (result) => {
    const newLine = { ...result, isNew: true, isCurrentChange: true };
    const currentModalTableData = JSON.parse(
      JSON.stringify(this.state.reviseData)
    );
    const duplicateLineIndex = currentModalTableData.findIndex(
      (x) => x.taskId === newLine.taskId
    );
    if (duplicateLineIndex > -1) {
      this._alertError("This Task already exists.");
      this.setState({
        taskTreePathValue: null,
      });
      return;
    }
    currentModalTableData.push(newLine);
    this.setState({
      reviseData: currentModalTableData,
      taskTreePathValue: null,
    });
    this.CheckDataChange(currentModalTableData);
  };

  AddTaskOnClickOnError = (result) => {};

  AddTaskOnClick = () => {
    const para = this.state.taskTreePathValue;
    reviseShareService.getRowData(
      para,
      this.AddTaskOnClickOnSuccess,
      this.AddTaskOnClickOnError
    );
  };

  GetAccountDDl = () => {
    const accountPara = {
      procedureTaskId: this.props.procedureTaskId,
      procedureStepId: this.props.procedureStepId,
    };
    reviseShareService.getAccountDDL(
      accountPara,
      this.GetAccountDDLOnSuccess,
      this.GetAccountDDLOnError
    );
  };

  updateReasonInputErrorStatus = () => {
    this.setState({
      reasonInputStatus: "error",
    });
  };

  resetReviseData = () => {
    this.deleteData = [];
    this.setState({
      reviseData: this.state.initReviseData,
      isAddNewShareTaskShow: false,
      accountSelectValue: null,
      taskTreePathValue: null,
      reasonValue: "",
      resonRequired: false,
      dataHasChange: false,
      reasonInputStatus: "",
    });
  };

  componentDidMount() {
    this.GetAccountDDl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.shareTaskData !== prevProps.shareTaskData) {
      this.setState({
        isShowReturnReasonLogIcon:
          this.props.shareTaskData.returnReasonLogs?.length > 0,
        returnReasonLog: this.transformReturnReasonLogData(
          this.props.shareTaskData.returnReasonLogs
        ),
        requestReasonLog: this.transformRequestReasonLogData(
          this.props.shareTaskData.submitReasonLogs
        ),
        initReviseData: this.transformPendingData(
          this.props.shareTaskData.shareTasks
        ),
        reviseData: this.transformPendingData(
          this.props.shareTaskData.shareTasks
        ),
        reason: this.props.shareTaskData.reason,
      });
    }
  }

  render() {
    const {
      isShowReturnReasonLogIcon,
      isReasonLogModalOpen,
      returnReasonLog,
      isRequestReasonLogModalOpen,
      requestReasonLog,
      reviseData,
      isAddNewShareTaskShow,
      accountSelectOption,
      accountSelectValue,
      taskTreePathOption,
      taskTreePathValue,
      resonRequired,
      reasonValue,
      reasonInputStatus,
      reason,
    } = this.state;

    const reviseDataColumn = [
      {
        title: "",
        dataIndex: "isMainTask",
        width: "8%",
        render: (text, record) => (
          <div>
            <Popover
              content={
                <span className={`${styles.errorPopContent} fs-14`}>
                  {record.errorMessage}
                </span>
              }
              title={null}
              trigger="click"
            >
              <img
                src={redTipIcon}
                alt="error"
                className={
                  record.hasError
                    ? styles.redTipIconShow
                    : styles.redTipIconHide
                }
              />
            </Popover>
            <span
              className={`${
                record.isMainTask ? styles.mainTaskText : styles.tableText
              } fs-14`}
            >
              {record.isMainTask ? "Main Task" : "Shared Task"}
            </span>
          </div>
        ),
      },
      {
        title: "Account",
        dataIndex: "account",
        ellipsis: {
          showTitle: false,
        },
        width: "8%",
        render: (account, record) => (
          <Tooltip placement="topLeft" title={account}>
            <span className={`${styles.tableText} fs-14`}>{account}</span>
          </Tooltip>
        ),
      },
      {
        title: "Task Tree Path",
        dataIndex: "taskTreePath",
        ellipsis: {
          showTitle: false,
        },
        render: (taskTreePath) => (
          <Tooltip placement="topLeft" title={taskTreePath}>
            <span className={`${styles.tableText} fs-14`}>{taskTreePath}</span>
          </Tooltip>
        ),
      },
      {
        title: "Task Label Name",
        dataIndex: "taskLabelName",
        ellipsis: {
          showTitle: false,
        },
        render: (taskLabelName, record) => (
          <Tooltip placement="topLeft" title={taskLabelName}>
            <span className={`${styles.tableText} fs-14`}>{taskLabelName}</span>
          </Tooltip>
        ),
      },
      {
        title: "Task ID",
        dataIndex: "taskId",
        ellipsis: {
          showTitle: false,
        },
        width: "6.5%",
        render: (taskId) => (
          <Tooltip placement="topLeft" title={taskId}>
            <span className={`${styles.tableText} fs-14`}>{taskId}</span>
          </Tooltip>
        ),
      },
      {
        title: "Primary Procedure Owner",
        dataIndex: "ppo",
        ellipsis: {
          showTitle: false,
        },
        width: "13%",
        render: (ppo) => (
          <Tooltip placement="bottomLeft" title={ppo}>
            <span className={`${styles.tableText} fs-14`}>{ppo}</span>
          </Tooltip>
        ),
      },
      {
        title: "Procedure Owner",
        dataIndex: "po",
        ellipsis: {
          showTitle: false,
        },
        width: "9.5%",
        render: (po) => (
          <Tooltip placement="bottomLeft" title={po}>
            <span className={`${styles.tableText} fs-14`}>{po}</span>
          </Tooltip>
        ),
      },
      {
        title: "Procedure Saved In",
        dataIndex: "procedureSavedIn",
        ellipsis: {
          showTitle: false,
        },
        width: "10.3%",
        render: (procedureSavedIn) => (
          <Tooltip placement="topLeft" title={procedureSavedIn}>
            <span className={`${styles.tableText} fs-14`}>
              {procedureSavedIn}
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Client Server Path",
        dataIndex: "clientServerPath",
        ellipsis: {
          showTitle: false,
        },
        width: "10%",
        render: (clientServerPath) => (
          <Tooltip placement="topLeft" title={clientServerPath}>
            <span className={`${styles.tableText} fs-14`}>
              {clientServerPath}
            </span>
          </Tooltip>
        ),
      },
      {
        title: "",
        dataIndex: "tag",
        width: "8%",
        render: (text, record) => (
          <div className={styles.fileStatus}>
            <FileStatusDiv status={this.HandleModalTableTag(record)} />
          </div>
        ),
      },
      {
        title: "Action",
        dataIndex: "action",
        align: "center",
        width: "8%",
        render: (text, record) => {
          if (
            record.isMainTask ||
            (!record.isShowAction && record.isCurrentLoad)
          ) {
            return;
          }

          if (record.isDeleted) {
            return (
              <div
                className={`${styles.modalTableActionIcon} ${styles.sectionRevokeIcon}`}
                onClick={() => this.ModalTableOnRevoke(record)}
              />
            );
          }

          return (
            <div
              className={`${styles.modalTableActionIcon} ${styles.sectionDeleteIcon}`}
              onClick={() => this.ModalTableOnDelete(record)}
            />
          );
        },
      },
    ];
    return (
      <div className={styles.shareInformation}>
        <div className={approveStyles.approveShareInfoTop}>
          <div className={approveStyles.approveShareTitleDiv}>
            <span className={`${styles.shareTitle} fs-18`}>Shared Tasks</span>
          </div>
        </div>
        <div className={reviseStyles.reviseShareInfoTop}>
          <div className={approveStyles.approveShareTitleDiv}>
            <span className={`${styles.shareTitle} fs-14`}>Return Reason</span>
          </div>
          {this.handleReasonLog(
            "View previous Return Reason",
            false,
            isShowReturnReasonLogIcon,
            false
          )}
        </div>
        {this.handleReadOnlyReasonInput(reason)}
        <div className={styles.sharedTaskArea}>
          <ShareTaskTable data={reviseData} columns={reviseDataColumn} />
          <AddNewShareTask
            isAddNewShareTaskShow={isAddNewShareTaskShow}
            showAddNewShareTask={this.ShowAddNewShareTask}
            accountSelectOption={accountSelectOption}
            accountSelectValue={accountSelectValue}
            accountSelectOnChange={this.AccountSelectOnChange}
            taskTreePathOption={taskTreePathOption}
            taskTreePathValue={taskTreePathValue}
            taskTreePathOnSearch={this.TaskTreePathOnSearch}
            taskTreePathOnChange={this.TaskTreePathOnChange}
            addTaskOnClick={this.AddTaskOnClick}
            clearAddTaskOnClick={this.ClearAddTaskOnClick}
          />
        </div>
        <div className={approveStyles.approveShareInfoTop}>
          <div className={approveStyles.approveShareTitleDiv}>
            <InputLabel
              text="Reason"
              required={resonRequired}
              className={styles.modalText}
            />
          </div>
          {this.handleReasonLog(
            "View previous Reason",
            true,
            isShowReturnReasonLogIcon,
            true
          )}
        </div>
        {this.handleReasonInput(reasonValue, reasonInputStatus)}
        {this.handleReasonLogModal(
          isReasonLogModalOpen,
          returnReasonLog,
          isRequestReasonLogModalOpen,
          requestReasonLog
        )}
      </div>
    );
  }
}
