import { React, createRef } from "react";
import Buttons from "../../../../base/basicComponents/button/index";
import ApplyJbSavedIn from "../fields/applyJbSavedIn";
import {
  dataStatus,
  procedureJbStatus,
  savedInRadioValues,
} from "../../../publicDictionaryValues";
import downloadIcon from "../../../../assets/image/downloadIcon.png";
import ApplyJbClientServerPath from "../fields/applyJbClientServerPath";
import styles from "../index.module.scss";
import "./approve.scss";
import NormalModal, {
  ModalWithComponent,
} from "../../../../base/basicComponents/modal";
import ApproveApplyJbTable from "../../procedureFileSections/fields/procedureFileTableFields/approveApplyJbFileTable";
import InputLabel from "../../../../base/basicComponents/inputLabel";
import AppSetting from "../../../../config/AppSetting";
import ProcedureDetailService from "../../../../service/procedureFile/ProcedureDetailService";
import BaseApplyForJbSection from "../baseApplyForJbSection";

const procedureServerPathRef = createRef();
const modalRef = createRef();
const procedureReplaceFileRef = createRef();
const procedureReturnAddFileRef = createRef();
class ApplyForJbApproveSection extends BaseApplyForJbSection {
  constructor(props) {
    super(props);

    this.state = {
      clientServerPathReturnReason: this.props.rejectReason,
      isServerPathReturnBtnDisabled: false,
      isServerPathApproveBtnDisabled: false,
      isServerPathReturned: false,
      serverPathReturnReasonInputValue: "",
      isModalOpen: false,
      downloadBtnDisabled: true,
      downloadIcon: downloadIcon,
      modalChildContent: "",
      handleModalConfirm: () => {
        //there is a empty function
      },
      modalState: {
        isModalWithComponentOpen: false,
        closable: false,
        okText: "",
        cancelText: "",
        modalTitle: "",
        handleOk: null,
        handleCancel: null,
        isOkBtnDisabled: false,
        isCancelBtnDisabled: false,
        isShowTextArea: false,
        textAreaPlacement: "",
        textAreaMaxLength: 1000,
        isShowSelect: false,
        isShowTextAreaAndUploadDragger: false,
        uploadDraggerFileType: "",
        rowIndex: 0,
        fileIds: [],
        fileNames: [],
        modalWidth: 900,
        isTableBorderError: this.props.isTableBorderError,
      },
      procedureFileTableState: {
        fileTableReasonStatus: "",
        isTableBorderError: false,
        fileTableReturnReasonValue: "",
        isChange: false,
      },
    };

    this.isShowDragger = false;
    this.fileTableRef = createRef();
    this.files = [];
  }

  getServerPathOffset = () => {
    return procedureServerPathRef.current.offsetTop;
  };

  setServerPathError = () => {
    procedureServerPathRef.current.setServerPathError();
  };

  getServerPathStatus = () => {
    if (
      procedureServerPathRef.current === null ||
      procedureServerPathRef.current === undefined
    ) {
      return "";
    }

    return procedureServerPathRef.current.state.serverPathSectionState
      .serverPathStatus;
  };

  getServerPathReturnReasonValue = () => {
    if (
      procedureServerPathRef.current === null ||
      procedureServerPathRef.current === undefined
    ) {
      return "";
    }

    return procedureServerPathRef.current.state.serverPathSectionState
      .serverPathReturnReasonValue;
  };

  updateDataList = (dataList) => {
    this.fileTableRef.current.updateDataList(dataList);
  };

  initDataList = () => {
    if (this.fileTableRef.current === null) {
      return;
    }
    const dataList = this.props.procedureFileData;
    this.fileTableRef.current.updateDataList(dataList);
  };

  getDataList = () => {
    if (this.fileTableRef.current === null) {
      return;
    }
    const dataList = JSON.parse(
      JSON.stringify(this.fileTableRef.current.state.data)
    );

    return dataList;
  };

  handleJbProcedureSavedValue = (procedureJbStatusId) => {
    if (procedureJbStatusId === null) {
      return null;
    }

    return procedureJbStatus[procedureJbStatusId];
  };

  handleProcedureFileTableApproveAfterReturnOkClick = () => {
    const index = this.procedureFileCurrentRowIndex;
    const procedureFileData = this.getDataList();
    this.handleApplyForJbApprovePendingProcedureFileNoReturnApprove(
      procedureFileData,
      index
    );
  };

  handleProcedureFileTableApproveCancelClick = () => {
    this.hideModal();
  };

  handleApprovedAfterReturned = (type) => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: true,
        modalLabel: (
          <span className={styles.modalText}>
            Are you sure to change the selection? Your Reject Reason and
            Attachment will be cleared.
          </span>
        ),
        okText: "Confirm",
        isShowTextArea: false,
        modalTitle: null,
        actionType: type,
        closable: false,
        modalWidth: 520,
        handleOk: this.handleProcedureFileTableApproveAfterReturnOkClick,
        handleCancel: this.handleProcedureFileTableApproveCancelClick,
      },
    });
  };

  handleApplyForJbApprovePendingProcedureFileNoReturnApprove = (
    procedureFileData,
    value
  ) => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        modalWidth: 520,
      },
    });
    procedureFileData[value].isRejected = false;
    procedureFileData[value].data.find((item) => item.name === "status").value =
      dataStatus.approval;
    procedureFileData[value].data.find(
      (item) => item.name === "attachment"
    ).fileId = null;
    procedureFileData[value].data.find(
      (item) => item.name === "attachmentFileID"
    ).value = null;
    procedureFileData[value].data.find(
      (item) => item.name === "rejectReason"
    ).value = null;
    procedureFileData[value].data.find(
      (item) => item.name === "rejectReason"
    ).isDisabled = true;
    procedureFileData[value].data.find(
      (item) => item.name === "attachment"
    ).value = null;
    procedureFileData[value].data.find(
      (item) => item.name === "attachment"
    ).isDisabled = true;

    procedureFileData[value].isApproveDisabled = true;
    procedureFileData[value].isReturnDisabled = false;
    procedureFileData[value].isError = false;
    this.fileTableRef.current.updateDataList(procedureFileData);
    this.hideModal();
  };

  hideModal = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: false,
        modalLabel: "",
        handleOk: null,
        handleCancel: null,
        isShowSelect: false,
        okText: "",
        cancelText: "",
        modalTitle: "",
        isOkBtnDisabled: false,
        isCancelBtnDisabled: false,
        isShowTextArea: false,
        textAreaPlacement: "",
        isShowTextAreaAndUploadDragger: false,
      },
    });
  };

  handleFileApprovedClick = (value) => {
    this.procedureFileCurrentRowIndex = value;
    const procedureFileData = this.getDataList();
    if (procedureFileData[value].isRejected) {
      this.handleApprovedAfterReturned();
    } else {
      this.handleApplyForJbApprovePendingProcedureFileNoReturnApprove(
        procedureFileData,
        value
      );
    }
  };

  setReturnReasonValue = (tableDataList, index, returnValue) => {
    tableDataList[index].data.find((item) => item.name === "status").value =
      dataStatus.reject;
    tableDataList[index].data.find(
      (item) => item.name === "rejectReason"
    ).value = returnValue;
    tableDataList[index].data.find(
      (item) => item.name === "rejectReason"
    ).isDisabled = false;
    tableDataList[index].data.find(
      (item) => item.name === "attachment"
    ).fileList = procedureReturnAddFileRef.current.state.fileList;
    this.setState({
      procedureFileTableState: {
        fileTableReturnReasonValue: null,
        fileTableReasonStatus: null,
      },
    });
    modalRef.current.setRichTextValue(null);
  };

  handleFileDelete = (tableDataList, index) => {
    tableDataList[index].data.find(
      (item) => item.name === "attachment"
    ).fileId = null;
    tableDataList[index].data.find((item) => item.name === "attachment").value =
      null;
    tableDataList[index].data.find(
      (item) => item.name === "attachmentFileID"
    ).value = null;
  };

  handleProcedureFileTableReturnOkClick = () => {
    const fileTableReturnValue =
      this.state.procedureFileTableState.fileTableReturnReasonValue;
    let returnValue =
      fileTableReturnValue !== null
        ? fileTableReturnValue.trim()
        : fileTableReturnValue;
    const tableDataList = this.getDataList();
    const index = this.procedureFileCurrentRowIndex;
    const files = procedureReturnAddFileRef.current.state.fileList;
    const fileIds = procedureReturnAddFileRef.current.state.fileIdList;
    if (
      returnValue !== null &&
      returnValue &&
      this.richTextEditorValueNotEmpty(returnValue)
    ) {
      this.hideModal();
      if (files.length === 0) {
        this.handleFileDelete(tableDataList, index);
      }
      tableDataList[index].isRejected = true;
      if (files.length > 0) {
        files.forEach((item, i) => {
          this.setReturnReasonValue(tableDataList, index, returnValue);
          tableDataList[index].data.find(
            (dataItem) => dataItem.name === "attachment"
          ).value = item.name;
          tableDataList[index].data.find(
            (dataItem) => dataItem.name === "attachmentFileID"
          ).value = fileIds[i];
          tableDataList[index].data.find(
            (dataItem) => dataItem.name === "attachment"
          ).fileId = fileIds[i];
          tableDataList[index].isApproveDisabled = false;
          tableDataList[index].isReturnDisabled = true;
          tableDataList[index].isReturned = true;
          tableDataList[index].isError = false;
          this.setState({
            procedureFileTableState: {
              fileTableReturnReasonValue: null,
              fileTableReasonStatus: null,
            },
          });
          modalRef.current.setRichTextValue(null);
          procedureReturnAddFileRef.current.clearFileAttach();
        });
      } else {
        this.setReturnReasonValue(tableDataList, index, returnValue);
        tableDataList[index].isApproveDisabled = false;
        tableDataList[index].isReturnDisabled = true;
        tableDataList[index].isRejected = true;
        tableDataList[index].isError = false;
      }
      this.fileTableRef.current.updateDataList(tableDataList);
    } else {
      this.setState({
        procedureFileTableState: {
          ...this.state.procedureFileTableState,
          fileTableReasonStatus: "error",
        },
      });

      const allToxTinymceElements = document.querySelectorAll(".tox-tinymce");

      allToxTinymceElements.forEach((element) => {
        element.style.border = "2px solid red";
      });
    }
  };

  handleProcedureFileTableReturnCancelComfirm = () => {
    procedureReturnAddFileRef.current.clearFileAttach();
    const tableDataList = this.getDataList();
    this.fileTableRef.current.updateDataList(tableDataList);
    this.hideModal();
    this.setState({
      procedureFileTableState: {
        ...this.state.procedureFileTableState,
        fileTableReturnReasonValue: null,
        fileTableReasonStatus: null,
      },
      isModalOpen: false,
      modalChildContent: "",
      handleModalConfirm: () => {
        // thre is a empty function
      },
    });

    const allToxTinymceElements = document.querySelectorAll(".tox-tinymce");

    allToxTinymceElements.forEach((element) => {
      element.style.border = "2px solid #eee";
    });

    modalRef.current.setRichTextValue(null);
  };

  handleProcedureFileTableReturnCancelClick = () => {
    this.setState({
      isModalOpen: true,
      modalChildContent: "Are you sure to quit without saving?",
      handleModalConfirm: this.handleProcedureFileTableReturnCancelComfirm,
    });
  };

  handleApplyForJbApprovePendingSetReturnModal = (value) => {
    const procedureFileData = this.getDataList();
    const fileName = procedureFileData[value].data.find(
      (item) => item.name === "attachment"
    ).value;
    const fileId = procedureFileData[value].data.find(
      (item) => item.name === "attachmentFileID"
    ).value;
    const fileNames = fileName == null ? [] : [fileName];
    const fileIds = fileId == null ? [] : [fileId];
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: true,
        isShowTextAreaAndUploadDragger: true,
        uploadDraggerFileType: "text",
        okText: "Confirm",
        closable: false,
        fileIds: fileIds,
        fileNames: fileNames,
        modalWidth: 900,
        modalTitle: <span className="fs-18">Reason for Rejection</span>,
        modalLabel: (
          <InputLabel
            text="Please fill in the reason for rejection"
            required={true}
            className={styles.modalText}
          />
        ),
        textAreaPlacement: this.returnModalInputPlacement,
        rowIndex: value,
        isShowTextArea: true,
        handleOk: this.handleProcedureFileTableReturnOkClick,
        handleCancel: this.handleProcedureFileTableReturnCancelClick,
      },
    });
  };

  handleFileRejectClick = (value) => {
    this.isShowDragger = !this.isShowDragger;
    const procedureFileData = this.getDataList();
    const returnReasonValue = procedureFileData[value].data.find(
      (item) => item.name === "rejectReason"
    ).value;
    this.setState({
      procedureFileTableState: {
        ...this.state.procedureFileTableState,
        fileTableReturnReasonValue: returnReasonValue,
      },
    });
    modalRef.current.setRichTextValue(returnReasonValue);
    this.procedureFileCurrentRowIndex = value;
    this.handleApplyForJbApprovePendingSetReturnModal(value);
  };

  handleSetServerPathReturnReasonValue = (content, editor) => {
    this.setState({
      procedureFileTableState: {
        ...this.state.procedureFileTableState,
        fileTableReturnReasonValue: content,
        fileTableReasonStatus: null,
      },
    });

    const allToxTinymceElements = document.querySelectorAll(".tox-tinymce");

    allToxTinymceElements.forEach((element) => {
      element.style.border = "2px solid #eee";
    });
  };

  getAddFileDto = (item) => {
    return {
      FileName: item.name,
      ContainerName: AppSetting.urlPrefix.addFile.ContainerName,
      AccountName: AppSetting.urlPrefix.addFile.AzureAccountName,
      ProductKey: AppSetting.urlPrefix.addFile.ProductKey,
      FolderName: AppSetting.urlPrefix.addFile.ProcedureFileFolder,
    };
  };

  uploadFileOnSuccess = (result, returnPara) => {
    returnPara.callback(returnPara.fileName, { text: returnPara.fileName });
    this.files.unshift({
      fileId: result.storageFileID,
      fileName: returnPara.fileName,
    });
  };

  uploadFileOnError = () => {
    this._alertError("Upload Fail");
  };

  uploadApplyForJbApprovePendingFile = (e, callback) => {
    let file = e.files[0];
    let fileForm = new FormData();
    let addFileDto = this.getAddFileDto(file);
    fileForm.append("formFile", file);
    fileForm.append("addFileDto", JSON.stringify(addFileDto));
    let returnPara = {
      fileName: file.name,
      callback: callback,
    };

    ProcedureDetailService.addFile(
      fileForm,
      this.uploadFileOnSuccess,
      this.uploadFileOnError,
      returnPara
    );
  };

  handleApplyForJbApprovePendingUploadFile = (callback, value, meta) => {
    let input = document.createElement("input");
    input.setAttribute("type", "file");
    input.click();
    input.onchange = (e) =>
      this.uploadApplyForJbApprovePendingFile(e.target, callback);
  };

  _hideModalBtnsDisabled = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isOkBtnDisabled: false,
        isCancelBtnDisabled: false,
      },
    });
  };

  _openModalBtnsDisabled = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isOkBtnDisabled: true,
        isCancelBtnDisabled: true,
      },
    });
  };

  hideActionButton = (record) => {
    const applyForValue = record.data.find(
      (item) => item.name === "applyfor"
    ).value;
    if (applyForValue === "JB") {
      return false;
    }
    return true;
  };

  hideNormalModal = () => {
    this.setState({
      isModalOpen: false,
      modalChildContent: "",
      handleModalConfirm: () => {
        // thre is a empty function
      },
    });
  };

  componentDidMount() {
    this.initDataList();
  }

  handleApplyForJbApprovePendingColumn = () => {
    return [
      {
        name: "ProcedureId",
        dataName: "procedureId",
        type: "text",
        isShow: false,
      },
      {
        name: "checkbox",
        dataName: "checkbox",
        type: "checkbox",
        isShow: true,
        check: this._check,
        onCheck: this._onCheck,
        headCheck: this._headCheck,
        headOnCheck: this._headOnCheck,
      },
      {
        name: "File Name",
        dataName: "fileName",
        type: "fileName",
        isShow: true,
        isDisabled: false,
      },
      {
        name: "Uploaded Date",
        dataName: "uploadedDate",
        type: "text",
        isShow: true,
      },
      {
        name: "Uploaded By",
        dataName: "uploadByName",
        type: "text",
        isShow: true,
      },
      {
        name: "Approval Date",
        dataName: "approveDate",
        type: "text",
        isShow: true,
      },
      {
        name: "Status",
        dataName: "status",
        type: "tag",
        isShow: true,
      },
      {
        name: "JB Procedure",
        dataName: "jbProcedure",
        type: "text",
        isShow: true,
      },
      {
        name: "Apply for?",
        dataName: "applyfor",
        type: "select",
        isShow: true,
        isDisabled: true,
        // onChange: this.applyForOnChange,
      },
      {
        name: "Reject Reason",
        dataName: "rejectReason",
        type: "modal",
        showText: "View Reject Reason",
        isShow: true,
      },
      {
        name: "Attachment",
        dataName: "attachment",
        type: "link",
        isShow: true,
        showEdit: true,
      },
      {
        name: "Action",
        dataName: "action",
        type: "button",
        isApproveDisabled: false,
        isReturnDisabled: false,
        approveText: "Approve",
        returnText: "Reject",
        approveColor: "blue",
        returnColor: "red",
        onApproveClick: this.handleFileApprovedClick,
        onReturnClick: this.handleFileRejectClick,
        hideActionButton: this.hideActionButton,
        isShow: true,
      },
    ];
  };

  handleApplyForJbApprovePendingApproveApplyJbTable = () => {
    const columns = this.handleApplyForJbApprovePendingColumn();

    return (
      <div className={`${styles.downloadButtonDiv} applyJbTable`}>
        <ApproveApplyJbTable
          ref={this.fileTableRef}
          columns={columns}
          procedureNoteMaxLength={1000}
          fileSectionDisabled={false}
          procedureNoteSize="small"
          inputOnChange={this.inputOnChange}
          hasSubmit={false}
          isScrollData={false}
          fileTableRef={this.fileTableRef}
          className="procedureFiles"
          isShowTotalFileNum={true}
          isSupportingFile={false}
          isShowExpendIcon={false}
          handleReturnClick={(e) =>
            this.handleFileRejectClick(e.target.attributes.rowindex.value)
          }
          downloadFile={this.downloadFile}
        />
      </div>
    );
  };

  handleApplyForJbApprovePendingApproveApplyJbTableButton = () => {
    const { downloadBtnDisabled } = this.state;

    return (
      <div className={styles.downloadButtonDiv}>
        <Buttons
          color="black"
          size="middle"
          onMouseEnter={this.handleDownloadBtnMouseEnter}
          onMouseLeave={this.handleDownloadBtnMouseLeave}
          onClick={this.downloadFile}
          disabled={downloadBtnDisabled}
        >
          <img
            src={downloadIcon}
            alt="Download"
            className={styles.downloadIcon}
          />
          Download
        </Buttons>
      </div>
    );
  };

  render() {
    const {
      savedInRadioValue,
      procedureJbStatusId,
      serverPathValue,
      applyfor,
    } = this.props;
    const {
      clientServerPathReturnReason,
      isServerPathReturnBtnDisabled,
      isServerPathApproveBtnDisabled,
      isServerPathReturned,
      serverPathReturnReasonInputValue,
      isModalOpen,
      modalChildContent,
      handleModalConfirm,
    } = this.state;

    const {
      okText,
      cancelText,
      modalTitle,
      closable,
      isOkBtnDisabled,
      isCancelBtnDisabled,
      isModalWithComponentOpen,
      modalLabel,
      textAreaPlacement,
      textAreaMaxLength,
      isShowTextArea,
      rowIndex,
      fileIds,
      fileNames,
      uploadDraggerFileType,
      modalWidth,
      handleOk,
      handleCancel,
    } = this.state.modalState;
    const { fileTableReasonStatus, fileTableReturnReasonValue } =
      this.state.procedureFileTableState;

    return (
      <div className={styles.applyJbSectionsDiv}>
        <div className={styles.applyJbSectionTop}>
          <span className={`${styles.applyJbSectionTitle} fs-18`}>
            Procedure Files
          </span>
        </div>
        <div className={styles.topDiv}>
          <div className={styles.savedInDiv}>
            <ApplyJbSavedIn savedInRadioValue={savedInRadioValue} />
          </div>
          {savedInRadioValue !== savedInRadioValues.systemOnly && (
            <div className={styles.clientPathDiv}>
              <ApplyJbClientServerPath
                clientServerPathReturnReason={clientServerPathReturnReason}
                isServerPathReturnBtnDisabled={isServerPathReturnBtnDisabled}
                isServerPathApproveBtnDisabled={isServerPathApproveBtnDisabled}
                serverPathReturnReasonInputValue={
                  serverPathReturnReasonInputValue
                }
                isServerPathReturned={isServerPathReturned}
                ref={procedureServerPathRef}
                serverPathValue={serverPathValue}
                procedureJbStatusId={procedureJbStatusId}
                applyForValue={applyfor}
              />
            </div>
          )}
        </div>

        {savedInRadioValue !== savedInRadioValues.serverOnly && (
          <>
            {this.handleApplyForJbApprovePendingApproveApplyJbTableButton()}
            {this.handleApplyForJbApprovePendingApproveApplyJbTable()}
          </>
        )}
        <div>
          <ModalWithComponent
            ref={modalRef}
            isModalOpen={isModalWithComponentOpen}
            closable={closable}
            okText={okText}
            cancelText={cancelText}
            title={modalTitle}
            className={styles.approvePageModal}
            handleOk={handleOk}
            handleCancel={handleCancel}
            isOkDisabled={isOkBtnDisabled}
            isCancelDisabled={isCancelBtnDisabled}
            isShowRichTextEditor={isShowTextArea}
            isShowRichTextEditorUploadDragger={isShowTextArea}
            richTextEditorUploadDraggerLabel={
              <InputLabel
                text="Attach Documents"
                className={styles.modalText}
              />
            }
            richTextValue={fileTableReturnReasonValue}
            handleEditorChange={this.handleSetServerPathReturnReasonValue}
            handleUploadFile={this.handleApplyForJbApprovePendingUploadFile}
            textAreaPlacement={textAreaPlacement}
            textAreaMaxLength={textAreaMaxLength}
            textAreaStatus={fileTableReasonStatus}
            label={modalLabel}
            modalWidth={modalWidth}
            fileUploadRef={procedureReplaceFileRef}
            fileUploadInputRef={procedureReturnAddFileRef}
            rowindex={rowIndex}
            fileIds={fileIds}
            fileNames={fileNames}
            isShowInnerDragger={this.isShowDragger}
            isMultiple={false}
            isShowDeleteBtn={true}
            hideLoading={this._hideModalBtnsDisabled}
            openLoading={this._openModalBtnsDisabled}
            isLoading={isOkBtnDisabled}
            uploadDraggerFileType={uploadDraggerFileType}
            centered={true}
          />
        </div>
        <NormalModal
          okText="Confirm"
          cancelText="Cancel"
          childContent={<p className="fs-16">{modalChildContent}</p>}
          isModalOpen={isModalOpen}
          handleOk={handleModalConfirm}
          handleCancel={this.hideNormalModal}
        />
      </div>
    );
  }
}

export default ApplyForJbApproveSection;
