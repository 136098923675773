import React from "react";
import { Modal } from "antd";
import InputLabel from "../../../../../../base/basicComponents/inputLabel";
import NormalSelect from "../../../../../../base/basicComponents/select";
import { NormalTextArea } from "../../../../../../base/basicComponents/input";
import BaseComponent from "../../../../../../base/common/BaseComponent";
import TopFilterBase from "../../../../../commonComponents/listBase/TopFilterBase";
import toDoListService from "../../../../../../service/toDoListService";

const { sortList, handleFilterOption } = TopFilterBase();
export default class DelegationModal extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      delegateOptionList: [],
      initDelegateSelectValue: this.props.delegatedTo,
      delegateSelectValue: this.props.delegatedTo,
      loading: false,
      open: false,
      reasonText: null,
      isShowEditModal: true,
      isShowMsgContent: false,
      msgContent: "",
      okText: "Confirm",
      cancelText: "Cancel",
      actionType: "edit",
      reasonTextStatus: "",
    };
    this.user = this.appSetting.localStorageValue.user.get();
    this.delegatedReason = null;
    this.delegateToUserId = null;
  }

  /**
   * reset state
   */
  handleSetInitState = () => {
    this.setState({
      initDelegateSelectValue: this.props.delegatedTo,
      delegateSelectValue: this.props.delegatedTo,
      reasonText: null,
      isShowEditModal: true,
      isShowMsgContent: false,
      msgContent: "",
      okText: "Confirm",
      cancelText: "Cancel",
      actionType: "edit",
      reasonTextStatus: "",
    });
    this.prevState = {};
  };

  /**
   * when close modal reset state
   * @param {object} prevProps
   * @param {object} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (
      (prevProps.isModalOpen !== this.props.isModalOpen &&
        this.props.isModalOpen === false) ||
      prevProps.delegatedTo !== this.props.delegatedTo
    ) {
      this.handleSetInitState();
    }
    if (prevProps.pmpExcluded !== this.props.pmpExcluded) {
      this.setState({
        initExcludedSelectValue: this.props.pmpExcluded,
        excludedSelectValue: this.props.pmpExcluded,
      });
    }
  }

  /**
   * function about change delegated to select
   * @param {string} value select value
   */
  handleDelegatedChange = (value) => {
    this.setState({ delegateSelectValue: value, open: false });
    this.delegateToUserId = value;
  };

  /**
   * function about change reason textarea value
   * @param {string} value - write value
   */
  handleReasonTextChange = (value) => {
    this.setState({ reasonText: value, reasonTextStatus: "" });
    this.delegatedReason = value;
  };

  /**
   * success callback when submit delegate change
   */
  handleSubmitDelegatedSuccuess = (response) => {
    this._alertSuccess("Submit Successful");
    const list = [...this.props.dataList];
    list.find(
      (item) => item.transactionId === this.props.transactionId
    ).delegatedBy = this.user.identityUserInfo.englishName;
    list.find(
      (item) => item.transactionId === this.props.transactionId
    ).delegateTo = response.delegateTo;
    list.find(
      (item) => item.transactionId === this.props.transactionId
    ).delegateToUserId = this.delegateToUserId;
    list.find(
      (item) => item.transactionId === this.props.transactionId
    ).delegateReason = this.delegatedReason;
    this.delegatedReason = null;
    this.props.setDelegatedValueToListData(list);
    this.setState({
      delegateSelectValue: response.delegateTo,
      initDelegateSelectValue: response.delegateTo,
    });
  };

  /**
   * error callback when submit delegate change
   */
  handleSubmitDelegatedError = (error) => {
    this.delegatedReason = null;
    console.log(error);
    this._alertError("Submit Failed");
  };

  /**
   * success callback about judge the employees are unable to be delegated
   * @param {*} response
   */
  handleDelegateCheckSuccess = (response) => {
    if (response.result) {
      this.handleSetSubmitModal();
    } else {
      this._alertError(
        "You can not delegate this to-do to process initiator, the upstream approvers and someone that already have this to-do."
      );
    }
  };

  /**
   * error callback about judge the employees are unable to be delegated
   * @param {*} response
   */
  handleDelegateCheckError = (error) => {
    console.log(error);
    this._alertError("delegate check Failed!");
  };

  /**
   * function about click confirm button in submit confirm modal
   */
  handleSubmitModalConfirm = () => {
    const { delegateSelectValue, reasonText } = this.state;
    const param = {
      procedureStepID: this.props.procedureStepId,
      delegateTo: delegateSelectValue,
      reason: reasonText,
    };
    toDoListService.submitDelegate(
      param,
      this.handleSubmitDelegatedSuccuess,
      this.handleSubmitDelegatedError
    );
    this.props.handleCloseModal();
  };

  /**
   *  function about judge the employees are unable to be delegated
   * @param {*} response
   */
  handleSubmitModalConfirmCheck = () => {
    const { delegateSelectValue } = this.state;
    const param = {
      procedureStepID: this.props.procedureStepId,
      delegateTo: delegateSelectValue,
    };
    toDoListService.delegateCheck(
      param,
      this.handleDelegateCheckSuccess,
      this.handleDelegateCheckError
    );
  };

  /**
   * function about click cancel button in submit confirm modal
   */
  handleSubmitModalCancel = () => {
    this.setState({ ...this.prevState });
  };

  /**
   * function about create submit modal
   */
  handleSetSubmitModal = () => {
    this.prevState = { ...this.state };
    this.setState({
      msgContent: <span className="fs-16">Are you sure to submit?</span>,
      okText: "Confirm",
      actionType: "submit",
      isShowEditModal: false,
      isShowMsgContent: true,
    });
  };

  /**
   * function about click confirm button in cancel modal
   */
  handleCancelModalConfirm = () => {
    this.props.handleCloseModal();
    this.setState({
      msgContent: "",
      okText: "Confirm",
      actionType: "edit",
      isShowEditModal: true,
      isShowMsgContent: false,
    });
  };

  /**
   * function about click cancel button in cancel modal
   */
  handleCancelModalCancel = () => {
    this.setState({
      ...this.prevState,
    });
  };

  /**
   * function about create cancel modal
   */
  handleSetCancelModal = () => {
    this.setState({
      msgContent: (
        <span className="fs-16">Are you sure to quit without saving?</span>
      ),
      okText: "Confirm",
      actionType: "cancel",
      isShowEditModal: false,
      isShowMsgContent: true,
    });
  };

  /**
   * function about click confirm button in delegate modal
   */
  handleEditSubmitConfirm = () => {
    if (
      this.props.processCategory === "Update Procedure" &&
      this.props.highlyRegulated === "Yes"
    ) {
      if (this.state.reasonText === null || this.state.reasonText === "") {
        this.setState({ reasonTextStatus: "error" });
      } else {
        this.handleSubmitModalConfirmCheck();
      }
    } else {
      this.handleSubmitModalConfirmCheck();
    }
  };

  /**
   * function about click cancel button in edit modal
   */
  handleEditModalCancel = () => {
    this.prevState = { ...this.state };
    this.setState({
      msgContent: (
        <span className="fs-16">Are you sure to quit without saving?</span>
      ),
      okText: "Confirm",
      actionType: "cancel",
      isShowEditModal: false,
      isShowMsgContent: true,
    });
  };

  /**
   * function about return submit method for modal component
   * @returns
   */
  handleSubmit = () => {
    switch (this.state.actionType) {
      case "edit":
        return this.handleEditSubmitConfirm();
      case "submit":
        return this.handleSubmitModalConfirm();
      case "cancel":
        return this.handleCancelModalConfirm();
      default:
        return;
    }
  };

  /**
   * function about return cancel method for modal component
   * @returns
   */
  handleCancel = () => {
    switch (this.state.actionType) {
      case "edit":
        return this.handleEditModalCancel();
      case "submit":
        return this.handleSubmitModalCancel();
      case "cancel":
        return this.handleCancelModalCancel();
      default:
        return this.handleCancelClick();
    }
  };

  /**
   * success callback for get ppo list
   */
  _getOptionListSuccess = (result) => {
    const list = result;
    const newList = list.map((item) => {
      return {
        name: item.name,
        value: item.id,
      };
    });

    const isDelegatedToExist = newList.find(
      (item) => item.value === this.props.delegatedToId
    );
    const oldDelegatedTo = isDelegatedToExist
      ? this.props.delegatedToId
      : this.props.delegatedTo;

    const isPropsDelegatedToNoValue =
      this.props.delegatedToId === null || this.props.delegatedToId.trim === "";
    const currentDelegatedTo =
      isPropsDelegatedToNoValue ||
      this.state.delegateSelectValue !== this.props.delegatedTo
        ? this.state.delegateSelectValue
        : oldDelegatedTo;
    const currentInitDelegatedTo = isPropsDelegatedToNoValue
      ? this.props.delegatedTo
      : this.props.delegatedToId;
    this.setState({
      delegateOptionList: newList.sort((s, t) => sortList(s, t)),
      delegateSelectValue: currentDelegatedTo,
      initDelegateSelectValue: currentInitDelegatedTo,
      loading: false,
      open: true,
    });
  };

  /**
   * error callback for get ppo list
   */
  _getOptionListFailed = (msg) => {
    console.log(msg);
  };

  /**
   * function about get ppo option list for delegated to
   */
  getPpoOptionsList = () => {
    toDoListService.getDelegateToList(
      this.props.procedureStepId,
      this._getOptionListSuccess,
      this._getOptionListFailed
    );
  };

  render() {
    const { isModalOpen } = this.props;
    const {
      initDelegateSelectValue,
      delegateSelectValue,
      reasonText,
      reasonTextStatus,
      isShowEditModal,
      okText,
      cancelText,
      actionType,
      loading,
      delegateOptionList,
      isShowMsgContent,
      msgContent,
      open,
    } = this.state;

    const isReasonRequired =
      this.props.processCategory === "Update Procedure" &&
      this.props.highlyRegulated === "Yes";
    return (
      <Modal
        open={isModalOpen}
        okText={okText}
        cancelText={cancelText}
        onOk={this.handleSubmit}
        onCancel={this.handleCancel}
        closable={false}
        maskClosable={false}
        okButtonProps={{
          disabled:
            actionType === "edit" &&
            delegateSelectValue === initDelegateSelectValue,
        }}
        wrapClassName="pmpExcludedModal"
        zIndex={1201}
      >
        {isShowEditModal && (
          <div className="delegateContainer">
            <div className="delegatePart">
              <InputLabel
                text="Delegate To"
                className="delegateLabel"
                required={true}
              />
              <NormalSelect
                showSearch={true}
                optionList={delegateOptionList}
                handleFilterOption={(input, option) =>
                  handleFilterOption(input, option)
                }
                handleChange={this.handleDelegatedChange}
                value={delegateSelectValue}
                loading={loading}
                onDropdownVisibleChange={(open) => {
                  if (open) {
                    this.setState({ loading: true });
                    this.getPpoOptionsList();
                  } else {
                    this.setState({
                      open: false,
                    });
                  }
                }}
                open={open}
              />
            </div>
            <div className="delegateReasonPart">
              <div className="delegateReasonInput">
                <InputLabel
                  text="Reason"
                  className="delegateLabel"
                  required={isReasonRequired}
                />
                <NormalTextArea
                  width="22.2"
                  value={reasonText}
                  onChange={(e) => this.handleReasonTextChange(e.target.value)}
                  status={reasonTextStatus}
                  className="delegateReasonText"
                />
              </div>
            </div>
          </div>
        )}
        {isShowMsgContent && msgContent}
      </Modal>
    );
  }
}
