import React, { createRef } from "react";
import { flowCategoryId } from "../../../../publicDictionaryValues";
import UpdateSupportingFileReviseSection from "../../../../sections/supportingFileSections/updateSupportingFileReviseSection";
import ReviseNewSupportingFileComplete from "../../createSupportingFileProcess/reviseNewSupportingFileComplete";

const supportingFileTableRef = createRef();
const supportingFileRef = createRef();
const reasonRef = createRef();
const noteRef = createRef();

export default class ReviseUpdateSupportingFileComplete extends ReviseNewSupportingFileComplete {
  handleReturnPageName = () => {
    if (
      this.props.pageProps.flowCategoryID === flowCategoryId.copySupportingFile
    ) {
      return "Copy Supporting File";
    }

    return "Revise Update Supporting Files";
  };

  handleReturnSupportingFileSection = () => {
    const { currentRole, supportingReplaceFileRef } = this.props.pageProps;
    return (
      <UpdateSupportingFileReviseSection
        sectionProps={{ currentRole, isDisableAll: true }}
        initSupportingTableData={this.props.initSupportingTableData}
        initProcedureFileTableState={this.props.initProcedureFileTableState}
        refs={{
          supportingRef: this.props.supportingRef,
          supportingFileTableRef,
          supportingFileRef,
          reasonRef,
          noteRef,
          supportingReplaceFileRef,
        }}
        data={this.props.data}
        setIsTableChange={this.setIsTableChange}
        cancelIsTableChange={this.cancelIsTableChange}
        _showLoading={this.props._showLoading}
        _hideLoading={this.props._hideLoading}
        updateUploadProcedureFileNum={this.props.updateUploadProcedureFileNum}
        updateSupportingFileNum={this.props.updateSupportingFileNum}
        downloadFile={this.props.downloadFile}
        isMount={this.props.isMount}
        isCopyPage={this.props.isCopyPage}
      />
    );
  };
}
