import React, { createRef } from "react";
import PageHeader from "../../../../commonComponents/pageHeaders/PageHeader";
import ProcedureBlock from "../../../../../base/basicComponents/procedureBlock";
import Buttons from "../../../../../base/basicComponents/button";
import NormalToolTip from "../../../../../base/basicComponents/toolTip";
import { ModalWithComponent } from "../../../../../base/basicComponents/modal";
import InputLabel from "../../../../../base/basicComponents/inputLabel";
import RoutingComponent from "../../../../../base/common/RoutingComponent";
import TaskInformation from "../../../../sections/taskInfoSections";
import PMPExcludedProcedureInformation from "../../../../sections/procedureInfoSections/pmpExcludedProcedureInfoSection";
import ReadOnlyProcedureFileSection from "../../../../sections/procedureFileSections/readOnlyProcedureFileSection";
import ReadOnlySupportingFileSection from "../../../../sections/supportingFileSections/readOnlySupportingFileSection";
import ActiveShareTaskReadOnly from "../../../../sections/sharedTaskSections/activeShareTaskReadOnly";
import {
  actions,
  pmpExcludedReasonSelects,
} from "../../../../publicDictionaryValues";
import applyPMPExcludedService from "../../../../../service/applyPmpExcluded";
import redTipIcon from "../../../../../assets/image/redTipIcon.png";
import styles from "../../style/index.module.scss";
import LostPermissionModal from "../../../../sections/lostPermissionModal";
import appService from "../../../../../service/app";
import StepService from "../../../../../service/stepBase/StepService";
import StepBusinessType from "../../../../../service/stepBase/stepBusinessType";

const procedureFileRef = createRef();
const supportingFileRef = createRef();
const supportingFileTableRef = createRef();
const procedureInfoRef = createRef();

export default class BasePMPExcludedPending extends RoutingComponent {
  constructor(props) {
    super(props);
    const { newRejectReason, rejectStatus } = this.props.pageProps;
    this.state = {
      pageState: {
        rejectReason: newRejectReason,
        rejectStatus,
        isCancelDisabled: false,
        isSubmitDisabled: false,
        isRejectDisabled: false,
        isTableBorderError: false,
      },
      modalState: {
        modalTitle: "",
        modalLabel: "",
        okText: "",
        cancelText: "",
        actionType: "",
        closable: false,
        isOkBtnDisabled: false,
        isCancelBtnDisabled: false,
        maskClosable: false,
        isModalWithComponentOpen: false,
        isShowTextArea: false,
        textAreaPlacement: "",
        textAreaMaxLength: 0,
        textAreaClassName: "",
        isShowSelect: false,
        isShowTextAreaAndUploadDragger: false,
        uploadDraggerFileType: "",
        rowIndex: 0,
        fileIds: [],
        fileNames: [],
        modalWidth: 520,
      },
      noPermission: false,
    };
    this.para = null;
    this.modalContentLabels = {
      approveConfirmModalLabel: (
        <span className={styles.modalText}>
          Are you sure to approve this request?
        </span>
      ),
      cancelConfimModalLabel: (
        <span className={styles.modalText}>
          Are you sure to quit without saving?
        </span>
      ),
      rejectModalWarningLabel: (
        <>
          <div className={styles.rejectModalWarning}>
            Are you sure to reject this request? Reject means that the process
            will be terminated. The status will be changed back to the original
            status before this initiation.
          </div>
          <InputLabel text="Reject Reason" required={true} />
        </>
      ),
    };
  }

  _applyPMPExcludedtriggerSuccess = (response) => {
    if (response.succeeded === false) {
      this._alertError(response.message);
    } else {
      this.props.closePage();
    }
    this.props._hideLoading();
  };

  _applyPMPExcludedtriggerError = (response) => {
    this._alertError(response);
  };

  createApplyPMPExcludedTriggerAction = () => {
    applyPMPExcludedService.applyPMPExcludedTriggerStepAction(
      this.para,
      this._applyPMPExcludedtriggerSuccess,
      this._applyPMPExcludedtriggerError
    );
  };

  handleApproveOkClick = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        isSubmitDisabled: true,
      },
    });
    this.props._showLoading();
    this.hideModal();

    this.para = this.props.handleReturnSubmitData(
      actions.approve,
      "Approve",
      procedureInfoRef.current,
      this.props.applyPMPExcludedRef.current
    );
    this.createApplyPMPExcludedTriggerAction();
  };

  /* function about click submit button in reject modal */
  handlePMPExcludedRejectOkClick = () => {
    const rejectReasonValue = this.state.pageState.rejectReason;
    const reason =
      rejectReasonValue !== null ? rejectReasonValue.trim() : rejectReasonValue;
    if (reason === null || reason === "") {
      this.setState({
        pageState: { ...this.state.pageState, rejectStatus: "error" },
      });
    } else {
      this.setState({
        pageState: {
          ...this.state.pageState,
          rejectStatus: null,
          isRejectDisabled: true,
        },
      });
      this.props._showLoading();
      this.hideModal();
      this.para = this.props.handleReturnSubmitData(
        actions.reject,
        "Reject",
        procedureInfoRef.current,
        this.props.applyPMPExcludedRef.current
      );
      this.createApplyPMPExcludedTriggerAction();
    }
  };

  /* function about click submit button in cancel action modal */
  handleCancelOkClick = () => {
    this.setState({
      pageState: { ...this.state.pageState, isCancelDisabled: true },
    });
    this.hideModal();
    this.props.closePage();
  };

  /* function about click cancel button in reject modal */
  handlePMPExcludedRejectCancelClick = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        rejectReason: null,
        rejectStatus: null,
      },
    });
    this.hideModal();
  };

  handleControlErrorScroll = (top) => window.scrollTo(0, top || 0);

  /* function about click submit button in approve page */
  handleApproveClick = () => {
    if (
      procedureInfoRef.current.state.currentPMPExcludedReasonSelect ===
        pmpExcludedReasonSelects.otherReason &&
      (procedureInfoRef.current.state.currentPMPExcludedReasonInput.trim() ===
        "" ||
        procedureInfoRef.current.state.currentPMPExcludedReasonInput === null)
    ) {
      procedureInfoRef.current.updatePMPExcludedReasonInputStatus("error");
    } else {
      this.handleCreateActionClickSectionCheckModal(
        "",
        this.modalContentLabels.approveConfirmModalLabel,
        this.handleApproveOkClick,
        this.hideModal
      );
    }
  };

  /* function about click reject button */
  handlePMPExcludedRejectClick = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: true,
        modalTitle: <span className="fs-18">Reject</span>,
        isShowTextArea: true,
        modalLabel: this.modalContentLabels.rejectModalWarningLabel,
        textAreaMaxLength: 1000,
        textAreaClassName: styles.rejectReasonInput,
        okText: "Confirm",
        cancelText: "Cancel",
        actionType: "reject",
        modalWidth: 520,
        handleCancel: this.handlePMPExcludedRejectCancelClick,
        handleOk: this.handlePMPExcludedRejectOkClick,
      },
    });
  };

  _checkStepPermissionSuccess = (result, actionType) => {
    this.props._hideLoading();
    const { statusCode } = result;
    const noPermission = statusCode !== 200;
    this.setState({
      noPermission,
    });
    if (noPermission) return;
    if (actionType === "approve") {
      this.handleApproveClick();
    } else if (actionType === "reject") {
      this.handlePMPExcludedRejectClick();
    }
  };

  checkStepPermission = (actionType) => {
    this.props._showLoading();
    appService.checkStepPermission(
      this.props.params,
      (response) => this._checkStepPermissionSuccess(response, actionType),
      (response) => console.log(response.message)
    );
  };

  /* function about close modal */
  hideModal = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        modalLabel: "",
        modalTitle: "",
        actionType: "",
        isModalWithComponentOpen: false,
        fileIds: [],
        fileNames: [],
        uploadDraggerFileType: "",
        modalWidth: 520,
        maskClosable: false,
        isShowTextArea: false,
        isShowSelect: false,
        isShowTextAreaAndUploadDragger: false,
        textAreaClassName: "",
        textAreaMaxLength: 0,
        textAreaPlacement: "",
      },
    });
  };

  /* function about click submit button in revise page to show the tip modal */
  handleCreateActionClickSectionCheckModal = (
    modalTitle,
    modalLabel,
    okMethod,
    cancelMethod
  ) => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        okText: "Confirm",
        cancelText: "Cancel",
        isModalWithComponentOpen: true,
        handleOk: okMethod,
        handleCancel: cancelMethod,
        modalLabel: modalLabel,
        modalWidth: 520,
        modalTitle: modalTitle,
      },
    });
  };

  /**
   * function about change reject state
   * @param {object} e
   */
  handleSetRejectValue = (e) => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        rejectReason: e.target.value,
        rejectStatus: null,
      },
    });
  };

  /**
   * function about click cancel button in page bottom
   */
  handleCancelClick = () => {
    this.handleCreateActionClickSectionCheckModal(
      "",
      this.modalContentLabels.cancelConfimModalLabel,
      this.handleCancelOkClick,
      this.hideModal
    );
  };

  /**
   * function about create cancel button in page buttom
   * @param {boolean} isCancelDisabled
   * @returns
   */
  handleReturnCancelButton = (isCancelDisabled) => (
    <Buttons
      color="grey"
      size="middle"
      onClick={this.handleCancelClick}
      disabled={isCancelDisabled}
      btnClassName={styles.approveActionBtn}
    >
      Cancel
    </Buttons>
  );

  /**
   * function about create approve button in page buttom
   * @param {boolean} isSubmitDisabled
   * @returns
   */
  handleReturnSubmitButton = (isSubmitDisabled) => (
    <Buttons
      color="blue"
      size="middle"
      onClick={() => this.checkStepPermission("approve")}
      disabled={isSubmitDisabled}
      btnClassName={styles.approveActionSubmitBtn}
    >
      Approve
    </Buttons>
  );

  /**
   * function about create reject button in page buttom
   * @param {boolean} isRejectDisabled
   * @returns
   */
  handleReturnRejectButton = (isRejectDisabled) => (
    <div className={styles.rejectBtn}>
      <Buttons
        color="red"
        size="middle"
        onClick={() => this.checkStepPermission("reject")}
        disabled={isRejectDisabled}
      >
        Reject
      </Buttons>
      <NormalToolTip
        element={
          <div className={styles.rejectTipIcon}>
            <img src={redTipIcon} alt="Reject" />
          </div>
        }
        title="Reject means that the process will be terminated. The status will be changed back to the original status before this initiation."
        color="#FF5858"
        overlayClassName={styles.rejectTip}
      />
    </div>
  );

  /**
   * function about create procedure information section
   */
  handleReturnProcedureInfoSection = () => {
    const { procedureInfoData, ppoList } = this.props.sectionProps;
    const { procedureStatus } = this.props.pageProps;
    return (
      <PMPExcludedProcedureInformation
        ref={procedureInfoRef}
        isShowActions={false}
        noteDisabled={true}
        ppoList={ppoList}
        procedureStatus={procedureStatus}
        isPPODisabled={true}
        showHintMsg={true}
        submitData={procedureInfoData}
        title="Procedure Information"
      />
    );
  };

  render() {
    const {
      rejectReason,
      isCancelDisabled,
      isSubmitDisabled,
      isRejectDisabled,
      rejectStatus,
    } = this.state.pageState;
    const { receiveDate, requestByName } = this.props.pageProps;
    const { serverPathValue, savedInRadioValue, supportingTableData } =
      this.props;
    const { taskInfoData, fileTableData, jbProcedureSavedIn } =
      this.props.sectionProps;
    const {
      modalTitle,
      modalLabel,
      okText,
      cancelText,
      closable,
      isOkBtnDisabled,
      isCancelBtnDisabled,
      maskClosable,
      isModalWithComponentOpen,
      isShowTextArea,
      textAreaPlacement,
      textAreaMaxLength,
      textAreaClassName,
      modalWidth,
      handleOk,
      handleCancel,
    } = this.state.modalState;
    const { noPermission } = this.state;
    const params = {
      TaskId: this.props.params.ProcedureTaskId,
      ProcedureID: this.props.params.ProcedureID,
      ProcedureStepID: this.props.params.ProcedureStepID,
    };

    return (
      <div className={styles.approveNewPro}>
        <PageHeader
          title="Approve PMP Excluded"
          receiveDate={this._formateDate(receiveDate)}
          requestBy={requestByName}
          status="Pending"
        />
        <ProcedureBlock
          className={styles.approveProcedureBlock}
          child={<TaskInformation data={taskInfoData} />}
          isShowArrow={true}
          isOpen={false}
          onShowDataLoad={() => {
            return StepService.getStepData(
              params,
              this.props.setTaskInfoData,
              this.setError,
              [StepBusinessType.taskInfo]
            );
          }}
        />
        <ProcedureBlock
          className={styles.approveProcedureBlock}
          child={
            <ReadOnlyProcedureFileSection
              showHintMsg={true}
              procedureFileRef={procedureFileRef}
              clientServerPath={serverPathValue}
              savedInRadioValue={savedInRadioValue}
              procedureFileData={
                fileTableData !== undefined ? fileTableData : []
              }
              jbProcedureSavedIn={jbProcedureSavedIn}
            />
          }
          isShowArrow={true}
          isOpen={false}
          onShowDataLoad={() => {
            return StepService.getStepData(
              params,
              this.props.setProcedureFileData,
              this.setError,
              [StepBusinessType.procedureFile]
            );
          }}
        />
        <ProcedureBlock
          child={
            <ReadOnlySupportingFileSection
              supportingFileData={supportingTableData}
              showHintMsg={true}
              supportingFileRef={supportingFileRef}
              supportingFileTableRef={supportingFileTableRef}
            />
          }
          isShowArrow={true}
          isOpen={false}
          onShowDataLoad={() => {
            return StepService.getStepData(
              params,
              this.props.setSupportingFileData,
              this.setError,
              [StepBusinessType.supportingFile]
            );
          }}
        />
        <ProcedureBlock
          className={`${styles.approveProcedureBlock} ${styles.excludedProcedureInfo}`}
          child={this.handleReturnProcedureInfoSection()}
          isShowArrow={true}
          isOpen={true}
        />
        <ProcedureBlock
          className={styles.approveProcedureBlock}
          child={
            <ActiveShareTaskReadOnly
              showHintMsg={true}
              shareTaskData={this.props.shareTaskData}
            />
          }
          isShowArrow={true}
          onShowDataLoad={() => {
            return StepService.getStepData(
              params,
              this.props.setShareTaskData,
              this.setError,
              [StepBusinessType.shareTask]
            );
          }}
        />
        <div className={styles.actionBtns}>
          <div className={styles.normalBtns}>
            {this.handleReturnCancelButton(isCancelDisabled)}
            {this.handleReturnSubmitButton(isSubmitDisabled)}
          </div>
          {this.handleReturnRejectButton(isRejectDisabled)}
        </div>
        <ModalWithComponent
          isModalOpen={isModalWithComponentOpen}
          closable={closable}
          okText={okText}
          cancelText={cancelText}
          title={modalTitle}
          handleOk={handleOk}
          handleCancel={handleCancel}
          isOkDisabled={isOkBtnDisabled}
          isCancelDisabled={isCancelBtnDisabled}
          maskClosable={maskClosable}
          isShowTextArea={isShowTextArea}
          textAreaValue={rejectReason}
          textAreaOnChange={this.handleSetRejectValue}
          textAreaPlacement={textAreaPlacement}
          textAreaMaxLength={textAreaMaxLength}
          textAreaStatus={rejectStatus}
          textAreaClassName={textAreaClassName}
          label={modalLabel}
          modalWidth={modalWidth}
        />
        <LostPermissionModal isModalOpen={noPermission} />
      </div>
    );
  }
}
