import React, { createRef } from "react";
import ProcedureFileSection from "../procedureFileSection";
import ReadOnlyFileManagement from "../../../commonComponents/fileManagementFields/readOnlyFileManagement";

const procedureFileTableRef = createRef();
export default class ReadOnlyCreateProcedureFileSection extends ProcedureFileSection {
  componentDidMount() {
    procedureFileTableRef.current !== null &&
      procedureFileTableRef.current.updateDataList(
        this.props.procedureFileData
      );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.procedureFileData !== this.props.procedureFileData) {
      procedureFileTableRef.current !== null &&
        procedureFileTableRef.current.updateDataList(
          this.props.procedureFileData
        );
    }
  }

  render() {
    const {
      procedureFileRef,
      clientServerPath,
      savedInRadioValue,
      showHintMsg,
      isCopyPage,
    } = this.props;
    return (
      <ReadOnlyFileManagement
        fileInputRef={procedureFileRef}
        fileTableRef={procedureFileTableRef}
        title={"Procedure Files"}
        clientPathShow={true}
        isScrollData={true}
        className="procedureFiles"
        isShowExpendIcon={true}
        isShowDownloadBtn={true}
        isShowShareBtn={false}
        isShowActionBtns={false}
        savedInDisabled={true}
        clientPathDisabled={true}
        noteDisabled={true}
        clientPath={clientServerPath}
        savedInRadioValue={savedInRadioValue}
        procedureSaveInValue={savedInRadioValue}
        hasSubmit={false}
        showHintMsg={showHintMsg}
        isCopyPage={isCopyPage}
      />
    );
  }
}
