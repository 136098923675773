import BaseShareTask from "../baseShareTask";
import ShareTaskTable from "../shareTaskTable";
import TaskInformation from "../../taskInfoSections";
import styles from "../index.module.scss";
import approveStyles from "./approveChangeMainTask.module.scss";
import "../../../commonComponents/readOnlyTable/antReadOnlyTableCustom.scss";
import NormalModal from "../../../../base/basicComponents/modal";

export default class ApproveChangeMainTask extends BaseShareTask {
  constructor(props) {
    super(props);
    this.state = this.initState(false, false);
  }

  componentDidUpdate(prevProps) {
    if (this.props.shareTaskData !== prevProps.shareTaskData) {
      this.setState({
        pendingApprovalData: this.transformPendingData(
          this.props.shareTaskData.shareTasksTableData
        ),
        isShowReturnReasonLogIcon:
          this.props.shareTaskData.returnReasonLogs?.length > 0,
        returnReasonLog: this.transformReturnReasonLogData(
          this.props.shareTaskData.returnReasonLogs
        ),
        requestReasonLog: this.transformRequestReasonLogData(
          this.props.shareTaskData.submitReasonLogs
        ),
      });
    }
  }

  render() {
    const {
      pendingApprovalData,
      isTaskInfoModalOpen,
      taskInformationData,
      isShowReturnReasonLogIcon,
      isReasonLogModalOpen,
      returnReasonLog,
      isRequestReasonLogModalOpen,
      requestReasonLog,
      reason,
    } = this.state;

    const pendingApprovalDataColumn = [
      this.changeMainTagText,
      ...this.commonShareDataColumn,
      this.tagColumn("6.5%"),
    ];

    return (
      <div className={styles.shareInformation}>
        <div className={approveStyles.approveShareInfoTop}>
          <div className={approveStyles.approveShareTitleDiv}>
            <span className={`${styles.shareTitle} fs-18`}>Shared Tasks</span>
          </div>
          {this.handleReasonLog(
            "View previous Return Reason",
            false,
            isShowReturnReasonLogIcon,
            false
          )}
        </div>
        <div className={styles.sharedTaskArea}>
          <ShareTaskTable
            data={pendingApprovalData}
            columns={pendingApprovalDataColumn}
          />
        </div>
        <div className={approveStyles.approveShareInfoTop}>
          <div className={approveStyles.approveShareTitleDiv}>
            <span className={`${styles.shareTitle} fs-14`}>Reason</span>
          </div>
          {this.handleReasonLog(
            "View previous Reason",
            false,
            isShowReturnReasonLogIcon,
            true
          )}
        </div>
        {this.handleReadOnlyReasonInput(reason)}
        <NormalModal
          centered
          footer={null}
          title="Task Information"
          isModalOpen={isTaskInfoModalOpen}
          handleCancel={this.HandleTaskInfoModalClose}
          width="96rem"
          childContent={
            <TaskInformation data={taskInformationData} isInModal={true} />
          }
          zIndex={990}
          className="taskInfoModal"
          closable={true}
        />
        {this.handleReasonLogModal(
          isReasonLogModalOpen,
          returnReasonLog,
          isRequestReasonLogModalOpen,
          requestReasonLog
        )}
      </div>
    );
  }
}
