import { Tooltip } from "antd";
import { GetColumnCheckboxGroupFilterDropdown } from "../../../commonComponents/listTable/CustomFilter";
import ListColumnBase from "../../../commonComponents/listBase/listColumnBase";
import DelegationField from "./delegationField";
import DelegatedReasonField from "./delegatedReasonField";
export default function ToDoListColumn({
  tab,
  toDoListFilterData,
  filteredInfo,
  transactionIdShow,
  delegateToOthersShow,
  taskLabelNameShow,
  actionCompletedDateShow,
  processCategoryShow,
  actionRequiredShow,
  delegatedByShow,
  delegatedToShow,
  actionTakenShow,
  dueDateShow,
  receiveDateShow,
  requestByShow,
  processInitiateDateShow,
  processInitiatorShow,
  accountAbbrShow,
  accountNameShow,
  taskGroupShow,
  categoryShow,
  taskIdShow,
  highlyRegulatedShow,
  tableSortData,
  toDoListData,
  setDelegatedValueToListData,
  handleColumnFilterClick,
  filterLoading,
}) {
  const {
    mapFilter,
    handleFilteredValue,
    handleWidth,
    handleTableSortOrder,
    formatDateTime,
    formatDate,
  } = ListColumnBase();
  const columns = [
    {
      title: "PMP Transaction ID",
      dataIndex: "transactionId",
      dbName: "TransactionID",
      filters: mapFilter(toDoListFilterData.TransactionId),
      filteredValue: handleFilteredValue(filteredInfo.transactionId),
      onFilterDropdownOpenChange: (e) => handleColumnFilterClick(e, 'TransactionId'),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      width: handleWidth(!transactionIdShow),
      ellipsis: {
        showTitle: false,
      },
      render: (transactionId) => (
        <Tooltip placement="topLeft" title={transactionId}>
          {transactionId}
        </Tooltip>
      ),
    },
    {
      title: "Delegate to Others",
      dataIndex: "delegateToOthers",
      dbName: "delegateToOthers",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) =>
        record.isAllowDelegate && (
          <DelegationField
            transactionId={record.transactionId}
            procedureTaskId={record.procedureTaskId}
            procedureStepId={record.procedureStepId}
            delegatedTo={record.delegateTo}
            delegatedToId={record.delegateToUserId}
            processCategory={record.processCategory}
            highlyRegulated={record.highlyRegulated}
            setDelegatedValueToListData={setDelegatedValueToListData}
            dataList={toDoListData}
          />
        ),
      width: handleWidth(!delegateToOthersShow),
      hidden: tab !== 0,
    },
    {
      title: "Task Label Name",
      dataIndex: "taskLabelName",
      dbName: "TaskLabelName",
      filters: mapFilter(toDoListFilterData.TaskLabelName),
      filteredValue: handleFilteredValue(filteredInfo.taskLabelName),
      onFilterDropdownOpenChange: (e) => handleColumnFilterClick(e, 'TaskLabelName'),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      sorter: true,
      sortOrder: handleTableSortOrder("TaskLabelName", tableSortData),
      width: handleWidth(!taskLabelNameShow),
      ellipsis: {
        showTitle: false,
      },
      render: (taskLabelName) => (
        <Tooltip placement="topLeft" title={taskLabelName}>
          {taskLabelName}
        </Tooltip>
      ),
    },
    {
      title: "Action Completed Date",
      dataIndex: "actionCompletedDate",
      dbName: "ActionCompletedDate",
      filters: mapFilter(toDoListFilterData.ActionCompletedDate),
      filteredValue: handleFilteredValue(filteredInfo.actionCompletedDate),
      onFilterDropdownOpenChange: (e) => handleColumnFilterClick(e, 'ActionCompletedDate'),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      sorter: true,
      sortOrder: handleTableSortOrder("ActionCompletedDate", tableSortData),
      width: handleWidth(!actionCompletedDateShow || tab === 0),
      ellipsis: {
        showTitle: false,
      },
      render: (actionCompletedDate) => (
        <Tooltip placement="topLeft" title={actionCompletedDate}>
          {actionCompletedDate}
        </Tooltip>
      ),
    },
    {
      title: "Process Category",
      dataIndex: "processCategory",
      dbName: "FlowCategoryGroupID",
      sortName: "ProcessCategory",
      sorter: true,
      sortOrder: handleTableSortOrder("ProcessCategory", tableSortData),
      width: handleWidth(!processCategoryShow),
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => (
        <Tooltip placement="topLeft" title={text}>
          <a
            href={record.processDetailUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {text}
          </a>
        </Tooltip>
      ),
    },
    {
      title: "Action Required",
      dataIndex: "actionRequired",
      dbName: "ActionRequiredID",
      sortName: "ActionRequired",
      sorter: true,
      sortOrder: handleTableSortOrder("ActionRequired", tableSortData),
      width: handleWidth(!actionRequiredShow || tab !== 0),
      ellipsis: {
        showTitle: false,
      },
      render: (actionRequired) => (
        <Tooltip placement="topLeft" title={actionRequired}>
          {actionRequired}
        </Tooltip>
      ),
    },
    {
      title: "Action Taken",
      dataIndex: "actionTaken",
      dbName: "ActionTakenID",
      sortName: "ActionTaken",
      sorter: true,
      sortOrder: handleTableSortOrder("ActionTaken", tableSortData),
      width: handleWidth(!actionTakenShow || tab === 0),
      ellipsis: {
        showTitle: false,
      },
      render: (actionTaken) => (
        <Tooltip placement="topLeft" title={actionTaken}>
          {actionTaken}
        </Tooltip>
      ),
    },
    {
      title: "Delegated by",
      dataIndex: "delegatedBy",
      dbName: "DelegatedByID",
      filters: mapFilter(toDoListFilterData.DelegatedBy),
      filteredValue: handleFilteredValue(filteredInfo.delegatedBy),
      onFilterDropdownOpenChange: (e) => handleColumnFilterClick(e, 'DelegatedBy'),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      width: handleWidth(!delegatedByShow),
      ellipsis: {
        showTitle: false,
      },
      render: (delegatedBy) => (
        <Tooltip placement="topLeft" title={delegatedBy}>
          {delegatedBy}
        </Tooltip>
      ),
    },
    {
      title: "Delegated to",
      dataIndex: "delegateTo",
      dbName: "DelegatedToID",
      filters: mapFilter(toDoListFilterData.DelegatedTo),
      filteredValue: handleFilteredValue(filteredInfo.delegateTo),
      onFilterDropdownOpenChange: (e) => handleColumnFilterClick(e, 'DelegatedTo'),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      width: handleWidth(!delegatedToShow),
      ellipsis: {
        showTitle: false,
      },
      render: (delegateTo, record) => (
        <div className="delegetedTo">
          <Tooltip placement="topLeft" title={delegateTo}>
            {delegateTo}
          </Tooltip>
          {delegateTo && (
            <DelegatedReasonField
              reason={record.delegateReason}
              delegateTo={delegateTo}
            />
          )}
        </div>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      dbName: "DueDate",
      sorter: true,
      sortOrder: handleTableSortOrder("DueDate", tableSortData),
      width: handleWidth(!dueDateShow || tab !== 0),
      ellipsis: {
        showTitle: false,
      },
      render: (dueDate) => (
        <Tooltip placement="topLeft" title={formatDateTime(dueDate)}>
          {formatDateTime(dueDate)}
        </Tooltip>
      ),
    },
    {
      title: "Receive Date",
      dataIndex: "receiveDate",
      dbName: "ReceiveDate",
      filters: mapFilter(toDoListFilterData.ReceiveDate, true, formatDate),
      filteredValue: handleFilteredValue(filteredInfo.receiveDate),
      onFilterDropdownOpenChange: (e) => handleColumnFilterClick(e, 'ReceiveDate'),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      sorter: true,
      sortOrder: handleTableSortOrder("ReceiveDate", tableSortData),
      width: handleWidth(!receiveDateShow),
      ellipsis: {
        showTitle: false,
      },
      render: (receiveDate) => (
        <Tooltip placement="topLeft" title={formatDate(receiveDate)}>
          {formatDate(receiveDate)}
        </Tooltip>
      ),
    },
    {
      title: "Request By",
      dataIndex: "requestBy",
      dbName: "RequestBy",
      filters: mapFilter(toDoListFilterData.RequestBy),
      filteredValue: handleFilteredValue(filteredInfo.requestBy),
      onFilterDropdownOpenChange: (e) => handleColumnFilterClick(e, 'RequestBy'),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      width: handleWidth(!requestByShow),
      ellipsis: {
        showTitle: false,
      },
      render: (requestBy) => (
        <Tooltip placement="topLeft" title={requestBy}>
          {requestBy}
        </Tooltip>
      ),
    },
    {
      title: "Process Initiate Date",
      dataIndex: "processInitiateDate",
      dbName: "ProcessInitiateDate",
      filters: mapFilter(
        toDoListFilterData.ProcessInitiateDate,
        true,
        formatDate
      ),
      filteredValue: handleFilteredValue(filteredInfo.processInitiateDate),
      onFilterDropdownOpenChange: (e) => handleColumnFilterClick(e, 'ProcessInitiateDate'),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      sorter: true,
      sortOrder: handleTableSortOrder("ProcessInitiateDate", tableSortData),
      width: handleWidth(!processInitiateDateShow),
      ellipsis: {
        showTitle: false,
      },
      render: (processInitiateDate) => (
        <Tooltip placement="topLeft" title={formatDate(processInitiateDate)}>
          {formatDate(processInitiateDate)}
        </Tooltip>
      ),
    },
    {
      title: "Process Initiator",
      dataIndex: "processInitiator",
      dbName: "ProcessInitiator",
      filters: mapFilter(toDoListFilterData.ProcessInitiator),
      filteredValue: handleFilteredValue(filteredInfo.processInitiator),
      onFilterDropdownOpenChange: (e) => handleColumnFilterClick(e, 'ProcessInitiator'),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      width: handleWidth(!processInitiatorShow),
      ellipsis: {
        showTitle: false,
      },
      render: (processInitiator) => (
        <Tooltip placement="topLeft" title={processInitiator}>
          {processInitiator}
        </Tooltip>
      ),
    },
    {
      title: "Account Code",
      dataIndex: "accountAbbreviation",
      dbName: "AccountID",
      sortName: "AccountAbbr",
      sorter: true,
      sortOrder: handleTableSortOrder("AccountAbbr", tableSortData),
      width: handleWidth(!accountAbbrShow),
      ellipsis: {
        showTitle: false,
      },
      render: (accountAbbreviation) => (
        <Tooltip placement="topLeft" title={accountAbbreviation}>
          {accountAbbreviation}
        </Tooltip>
      ),
    },
    {
      title: "Account Name",
      dataIndex: "accountName",
      dbName: "AccountName",
      filters: mapFilter(toDoListFilterData.AccountName),
      filteredValue: handleFilteredValue(filteredInfo.accountName),
      onFilterDropdownOpenChange: (e) => handleColumnFilterClick(e, 'AccountName'),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      ellipsis: {
        showTitle: false,
      },
      render: (accountName) => (
        <Tooltip placement="topLeft" title={accountName}>
          {accountName}
        </Tooltip>
      ),
      width: handleWidth(!accountNameShow),
    },
    {
      title: "Task Group",
      dataIndex: "taskGroup",
      dbName: "ProcedureTaskGroupID",
      sortName: "TaskGroupName",
      filters: mapFilter(toDoListFilterData.TaskGroup),
      filteredValue: handleFilteredValue(filteredInfo.taskGroup),
      onFilterDropdownOpenChange: (e) => handleColumnFilterClick(e, 'TaskGroup'),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      sorter: true,
      sortOrder: handleTableSortOrder("TaskGroupName", tableSortData),
      width: handleWidth(!taskGroupShow),
      ellipsis: {
        showTitle: false,
      },
      render: (taskGroup) => (
        <Tooltip placement="topLeft" title={taskGroup}>
          {taskGroup}
        </Tooltip>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      dbName: "Path",
      sortName: "Path",
      filters: mapFilter(toDoListFilterData.Category),
      filteredValue: handleFilteredValue(filteredInfo.category),
      onFilterDropdownOpenChange: (e) => handleColumnFilterClick(e, 'Category'),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      sorter: true,
      sortOrder: handleTableSortOrder("Path", tableSortData),
      width: handleWidth(!categoryShow),
      ellipsis: {
        showTitle: false,
      },
      render: (category) => (
        <Tooltip placement="topLeft" title={category}>
          {category}
        </Tooltip>
      ),
    },
    {
      title: "Task ID",
      dataIndex: "taskId",
      dbName: "TaskID",
      filters: mapFilter(toDoListFilterData.TaskID),
      filteredValue: handleFilteredValue(filteredInfo.taskId),
      onFilterDropdownOpenChange: (e) => handleColumnFilterClick(e, 'TaskID'),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      width: handleWidth(!taskIdShow),
      ellipsis: {
        showTitle: false,
      },
      render: (taskId) => (
        <Tooltip placement="topLeft" title={taskId}>
          {taskId}
        </Tooltip>
      ),
    },
    {
      title: "Highly Regulated?",
      dataIndex: "highlyRegulated",
      dbName: "HighRegulated",
      filters: mapFilter(toDoListFilterData.HighlyRegulated),
      filteredValue: handleFilteredValue(filteredInfo.highlyRegulated),
      onFilterDropdownOpenChange: (e) => handleColumnFilterClick(e, 'HighlyRegulated'),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      width: handleWidth(!highlyRegulatedShow),
      ellipsis: {
        showTitle: false,
      },
      render: (highlyRegulated) => (
        <Tooltip placement="topLeft" title={highlyRegulated}>
          {highlyRegulated}
        </Tooltip>
      ),
    },
    {
      title: "",
      dataIndex: "space",
      render: () => {},
      width: 1,
    },
  ];

  return columns;
}
