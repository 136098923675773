import React, { createRef } from "react";
import SupportingFileProcess from "..";
import {
  handleUpdateReviseSortFile,
  reviseUpdateSortFilesFollowFileTag,
  downloadSingleFile,
  flowCategoryId,
} from "../../../publicDictionaryValues";
import NormalSpin from "../../../../base/basicComponents/spin";
import ApproveUpdateSupportingFilePending from "./approveUpdateSupportingFilePending";
import ApproveUpdateSupportingFileComplete from "./approveUpdateSupportingFileComplete";
import ReviseUpdateSupportingFilePending from "./reviseUpdateSupportingFilePending";
import ReviseUpdateSupportingFileComplete from "./reviseUpdateSupportingFileComplete";
import UpdateSupportingFileSubmitComplete from "./updateSupportingFileSubmitComplete";
import styles from "../style/index.module.scss";

const supportingRef = createRef();
const approvePageRef = createRef();
const supportingReplaceFileRef = createRef();
export default class UpdateSupportingFileProcess extends SupportingFileProcess {
  /* sort supporting files follow status in revise page */
  handleSortFile = (result) => {
    let isRevise = false;
    if (
      result.workflowProcessStepInfo !== null &&
      result.workflowProcessStepInfo.annotations.length !== 0
    ) {
      isRevise =
        result.workflowProcessStepInfo.annotations.find(
          (item) => (item.name = "IsRevise")
        ).value === "1";
    }
    const tiggerTimes = result.sequence;
    if (tiggerTimes > 1) {
      return handleUpdateReviseSortFile(
        result.supportingFolder.supportingFiles,
        this._formateDate,
        isRevise
      );
    }
    return reviseUpdateSortFilesFollowFileTag(
      result.supportingFolder.supportingFiles,
      this._formateDate
    );
  };

  handleUpdateSupportingFileProcessPageType = () => {
    const { procedureStepStatus } = this.state.pageState;

    this.isSubmitComplete =
      this.isRevisePage &&
      procedureStepStatus === "Complete" &&
      this.triggerTimes === 1;
    this.isApprovePending =
      !this.isRevisePage && procedureStepStatus === "Pending";
    this.isApproveComplete =
      !this.isRevisePage && procedureStepStatus === "Complete";
    this.isRevisePending =
      this.isRevisePage &&
      procedureStepStatus === "Pending" &&
      this.tiggerTimes > 1;
    this.isReviseComplete =
      this.isRevisePage &&
      procedureStepStatus === "Complete" &&
      this.tiggerTimes > 1;
  };

  handleUpdateSupportingFileProcessServerPathValue = () => {
    return this.state.serverPathSectionState !== undefined
      ? this.state.serverPathSectionState.serverPathValue
      : "";
  };

  handleUpdateSupportingFileProcessSavedInRadioValue = () => {
    return this.state.savedInSectionState !== undefined
      ? this.state.savedInSectionState.savedInRadioValue
      : null;
  };

  handleUpdateSupportingFileProcessNewRejectReason = () => {
    const { rejectReason } = this.state.pageState;

    const replaceRegex = /(\n\r|\r\n|\r|\n)/g;
    if (rejectReason !== undefined && rejectReason !== null) {
      return rejectReason.replace(replaceRegex, "<br/>");
    }

    return rejectReason;
  };

  handleUpdateSupportingFileProcessNewSupportingTableData = () => {
    const { supportingTableData } = this.state;
    return JSON.parse(JSON.stringify(supportingTableData));
  };

  handleUpdateSupportingFileProcessParams = () => {
    return {
      ProcedureTaskId: this.taskID,
      ProcedureID: this.procedureID,
      ProcedureStepID: this.procedureStepID,
    };
  };

  handleUpdateSupportingFileProcessPageProps = () => {
    const {
      procedureStatus,
      receiveDate,
      requestByName,
      rejectStatus,
      flowCategoryID,
    } = this.state.pageState;
    let newRejectReason =
      this.handleUpdateSupportingFileProcessNewRejectReason();

    return {
      procedureStatus,
      receiveDate,
      requestByName,
      newRejectReason,
      rejectStatus,
      supportingReplaceFileRef,
      flowCategoryID,
    };
  };

  handleUpdateSupportingFileProcessSectionProps = () => {
    const { jbProcedureSavedIn } = this.state;
    const { taskInfoData, procedureInfoData, ppoList, fileTableData } =
      this.state;

    return {
      taskInfoData,
      procedureInfoData,
      ppoList,
      fileTableData,
      jbProcedureSavedIn,
    };
  };

  handleUpdateSupportingFileProcessSubmitComplete = () => {
    const { isMount } = this.state;
    const { shareTaskData } = this.state;
    const serverPathValue =
      this.handleUpdateSupportingFileProcessServerPathValue();
    const savedInRadioValue =
      this.handleUpdateSupportingFileProcessSavedInRadioValue();
    const newSupportingTableData =
      this.handleUpdateSupportingFileProcessNewSupportingTableData();

    const isCopyPage =
      this.state.pageState.flowCategoryID &&
      this.state.pageState.flowCategoryID === flowCategoryId.copySupportingFile;

    return (
      <UpdateSupportingFileSubmitComplete
        params={this.handleUpdateSupportingFileProcessParams()}
        pageProps={this.handleUpdateSupportingFileProcessPageProps()}
        sectionProps={this.handleUpdateSupportingFileProcessSectionProps()}
        shareTaskData={shareTaskData}
        supportingRef={supportingRef}
        _showLoading={this._showLoading}
        _hideLoading={this._hideLoading}
        initSupportingTableData={[...this.initSupportingTableData]}
        data={newSupportingTableData}
        serverPathValue={serverPathValue}
        savedInRadioValue={savedInRadioValue}
        handleReturnSubmitData={this.handleReturnSubmitData}
        downloadFile={downloadSingleFile}
        isMount={isMount}
        isCopyPage={isCopyPage}
        setProcedureFileData={this.setProcedureFileData}
        setSupportingFileData={this.setSupportingFileData}
        setTaskInfoData={this.setTaskInfoData}
        setProcedureInfoData={this.setProcedureInfoData}
        setShareTaskData={this.setShareTaskData}
      />
    );
  };

  handleUpdateSupportingFileProcessApprovePending = () => {
    const { isMount } = this.state;
    const { shareTaskData } = this.state;
    const serverPathValue =
      this.handleUpdateSupportingFileProcessServerPathValue();
    const savedInRadioValue =
      this.handleUpdateSupportingFileProcessSavedInRadioValue();
    const newSupportingTableData =
      this.handleUpdateSupportingFileProcessNewSupportingTableData();

    return (
      <ApproveUpdateSupportingFilePending
        params={this.handleUpdateSupportingFileProcessParams()}
        pageProps={{
          ...this.handleUpdateSupportingFileProcessPageProps(),
          isDisableAll: false,
        }}
        sectionProps={this.handleUpdateSupportingFileProcessSectionProps()}
        shareTaskData={shareTaskData}
        supportingRef={supportingRef}
        _showLoading={this._showLoading}
        _hideLoading={this._hideLoading}
        initSupportingTableData={[...this.initSupportingTableData]}
        data={newSupportingTableData}
        serverPathValue={serverPathValue}
        savedInRadioValue={savedInRadioValue}
        handleReturnSubmitData={this.handleReturnSubmitData}
        ref={approvePageRef}
        approvePageRef={approvePageRef}
        closePage={this.closePage}
        downloadFile={downloadSingleFile}
        isMount={isMount}
        setProcedureFileData={this.setProcedureFileData}
        setSupportingFileData={this.setSupportingFileData}
        setTaskInfoData={this.setTaskInfoData}
        setProcedureInfoData={this.setProcedureInfoData}
        setShareTaskData={this.setShareTaskData}
      />
    );
  };

  handleUpdateSupportingFileProcessApproveComplete = () => {
    const { isMount } = this.state;
    const { shareTaskData } = this.state;
    const serverPathValue =
      this.handleUpdateSupportingFileProcessServerPathValue();
    const savedInRadioValue =
      this.handleUpdateSupportingFileProcessSavedInRadioValue();
    const newSupportingTableData =
      this.handleUpdateSupportingFileProcessNewSupportingTableData();

    const isCopyPage =
      this.state.pageState.flowCategoryID &&
      this.state.pageState.flowCategoryID === flowCategoryId.copySupportingFile;

    return (
      <ApproveUpdateSupportingFileComplete
        params={this.handleUpdateSupportingFileProcessParams()}
        pageProps={{
          ...this.handleUpdateSupportingFileProcessPageProps(),
          isDisableAll: true,
          actionID: this.actionID,
        }}
        sectionProps={this.handleUpdateSupportingFileProcessSectionProps()}
        shareTaskData={shareTaskData}
        supportingRef={supportingRef}
        _showLoading={this._showLoading}
        _hideLoading={this._hideLoading}
        initSupportingTableData={[...this.initSupportingTableData]}
        data={newSupportingTableData}
        serverPathValue={serverPathValue}
        savedInRadioValue={savedInRadioValue}
        ref={approvePageRef}
        approvePageRef={approvePageRef}
        downloadFile={downloadSingleFile}
        updateSupportingFileNum={this.updateSupportingFileNum}
        isMount={isMount}
        isCopyPage={isCopyPage}
        setProcedureFileData={this.setProcedureFileData}
        setSupportingFileData={this.setSupportingFileData}
        setTaskInfoData={this.setTaskInfoData}
        setProcedureInfoData={this.setProcedureInfoData}
        setShareTaskData={this.setShareTaskData}
      />
    );
  };

  handleUpdateSupportingFileProcessRevisePending = () => {
    const { isMount } = this.state;
    const { shareTaskData } = this.state;
    const serverPathValue =
      this.handleUpdateSupportingFileProcessServerPathValue();
    const savedInRadioValue =
      this.handleUpdateSupportingFileProcessSavedInRadioValue();
    const newSupportingTableData =
      this.handleUpdateSupportingFileProcessNewSupportingTableData();

    return (
      <ReviseUpdateSupportingFilePending
        params={this.handleUpdateSupportingFileProcessParams()}
        pageProps={{
          ...this.handleUpdateSupportingFileProcessPageProps(),
          actionID: this.actionID,
          isDisableAll: false,
        }}
        sectionProps={this.handleUpdateSupportingFileProcessSectionProps()}
        shareTaskData={shareTaskData}
        supportingRef={supportingRef}
        _showLoading={this._showLoading}
        _hideLoading={this._hideLoading}
        initSupportingTableData={[...this.initSupportingTableData]}
        data={newSupportingTableData}
        serverPathValue={serverPathValue}
        savedInRadioValue={savedInRadioValue}
        ref={approvePageRef}
        approvePageRef={approvePageRef}
        handleReturnSubmitData={this.handleReturnSubmitData}
        closePage={this.closePage}
        downloadFile={downloadSingleFile}
        updateSupportingFileNum={this.updateSupportingFileNum}
        isMount={isMount}
        setProcedureFileData={this.setProcedureFileData}
        setSupportingFileData={this.setSupportingFileData}
        setTaskInfoData={this.setTaskInfoData}
        setProcedureInfoData={this.setProcedureInfoData}
        setShareTaskData={this.setShareTaskData}
      />
    );
  };

  handleUpdateSupportingFileProcessReviseComplete = () => {
    const { isMount } = this.state;
    const { shareTaskData } = this.state;
    const serverPathValue =
      this.handleUpdateSupportingFileProcessServerPathValue();
    const savedInRadioValue =
      this.handleUpdateSupportingFileProcessSavedInRadioValue();
    const newSupportingTableData =
      this.handleUpdateSupportingFileProcessNewSupportingTableData();

    const isCopyPage =
      this.state.pageState.flowCategoryID &&
      this.state.pageState.flowCategoryID === flowCategoryId.copySupportingFile;

    return (
      <ReviseUpdateSupportingFileComplete
        params={this.handleUpdateSupportingFileProcessParams()}
        pageProps={{
          ...this.handleUpdateSupportingFileProcessPageProps(),
          isDisableAll: true,
          actionID: this.actionID,
        }}
        sectionProps={this.handleUpdateSupportingFileProcessSectionProps()}
        shareTaskData={shareTaskData}
        supportingRef={supportingRef}
        _showLoading={this._showLoading}
        _hideLoading={this._hideLoading}
        initSupportingTableData={[...this.initSupportingTableData]}
        data={newSupportingTableData}
        serverPathValue={serverPathValue}
        savedInRadioValue={savedInRadioValue}
        downloadFile={downloadSingleFile}
        isMount={isMount}
        isCopyPage={isCopyPage}
        setProcedureFileData={this.setProcedureFileData}
        setSupportingFileData={this.setSupportingFileData}
        setTaskInfoData={this.setTaskInfoData}
        setProcedureInfoData={this.setProcedureInfoData}
        setShareTaskData={this.setShareTaskData}
      />
    );
  };

  render() {
    const { loading, supportingFileNum, uploadSupportingFileNum } =
      this.state.pageState;

    this.handleUpdateSupportingFileProcessPageType();

    return (
      <NormalSpin
        spinning={loading}
        size="large"
        indicator={
          supportingFileNum > 0 ? (
            <p className={styles.loadingText}>
              uploading {uploadSupportingFileNum}/{supportingFileNum} file
            </p>
          ) : undefined
        }
        children={
          <>
            {this.isSubmitComplete &&
              this.handleUpdateSupportingFileProcessSubmitComplete()}
            {this.isApprovePending &&
              this.handleUpdateSupportingFileProcessApprovePending()}
            {this.isApproveComplete &&
              this.handleUpdateSupportingFileProcessApproveComplete()}
            {this.isRevisePending &&
              this.handleUpdateSupportingFileProcessRevisePending()}
            {this.isReviseComplete &&
              this.handleUpdateSupportingFileProcessReviseComplete()}
          </>
        }
      />
    );
  }
}
