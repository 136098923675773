import { Radio } from "antd";
import {
  dataStatus,
  savedInRadioValues,
} from "../../../../../publicDictionaryValues";
import ReviseProcedureSavedIn from "../reviseSavedIn";
import styles from "../index.module.scss";

export default class ReviseUpdateSavedIn extends ReviseProcedureSavedIn {
  /* funtion about click confirm when saved in radio button changed to client server only */
  handleChangeSavedInCofirm = (value) => {
    this.props.updateSavedInRadioValue !== undefined &&
      this.props.updateSavedInRadioValue(
        value,
        this.state.savedInSectionState.savedInRadioValue
      );
    this.setState({
      savedInSectionState: {
        ...this.state.savedInSectionState,
        savedInRadioValue: value,
      },
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: false,
        modalLabel: "",
        okText: "",
      },
    });
  };

  /* funtion about saved in radio button change */
  handleRadioClick = (e) => {
    let fileList = [];
    let currentValue = null;
    if (this.props.procedureRef.current !== null) {
      fileList = this.props.procedureRef.current.getDataList();
    }
    if (e.target.value !== this.state.savedInSectionState.savedInRadioValue) {
      currentValue = e.target.value;
    }
    if (
      e.target.value === savedInRadioValues.serverOnly &&
      fileList.length > 0
    ) {
      this.setState({
        modalState: {
          ...this.state.modalState,
          isModalWithComponentOpen: true,
          modalLabel: (
            <span className={styles.modalText}>
              You are switching the <b>Procedure Saved In</b> to{" "}
              <b>Client Server Only</b>. Please note that if you want to change
              it to <b>PMP System Only</b> or <b>PMP System & Client Server</b>{" "}
              in future revisions, the approved files listed cannot be revised.
              Please double check.
            </span>
          ),
          okText: "Confirm",
          handleOk: () => this.handleChangeSavedInCofirm(e.target.value),
          handleCancel: this.hideModal,
        },
      });
    } else {
      this.props.updateSavedInRadioValue !== undefined &&
        this.props.updateSavedInRadioValue(
          currentValue,
          this.state.savedInSectionState.savedInRadioValue
        );
      this.setState({
        savedInSectionState: {
          ...this.state.savedInSectionState,
          savedInRadioValue: currentValue,
        },
      });
    }
  };

  handleReturnSavedInRadios = (obj) => {
    const { procedureSaveInCategoryStatusID, savedInRadioValue, isDisableAll } =
      obj;
    return (
      <Radio.Group
        value={savedInRadioValue}
        disabled={
          procedureSaveInCategoryStatusID === dataStatus.approval ||
          isDisableAll
        }
      >
        <Radio
          value={savedInRadioValues.systemOnly}
          onClick={(e) => this.handleRadioClick(e)}
        >
          PMP System Only
        </Radio>
        <Radio
          value={savedInRadioValues.systemAndClientServer}
          onClick={(e) => this.handleRadioClick(e)}
        >
          PMP System & Client Server
        </Radio>
        <Radio
          value={savedInRadioValues.serverOnly}
          onClick={(e) => this.handleRadioClick(e)}
        >
          Client Server Only
        </Radio>
      </Radio.Group>
    );
  };
}
